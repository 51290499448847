import { mode } from "@chakra-ui/theme-tools";

export const Button = {
  baseStyle: {
    border: 0,
  },
  sizes: {},
  variants: {
    primary: (props: any) => ({
      backgroundColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      color: mode("white", "ssPrimaryDark")(props),
      _hover: {
        backgroundColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
          color: mode("white", "ssPrimaryDark")(props),
          transform: "none",
        },
      },
    }),
    primaryOutline: (props: any) => ({
      backgroundColor: "transparent",
      color: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      border: "1px",
      borderColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      _hover: {
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: "transparent",
          color: mode("ssPrimaryDark", "ssPrimaryLight")(props),
          transform: "none",
        },
      },
    }),
    orange: (props: any) => ({
      backgroundColor: mode("ssNeonOrangeDark", "ssNeonOrangeLight")(props),
      color: mode("white", "ssPrimaryDark")(props),
      _hover: {
        backgroundColor: mode("ssNeonOrangeDark", "ssNeonOrangeLight")(props),
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: mode("ssNeonOrangeDark", "ssNeonOrangeLight")(props),
          color: mode("white", "ssPrimaryDark")(props),
          transform: "none",
        },
      },
    }),
    orangeOutline: (props: any) => ({
      backgroundColor: "transparent",
      color: mode("ssNeonOrangeDark", "ssNeonOrangeLight")(props),
      border: "1px",
      borderColor: mode("ssNeonOrangeDark", "ssNeonOrangeLight")(props),
      _hover: {
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: "transparent",
          color: mode("ssNeonOrangeDark", "ssNeonOrangeLight")(props),
          transform: "none",
        },
      },
    }),
    green: (props: any) => ({
      backgroundColor: mode("ssNeonGreenDark", "ssNeonGreenLight")(props),
      color: mode("white", "ssPrimaryDark")(props),
      _hover: {
        backgroundColor: mode("ssNeonGreenDark", "ssNeonGreenLight")(props),
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: mode("ssNeonGreenDark", "ssNeonGreenLight")(props),
          color: mode("white", "ssPrimaryDark")(props),
          transform: "none",
        },
      },
    }),
    greenOutline: (props: any) => ({
      backgroundColor: "transparent",
      color: mode("ssNeonGreenDark", "ssNeonGreenLight")(props),
      border: "1px",
      borderColor: mode("ssNeonGreenDark", "ssNeonGreenLight")(props),
      _hover: {
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: "transparent",
          color: mode("ssNeonGreenDark", "ssNeonGreenLight")(props),
          transform: "none",
        },
      },
    }),
    pink: (props: any) => ({
      backgroundColor: mode("ssNeonPinkDark", "ssNeonPinkLight")(props),
      color: mode("white", "ssPrimaryDark")(props),
      _hover: {
        backgroundColor: mode("ssNeonPinkDark", "ssNeonPinkLight")(props),
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: mode("ssNeonPinkDark", "ssNeonPinkLight")(props),
          color: mode("white", "ssPrimaryDark")(props),
          transform: "none",
        },
      },
    }),
    pinkOutline: (props: any) => ({
      backgroundColor: "transparent",
      color: mode("ssNeonPinkDark", "ssNeonPinkLight")(props),
      border: "1px",
      borderColor: mode("ssNeonPinkDark", "ssNeonPinkLight")(props),
      _hover: {
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: "transparent",
          color: mode("ssNeonPinkDark", "ssNeonPinkLight")(props),
          transform: "none",
        },
      },
    }),
    warning: (props: any) => ({
      backgroundColor: "red.500",
      color: "white",
      _hover: {
        backgroundColor: mode("red.500", "red.500")(props),
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: "red.500",
          color: "white",
          transform: "none",
        },
      },
    }),
    warningOutline: (props: any) => ({
      backgroundColor: "transparent",
      color: mode("red.500", "red.500")(props),
      border: "1px",
      borderColor: mode("red.500", "red.500")(props),
      _hover: {
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: "transparent",
          color: mode("red.500", "red.500")(props),
          transform: "none",
        },
      },
    }),
    success: (props: any) => ({
      backgroundColor: "green.500",
      color: "white",
      _hover: {
        backgroundColor: mode("green.500", "green.500")(props),
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: "green.500",
          color: "white",
          transform: "none",
        },
      },
    }),
    successOutline: (props: any) => ({
      backgroundColor: "transparent",
      color: mode("green.500", "green.500")(props),
      border: "1px",
      borderColor: mode("green.500", "green.500")(props),
      _hover: {
        boxShadow: "md",
        transform: "scale(1.05)",
        _disabled: {
          backgroundColor: "transparent",
          color: mode("green.500", "green.500")(props),
          transform: "none",
        },
      },
    }),
    link: (props: any) => ({
      color: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      _hover: {
        color: mode("ssPrimaryLight", "white")(props),
      },
    }),
    menuLink: (props: any) => ({
      color: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      _hover: {
        color: mode("ssPrimaryLight", "white")(props),
      },
    }),
  },
  defaultProps: {
    variant: "primary",
  },
};
