import {
  Box,
  Center,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { decodeJwt } from "jose";
import { useEffect, useState } from "react";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { SSAccessJWTPayloadRaw, SSRefreshJWTPayload } from "../../types/auth";

const VersionBanner = () => {
  const bannerColour = useColorModeValue("ssNeonPinkLight", "ssNeonPinkDark");
  const {
    access: accessToken,
    refresh: refreshToken,
    admin: adminToken,
    forceChangePassword,
  } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [accessTokenExpiry, setAccessTokenExpiry] = useState<string | null>(
    null
  );
  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [refreshTokenExpiry, setRefreshTokenExpiry] = useState<string | null>(
    null
  );
  const [adminTokenExpiry, setAdminTokenExpiry] = useState<string | null>(null);

  const breakpoint = useBreakpointValue({
    base: "Base",
    sm: "Small",
    md: "Medium",
    lg: "Large",
    xl: "Extra Large",
    "2xl": "2X Large",
  });

  useEffect(() => {
    const handleResize = () => {
      setResolution({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // If admin decode Tokens to get expiries
    if (accessToken) {
      const at = decodeJwt(accessToken) as unknown as SSAccessJWTPayloadRaw;
      at.roles = at.roles.map((role: string) => {
        return JSON.parse(role);
      });
      if (at && at.exp) {
        const AtExpiry = new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        }).format(new Date(new Date(at.exp * 1000)));
        setAccessTokenExpiry(AtExpiry);
      } else {
        setAccessTokenExpiry(null);
      }
    }
    if (refreshToken) {
      const rt = decodeJwt(refreshToken) as unknown as SSRefreshJWTPayload;
      if (rt && rt.exp) {
        const RtExpiry = new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        }).format(new Date(new Date(rt.exp * 1000)));
        setRefreshTokenExpiry(RtExpiry);
      } else {
        setRefreshTokenExpiry(null);
      }
    }
    if (adminToken) {
      const aDt = decodeJwt(adminToken) as unknown as SSRefreshJWTPayload;
      if (aDt && aDt.exp) {
        const AdtExpiry = new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        }).format(new Date(new Date(aDt.exp * 1000)));
        setAdminTokenExpiry(AdtExpiry);
      } else {
        setAdminTokenExpiry(null);
      }
    }
  }, [accessToken, refreshToken, adminToken]);

  if (process.env.REACT_APP_ENV === "alpha") {
    return (
      <Box bg={bannerColour} w="full" py={2}>
        <Center>
          {process.env.REACT_APP_ENV?.toUpperCase()} | {user?._id} |{" "}
          {accessTokenExpiry} | {refreshTokenExpiry} | {adminTokenExpiry} |{" "}
          {forceChangePassword?.toString()} | {user?.data.billing.source} |{" "}
          {user?.data.billing.level} | {resolution.width}x{resolution.height} |{" "}
          {breakpoint}
        </Center>
      </Box>
    );
  }
  return null;
};

export { VersionBanner };
