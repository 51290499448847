import { BoxProps, Button, Td, Tr, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { checkRole } from "../../utils/authHelper";
import { dp } from "../../utils/coreHelper";
import { formatDuration, formatPace } from "../../utils/dateHelper";
import { StrokeIcon } from "../svg/StrokeIcon";

interface ActivityDetailSwimLapProps extends BoxProps {
  lap: any;
  swimIndex: number;
  lapIndex: number;
}

const ActivityDetailSwimLap: React.FC<ActivityDetailSwimLapProps> = ({
  lap,
  swimIndex,
  lapIndex,
}) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );

  const navigate = useNavigate();
  const authIntensity = checkRole(accessToken, "intensity", "activity");
  const authStrokeRate = checkRole(accessToken, "strokeRate", "activity");
  const authLapPace = checkRole(accessToken, "lapPace", "activity");

  const upgradeAction = () => {
    navigate("/account/subscription", { replace: false });
  };

  return useBreakpointValue({
    base: (
      <Tr key={"swim" + swimIndex + "lap" + lapIndex}>
        <Td isNumeric>{lap.index + 1}</Td>
        <Td isNumeric>
          {lap.distance}
          {lap.distanceUnit}
        </Td>
        <Td>
          <StrokeIcon strokes={[lap.stroke]} size={30} />
        </Td>
        <Td isNumeric>
          {authStrokeRate && lap.cadence > -1 ? (
            dp(lap.cadence, 0)
          ) : !authStrokeRate ? (
            <Button onClick={upgradeAction} size={"xs"}>
              Upgrade
            </Button>
          ) : (
            "-"
          )}
        </Td>
        <Td>
          {authIntensity && lap.intensity !== "unknown" ? (
            lap.intensity
          ) : !authIntensity ? (
            <Button onClick={upgradeAction} size={"xs"}>
              Upgrade
            </Button>
          ) : (
            "-"
          )}
        </Td>
        <Td isNumeric>{formatDuration(lap.duration, true)}</Td>
      </Tr>
    ),
    md: (
      <Tr key={"swim" + swimIndex + "lap" + lapIndex}>
        <Td isNumeric>{lap.index + 1}</Td>
        <Td isNumeric>
          {lap.distance}
          {lap.distanceUnit}
        </Td>
        <Td isNumeric>
          {lap.cumulativeDistance}
          {lap.distanceUnit}
        </Td>
        <Td>
          <StrokeIcon strokes={[lap.stroke]} size={30} />
        </Td>
        <Td>
          {authLapPace ? (
            formatPace(lap.pace)
          ) : (
            <Button onClick={upgradeAction} size={"xs"}>
              Upgrade
            </Button>
          )}
        </Td>
        <Td isNumeric>
          {authStrokeRate && lap.cadence > -1 ? (
            dp(lap.cadence, 0)
          ) : !authStrokeRate ? (
            <Button onClick={upgradeAction} size={"xs"}>
              Upgrade
            </Button>
          ) : (
            "-"
          )}
        </Td>
        <Td isNumeric>{formatDuration(lap.duration, true)}</Td>
        <Td>
          {authIntensity && lap.intensity !== "unknown" ? (
            lap.intensity
          ) : !authIntensity ? (
            <Button onClick={upgradeAction} size={"xs"}>
              Upgrade
            </Button>
          ) : (
            "-"
          )}
        </Td>
      </Tr>
    ),
  });
};

export { ActivityDetailSwimLap };
