import { useContext, useEffect } from "react";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";
import { TrainingTimelineSettings } from "../../components/trainingTimeline/TrainingTimelineSettings";

const TrainingTimelineSettingsPage = () => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks = [
      { href: "/training", title: "Training" },
      { href: "/training/settings", title: "Settings" },
    ];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TrainingTimelineSettings />;
};

export { TrainingTimelineSettingsPage };
