import {
  Box,
  Button,
  Center,
  GridItem,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { LoginForm } from "../../components/auth/LoginForm";
import { BaseLayout } from "../../components/layouts/BaseLayout";
import { NoHeaderLayout } from "../../components/layouts/NoHeaderLayout";
import { ProgressPage } from "../progress/Progress";

interface navigationState {
  requestedLocation?: string;
}

const LoginPage = () => {
  const location = useLocation();
  const { requestedLocation } = location.state
    ? (location.state as navigationState)
    : { requestedLocation: "" };
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();

  const [smallBreakPoint] = useMediaQuery("(max-width: 1024px)");
  const [midBreakPoint] = useMediaQuery("(max-width: 1280px)");

  if (user) {
    return (
      <BaseLayout>
        <ProgressPage />
      </BaseLayout>
    );
  }

  if (smallBreakPoint || midBreakPoint) {
    return (
      <NoHeaderLayout>
        <Center>
          <Image
            src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png"
            height={125}
            my={3}
          />
        </Center>
        <Heading size={"xl"} textAlign={"center"} mb={5}>
          The Power of Swim Smooth Coaching
          <br />
          on the device you already own!
        </Heading>
        <SimpleGrid columns={1} columnGap={10} w={"90%"} mt={-2}>
          <VStack alignItems={"flex-start"} w={"full"}>
            <HStack alignItems={"flex-start"} w={"full"}>
              <VStack alignItems={"flex-start"} w={"full"}>
                <Heading as={"h2"} size={"md"}>
                  Login
                </Heading>
                <Text>
                  Welcome to The Swim Smooth Guru, login here to get started.
                </Text>
                <Button
                  onClick={() => navigate("/newuser", { replace: false })}
                >
                  Don't have an account? Create one here.
                </Button>
              </VStack>
            </HStack>
            <Box w={"full"}>
              <LoginForm requestedLocation={requestedLocation} />
            </Box>
          </VStack>
          <VStack
            backgroundImage={"/marketing/marketingSwimmerPhone.jpg"}
            backgroundPosition={"left"}
            backgroundSize={"cover"}
            borderRadius={20}
            minHeight={700}
          ></VStack>
        </SimpleGrid>
      </NoHeaderLayout>
    );
  }

  return (
    <NoHeaderLayout>
      <SimpleGrid w={"full"} columns={7} mb={3}>
        <GridItem colSpan={1} alignContent={"center"}>
          <Image
            src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png"
            height={125}
            my={3}
          />
        </GridItem>
        <GridItem colSpan={5} textAlign={"center"} alignContent={"center"}>
          <Heading size={"xl"}>
            The Power of Swim Smooth Coaching
            <br />
            on the device you already own!
          </Heading>
        </GridItem>
      </SimpleGrid>
      <SimpleGrid columns={3} columnGap={10} w={"full"} mt={-2}>
        <VStack
          backgroundImage={"/marketing/marketingPaulSwimmer.jpg"}
          backgroundPosition={"right"}
          backgroundSize={"cover"}
          borderTopRightRadius={20}
          borderBottomRightRadius={20}
          minHeight={700}
        ></VStack>
        <VStack alignItems={"flex-start"} w={"full"}>
          <HStack alignItems={"flex-start"} w={"full"}>
            <VStack alignItems={"flex-start"} w={"full"}>
              <Heading as={"h2"} size={"md"}>
                Login
              </Heading>
              <Text>
                Welcome to The Swim Smooth Guru, login here to get started.
              </Text>
              <Button onClick={() => navigate("/newuser", { replace: false })}>
                Don't have an account? Create one here.
              </Button>
            </VStack>
          </HStack>
          <Box mt={5} w={"full"}>
            <LoginForm requestedLocation={requestedLocation} />
          </Box>
        </VStack>
        <VStack
          backgroundImage={"/marketing/marketingSwimmerPhone.jpg"}
          backgroundPosition={"left"}
          backgroundSize={"cover"}
          borderTopLeftRadius={20}
          borderBottomLeftRadius={20}
          minHeight={700}
        ></VStack>
      </SimpleGrid>
    </NoHeaderLayout>
  );
};

export { LoginPage };
