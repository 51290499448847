import { Heading, VStack } from '@chakra-ui/react';
import { LibraryAreas } from '../../components/library/LibraryAreas';

const LibraryPage = () => {
  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Library
      </Heading>
      <LibraryAreas />
    </VStack>
  );
};

export { LibraryPage };
