import {
  Box,
  BoxProps,
  Button,
  Center,
  Heading,
  Icon,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import * as contentful from "contentful";
import { useContext, useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { selectActivityState } from "../../app/features/activity/activitySlice";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectSessionTypesState,
  updateSessionTypesState,
} from "../../app/features/session/sessionTypesSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import Loading from "../../components/ui/Loading";
import { sessionType } from "../../types/session";
import { checkLevelInArray, getLevelInSentence } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import { CustomToast } from "../ui/CustomToast";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});
interface SessionTypesProps extends BoxProps {
  linkActivityId?: string;
}

const SessionTypes: React.FC<SessionTypesProps> = ({ linkActivityId }) => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const { activity } = useAppSelector(selectActivityState);

  const { sessionTypes, lastUpdated: lastUpdatedSessionTypes } = useAppSelector(
    selectSessionTypesState
  );
  const [loadingSessionTypes, setLoadingSessionTypes] = useState<boolean>(true);
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/sessions", title: "Sessions" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getEntriesLocal = async () => {
      const entries = await client.getEntries({
        content_type: "sessionType",
      });
      dispatch(
        updateSessionTypesState({
          sessionTypes: entries.items
            .map((a: any) => {
              return {
                code: a.fields.code,
                title: a.fields.title,
                description: a.fields.description,
                image: a.fields.image.fields.file.url,
                vimeoId: a.fields.vimeoId,
                levels: a.fields.levels,
                order: a.fields.order,
              };
            })
            .sort((a: any, b: any) => {
              return a.order - b.order;
            }),
        })
      );
      setLoadingSessionTypes(false);
    };
    if (lastUpdatedSessionTypes) {
      const test = new Date(lastUpdatedSessionTypes).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!sessionTypes || sessionTypes.length === 0 || anHourAgo > test) {
        getEntriesLocal();
      } else {
        setLoadingSessionTypes(false);
      }
    } else {
      getEntriesLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (loadingSessionTypes) {
    return <Loading message="Loading Session Types" />;
  }

  return (
    <VStack alignItems="flex-start" w="full">
      {linkActivityId && activity && linkActivityId === activity.id && (
        <Box
          bg="seagreen"
          w="full"
          px={5}
          py={2}
          alignItems="center"
          borderRadius={5}
          mb={4}
        >
          <Text>Linking to &#8220;{activity.data.name}&#8221;</Text>
        </Box>
      )}
      <SimpleGrid columns={[2, 2, 4, 5]} rowGap={10} columnGap={4} w="full">
        <Box
          key={"wbss"}
          cursor="pointer"
          borderRadius={10}
          onClick={() => {
            navigate("/sessions/wbss", { replace: false });
          }}
        >
          <VStack justifyContent={"center"}>
            <Image
              borderRadius={10}
              src={
                "https://images.ctfassets.net/50b15ahactsg/3vwOCzvCYcmp7pyDRMrywz/07a803ed9ee6362f53bc704159dbb7ea/bjarne-1.jpg"
              }
              h={208}
              minH={208}
              maxH={208}
              w={250}
              minW={250}
              maxW={250}
              fit="cover"
            />
            <Heading as="h2" pt={1} size="sm">
              Worlds Biggest Swim Squad
            </Heading>
          </VStack>
        </Box>
        {sessionTypes.map((sessionType: sessionType) => {
          if (!checkLevelInArray(accessToken, sessionType.levels)) {
            return (
              <Box
                key={sessionType.code}
                cursor="pointer"
                borderRadius={10}
                opacity={
                  !checkLevelInArray(accessToken, sessionType.levels) ? 0.5 : 1
                }
                onClick={() => {
                  createToast(toast, (props: any) => {
                    return (
                      <CustomToast
                        title={"Upgrade Required"}
                        status={"Warning"}
                        toast={toast}
                        toastId={props.id}
                      >
                        {sessionType.levels && (
                          <>
                            <Text>
                              '{sessionType.title}' requires one of the
                              following subscriptions,
                            </Text>
                            <UnorderedList>
                              {sessionType.levels.map(
                                (l: string, index: number) => {
                                  return <ListItem key={index}>{l}</ListItem>;
                                }
                              )}
                            </UnorderedList>
                            <Text>
                              You currently have{" "}
                              {getLevelInSentence(accessToken)}.
                            </Text>
                          </>
                        )}
                        <Button
                          w={"full"}
                          mt={2}
                          onClick={() => {
                            navigate("/account/subscription", {
                              replace: false,
                            });
                            toast.closeAll();
                          }}
                        >
                          Upgrade Now
                        </Button>
                      </CustomToast>
                    );
                  });
                }}
              >
                <Image
                  borderRadius={10}
                  src={sessionType.image}
                  h={208}
                  minH={208}
                  maxH={208}
                  w={250}
                  minW={250}
                  maxW={250}
                  fit="cover"
                />
                <Center>
                  <Heading as="h2" pt={1} size="sm">
                    <Icon
                      h={15}
                      w={15}
                      mr={2}
                      as={FaLock}
                      color={intensityColorMode}
                    />
                    {sessionType.title}
                  </Heading>
                </Center>
              </Box>
            );
          }
          return (
            <Box
              key={sessionType.code}
              cursor="pointer"
              borderRadius={10}
              onClick={() => {
                if (linkActivityId) {
                  navigate(
                    "/sessions/" +
                      sessionType.code +
                      "?linkActivityId=" +
                      linkActivityId,
                    { replace: false }
                  );
                } else {
                  navigate("/sessions/" + sessionType.code, { replace: false });
                }
              }}
            >
              <VStack justifyContent={"center"}>
                <Image
                  borderRadius={10}
                  src={sessionType.image}
                  h={208}
                  minH={208}
                  maxH={208}
                  w={250}
                  minW={250}
                  maxW={250}
                  fit="cover"
                />
                <Heading as="h2" pt={1} size="sm">
                  {sessionType.title}
                </Heading>
              </VStack>
            </Box>
          );
        })}
      </SimpleGrid>
    </VStack>
  );
};

export { SessionTypes };
