import {
  BoxProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { affiliate } from "../../types/affiliate";
import { coachAthlete } from "../../types/coachAthlete";
import { CoachAthleteItem } from "./CoachAthletesItem";

interface LinkedUsersProps extends BoxProps {
  affiliate: affiliate;
  coachAthletes: coachAthlete[];
}

const LinkedUsers: React.FC<LinkedUsersProps> = ({ coachAthletes }) => {
  return (
    <TableContainer mb={10} w={"full"}>
      <Table size="sm" w={"full"}>
        <Thead>
          <Tr>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Routine</Th>
            <Th></Th>
            <Th>Understanding</Th>
            <Th></Th>
            <Th>Subscription</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {coachAthletes.length < 1 && (
            <Tr>
              <Td colSpan={10} textAlign={"center"} fontWeight={"bold"} py={10}>
                No Linked Users Found
              </Td>
            </Tr>
          )}
          {coachAthletes.map((r) => {
            return <CoachAthleteItem key={r._id} coachAthlete={r} />;
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Routine</Th>
            <Th></Th>
            <Th>Understanding</Th>
            <Th></Th>
            <Th>Subscription</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export { LinkedUsers };
