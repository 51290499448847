import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  acceptInvitation,
  deleteInvitation,
  getInvitationById,
} from "../../DataAccess/invitations";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AffiliateTerms } from "../../components/auth/AffiliateTerms";
import { CoachBox } from "../../components/coaches/CoachBox";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import Loading from "../../components/ui/Loading";
import { breadcrumbLink } from "../../types/breadcrumb";
import { invitation } from "../../types/invitation";
import { forceRefreshToken } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";

const InvitationAcceptPage = () => {
  const { invitationId } = useParams<{ invitationId: string }>();
  const { user } = useAppSelector(selectUserState);
  const [loadingInvitation, setLoadingInvitation] = useState<boolean>(true);
  const [invitation, setInvitation] = useState<invitation | null>(null);
  const [isDeclining, setIsDeclining] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/relationships", title: "Relationships" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getInvitationByIdLocal = async () => {
      if (invitationId) {
        try {
          const invitationResult = await getInvitationById(invitationId);
          if (invitationResult) {
            setInvitation(invitationResult);
            setLoadingInvitation(false);
          }
        } catch (error) {
          console.log(error);
          setLoadingInvitation(false);
        }
      } else {
        setLoadingInvitation(false);
      }
    };
    getInvitationByIdLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId]);

  if (loadingInvitation) {
    return <Loading message={"Loading invitation details"} />;
  }

  if (!user || !invitation) {
    return <Loading message={"Unable to find user or invitation"} />;
  }

  if (user.data.basic.emailAddress !== invitation.data.recipientEmail) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Wrong Account
        </Heading>
        <Text>
          The invitation is not for this account. Please log in with the email
          address the invitation was sent to.
        </Text>
      </VStack>
    );
  }

  const acceptInvitationHandler = async () => {
    try {
      setIsAccepting(true);
      await acceptInvitation(invitation.id);
      await forceRefreshToken(dispatch);
      navigate("/progress", { replace: false });
      setIsAccepting(false);
      onClose();
    } catch (error) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Accept Invitation"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to connect, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsAccepting(false);
      onClose();
    }
  };

  const declineInvitationHandler = async () => {
    try {
      setIsDeclining(true);
      await deleteInvitation(invitation.id);
      await forceRefreshToken(dispatch);
      navigate("/progress", { replace: false });
      setIsDeclining(false);
      onClose();
    } catch (error) {
      const realError = error as Error;
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Decline Invitation"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>{realError.message}</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsDeclining(false);
      onClose();
    }
  };

  return (
    <VStack w="full">
      <Heading mb={5}>Swim Smooth Certified Coaches Program</Heading>
      <HStack w={"full"} alignItems={"flex-start"}>
        <CoachBox coachData={invitation.data.coach!} w={400} mr={10} />
        <Box w={"full"}>
          <Text as={"span"}>
            {invitation.data.recipientName}, you've been invited to connect to{" "}
            <em>{invitation.data.senderName}</em> in the Swim Smooth Guru.
          </Text>
          {user.data.billing.source === "None" && invitation.data.trial && (
            <>
              <Heading as={"h3"} size={"md"} mt={4} mb={2}>
                Free Trial
              </Heading>
              <Text as="span">
                <em>{invitation.data.senderName}</em> is offering you
              </Text>
              {invitation.data.trial.routineDays > 0 &&
                invitation.data.trial.understandingDays > 0 && (
                  <Text as="span">
                    {" "}
                    {invitation.data.trial.routineDays} days 'Routine' access,
                    followed by {invitation.data.trial.understandingDays} days
                    'Understanding' access.
                  </Text>
                )}
              {invitation.data.trial.routineDays > 0 &&
                invitation.data.trial.understandingDays === 0 && (
                  <Text as="span">
                    {" "}
                    {invitation.data.trial.routineDays} days 'Routine' access.
                  </Text>
                )}
              {invitation.data.trial.routineDays === 0 &&
                invitation.data.trial.understandingDays > 0 && (
                  <Text as="span">
                    {" "}
                    {invitation.data.trial.understandingDays} days
                    'Understanding' access.
                  </Text>
                )}
              <Text as="span">
                {" "}
                Once your trial has expired you'll have free forever 'Guidance'
                access.
              </Text>
            </>
          )}
          <AffiliateTerms
            parentName={invitation.data.senderName}
            program={"Swim Smooth Certified Coach Program"}
          />
          <HStack w={"full"} mt={5}>
            <Button
              variant={"success"}
              isLoading={isAccepting}
              onClick={acceptInvitationHandler}
            >
              Accept
            </Button>
            <Spacer />
            <>
              <Button
                variant="warning"
                px={10}
                isLoading={isDeclining}
                onClick={onOpen}
              >
                Decline
              </Button>
              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={deleteRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Decline Invitation
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={deleteRef} onClick={onClose}>
                        Back
                      </Button>
                      <Button
                        variant="warning"
                        px={20}
                        isLoading={isDeclining}
                        onClick={declineInvitationHandler}
                        ml={3}
                      >
                        Decline Invitation
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </>
          </HStack>
        </Box>
      </HStack>
    </VStack>
  );
};

export { InvitationAcceptPage };
