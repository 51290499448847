import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  Tooltip,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";
import { GiPathDistance } from "react-icons/gi";
import { IoFlame, IoTimerOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { wbssSession } from "../../types/session";
import { checkLevelInArray, getLevelInSentence } from "../../utils/authHelper";
import { formatDate } from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";
import { CustomToast } from "../ui/CustomToast";

interface WorldsBiggestSwimSquadListItemProps {
  session: wbssSession;
  linkActivityId?: string | null;
}

const WorldsBiggestSwimSquadListItem: React.FC<
  WorldsBiggestSwimSquadListItemProps
> = ({ session, linkActivityId }) => {
  const toast = useToast();
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const navigate = useNavigate();

  return (
    <Box
      cursor="pointer"
      bg={boxColorMode}
      borderRadius={10}
      opacity={!checkLevelInArray(accessToken, session.levels) ? 0.5 : 1}
    >
      <HStack alignItems="flex-start">
        {session.coach.image && (
          <Image
            src={session.coach.image}
            borderLeftRadius={10}
            h={[400, 400, 350, 350]}
            w={[150, 150, 200, 200]}
            fit="cover"
            fallbackSrc="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
          />
        )}
        {!session.coach.image && (
          <Image
            borderLeftRadius={10}
            h={[400, 400, 350, 350]}
            w={[150, 150, 200, 200]}
            fit="cover"
            src="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
          />
        )}
        <VStack p={2} alignItems="flex-start" w="full">
          <Heading size="xs" mb={0} as="h4" color={intensityColorMode}>
            WBSS: {session.type}
          </Heading>
          <Heading size="md" mb={0} as="h3" noOfLines={1} mt={-2}>
            {session.session.name}
          </Heading>
          {checkLevelInArray(accessToken, session.levels) && (
            <>
              <HStack w={"full"} mt={3}>
                <Tooltip hasArrow label="Distance">
                  <span>
                    <Icon
                      h={6}
                      w={6}
                      color="grey"
                      viewBox="0 0 100 100"
                      mr={2}
                      as={GiPathDistance}
                    />
                    <Box
                      display="inline-block"
                      position="relative"
                      top="-6px"
                      mr={5}
                    >
                      {session.session.distance}
                    </Box>
                  </span>
                </Tooltip>
                <Tooltip hasArrow label="Expected Time">
                  <span>
                    <Icon h={6} w={6} color="grey" mr={2} as={IoTimerOutline} />
                    <Box
                      display="inline-block"
                      position="relative"
                      top="-6px"
                      mr={5}
                    >
                      {session.session.time}
                    </Box>
                  </span>
                </Tooltip>
                <Tooltip hasArrow label="sTSS">
                  <span>
                    <Icon
                      h={6}
                      w={6}
                      color="grey"
                      mr={2}
                      as={MdModelTraining}
                    />
                    <Box display="inline-block" position="relative" top="-6px">
                      {session.session.stss
                        ? session.session.stss.toFixed(2)
                        : 0}
                    </Box>
                  </span>
                </Tooltip>
              </HStack>
              <Tooltip hasArrow label="Intensity">
                <span>
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.session.intensity > 0
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.session.intensity > 1
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.session.intensity > 2
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.session.intensity > 3
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.session.intensity > 4
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                </span>
              </Tooltip>
            </>
          )}
          {!checkLevelInArray(accessToken, session.levels) && (
            <>
              <Text fontSize={"small"}>
                <Icon
                  h={15}
                  w={15}
                  mr={2}
                  as={FaLock}
                  color={intensityColorMode}
                />
                This session requires:
              </Text>
              <UnorderedList mt={-2} fontSize={"small"}>
                <SimpleGrid columns={2}>
                  {session.levels.map((s: string) => {
                    return <ListItem key={s.toLowerCase()}>{s}</ListItem>;
                  })}
                </SimpleGrid>
              </UnorderedList>
            </>
          )}
          <Text fontSize="smaller">
            This '{session.type.toLowerCase()}' session was swum on{" "}
            {formatDate(session.dateSwum)} in {session.location} and coached by{" "}
            {session.coach.name}.
          </Text>
          <SimpleGrid columns={[1, 1, 2, 2]} columnGap={5} rowGap={2} w="full">
            <Button
              w={"full"}
              variant={"success"}
              onClick={() => {
                if (checkLevelInArray(accessToken, session.levels)) {
                  if (linkActivityId) {
                    navigate(
                      "/sessions/id/" +
                        session.sessionId +
                        "?linkActivityId=" +
                        linkActivityId,
                      { replace: false }
                    );
                  } else {
                    navigate("/sessions/id/" + session.sessionId, {
                      replace: false,
                    });
                  }
                } else {
                  createToast(toast, (props: any) => {
                    return (
                      <CustomToast
                        title={"Upgrade Required"}
                        status={"Warning"}
                        toast={toast}
                        toastId={props.id}
                      >
                        {session.levels && (
                          <>
                            <Text>
                              '{session.session.name}' requires one of the
                              following subscriptions,
                            </Text>
                            <UnorderedList>
                              {session.levels.map(
                                (l: string, index: number) => {
                                  return <ListItem key={index}>{l}</ListItem>;
                                }
                              )}
                            </UnorderedList>
                            <Text>
                              You currently have{" "}
                              {getLevelInSentence(accessToken)}.
                            </Text>
                          </>
                        )}
                        <Button
                          w={"full"}
                          mt={2}
                          onClick={() => {
                            navigate("/account/subscription", {
                              replace: false,
                            });
                            toast.closeAll();
                          }}
                        >
                          Upgrade Now
                        </Button>
                      </CustomToast>
                    );
                  });
                }
              }}
            >
              Swim It
            </Button>
            <Button
              onClick={() => {
                navigate("/coaches/" + session.coach.shortCode, {
                  replace: false,
                });
              }}
              w={"full"}
            >
              See 'Coach {session.coach.name}'
            </Button>
          </SimpleGrid>
        </VStack>
      </HStack>
    </Box>
  );
};

export { WorldsBiggestSwimSquadListItem };
