import { mode } from "@chakra-ui/theme-tools";

export const Select = {
  parts: ["field", "icon"],
  baseStyle: (props: any) => ({
    field: {
      color: mode("ssPrimaryDark", "white")(props),
      backgroundColor: mode("ssBoxBackgroundLight", "ssBoxBackgroundDark")(props),
      borderWidth: "1px",
      borderColor: mode("ssBoxBackgroundLight", "ssBoxBackgroundDark")(props),
      borderRadius: "6px",
      _focus: {
        borderColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      },
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {
    variant: "swimsmooth",
  },
};
