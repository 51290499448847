import { Heading, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

const InsightsDebugPage = () => {
  const { activityId } = useParams<{
    activityId: string;
  }>();

  if (!activityId) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Insights Debug
        </Heading>
        <Text>Please enter an activity ID</Text>
      </VStack>
    );
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Insights Debug ({activityId})
      </Heading>
      <Text>
        Here you can view the deeper detail on how insights were calculated for
        a given activity. This is only possible for activities where we still
        have the original motion data upload, which we only keep for 90 days.
      </Text>
      <Text>Coming Soon</Text>
    </VStack>
  );
};

export { InsightsDebugPage };
