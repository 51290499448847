import { BoxProps, Heading, Spinner, VStack } from '@chakra-ui/react';

interface LoadingProps extends BoxProps {
  message?: string;
}

const Loading: React.FC<LoadingProps> = ({ message }) => {
  if (typeof message === 'undefined') {
    message = 'Loading';
  }
  return (
    <VStack w="full">
      {message && (
        <Heading as="h2" size="m" mb={4}>
          {message}
        </Heading>
      )}
      <Spinner thickness="4px" speed="0.65s" emptyColor="ssPrimaryLight" color="ssPrimaryDark" size="xl" />
    </VStack>
  );
};

export default Loading;
