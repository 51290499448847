import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { session } from "../../../types/session";
import { RootState } from "../../store";

export const loadSize = 25;
// Define the state of the slice as an object
export interface SessionRouletteState {
  date: string;
  spins: number;
  sessions: session[];
  taken: boolean;
}

export interface SessionRouletteStateUpdate {
  spins: number;
  sessions: session[];
  taken: boolean;
}

// Define an initial state
const initialState: SessionRouletteState = {
  date: new Date("1970-01-01T00:00:00.000").toISOString(),
  spins: 0,
  sessions: [],
  taken: false,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const sessionsSlice = createSlice({
  name: "sessionRouletteState",
  initialState,
  reducers: {
    updateSessionRouletteState(
      state,
      action: PayloadAction<SessionRouletteStateUpdate | null>
    ) {
      if (action.payload) {
        state.date = new Date().toISOString();
        state.spins = action.payload.spins;
        state.sessions = action.payload.sessions;
        state.taken = action.payload.taken;
      } else {
        state.date = initialState.date;
        state.spins = initialState.spins;
        state.sessions = initialState.sessions;
        state.taken = initialState.taken;
      }
    },
    updateSessionRouletteTakenState(state, action: PayloadAction<boolean>) {
      state.taken = action.payload;
    },
  },
});

// Export each reducers function defined in createSlice
export const updateSessionRouletteState =
  sessionsSlice.actions.updateSessionRouletteState;
export const updateSessionRouletteTakenState =
  sessionsSlice.actions.updateSessionRouletteTakenState;

export const selectSessionRouletteState = (
  state: RootState
): SessionRouletteState => {
  return state.sessionRouletteState;
};

// Export default the slice reducer
export default sessionsSlice.reducer;
