import { BoxProps } from '@chakra-ui/react';

interface TempoTrainerProps extends BoxProps {
  height: number;
  width: number;
  colour?: string;
}

const TempoTrainer: React.FC<TempoTrainerProps> = ({ height, width, colour }) => {
  if (!colour) {
    colour = 'grey';
  }
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={colour}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4H12C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 10.6803 3.72136 10.1966 3.45825 9.73982C3.22291 9.33126 3 8.94427 3 8C3 5.79086 4.79086 4 7 4ZM8 9C7.44772 9 7 9.44772 7 10V12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12V10C17 9.44772 16.5523 9 16 9H8ZM10 7C10 6.44772 10.4477 6 11 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H11C10.4477 8 10 7.55228 10 7ZM9.5497 14C8.69383 14 8 14.6938 8 15.5497C8 16.2167 8.42683 16.8089 9.05964 17.0199L10.7351 17.5784C11.5562 17.8521 12.4438 17.8521 13.2649 17.5784L14.9404 17.0199C15.5732 16.8089 16 16.2167 16 15.5497C16 14.6938 15.3062 14 14.4503 14H9.5497Z"
      ></path>
    </svg>
  );
};

export { TempoTrainer };
