import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import clonedeep from "lodash.clonedeep";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { IoMdRefresh } from "react-icons/io";
import { Navigate, useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectTrainingTimelineState,
  updateTrainingTimelineState,
} from "../../app/features/trainingTimeline/trainingTimelineSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  deleteTrainingTimelineById,
  getTrainingTimelineCurrent,
  updateTrainingTimeline,
} from "../../DataAccess/trainingTimelines";
import { updateTrainingTimelineQuestions } from "../../types/trainingTimeline";
import { checkRole } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import FilledBar from "../ui/FilledBar";
import Loading from "../ui/Loading";
import { ActivitiesPerWeekSelector } from "./ActivitiesPerWeekSelector";
import { LaneSelector } from "./LaneSelector";

const TrainingTimelineSettings = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const [loadingTrainingTimeline, setLoadingTrainingTimeline] =
    useState<boolean>(true);
  const [min, setMin] = useState<Date>(new Date());
  const { trainingTimeline, lastUpdated: lastUpdatedTrainingTimeline } =
    useAppSelector(selectTrainingTimelineState);
  const { user } = useAppSelector(selectUserState);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [updateQuestions, setUpdateQuestions] =
    useState<updateTrainingTimelineQuestions>({
      name: "",
      lane: 0,
      activitiesPerWeek: 0,
      date: "",
    });

  useEffect(() => {
    const getTrainingTimelineLocal = async () => {
      try {
        const ttl = await getTrainingTimelineCurrent();
        dispatch(updateTrainingTimelineState(ttl));
        setLoadingTrainingTimeline(false);
      } catch (error) {
        dispatch(updateTrainingTimelineState(null));
      }
    };
    if (lastUpdatedTrainingTimeline) {
      const test = new Date(lastUpdatedTrainingTimeline).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!trainingTimeline || anHourAgo > test) {
        getTrainingTimelineLocal();
      } else {
        setLoadingTrainingTimeline(false);
      }
    } else {
      getTrainingTimelineLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trainingTimeline) {
      const minLocal = new Date(trainingTimeline.data.overview.startDate);
      minLocal.setDate(min.getDate() + 21);
      setMin(minLocal);
      updateQuestions.name = trainingTimeline.data.name;
      updateQuestions.lane = trainingTimeline.setup.lane;
      updateQuestions.activitiesPerWeek =
        trainingTimeline.setup.activitiesPerWeek;
      updateQuestions.date = trainingTimeline.setup.date;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingTimeline]);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (loadingTrainingTimeline) {
    return <Loading message="Loading Training Timeline" />;
  }

  if (!trainingTimeline) {
    return <Navigate to={{ pathname: "/training" }} />;
  }

  if (!checkRole(accessToken, "update", "ttl")) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          Training Plans
        </Heading>
        <NotAuthorised size={"full"} functionText={"Dynamic Training Plans"} />
      </VStack>
    );
  }

  const refreshTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    try {
      const ttl = await getTrainingTimelineCurrent();
      dispatch(updateTrainingTimelineState(ttl));
    } catch (error) {
      dispatch(updateTrainingTimelineState(null));
    }
    setLoadingTrainingTimeline(false);
  };

  const deleteTrainingPlanHandler = async () => {
    try {
      setLoadingTrainingTimeline(true);
      await deleteTrainingTimelineById(trainingTimeline._id);
      dispatch(updateTrainingTimelineState(null));
      setLoadingTrainingTimeline(false);
      onClose();
      navigate("/training", { replace: false });
    } catch (error) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete Training Plan"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to delete training plan, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setLoadingTrainingTimeline(false);
      onClose();
    }
  };

  const setQuestionsLane = (lane: number) => {
    const updatedState = clonedeep(updateQuestions);
    updatedState.lane = lane;
    setUpdateQuestions(updatedState);
  };

  const setQuestionsActivitiesPerWeek = (activitiesPerWeek: number) => {
    const updatedState = clonedeep(updateQuestions);
    updatedState.activitiesPerWeek = activitiesPerWeek;
    setUpdateQuestions(updatedState);
  };

  const setQuestionsDate = (date: Date | null) => {
    const updatedState = clonedeep(updateQuestions);
    if (date) {
      updatedState.date = date.toISOString();
    } else {
      updatedState.date = null;
    }
    setUpdateQuestions(updatedState);
  };

  const setQuestionsName = (name: string) => {
    const updatedState = clonedeep(updateQuestions);
    updatedState.name = name;
    setUpdateQuestions(updatedState);
  };

  const updateTrainingTimelineHandler = async () => {
    try {
      setLoadingTrainingTimeline(true);
      const ttlResponse = await updateTrainingTimeline(
        trainingTimeline._id,
        updateQuestions
      );
      if (ttlResponse) {
        dispatch(updateTrainingTimelineState(ttlResponse));
        navigate("/training", { replace: false });
      }
      setLoadingTrainingTimeline(false);
    } catch (error) {
      setLoadingTrainingTimeline(false);
    }
  };

  if (trainingTimeline.type === "*SPECIAL") {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          {trainingTimeline.data.name}
        </Heading>
        <Text>
          {trainingTimeline.data.subTitle} :{" "}
          {trainingTimeline.data.overview.remainingText}
        </Text>
        <Box mt={5} mb={5}>
          <FilledBar width={700} score={10} max={100} showScore={false} />
        </Box>
        <VStack pt={1}>
          <Text>
            Settings for this training plan cannot be changed, you can however
            delete the plan if it is no longer required.
          </Text>
          <>
            <Button
              variant="warning"
              px={10}
              isLoading={loadingTrainingTimeline}
              onClick={onOpen}
            >
              Delete Training Plan
            </Button>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={deleteRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Training Plan
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={deleteRef} onClick={onClose}>
                      Back
                    </Button>
                    <Button
                      variant="warning"
                      px={20}
                      isLoading={loadingTrainingTimeline}
                      onClick={deleteTrainingPlanHandler}
                      ml={3}
                    >
                      Delete Training Plan
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        </VStack>
        <HStack w="full" alignItems="flex-start" pt={5}>
          <Button
            variant="warning"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
          <Box w="full">{/* <!-- Spanner --> */}</Box>
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        {trainingTimeline.data.name}
      </Heading>
      <Text>
        {trainingTimeline.data.subTitle} :{" "}
        {trainingTimeline.data.overview.remainingText}
      </Text>
      <Box mt={5} mb={5}>
        <FilledBar width={700} score={10} max={100} showScore={false} />
      </Box>
      <HStack pt={1}>
        <>
          <Button
            variant="warning"
            px={10}
            isLoading={loadingTrainingTimeline}
            onClick={onOpen}
          >
            Delete Training Plan
          </Button>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={deleteRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Training Plan
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={deleteRef} onClick={onClose}>
                    Back
                  </Button>
                  <Button
                    variant="warning"
                    px={20}
                    isLoading={loadingTrainingTimeline}
                    onClick={deleteTrainingPlanHandler}
                    ml={3}
                  >
                    Delete Training Plan
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
        <Button
          variant="success"
          isLoading={loadingTrainingTimeline}
          onClick={refreshTrainingTimeline}
        >
          <Icon as={IoMdRefresh} />
        </Button>
      </HStack>
      <VStack w="full" alignItems="flex-start">
        <Text>Name</Text>
        <Input
          id="updateQuestionsName"
          value={updateQuestions.name}
          size="lg"
          placeholder="Name"
          onChange={(event: any) => {
            setQuestionsName(event.target.value);
          }}
        />
        <Text>Level</Text>
        <LaneSelector
          laneInput={updateQuestions.lane}
          setLaneInput={setQuestionsLane}
        />
        <Text>Activities Per Week</Text>
        <ActivitiesPerWeekSelector
          type={trainingTimeline.setup.type}
          subType={trainingTimeline.setup.subType}
          activitiesPerWeekInput={updateQuestions.activitiesPerWeek}
          setActivitiesPerWeekInput={setQuestionsActivitiesPerWeek}
        />
        <Text>Event Date</Text>
        <DatePicker
          selected={updateQuestions.date ? new Date(updateQuestions.date) : min}
          onChange={(date: Date | null) => {
            setQuestionsDate(date);
          }}
          minDate={min}
          dateFormat="do MMMM yyyy"
          calendarStartDay={1}
          customInput={<Input />}
          withPortal
        />
      </VStack>
      <HStack w="full" alignItems="flex-start" pt={5}>
        <Button
          variant="warning"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
        <Box w="full">{/* <!-- Spanner --> */}</Box>
        <Button
          onClick={() => {
            updateTrainingTimelineHandler();
          }}
        >
          Save Plan
        </Button>
      </HStack>
    </VStack>
  );
};

export { TrainingTimelineSettings };
