import {
  Box,
  BoxProps,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

interface LaneSelectorProps extends BoxProps {
  laneInput: number;
  setLaneInput: any;
}

const LaneSelector: React.FC<LaneSelectorProps> = ({
  laneInput,
  setLaneInput,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const selectedBoxColorMode = useColorModeValue(
    "ssNeonOrangeLight",
    "ssNeonOrangeDark"
  );

  const isSelected = (lane: number): string => {
    let unFettledLane = laneInput;
    if (unFettledLane === 4) {
      unFettledLane = 3;
    }
    if (unFettledLane === 6) {
      unFettledLane = 5;
    }
    if (unFettledLane === lane) {
      return selectedBoxColorMode;
    }
    return boxColorMode;
  };

  let lanes: any[] = [
    [0, "Getting Started", "Up to 1000m in a session"],
    [1, "Beginner", "1000-2000m in a session"],
    [2, "Intermediate", "2000-2500m in an hour"],
    [3, "Experienced", "2500-3300m in an hour"],
    [5, "Advanced", "I can sustain 1:20/100m or faster"],
  ];

  return (
    <SimpleGrid columns={[1, 1, 3, 3]} spacing={3} w="full">
      {lanes.map((lane: any) => {
        return (
          <Box
            key={lane[0]}
            bg={boxColorMode}
            borderWidth={3}
            borderColor={isSelected(lane[0])}
            borderRadius={5}
            px={5}
            py={2}
            cursor="pointer"
            onClick={() => {
              setLaneInput(lane[0]);
            }}
            w="full"
          >
            <Text fontSize="lg">
              <b>{lane[1]}</b>
            </Text>
            <Text color="gray">{lane[2]}</Text>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export { LaneSelector };
