import { useParams } from "react-router-dom";
import { CoachEditProfile } from "../../components/coaches/CoachEditProfile";

const CoachEditProfilePage = () => {
  const params = useParams<{ coachShortCode: string }>();

  if (!params.coachShortCode) {
    return null;
  }

  return <CoachEditProfile coachShortCode={params.coachShortCode} />;
};

export { CoachEditProfilePage };
