import {
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { breadcrumbLink } from "../../types/breadcrumb";

const NotFoundPage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Not Found (404)
      </Heading>
      <VStack w="full" alignItems="flex-start">
        <Text>
          Well, now this is embarrassing isn't it. The page you are looking for,{" "}
          <em>{window.location.href}</em>, doesn't seem to exist.
        </Text>
        <Text>
          We don't like to get into the blame game here at Swim Smooth but there
          are a couple of things to check before asking us to take a look:
        </Text>
        <List spacing={2}>
          <ListItem>
            <ListIcon as={MdOutlineCheckCircleOutline} mb={1} />
            Have you typed the address correctly?
          </ListItem>
          <ListItem>
            <ListIcon as={MdOutlineCheckCircleOutline} mb={1} />
            Did your 'best friends aunties second cousin'<sup>*</sup> send you a
            link, maybe check she got it right!
          </ListItem>
          <ListItem>
            <ListIcon as={MdOutlineCheckCircleOutline} mb={1} />
            Did you click on a link from an email you received in 2004
            <sup>^</sup>? Although we try to keep things backwards compatible we
            can't keep it up forever!
          </ListItem>
          <ListItem>
            <ListIcon as={MdOutlineCheckCircleOutline} mb={1} />
            Script kiddie or just trying random addresses? We did think of that
            when we put security in place you know!
          </ListItem>
        </List>
        <Text>
          All looking good from your end, maybe we've dropped a clanger. Let us
          know and we'll take a look.
        </Text>
        <Text fontSize="smaller">
          * Other relationships, distant or close, are available!
        </Text>
        <Text fontSize="smaller">^ Other years are also available!</Text>
      </VStack>
    </VStack>
  );
};

export { NotFoundPage };
