import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { Component } from "react";
import { SwimSmoothLogo } from "../svg/SwimSmoothLogo";

interface IProps {
  children: any;
}

interface IState {
  error: Error | undefined;
}
export class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error: error });
  }

  render() {
    const { error } = this.state;
    if (error) {
      // You can render any custom fallback UI
      return (
        <VStack w={"full"}>
          <VStack w={["full", "90%", "75%", "60%"]}>
            {/* <Box
              bg={"#ff0000"}
              borderColor={"#cc0000"}
              borderWidth={3}
              borderStyle={"solid"}
              borderRadius={10}
              w="full"
              m={10}
              py={"0px"}
              fontSize={"20pt"}
              lineHeight={"22px"}
              px={"30px"}
            >
              <VStack>
                <Text>
                  We are aware of a few issues still remainining after our
                  upgrade including issues with completing sessions, linking
                  sessions and incorrect display of subscription details.
                </Text>
                <Text>
                  If you don't have the expected access please{" "}
                  <a href={"/logout"}>logout</a> and back in again before
                  reporting. Sorry for any inconvenience caused.
                </Text>
              </VStack>
            </Box> */}
            <Box
              cursor="pointer"
              onClick={() => (window.location.href = "/")}
              py={2}
            >
              <SwimSmoothLogo
                height={100}
                width={100}
                colour={
                  process.env.REACT_APP_ENV === "alpha"
                    ? "#ff0000"
                    : process.env.REACT_APP_ENV === "beta"
                    ? "#ff9900"
                    : "#fffff"
                }
              />
            </Box>
            <Heading as="h1" pb={5}>
              Oops! Something went wrong :(
            </Heading>
            <Heading as="h2" size="xs" pb={4}>
              Not your fault. It's on us and we are on it.
            </Heading>
            <Text>
              Please send feedback if you remember what you did to help us
              diagnose the problem. Then start your journey over or if this has
              happened multiple times, try <a href={"/logout"}>logging out</a>{" "}
              and then log back in again.
            </Text>
            <Heading as="h3" size="xs" pb={4}>
              {error.name}
            </Heading>
            <Text pb={4}>{error.message}</Text>
            <Text pb={4}>{error.stack}</Text>
          </VStack>
        </VStack>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}
