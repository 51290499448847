import { VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { LibraryArea } from "../../components/library/LibraryArea";
import Loading from "../../components/ui/Loading";

const LibraryAreaPage = () => {
  const { area } = useParams<{ area: string; category: string }>();

  if (!area) {
    return <Loading message="Area Not Found" />;
  }

  return (
    <VStack w="full">
      <LibraryArea areaId={area} />
    </VStack>
  );
};

export { LibraryAreaPage };
