import { BoxProps } from '@chakra-ui/react';

interface GarminLogoProps extends BoxProps {
  height: number;
  width: number;
  colour?: string;
  opacity?: number;
}

const GarminLogo: React.FC<GarminLogoProps> = ({ height, width, colour, opacity }) => {
  if (!colour) {
    colour = '#002140';
  }
  if (!opacity) {
    opacity = 1;
  }
  return (
    <svg width={width} height={height} viewBox="0 0 447 142.5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          fill={colour}
          d="M296.1,67.2h-8.6c-2.2,0-3.5,1.1-4.3,2.5l-17.2,30.6c-0.8,1.4-2,1.4-2.8,0l-16.6-30.5c-0.7-1.4-2.2-2.6-4.2-2.6h-9
	c-1.6,0-2.9,1.3-2.9,2.9v53c0,1.6,1,2.7,2.9,2.7h5.6c1.6,0,2.7-1,2.7-2.5c0-0.7,0-38.6,0.1-38.6c0.1,0,20.7,38.6,20.7,38.6
	c0.7,1.4,2.6,1.4,3.4,0c0,0,20.9-38.5,20.9-38.5c0.1,0,0,37.3,0,38.5c0,1.5,1.2,2.5,2.8,2.5h6.5c1.6,0,2.9-0.8,2.9-2.9V70.1
	C299,68.5,297.7,67.2,296.1,67.2z M120.5,69.8c-0.7-1.4-2.6-2.6-4.2-2.6h-3.2c-1.6,0-3.4,1.2-4.1,2.6l-25.6,53.5
	c-0.7,1.4,0.1,2.4,1.7,2.4h5.9c2.3,0,3.4-1.1,4-2.4c0.6-1.3,2-4.1,2.3-4.7c0.4-0.8,1.5-1.3,3.1-1.3l27.6,0c1.6,0,2.6,0.3,3.1,1.2
	c0.3,0.6,1.7,3.2,2.4,4.5c0.7,1.4,1.9,2.5,4.1,2.5h8.6c1.6,0,2.3-1.2,1.7-2.3C147.3,122.3,120.5,69.8,120.5,69.8z M123.3,109.2
	l-19,0c-1.6,0-2.3-1.2-1.7-2.6l10.1-21.6c0.7-1.4,1.8-1.4,2.4,0l9.8,21.6C125.7,108,124.9,109.2,123.3,109.2z M202.3,100.3
	c0,0,6.1-1.8,8.9-4.4c2.9-2.6,4.4-6.4,4.4-11.3c0-3.2-0.6-5.9-1.7-8.1c-1.1-2.2-2.7-4-4.8-5.3c-2.1-1.4-4.6-2.4-7.5-3
	c-2.9-0.6-6.8-0.8-6.8-0.8c-1.6-0.1-4.2-0.1-5.8-0.1H160c-1.6,0-2.9,1.3-2.9,2.9v53.1c0,1.6,1.3,2.6,2.9,2.6h6.9
	c1.6,0,2.9-1,2.9-2.6c0,0,0-21.1,0-21.1c0,0,0,0,0,0c0,0,13,0,13,0c1.6,0,3.6,1.1,4.6,2.4l13.5,19.1c1.3,1.7,2.5,2.3,4,2.3h9
	c1.6,0,1.8-1.5,1.3-2.3c-0.5-0.8-14.1-20-14.1-20C200.3,102.2,200.8,100.7,202.3,100.3z M200,91c-1,0.7-2.4,1.3-4.2,1.7
	c-1.8,0.4-3.8,0.5-3.8,0.5c-1.6,0.1-4.2,0.1-5.8,0.1h-13.5c-1.6,0-2.9-1.3-2.9-2.9V79.7c0-1.6,1.3-2.9,2.9-2.9h13.5
	c1.6,0,4.2,0.1,5.8,0.1c0,0,2,0.1,3.8,0.5c1.8,0.4,3.2,1,4.2,1.7c1,0.7,1.8,1.6,2.2,2.6c0.4,1,0.7,2.1,0.7,3.3
	c0,1.2-0.2,2.3-0.7,3.3C201.7,89.3,201,90.2,200,91z M328.7,67.2h-7.3c-1.6,0-2.9,1-2.9,3v53.1c0,1.5,1.3,2.5,2.9,2.5h7
	c1.6,0,2.9-1,2.9-2.6V70.1C331.4,68.5,330.3,67.2,328.7,67.2z M410.5,67.2h-5.9c-1.6,0-2.9,1-2.9,2.6c0,0.7,0,34,0,34
	c0,1.6-0.9,2-2,0.8l-34-35.3c-1.1-1.1-2.7-2.1-4.9-2.1h-6.4c-2,0-2.9,1.1-2.9,2.2v54.3c0,1.1,1,2.1,2.6,2.1h6.4
	c1.6,0,2.7-0.9,2.7-2.3c0-0.7,0-35.8,0-35.8c0-1.6,0.9-2,2-0.8l35.5,36.9c1.1,1.1,2.5,2,4.9,2h4.8c1.6,0,2.9-1.3,2.9-2.9V70.1
	C413.4,68.5,412.1,67.2,410.5,67.2z M72.2,93.3H47.8c-1.8,0-3,1.5-3,3.3v2c0,1.8,1.2,3.3,3,3.3h14.1c1.4,0,2.5,1.1,2.5,2.5
	c0,0,0,8.7,0,9.2c-2.8,1.1-15.5,4.8-25.7,2.4c-14.8-3.5-16-15.7-16-19.4c0-3.1,0.9-18.4,19.1-20c14.7-1.3,24.4,4.7,24.5,4.7
	c1.7,0.9,3.5,0.3,4.5-1.6c0,0,1.8-3.7,1.8-3.7c0.8-1.5,0.5-3.1-1.4-4.4c-0.1-0.1-10.4-5.7-26.4-5.7c-34.1,0-35.1,27.3-35.1,30.4
	c0,28.6,29.3,30.1,35.1,30.1c19.4,0,27.6-5.2,28-5.5c1.2-0.7,2.7-2.1,2.7-4.7V96.6C75.5,94.8,74,93.3,72.2,93.3z M363,52.6l38.3,0
	c1.4,0,2.6-0.7,3.3-1.9c0.7-1.2,0.7-2.6,0-3.8l-19.2-33.2c-0.7-1.2-1.9-1.9-3.3-1.9c-1.4,0-2.6,0.7-3.3,1.9l-19.2,33.2
	c-0.7,1.2-0.7,2.6,0,3.8C360.4,51.9,361.6,52.6,363,52.6z M427.7,113.6c-3.7,0-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7
	c3.7,0,6.7-3,6.7-6.7C434.4,116.6,431.4,113.6,427.7,113.6z M427.7,126c-3.1,0-5.4-2.5-5.4-5.7c0-3.2,2.3-5.7,5.4-5.7
	c3.1,0,5.4,2.5,5.4,5.7C433.1,123.5,430.8,126,427.7,126z M430.8,118.6c0-1.5-0.9-2.2-2.7-2.2h-3v7.8h1.3v-3.5h1.1l2.2,3.5h1.4
	l-2.2-3.5C430,120.7,430.8,119.8,430.8,118.6z M426.4,119.7v-2.4h1.5c0.8,0,1.6,0.3,1.6,1.1c0,1-0.6,1.2-1.6,1.2H426.4z"
        />
      </g>
    </svg>
  );
};

export { GarminLogo };
