import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface AuthState {
  access: string | null;
  refresh: string | null;
  admin: string | null;
  userId: string | null;
  forceChangePassword: boolean;
}

// Define an initial state
const initialState: AuthState = {
  access: null,
  refresh: null,
  admin: null,
  userId: null,
  forceChangePassword: false,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const authSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    updateAuthState(state, action: PayloadAction<Partial<AuthState> | null>) {
      if (action.payload) {
        state.access =
          action.payload.access === null
            ? null
            : action.payload.access
            ? action.payload.access
            : state.access;
        state.refresh =
          action.payload.refresh === null
            ? null
            : action.payload.refresh
            ? action.payload.refresh
            : state.refresh;
        state.admin =
          action.payload.admin === null
            ? null
            : action.payload.admin
            ? action.payload.admin
            : state.admin;
        state.userId =
          action.payload.userId === null
            ? null
            : action.payload.userId
            ? action.payload.userId
            : state.userId;
        state.forceChangePassword =
          typeof action.payload.forceChangePassword === "undefined"
            ? state.forceChangePassword
            : action.payload.forceChangePassword;
      } else {
        state.access = initialState.access;
        state.refresh = initialState.refresh;
        state.admin = initialState.admin;
        state.userId = initialState.userId;
        state.forceChangePassword = initialState.forceChangePassword;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateAuthState = authSlice.actions.updateAuthState;

export const selectAuthState = (state: RootState): Partial<AuthState> =>
  state.authState;

// Export default the slice reducer
export default authSlice.reducer;
