import { Heading, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { SubscriptionDetails } from "../../components/account/subscription/SubscriptionDetails";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { breadcrumbLink } from "../../types/breadcrumb";

const AccountSubscriptionPage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/account", title: "Account" });
    breadcrumbLinks.push({
      href: "/account/subscription",
      title: "Subscription",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Your Subscription
      </Heading>
      <SubscriptionDetails w="full" />
    </VStack>
  );
};

export { AccountSubscriptionPage };
