import { useContext, useEffect } from "react";
import { Signup } from "../../components/auth/Signup";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";

const AccountCreatePage = () => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumbLinks(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Signup />;
};

export { AccountCreatePage };
