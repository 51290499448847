import { coach } from "../types/coach";
import { distanceBetweenPoints } from "./locationHelper";

const regionDescription = (region: string, type?: string): string => {
  if (typeof type === "undefined") {
    type = "long";
  }
  switch (region) {
    case "uk":
      return type === "long" ? "United Kingdom" : "UK";
    case "europe":
      return type === "long" ? "Europe" : "Europe";
    case "americas":
      return type === "long" ? "Americas" : "Americas";
    case "asiaoceania":
      return type === "long" ? "Asia & Oceania" : "Asia";
    case "africa":
      return type === "long" ? "Africa" : "Africa";
    case "other":
      return type === "long" ? "Other" : "Other";
    case "all":
      return type === "long" ? "All" : "All";
  }
  return "Unknown Region";
};

const defaultSortAndFilter = (
  coaches: coach[],
  latitude: number | null,
  longitude: number | null
): coach[] => {
  return coaches
    .map((coach: coach) => {
      let distanceFromYou = 384400; // Moon
      if (latitude && longitude) {
        if (
          !coach.data.distanceLocation ||
          (coach.data.distanceLocation[0] === 1 &&
            coach.data.distanceLocation[1] === 1) ||
          (coach.data.distanceLocation[0] === 0 &&
            coach.data.distanceLocation[1] === 0)
        ) {
          distanceFromYou = 253890000; // sun
        } else {
          distanceFromYou = distanceBetweenPoints(
            [latitude, longitude],
            coach.data.distanceLocation,
            "K"
          );
        }
      } else if (
        !coach.data.distanceLocation ||
        (coach.data.distanceLocation[0] === 1 &&
          coach.data.distanceLocation[1] === 1) ||
        (coach.data.distanceLocation[0] === 0 &&
          coach.data.distanceLocation[1] === 0)
      ) {
        // Coach Doesn't have a distance location ... de-prioritise
        distanceFromYou = 253890000; // sun
      }

      return {
        id: coach.id,
        auditSummary: coach.auditSummary,
        data: { ...coach.data, ...{ distanceFromYou: distanceFromYou } },
      } as coach;
    })
    .sort((a: coach, b: coach) => {
      return (
        a.data.lastName.localeCompare(b.data.lastName) ||
        a.data.firstName.localeCompare(b.data.firstName)
      );
    })
    .sort((a: coach, b: coach) => {
      if (a.data.distanceFromYou && b.data.distanceFromYou) {
        return a.data.distanceFromYou - b.data.distanceFromYou;
      }
      return 0;
    })
    .sort((a: coach, b: coach) => {
      if (a.data.myCoach) {
        return -1;
      }
      return 1;
    })
    .sort((a: coach, b: coach) => {
      if (a.data.me) {
        return -1;
      }
      return 1;
    });
};

export { defaultSortAndFilter, regionDescription };
