import { useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { SessionDetail } from "../../components/sessions/SessionDetail";
import Loading from "../../components/ui/Loading";

const SessionPage = () => {
  const { sessionId, targetDistance, requestedDistance } = useParams<{
    sessionId: string;
    targetDistance: string;
    requestedDistance: string;
  }>();

  if (!sessionId || !targetDistance || !requestedDistance) {
    <Loading message={"Required Params Not Sent"} />;
  }

  return (
    <SessionDetail
      sessionId={sessionId ? sessionId : ""}
      targetDistance={targetDistance ? parseInt(targetDistance, 10) : undefined}
      requestedDistance={
        requestedDistance ? parseInt(requestedDistance, 10) : undefined
      }
    />
  );
};

export { SessionPage };
