import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import {
  session,
  sessionType,
  wbssHistoryResponse,
  wbssWeek,
} from "../types/session";

interface sessionsResponse {
  sessions: session[];
  last: string;
}

const getSessionTypes = async (): Promise<sessionType[]> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "sessions/types"
    );
    return result.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const getSessionsByType = async (
  type: string,
  last?: string
): Promise<sessionsResponse> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "sessions/type/" + type;
    if (last) {
      url += "?last=" + last;
    }
    const result = await axios.get(url);
    return {
      sessions: result.data.payload,
      last: result.data.last,
    };
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const getSessionById = async (
  sessionId: string,
  targetDistance?: number
): Promise<any | null> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "sessions/id/" + sessionId;
    if (typeof targetDistance !== "undefined") {
      url += "?targetdistance=" + targetDistance;
    }
    const result = await axios.get(url);
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const playSessionRoulette = async (exclude?: string): Promise<session> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "sessions/roulette";
    if (exclude) {
      url += "?exclude=" + exclude;
    }
    const result = await axios.get(url);
    return result.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const getWbssWeek = async (): Promise<wbssWeek> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "sessions/wbss";
    const result = await axios.get(url);
    return result.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const getWbssHistory = async (last?: string): Promise<wbssHistoryResponse> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "sessions/wbss/history";
    if (last) {
      url += "?last=" + last;
    }
    const result = await axios.get(url);
    return {
      weeks: result.data.payload,
      last: result.data.last,
    };
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

export {
  getSessionById,
  getSessionsByType,
  getSessionTypes,
  getWbssHistory,
  getWbssWeek,
  playSessionRoulette,
};
