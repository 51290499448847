import {
  Box,
  Button,
  FormControl,
  Grid,
  Heading,
  Icon,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoMdRefresh } from "react-icons/io";
import { getCoaches } from "../../DataAccess/coaches";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectCoachesState,
  updateCoachesState,
} from "../../app/features/coach/coachesSlice";
import { selectLocationState } from "../../app/features/location/locationSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CoachesList } from "../../components/coaches/CoachesList";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import Loading from "../../components/ui/Loading";
import { coach } from "../../types/coach";
import { checkRole } from "../../utils/authHelper";
import { defaultSortAndFilter } from "../../utils/coachHelper";

const CoachesPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const [userIsInCoaches, setUserIsInCoaches] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const {
    latitude,
    longitude,
    lastUpdated: lastUpdatedLocation,
  } = useAppSelector(selectLocationState);
  const { coaches, lastUpdated, region, viewMode } =
    useAppSelector(selectCoachesState);
  const dispatch = useAppDispatch();

  const {
    control: controlBasic,
    watch: watchBasic,
    handleSubmit: handleSubmitBasic,
  } = useForm({
    defaultValues: {
      searchNameLocation: "",
      searchRegion: "*ALL",
    },
    mode: "all",
  });

  const handleBasic = async (data: any): Promise<void> => {
    console.log("Denada!");
  };

  const watchSearchNameLocation = watchBasic("searchNameLocation", "");
  const watchSearchRegion = watchBasic("searchRegion", "*ALL");

  useEffect(() => {
    // Set breadcrumb
    const breadcrumbLinks = [{ href: "/coaches", title: "Coaches" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCoachesLocal = async () => {
    setLoading(true);
    const coaches = await getCoaches();
    setUserIsInCoaches(
      coaches.filter((c: coach) => user && c.data.user === user?._id).length > 0
        ? true
        : false
    );
    dispatch(
      updateCoachesState({
        coaches: coaches,
        region: region,
        viewMode: viewMode,
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    if (lastUpdated && lastUpdatedLocation) {
      const testCoaches = new Date(lastUpdated).getTime();
      const testLocation = new Date(lastUpdatedLocation).getTime();
      const anDayAgo = new Date().getTime() - 24 * 1000 * 60 * 60;
      if (anDayAgo > testCoaches || testLocation > testCoaches) {
        getCoachesLocal();
      } else {
        setLoading(false);
        setUserIsInCoaches(
          coaches.filter((c: coach) => user && c.data.user === user?._id)
            .length > 0
            ? true
            : false
        );
      }
    } else {
      getCoachesLocal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, lastUpdatedLocation, latitude, longitude, viewMode]);

  if (loading) {
    return <Loading message="Loading coaches" />;
  }

  return (
    <VStack>
      <Heading as="h2" size="xl" mb={4}>
        Smooth Certified Coaches
      </Heading>
      <Box w="full">
        <Text w="full" mb={2}>
          We consider our certified coaches to be the pinnacle of coaching in
          freestyle, long distance and triathlon coaching. They are hand
          selected, trained and certified by our team of coach educators lead by
          head coach Paul in Perth, Western Australia.
        </Text>
        <Heading size="md" mb={5}>
          Coach Search
        </Heading>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={1}
          mb={10}
          as="form"
          onSubmit={handleSubmitBasic(handleBasic)}
          w="full"
          alignItems="flex-start"
        >
          <FormControl>
            <Controller
              control={controlBasic}
              rules={{ required: true }}
              name="searchNameLocation"
              render={({ field: { ref, ...restField } }) => (
                <Input
                  {...restField}
                  placeholder="Search by Name or Location"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              control={controlBasic}
              name="searchRegion"
              rules={{ required: true }}
              render={({ field: { ref, ...restField } }) => (
                <Select {...restField}>
                  <option value="*ALL">All Regions</option>
                  <option value="uk">United Kingdom</option>
                  <option value="europe">Europe</option>
                  <option value="americas">Americas</option>
                  <option value="asiaoceania">Asia & Oceania</option>
                  <option value="africa">Africa</option>
                  <option value="other">Other</option>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Box>
      <CoachesList
        title={"Regional Coaches"}
        size={"standard"}
        global={false}
        coaches={defaultSortAndFilter(coaches, latitude, longitude).filter(
          (c: coach) => {
            if (watchSearchNameLocation && watchSearchRegion !== "*ALL") {
              return (
                c.data.certified &&
                c.data.regional &&
                ((c.data.displayName &&
                  c.data.displayName
                    .toLowerCase()
                    .indexOf(watchSearchNameLocation.toLowerCase()) > -1) ||
                  c.data.brandLocation
                    .toLowerCase()
                    .indexOf(watchSearchNameLocation.toLowerCase()) > -1) &&
                c.data.region === watchSearchRegion
              );
            } else if (watchSearchNameLocation) {
              return (
                c.data.certified &&
                c.data.regional &&
                ((c.data.displayName &&
                  c.data.displayName
                    .toLowerCase()
                    .indexOf(watchSearchNameLocation.toLowerCase()) > -1) ||
                  c.data.brandLocation
                    .toLowerCase()
                    .indexOf(watchSearchNameLocation.toLowerCase()) > -1)
              );
            } else if (watchSearchRegion !== "*ALL") {
              return (
                c.data.certified &&
                c.data.regional &&
                c.data.region === watchSearchRegion
              );
            } else {
              return c.data.certified && c.data.regional;
            }
          }
        )}
      />
      <CoachesList
        title={"Global Team"}
        size={"standard"}
        global={true}
        coaches={defaultSortAndFilter(coaches, latitude, longitude).filter(
          (c: coach) => {
            if (watchSearchNameLocation && watchSearchRegion !== "*ALL") {
              return (
                c.data.certified &&
                c.data.global &&
                ((c.data.displayName &&
                  c.data.displayName
                    .toLowerCase()
                    .indexOf(watchSearchNameLocation.toLowerCase()) > -1) ||
                  c.data.brandLocation
                    .toLowerCase()
                    .indexOf(watchSearchNameLocation.toLowerCase()) > -1)
              );
            } else if (watchSearchNameLocation) {
              return (
                c.data.certified &&
                c.data.global &&
                ((c.data.displayName &&
                  c.data.displayName
                    .toLowerCase()
                    .indexOf(watchSearchNameLocation.toLowerCase()) > -1) ||
                  c.data.brandLocation
                    .toLowerCase()
                    .indexOf(watchSearchNameLocation.toLowerCase()) > -1)
              );
            } else if (watchSearchRegion !== "*ALL") {
              return c.data.certified && c.data.global;
            } else {
              return c.data.certified && c.data.global;
            }
          }
        )}
      />
      {(checkRole(accessToken, "*ALL", "admin") || userIsInCoaches) && (
        <CoachesList
          title={"Training Coaches"}
          size={"small"}
          global={false}
          coaches={defaultSortAndFilter(coaches, latitude, longitude).filter(
            (c: coach) => {
              if (watchSearchNameLocation && watchSearchRegion !== "*ALL") {
                return (
                  !c.data.certified &&
                  ((c.data.displayName &&
                    c.data.displayName
                      .toLowerCase()
                      .indexOf(watchSearchNameLocation.toLowerCase()) > -1) ||
                    c.data.brandLocation
                      .toLowerCase()
                      .indexOf(watchSearchNameLocation.toLowerCase()) > -1) &&
                  c.data.region === watchSearchRegion
                );
              } else if (watchSearchNameLocation) {
                return (
                  !c.data.certified &&
                  ((c.data.displayName &&
                    c.data.displayName
                      .toLowerCase()
                      .indexOf(watchSearchNameLocation.toLowerCase()) > -1) ||
                    c.data.brandLocation
                      .toLowerCase()
                      .indexOf(watchSearchNameLocation.toLowerCase()) > -1)
                );
              } else if (watchSearchRegion !== "*ALL") {
                return !c.data.certified && c.data.region === watchSearchRegion;
              } else {
                return !c.data.certified;
              }
            }
          )}
        />
      )}
      {checkRole(accessToken, "*ALL", "admin") && (
        <Button variant="success" isLoading={loading} onClick={getCoachesLocal}>
          <Icon as={IoMdRefresh} />
        </Button>
      )}
    </VStack>
  );
};

export { CoachesPage };
