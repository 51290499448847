import {
  BoxProps,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

interface TempoTrainerReadoutProps extends BoxProps {
  data: any;
}

const TempoTrainerReadout: React.FC<TempoTrainerReadoutProps> = ({ data }) => {
  const ttpColour = useColorModeValue("#BB9703", "#E9BC00");

  return (
    <VStack mt={-10} mb={10}>
      <HStack
        style={{ position: "relative", top: "85px" }}
        w="full"
        alignItems="flex-start"
      >
        <Text pl={100} w="100%" fontSize="4xl">
          <b>{data.actual.mode}</b>
        </Text>
        <Text pl={160} w="100%" fontSize="4xl">
          <b>{data.actual.setting}</b>
        </Text>
      </HStack>
      <svg
        width={600}
        height={90}
        viewBox="0 0 311 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.833 3.8147e-06H157.353H157.353C169.308 3.8147e-06 179 9.69168 179 21.647C179 33.6023 169.308 43.2939 157.353 43.2939C145.427 43.2939 135.754 33.6503 135.706 21.7359C135.706 21.7066 135.706 21.677 135.706 21.647C135.706 18.0762 134.952 16.7672 134.24 15.5312C133.603 14.4257 133 13.3786 133 10.8235V10.8235C133 4.84584 137.846 0 143.823 0L143.833 3.8147e-06ZM144.823 13.5294C144.271 13.5294 143.823 13.9771 143.823 14.5294V23.3529C143.823 23.9052 144.271 24.3529 144.823 24.3529H169.882C170.434 24.3529 170.882 23.9052 170.882 23.3529V14.5294C170.882 13.9771 170.434 13.5294 169.882 13.5294H144.823ZM146.529 31.252C146.529 28.9361 148.407 27.0587 150.723 27.0587H163.983C166.299 27.0587 168.176 28.9361 168.176 31.252C168.176 33.0569 167.021 34.6594 165.309 35.2301L159.883 37.0389C158.241 37.5863 156.465 37.5863 154.823 37.0389L149.397 35.2301C147.684 34.6594 146.529 33.0569 146.529 31.252ZM154.647 5.41174C153.153 5.41174 151.941 6.6232 151.941 8.11761C151.941 9.61203 153.153 10.8235 154.647 10.8235H160.059C161.553 10.8235 162.765 9.61203 162.765 8.11761C162.765 6.6232 161.553 5.41174 160.059 5.41174H154.647Z"
          fill={ttpColour}
          fillOpacity="0.6"
        ></path>
        <path
          d="M150.723 27.5587H163.983C166.023 27.5587 167.676 29.2123 167.676 31.252C167.676 32.8417 166.659 34.2531 165.151 34.7558L159.724 36.5646C158.185 37.0778 156.521 37.0778 154.981 36.5646L149.555 34.7558C148.047 34.2531 147.029 32.8417 147.029 31.252C147.029 29.2123 148.683 27.5587 150.723 27.5587Z"
          fill={ttpColour}
          fillOpacity="0.17"
          stroke={ttpColour}
        ></path>
        <rect
          x="152.441"
          y="5.91174"
          width="9.82349"
          height="4.41174"
          rx="2.20587"
          fill="#78DFFF"
          fillOpacity="0.17"
          stroke="#35B0F4"
        ></rect>
        <rect
          x="195.5"
          y="3.02945"
          width="115"
          height="38"
          rx="12.5"
          stroke="#E755FF"
        ></rect>
        <line x1="167" y1="30.5" x2="196" y2="30.5" stroke="#E755FF"></line>
        <rect
          x="0.5"
          y="3.02945"
          width="116"
          height="38"
          rx="12.5"
          stroke="#35B0F4"
        ></rect>
        <line x1="153" y1="8.5" x2="114" y2="8.5" stroke="#35B0F4"></line>
      </svg>
      <HStack
        style={{ position: "relative", top: "-10px" }}
        w="full"
        alignItems="flex-start"
      >
        <Text w="100%">Hold top button to change mode</Text>
        <Text w="100%" textAlign="right">
          Adjust time with bottom buttons
        </Text>
      </HStack>
    </VStack>
  );
};

export { TempoTrainerReadout };
