import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sessionType } from "../../../types/session";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface SessionTypesState {
  lastUpdated: string;
  sessionTypes: sessionType[];
}

export interface SessionTypesStateUpdate {
  sessionTypes: sessionType[];
}

// Define an initial state
const initialState: SessionTypesState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
  sessionTypes: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const sessionsSlice = createSlice({
  name: "sessionTypesState",
  initialState,
  reducers: {
    updateSessionTypesState(
      state,
      action: PayloadAction<SessionTypesStateUpdate | null>
    ) {
      if (action.payload) {
        state.lastUpdated = new Date().toISOString();
        state.sessionTypes = action.payload.sessionTypes;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.sessionTypes = initialState.sessionTypes;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateSessionTypesState =
  sessionsSlice.actions.updateSessionTypesState;

export const selectSessionTypesState = (
  state: RootState
): SessionTypesState => {
  return state.sessionTypesState;
};

// Export default the slice reducer
export default sessionsSlice.reducer;
