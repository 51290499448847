import {
  Button,
  GridItem,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  selectActivitiesState,
  updateActivitiesState,
} from "../../app/features/activity/activitiesSlice";
import { updateActivityState } from "../../app/features/activity/activitySlice";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ActivityList } from "../../components/activities/ActivityList";
import { YourCoach } from "../../components/coaches/YourCoach";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { NotAuthorised } from "../../components/generic/NotAuthorised";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import Loading from "../../components/ui/Loading";
import { getActivities } from "../../DataAccess/activities";
import { checkRole } from "../../utils/authHelper";
import { formatDateTime } from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";

const ActivitiesPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { user } = useAppSelector(selectUserState);
  const { lastUpdated: lastUpdatedActivities } = useAppSelector(
    selectActivitiesState
  );
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/activities", title: "Activities" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = async () => {
    try {
      toast.closeAll();
      setRefreshing(true);
      dispatch(updateActivityState(null));
      const { activities, last } = await getActivities();
      dispatch(
        updateActivitiesState({
          lastActivity: last,
          lastLoad: activities.length,
          activities: activities,
        })
      );
      setRefreshing(false);
    } catch (error) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Refersh Activities"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to refresh, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setRefreshing(false);
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (!checkRole(accessToken, "read", "activity")) {
    return <NotAuthorised size={"full"} functionText={"activities"} />;
  }

  if (refreshing) {
    return <Loading message="Refreshing activities" />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        Activities
      </Heading>
      <SimpleGrid columns={12} columnGap={2} rowGap={2} w="full">
        <GridItem colSpan={[12, 12, 6, 6]}>
          <Text>
            <b>Activities Last Updated:</b>{" "}
            {formatDateTime(lastUpdatedActivities)}
            <Icon
              ml={1}
              as={IoMdRefresh}
              cursor={"pointer"}
              color={refreshing ? "grey" : "green"}
              onClick={refresh}
            />
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 6]} textAlign={"right"}>
          {checkRole(accessToken, "create", "activity") && (
            <Button
              w={["full", "full", "fit-content", "fit-content"]}
              ml={[0, 0, 2, 2]}
              mb={[2, 2, 0, 0]}
              onClick={() => {
                navigate("/activities/upload/fit", { replace: false });
              }}
            >
              Upload FIT File
            </Button>
          )}
          {checkRole(accessToken, "create", "activity") && (
            <Button
              w={["full", "full", "fit-content", "fit-content"]}
              ml={[0, 0, 2, 2]}
              mb={[2, 2, 0, 0]}
              onClick={() => {
                navigate("/activities/create", { replace: false });
              }}
            >
              Create Manual Activity
            </Button>
          )}
        </GridItem>
        <GridItem colSpan={[12, 12, 9, 9]}>
          <ActivityList />
        </GridItem>
        <GridItem colSpan={[12, 12, 3, 3]}>
          <YourCoach />
        </GridItem>
      </SimpleGrid>
    </VStack>
  );
};

export { ActivitiesPage };
