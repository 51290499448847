import {
  BoxProps,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";

interface CustomModalProps extends BoxProps {
  showButtonText: string;
  showButtonColour: string;
  confirmButtonText: string;
  cancelButtonText: string;
  header: string;
  confirmFunction: any;
}

const CustomModal: React.FC<CustomModalProps> = ({
  showButtonText,
  showButtonColour,
  confirmButtonText,
  cancelButtonText,
  header,
  confirmFunction,
  children,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  return (
    <>
      <Button
        bg={showButtonColour}
        _hover={{ backgroundColor: showButtonColour, transform: "scale(1.05)" }}
        color="black"
        onClick={onOpen}
        w={rest.w ? rest.w : 60}
      >
        {showButtonText}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button mr={3} isDisabled={isProcessing} onClick={onClose}>
              {cancelButtonText}
            </Button>
            <Button
              variant="success"
              isLoading={isProcessing}
              onClick={() => {
                setIsProcessing(true);
                confirmFunction().then(() => {
                  setIsProcessing(false);
                  onClose();
                });
              }}
            >
              {confirmButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export { CustomModal };
