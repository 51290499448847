import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCssEntryById } from "../../DataAccess/css";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { CssDisplay } from "../../components/css/CssDisplay";
import Loading from "../../components/ui/Loading";
import { breadcrumbLink } from "../../types/breadcrumb";
import { cssEntry } from "../../types/css";

const ViewCssPage = () => {
  const [cssEntry, setCssEntry] = useState<cssEntry>();
  const { cssEntryId } = useParams<{
    cssEntryId: string;
  }>();

  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push(
      {
        href: "/css/history",
        title: "CSS History",
      },
      {
        href: "/css/" + cssEntryId,
        title: "CSS Entry",
      }
    );
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getCssEntryLocal = async () => {
      if (cssEntryId) {
        try {
          setCssEntry(await getCssEntryById(cssEntryId));
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    getCssEntryLocal();
  }, [cssEntryId]);

  if (!cssEntryId) {
    return <Loading message={"Required Params Not Sent"} />;
  }

  if (!cssEntry) {
    return <Loading message={"Loading CSS Entry"} />;
  }

  return (
    <CssDisplay
      mode={"VIEW"}
      cssResult={cssEntry}
      resetCalculation={() => {}}
    />
  );
};

export { ViewCssPage };
