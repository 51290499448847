import {
  Box,
  BoxProps,
  SimpleGrid,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import "react-circular-progressbar/dist/styles.css";
import { activity } from "../../types/activity";
interface ActivityProcessingProgressItemProps extends BoxProps {
  activity: activity;
}

const ActivityProcessingProgress: React.FC<
  ActivityProcessingProgressItemProps
> = ({ activity }) => {
  const processedColour = useColorModeValue(
    "ssNeonGreenDark",
    "ssNeonGreenLight"
  );
  const errorColour = useColorModeValue("ssNeonPinkDark", "ssNeonPinkLight");
  const notProcessedColour = useColorModeValue("grey", "grey");

  return (
    <SimpleGrid columns={4} columnGap={1} mt={2} mb={0}>
      <Tooltip hasArrow label="Activity created">
        <Box
          borderTopLeftRadius={8}
          borderBottomRightRadius={8}
          h={2}
          w={8}
          bg={processedColour}
        ></Box>
      </Tooltip>
      {activity.has.wearable && (
        <Tooltip
          hasArrow
          label={
            activity.processed.wearable
              ? "Wearable data processed"
              : "Wearable data not processed"
          }
        >
          <Box
            borderTopLeftRadius={8}
            borderBottomRightRadius={8}
            h={2}
            w={8}
            bg={
              activity.processed.wearable ? processedColour : notProcessedColour
            }
          ></Box>
        </Tooltip>
      )}
      {!activity.has.wearable && (
        <Tooltip hasArrow label={"No wearable data found"}>
          <Box
            borderTopLeftRadius={8}
            borderBottomRightRadius={8}
            h={2}
            w={8}
            bg={
              activity.processed.wearable ? processedColour : notProcessedColour
            }
          ></Box>
        </Tooltip>
      )}
      {activity.has.motion && !activity.data.summary.counts.insightGroups && (
        <Tooltip hasArrow label={"Motion counts not present"}>
          <Box
            borderTopLeftRadius={8}
            borderBottomRightRadius={8}
            h={2}
            w={8}
            bg={errorColour}
          ></Box>
        </Tooltip>
      )}
      {activity.has.motion &&
        activity.data.summary.counts.insightGroups &&
        activity.data.summary.counts.insightGroups.all > 0 && (
          <Tooltip hasArrow label={"Motion data processed"}>
            <Box
              borderTopLeftRadius={8}
              borderBottomRightRadius={8}
              h={2}
              w={8}
              bg={processedColour}
            ></Box>
          </Tooltip>
        )}
      {activity.has.motion &&
        activity.data.summary.counts.insightGroups &&
        activity.data.summary.counts.insightGroups.all === 0 && (
          <Tooltip
            hasArrow
            label={
              activity.processed.motion
                ? "Motion data processing failed"
                : "Motion data processing"
            }
          >
            <Box
              borderTopLeftRadius={8}
              borderBottomRightRadius={8}
              h={2}
              w={8}
              bg={activity.processed.motion ? errorColour : notProcessedColour}
            ></Box>
          </Tooltip>
        )}
      {!activity.has.motion && (
        <Tooltip hasArrow label={"No motion data found"}>
          <Box
            borderTopLeftRadius={8}
            borderBottomRightRadius={8}
            h={2}
            w={8}
            bg={notProcessedColour}
          ></Box>
        </Tooltip>
      )}
      <Tooltip
        hasArrow
        label={
          activity.processed.overall
            ? "Processing complete"
            : "Processing not complete"
        }
      >
        <Box
          borderTopLeftRadius={8}
          borderBottomRightRadius={8}
          h={2}
          w={8}
          bg={activity.processed.overall ? processedColour : notProcessedColour}
        ></Box>
      </Tooltip>
    </SimpleGrid>
  );
};

export { ActivityProcessingProgress };
