import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";
import { CssManualCapture } from "../../components/css/CssManualCapture";
import { breadcrumbLink } from "../../types/breadcrumb";

const CssEnterSingle = (props: any) => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/tools", title: "Tools" });
    breadcrumbLinks.push({
      href: "/css/entersingle",
      title: "CSS Manual Entry",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading>Manual Critical Swim Speed Entry</Heading>
      <Text>
        Critical Swim Speed (CSS) has been used by Swim Smooth for over 15 years as our primary method of determining your 1500m threshold pace. We recommend{" "}
        <Button
          variant="link"
          onClick={() => {
            navigate("/css/calcsingle", { replace: false });
          }}
        >
          performing a CSS test
        </Button>{" "}
        to determine you CSS pace you can enter a pace here if you are sure it is correct.
      </Text>
      <CssManualCapture />
    </VStack>
  );
};

export { CssEnterSingle };
