import { Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { GarminLogo } from "../svg/GarminLogo";

const TopBanner = () => {
  const bannerColour = useColorModeValue("green.400", "green.700");
  const garminLogoColour = useColorModeValue("#002140", "white");
  return (
    <VStack
      bg={bannerColour}
      w="full"
      px={[5, 5, 20, 20]}
      pt={2}
      pb={5}
      justifyContent={"center"}
    >
      <GarminLogo height={50} width={100} colour={garminLogoColour} />
      <Text textAlign={"center"}>
        The wait <em>is</em> over! Stroke Insights&#8471; are now available on
        your
        <sup>*</sup> Garmin device using the{" "}
        <a href="https://apps.garmin.com/apps/b4ea2e83-ec07-4fec-80ad-6b1b67f951d0?tid=1">
          Swim Smooth ConnectIQ app
        </a>
        .
      </Text>
      <Text fontSize={"x-small"}>
        <sup>*</sup>Not all Garmin devices will support Stroke Insights&#8471;
      </Text>
    </VStack>
  );
};

export { TopBanner };
