import {
  Box,
  BoxProps,
  Heading,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { trainingTimeline } from "../../types/trainingTimeline";
import { user } from "../../types/user";
import { ThisWeekEvent } from "./ThisWeekEvent";
import { ThisWeekSession } from "./ThisWeekSession";

interface ThisWeekProps extends BoxProps {
  user: user;
  trainingTimeline: trainingTimeline;
}

const ThisWeek: React.FC<ThisWeekProps> = ({ user, trainingTimeline }) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );

  return (
    <Box
      bg={boxColorMode}
      p={5}
      borderRadius={10}
      w="full"
      minWidth="50%"
      h="full"
    >
      <VStack w="full" mb={2}>
        <Heading as="h3" size="lg">
          This Week
        </Heading>
      </VStack>
      {trainingTimeline.data.weeks.has.thisWeek && (
        <>
          <Heading as="h5" size="sm" mb={2}>
            {trainingTimeline.data.weeks.thisWeek?.comment.short}
          </Heading>
          <Text mb={5}>
            {trainingTimeline.data.weeks.thisWeek?.comment.long.map(
              (line: string) => {
                return line;
              }
            )}
          </Text>
          {trainingTimeline.data.weeks.thisWeek?.coachSays &&
            trainingTimeline.data.weeks.thisWeek?.coachSays.length > 0 && (
              <Box mb={5}>
                <Heading as="h5" size="sm" mb={2}>
                  Coach Says ...
                </Heading>
                {trainingTimeline.data.weeks.thisWeek?.coachSays.map(
                  (line: any, lineIndex: number) => {
                    return (
                      <Text key={lineIndex} mb={2}>
                        {line.data}
                      </Text>
                    );
                  }
                )}
              </Box>
            )}
          <Heading as="h5" size="sm" mb={2}>
            Sessions
          </Heading>
          {trainingTimeline.data.weeks.thisWeek?.sessions.map(
            (session: any, sessionIndex: number) => {
              if (session.type === "Session") {
                return (
                  <ThisWeekSession
                    key={session._id + "" + sessionIndex}
                    session={session}
                    lapUnit={user.data.basic.defaultPool.lapUnit}
                  />
                );
              } else if (session.type === "Event") {
                return (
                  <ThisWeekEvent
                    key={session._id + "" + sessionIndex}
                    session={session}
                    lapUnit={user.data.basic.defaultPool.lapUnit}
                  />
                );
              }
              return <Text>Error</Text>;
            }
          )}
        </>
      )}
    </Box>
  );
};

export { ThisWeek };
