import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  Tooltip,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";
import { GiPathDistance } from "react-icons/gi";
import { IoFlame, IoTimerOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { session, sessionType } from "../../types/session";
import { user } from "../../types/user";
import { checkLevelInArray, getLevelInSentence } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import { CustomToast } from "../ui/CustomToast";

interface SessionListItemProps {
  user: user;
  sessionType: sessionType;
  session: session;
  linkActivityId?: string | null;
}

const SessionListItem: React.FC<SessionListItemProps> = ({
  user,
  sessionType,
  session,
  linkActivityId,
}) => {
  const toast = useToast();
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const navigate = useNavigate();

  return (
    <Box
      cursor="pointer"
      bg={boxColorMode}
      borderRadius={10}
      opacity={!checkLevelInArray(accessToken, session.data.levels) ? 0.5 : 1}
      onClick={() => {
        if (checkLevelInArray(accessToken, session.data.levels)) {
          if (linkActivityId) {
            navigate(
              "/sessions/id/" +
                session.id +
                "?linkActivityId=" +
                linkActivityId,
              { replace: false }
            );
          } else {
            navigate("/sessions/id/" + session.id, {
              replace: false,
            });
          }
        } else {
          createToast(toast, (props: any) => {
            return (
              <CustomToast
                title={"Upgrade Required"}
                status={"Warning"}
                toast={toast}
                toastId={props.id}
              >
                {session.data.levels && (
                  <>
                    <Text>
                      '{session.data.name}' requires one of the following
                      subscriptions,
                    </Text>
                    <UnorderedList>
                      {session.data.levels.map((l: string, index: number) => {
                        return <ListItem key={index}>{l}</ListItem>;
                      })}
                    </UnorderedList>
                    <Text>
                      You currently have {getLevelInSentence(accessToken)}.
                    </Text>
                  </>
                )}
                <Button
                  w={"full"}
                  mt={2}
                  onClick={() => {
                    navigate("/account/subscription", {
                      replace: false,
                    });
                    toast.closeAll();
                  }}
                >
                  Upgrade Now
                </Button>
              </CustomToast>
            );
          });
        }
      }}
    >
      <HStack alignItems="flex-start">
        {session.data.images.has && (
          <Image
            src={session.data.images.data.preview}
            borderLeftRadius={10}
            h={[100, 100, 200, 200]}
            w={[100, 100, 200, 200]}
            fit="cover"
            fallbackSrc="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
          />
        )}
        {!session.data.images.has && (
          <Image
            borderLeftRadius={10}
            h={[100, 100, 200, 200]}
            w={[100, 100, 200, 200]}
            fit="cover"
            src="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
          />
        )}
        <VStack p={2} alignItems="flex-start" w="full">
          <Text fontSize="smaller">{session.data.typeName}</Text>
          <Heading size="md" as="h3" noOfLines={1}>
            {session.data.name}
          </Heading>

          {checkLevelInArray(accessToken, session.data.levels) && (
            <>
              <HStack w={"full"} mt={3}>
                <Tooltip hasArrow label="Distance">
                  <span>
                    <Icon
                      h={6}
                      w={6}
                      color="grey"
                      viewBox="0 0 100 100"
                      mr={2}
                      as={GiPathDistance}
                    />
                    <Box
                      display="inline-block"
                      position="relative"
                      top="-6px"
                      mr={5}
                    >
                      {session.data.scaled.distance}
                    </Box>
                  </span>
                </Tooltip>
                <Tooltip hasArrow label="Expected Time">
                  <span>
                    <Icon h={6} w={6} color="grey" mr={2} as={IoTimerOutline} />
                    <Box
                      display="inline-block"
                      position="relative"
                      top="-6px"
                      mr={5}
                    >
                      {session.data.scaled.time}
                    </Box>
                  </span>
                </Tooltip>
                <Tooltip hasArrow label="sTSS">
                  <span>
                    <Icon
                      h={6}
                      w={6}
                      color="grey"
                      mr={2}
                      as={MdModelTraining}
                    />
                    <Box display="inline-block" position="relative" top="-6px">
                      {session.data.scaled.stss
                        ? session.data.scaled.stss.toFixed(2)
                        : 0}
                    </Box>
                  </span>
                </Tooltip>
              </HStack>
              <Tooltip hasArrow label="Intensity">
                <span>
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.data.scaled.intensity > 0
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.data.scaled.intensity > 1
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.data.scaled.intensity > 2
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.data.scaled.intensity > 3
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    as={IoFlame}
                    fill={
                      session.data.scaled.intensity > 4
                        ? intensityColorMode
                        : "grey"
                    }
                  />
                </span>
              </Tooltip>
            </>
          )}
          {!checkLevelInArray(accessToken, session.data.levels) && (
            <>
              <Text fontSize={"small"}>
                <Icon
                  h={15}
                  w={15}
                  mr={2}
                  as={FaLock}
                  color={intensityColorMode}
                />
                This session requires:
              </Text>
              <UnorderedList mt={-2} fontSize={"small"}>
                <SimpleGrid columns={2}>
                  {session.data.levels.map((s: string) => {
                    return <ListItem key={s.toLowerCase()}>{s}</ListItem>;
                  })}
                </SimpleGrid>
              </UnorderedList>
            </>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};

export { SessionListItem };
