import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { CssTestCapture } from "../../components/css/CssTestCapture";
import { breadcrumbLink } from "../../types/breadcrumb";

const CssCalcSingle = (props: any) => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/tools", title: "Tools" });
    breadcrumbLinks.push({
      href: "/css/calcsingle",
      title: "CSS Calculator",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading>The Swim Smooth Critical Swim Speed Calculator</Heading>
      <Text>
        Critical Swim Speed (CSS) has been used by Swim Smooth for over 15 years
        as our primary method of determining your 1500m threshold pace. If you
        know your CSS pace but don't have the test details you can{" "}
        <Button
          variant="link"
          onClick={() => {
            navigate("/css/entersingle", { replace: false });
          }}
        >
          add a manual CSS pace
        </Button>
        . Heading to the pool to do a test? Why not take the{" "}
        <Button
          onClick={() => {
            navigate(
              "/sessions/id/bcf5a54e-9e70-4814-9bb1-771e9f1c4874/1500/1500",
              { replace: false }
            );
          }}
          variant={"link"}
        >
          CSS Test
        </Button>{" "}
        session with you? .
      </Text>
      <CssTestCapture />
    </VStack>
  );
};

export { CssCalcSingle };
