import { Heading, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { UserLookup } from "../../components/admin/user/UserLookup";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { checkRole } from "../../utils/authHelper";

const UserLookupPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks = [
      { href: "/admin", title: "Admin" },
      { href: "/admin/user", title: "User" },
      { href: "/admin/user/lookup", title: "Lookup" },
    ];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checkRole(accessToken, "*ALL", "admin")) {
    return null;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        User Lookup
      </Heading>
      <UserLookup />
    </VStack>
  );
};

export { UserLookupPage };
