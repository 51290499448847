import {
  Box,
  BoxProps,
  Button,
  Center,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCoaches } from "../../DataAccess/coaches";
import {
  selectCoachesState,
  updateCoachesState,
} from "../../app/features/coach/coachesSlice";
import { selectLocationState } from "../../app/features/location/locationSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { coach } from "../../types/coach";
import { defaultSortAndFilter } from "../../utils/coachHelper";
import Loading from "../ui/Loading";

interface YourCoachProps extends BoxProps {}

const YourCoach: React.FC<YourCoachProps> = () => {
  const [yourCoach, setYourCoach] = useState<coach | undefined>();
  const [type, setType] = useState<string>("");
  const [loadingCoaches, setLoadingCoaches] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const { latitude, longitude } = useAppSelector(selectLocationState);
  const { coaches, lastUpdated, region, viewMode } =
    useAppSelector(selectCoachesState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );

  useEffect(() => {
    const getCoachesLocal = async () => {
      try {
        setLoadingCoaches(true);
        const coaches = await getCoaches();
        dispatch(
          updateCoachesState({
            coaches: coaches,
            region: region,
            viewMode: viewMode,
          })
        );
      } catch (error) {
        console.log(error);
      }
      setLoadingCoaches(false);
    };
    if (lastUpdated) {
      const test = new Date(lastUpdated).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (anHourAgo > test) {
        getCoachesLocal();
      } else {
        setLoadingCoaches(false);
      }
    } else {
      getCoachesLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (coaches) {
      const yourCoach = defaultSortAndFilter(
        coaches,
        latitude,
        longitude
      ).filter((c: coach) => c.data.myCoach);
      if (yourCoach.length === 1) {
        setYourCoach(yourCoach[0]);
        setType("*COACH");
      } else {
        const distanceCoaches = defaultSortAndFilter(
          coaches,
          latitude,
          longitude
        )
          .filter(
            (c: coach) =>
              c.data.regional &&
              c.data.distanceFromYou &&
              c.data.distanceFromYou < 384400
          )
          .sort((a: coach, b: coach) => {
            if (a.data.distanceFromYou && b.data.distanceFromYou) {
              return a.data.distanceFromYou - b.data.distanceFromYou;
            }
            return 0;
          });
        if (distanceCoaches.length > 0) {
          setYourCoach(distanceCoaches[0]);
          setType("*DISTANCE");
        } else {
          const headCoach = coaches.filter(
            (c: coach) => c.data.shortCode === "paul"
          );
          if (headCoach.length === 1) {
            setYourCoach(headCoach[0]);
            setType("*DEFAULT");
          }
        }
      }
      setLoading(false);
    }
  }, [coaches, latitude, longitude]);

  if (loadingCoaches) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} w="full" height="100%">
        <Heading mb={5} size="md">
          Your Coach
        </Heading>
        <Loading message="Getting Coaches" />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} w="full" height="100%">
        <Heading mb={5} size="md">
          Your Coach
        </Heading>
        <Loading message="Getting YOUR Coach" />
      </Box>
    );
  }

  if (!yourCoach) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} w="full" height="100%">
        <Heading mb={5} size="md">
          Your Coach
        </Heading>
        <Text>Coach not found</Text>
      </Box>
    );
  }

  return (
    <Box bg={boxColorMode} borderRadius={10} w="full" height="100%">
      <Image
        src={
          yourCoach.data.images.length > 0
            ? yourCoach.data.images[0].src + "?w=700&h=700&f=face&fit=fill"
            : "https://images.ctfassets.net/50b15ahactsg/2bjEpuoUucy7OzxXezpZkt/2f92a378f22c69fbba09f6803b313ffd/460x0w.png?w=300&h=300&f=face&fit=fill"
        }
        alt={yourCoach.data.displayName}
        borderTopRadius={8}
        fit={"cover"}
        w={"full"}
      />

      <Box py={4} px={5} h={"full"}>
        {type === "*COACH" && (
          <Heading
            fontWeight="bold"
            fontSize="lg"
            as="h4"
            lineHeight="tight"
            noOfLines={1}
          >
            Your Coach:{" "}
            {yourCoach.data.displayName ||
              yourCoach.data.firstName + " " + yourCoach.data.lastName}
          </Heading>
        )}
        {type === "*MENTOR" && (
          <Heading
            fontWeight="bold"
            fontSize="lg"
            as="h4"
            lineHeight="tight"
            noOfLines={1}
          >
            Your Mentor:{" "}
            {yourCoach.data.displayName ||
              yourCoach.data.firstName + " " + yourCoach.data.lastName}
          </Heading>
        )}
        {type === "*DISTANCE" && (
          <Heading
            fontWeight="bold"
            fontSize="lg"
            as="h4"
            lineHeight="tight"
            noOfLines={1}
          >
            Your Nearest Coach:{" "}
            {yourCoach.data.displayName ||
              yourCoach.data.firstName + " " + yourCoach.data.lastName}
          </Heading>
        )}
        {type === "*DEFAULT" && (
          <Heading
            fontWeight="bold"
            fontSize="lg"
            as="h4"
            lineHeight="tight"
            noOfLines={1}
          >
            Swim Smooth Head Coach:{" "}
            {yourCoach.data.displayName ||
              yourCoach.data.firstName + " " + yourCoach.data.lastName}
          </Heading>
        )}

        <Text>{yourCoach.data.brandName || "-"}</Text>
        <Text>{yourCoach.data.brandLocation || "-"}</Text>
        {yourCoach.data.distanceFromYou &&
          yourCoach.data.distanceFromYou > -1 &&
          yourCoach.data.distanceFromYou < 384400 && (
            <Text color="gray.500" fontSize="xs">
              ~
              {Number(
                Math.ceil(yourCoach.data.distanceFromYou)
              ).toLocaleString()}
              km from you
            </Text>
          )}
        <Center mt={2}>
          <Button
            w="full"
            onClick={() =>
              navigate("/coaches/" + yourCoach.data.shortCode, {
                replace: false,
              })
            }
          >
            View {yourCoach.data.firstName}'s Profile
          </Button>
        </Center>
      </Box>
    </Box>
  );
};

export { YourCoach };
