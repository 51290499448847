import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectUserState,
  updateUserState,
} from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { NotAuthorised } from "../../components/generic/NotAuthorised";
import { GarminLogo } from "../../components/svg/GarminLogo";
import { StravaLogo } from "../../components/svg/StravaLogo";
import { BoxWrapper } from "../../components/ui/BoxWrapper";
import Loading from "../../components/ui/Loading";
import {
  disconnectGarmin,
  getGarminRequestToken,
} from "../../DataAccess/garmin";
import { getUserById } from "../../DataAccess/users";
import { breadcrumbLink } from "../../types/breadcrumb";
import { checkRole } from "../../utils/authHelper";

const ConnectionsPage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [connectingGarmin, setConnectingGarmin] = useState<boolean>(false);
  const [disconnectingGarmin, setDisconnectingGarmin] =
    useState<boolean>(false);

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);

  const logoColorMode = useColorModeValue("#002140", "#78DFFF");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/account", title: "Account" });
    breadcrumbLinks.push({ href: "/account/settings", title: "Settings" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  const garminConnectHandler = async () => {
    setConnectingGarmin(true);
    const requestToken = await getGarminRequestToken();
    const callbackUri: string = process.env
      .REACT_APP_CALLBACK_ROOT_URL as string;
    if (requestToken) {
      window.location.href =
        "https://connect.garmin.com/oauthConfirm?" +
        requestToken +
        "&oauth_callback=" +
        encodeURI(callbackUri) +
        "account/connections/garmin/oauth";
    }
    setConnectingGarmin(false);
  };

  const garminDisconnectHandler = async () => {
    setDisconnectingGarmin(true);
    await disconnectGarmin();
    const userResponse = await getUserById(user._id);
    if (userResponse) {
      dispatch(updateUserState(userResponse));
    }
    setDisconnectingGarmin(false);
  };

  if (
    !checkRole(accessToken, "garmin", "connections") &&
    !checkRole(accessToken, "strava", "connections")
  ) {
    return (
      <NotAuthorised
        title={"Connections"}
        functionText={"Connections"}
        size={"full"}
      />
    );
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <Heading as="h2" size="xl" mb={4}>
        Connections
      </Heading>
      <SimpleGrid columns={[1, 1, 2, 2]} rowGap={5} columnGap={10} w="full">
        <BoxWrapper>
          <SimpleGrid w="full" columns={[1, 1, 2, 2]}>
            <Box px={5}>
              <GarminLogo height={200} width={200} colour={logoColorMode} />
            </Box>
            <VStack w="full" alignItems="flex-start">
              <Heading as="h4" size="small">
                Garmin
              </Heading>
              <Text>
                Connect your Garmin account to automatically import and process
                your swims with your Garmin device.
              </Text>
              {user.settings.integrations.garmin &&
                user.settings.integrations.garmin.connected && (
                  <>
                    <Text pb={2}>
                      Status:{" "}
                      <Box as="span" color="green">
                        <strong>Connected</strong>
                      </Box>
                    </Text>
                    <Button
                      variant="warning"
                      isLoading={disconnectingGarmin}
                      onClick={garminDisconnectHandler}
                    >
                      Disconnect
                    </Button>
                  </>
                )}
              {!user.settings.integrations.garmin ||
                (user.settings.integrations.garmin &&
                  !user.settings.integrations.garmin.connected && (
                    <>
                      <Text pb={2}>
                        Status:{" "}
                        <Box as="span" color="red">
                          <strong>Not Connected</strong>
                        </Box>
                      </Text>
                      <Button
                        isLoading={connectingGarmin}
                        onClick={garminConnectHandler}
                      >
                        Connect
                      </Button>
                    </>
                  ))}
            </VStack>
          </SimpleGrid>
        </BoxWrapper>
        <BoxWrapper>
          <SimpleGrid w="full" columns={[1, 1, 2, 2]}>
            <Box px={5}>
              <StravaLogo height={200} width={200} colour={logoColorMode} />
            </Box>
            <VStack w="full" alignItems="flex-start">
              <Heading as="h4" size="small">
                Strava
              </Heading>
              <Text>
                Connect your Strava account to automatically export your Swim
                Smooth activites to Strava.
              </Text>
              <Text>
                Status:{" "}
                <Box as="span" color="gray">
                  <strong>Coming Soon</strong>
                </Box>
              </Text>
            </VStack>
          </SimpleGrid>
        </BoxWrapper>
      </SimpleGrid>
    </VStack>
  );
};

export { ConnectionsPage };
