import { Badge, BoxProps, Tooltip } from "@chakra-ui/react";

interface BetaBadgeProps extends BoxProps {}

const BetaBadge: React.FC<BetaBadgeProps> = ({ ...rest }) => {
  return (
    <Tooltip
      hasArrow
      label="The term “BETA” implies that we are still testing and validating some of these features. We'd love your feedback on their function and how well they are working for you!"
    >
      <Badge color="white" bg="#E755FF" borderRadius={6} px={2} ml={1} fontSize="0.75rem" mt={-1}>
        Beta
      </Badge>
    </Tooltip>
  );
};

export { BetaBadge };
