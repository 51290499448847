import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { coachAthlete } from "../../../types/coachAthlete";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface CoachAthletesState {
  lastUpdated: string;
  coachAthletes: coachAthlete[];
}

// Define an initial state
const initialState: CoachAthletesState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
  coachAthletes: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const coachAthletesSlice = createSlice({
  name: "coachAthletesState",
  initialState,
  reducers: {
    updateCoachAthletesState(
      state,
      action: PayloadAction<coachAthlete[] | null>
    ) {
      if (action.payload) {
        state.lastUpdated = new Date().toISOString();
        state.coachAthletes = action.payload;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.coachAthletes = [];
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateCoachAthletesState =
  coachAthletesSlice.actions.updateCoachAthletesState;

export const selectCoachAthletesState = (
  state: RootState
): CoachAthletesState => {
  return state.coachAthleteState;
};

// Export default the slice reducer
export default coachAthletesSlice.reducer;
