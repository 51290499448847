import {
  BoxProps,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  getAffiliateByUserId,
  updateAffiliate,
} from "../../DataAccess/affiliates";
import {
  selectAffiliateState,
  updateAffiliateState,
} from "../../app/features/affiliate/affiliateSlice";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AffiliateNotSetup } from "../../components/coachAthletes/AffiliateNotSetup";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { NotAuthorised } from "../../components/generic/NotAuthorised";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import { HtmlEditorBasic } from "../../components/ui/HtmlEditorBasic";
import Loading from "../../components/ui/Loading";
import LoadingMulti from "../../components/ui/LoadingMulti";
import { affiliate } from "../../types/affiliate";
import { breadcrumbLink } from "../../types/breadcrumb";
import { checkRole } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "ss-affiliate-signup": any;
      "ss-affiliate-link": any;
    }
  }
}

const AffiliateWidgetsPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [loadingAffiliate, setLoadingAffiliate] = useState<boolean>(true);
  const { user } = useAppSelector(selectUserState);
  const { affiliate } = useAppSelector(selectAffiliateState);
  const toast = useToast();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getAffiliateByUserIdLocal = async () => {
      try {
        if (user) {
          const response = await getAffiliateByUserId(user._id);
          dispatch(updateAffiliateState(response));
        }
        setLoadingAffiliate(false);
      } catch (error) {
        setLoadingAffiliate(false);
      }
    };
    getAffiliateByUserIdLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (affiliate) {
      setValueAffiliate("background", affiliate.data.colours.background);
      setValueAffiliate(
        "buttonHighlight",
        affiliate.data.colours.buttonHighlight
      );
      setValueAffiliate(
        "buttonBackground",
        affiliate.data.colours.buttonBackground
      );
      setValueAffiliate("border", affiliate.data.colours.border);
      setValueAffiliate("inputBorder", affiliate.data.colours.inputBorder);
      setValueAffiliate("logo", affiliate.data.colours.logo);
      setValueAffiliate("text", affiliate.data.colours.text);
      setValueAffiliate("errorText", affiliate.data.colours.errorText);
      setValueAffiliate("title", affiliate.data.colours.title);
      setValueAffiliate("customText", affiliate.data.customText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate]);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/account", title: "Account" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control: controlAffiliate,
    handleSubmit: handleSubmitAffiliate,
    setValue: setValueAffiliate,
    formState: { isSubmitting: isSubmittingAffiliate },
  } = useForm({
    defaultValues: {
      background: "",
      buttonHighlight: "",
      buttonBackground: "",
      border: "",
      inputBorder: "",
      logo: "",
      text: "",
      errorText: "",
      title: "",
      customText: "",
    },
    mode: "all",
  });

  const handleAffiliate = async (data: any): Promise<void> => {
    toast.closeAll();
    try {
      if (affiliate) {
        const updatedAffiliate = (await updateAffiliate(affiliate._id, {
          colours: {
            background: data.background,
            buttonHighlight: data.buttonHighlight,
            buttonBackground: data.buttonBackground,
            border: data.border,
            inputBorder: data.inputBorder,
            logo: data.logo,
            text: data.text,
            errorText: data.errorText,
            title: data.title,
          },
          customText: data.customText,
        })) as unknown as affiliate;
        dispatch(updateAffiliateState(updatedAffiliate));
      }
      window.location.reload();
    } catch (error) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Setup Widget"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to setup widget, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  if (loadingAffiliate) {
    return (
      <LoadingMulti
        messages={[
          "Loading Affiliate",
          "Loading User",
          "Loading Squad members",
        ]}
      />
    );
  }

  if (!checkRole(accessToken, "affiliates", "widgets")) {
    return <NotAuthorised functionText={"Affiliates"} size={"full"} />;
  }

  if (!affiliate) {
    return <AffiliateNotSetup />;
  }

  const signup = `<ss-affiliate-signup affiliateId="${affiliate._id}">
<div slot="h-captcha" class="h-captcha" data-sitekey="${process.env.REACT_APP_HCAPTCHA_SITE_KEY}"></div>
</ss-affiliate-signup>`;
  const signupScript = `
<script src="${process.env.REACT_APP_CALLBACK_ROOT_URL}wc/ssAffiliateSignup.js" defer></script>`;

  const link = `<ss-affiliate-link affiliateId="${affiliate._id}" />`;
  const linkScript = `
<script src="${process.env.REACT_APP_CALLBACK_ROOT_URL}wc/ssAffiliateLink.js" defer></script>`;

  const basicLinkRaw = `${process.env.REACT_APP_CALLBACK_ROOT_URL}account/create?affiliateId=${affiliate._id}`;
  const basicLink = `<a href="${basicLinkRaw}" target="_blank">
  Signup now at swimsmooth.guru
</a>`;

  return (
    <VStack w="full" alignItems={"flex-start"}>
      <Heading as="h2" size="xl" mb={4}>
        Athlete Widgets
      </Heading>
      <Heading as="h3" size={"lg"} mt={4}>
        Setup
      </Heading>
      <Text>Leave these fields blank to use default values.</Text>
      <VStack
        as="form"
        onSubmit={handleSubmitAffiliate(handleAffiliate)}
        p={5}
        spacing={3}
        w={"full"}
        alignItems="flex-start"
      >
        <VStack w={"full"}>
          <SimpleGrid columns={[1, 1, 3, 3]} columnGap={5} w={"full"}>
            <FormControl pb={1}>
              <FormLabel>Background Colour</FormLabel>
              <Controller
                control={controlAffiliate}
                name="background"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>Button Background Color</FormLabel>
              <Controller
                control={controlAffiliate}
                name="buttonBackground"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>Button Hightlight Colour</FormLabel>
              <Controller
                control={controlAffiliate}
                name="buttonHighlight"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>Widget Border Colour</FormLabel>
              <Controller
                control={controlAffiliate}
                name="border"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>Input Border Colour</FormLabel>
              <Controller
                control={controlAffiliate}
                name="inputBorder"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>Title Colour</FormLabel>
              <Controller
                control={controlAffiliate}
                name="title"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>Text Colour</FormLabel>
              <Controller
                control={controlAffiliate}
                name="text"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>Error Text Colour</FormLabel>
              <Controller
                control={controlAffiliate}
                name="errorText"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>Logo Color</FormLabel>
              <Controller
                control={controlAffiliate}
                name="logo"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
          </SimpleGrid>
        </VStack>
        <FormControl pb={1}>
          <FormLabel>Custom Text</FormLabel>
          <Controller
            control={controlAffiliate}
            name="customText"
            render={({ field: { ref, onChange, ...restField } }) => (
              <HtmlEditorBasic
                onChange={(value: string) => {
                  onChange(value);
                }}
                height={200}
                {...restField}
              />
            )}
          />
        </FormControl>
        <Button isLoading={isSubmittingAffiliate} type="submit">
          Save Widget Setup
        </Button>
      </VStack>
      <Heading as="h3" size={"lg"} mt={4}>
        Signup Widget
      </Heading>
      <UnorderedList mb={5}>
        <ListItem>
          hCaptcha code must be present and untouched or the signup widget will
          not work
        </ListItem>
        <ListItem>
          There must be at most <strong>one</strong> instance of this widget on
          a page
        </ListItem>
        <ListItem>It can co-exist with the link widget below</ListItem>
        <ListItem>
          The script part can be included once in the page header or inline on
          each page if preferred, <strong>BUT</strong> must be included only
          once on a page
        </ListItem>
      </UnorderedList>
      <HStack w={"full"} alignItems={"flex-start"}>
        <pre>
          <code>
            {signup}
            {signupScript}
          </code>
        </pre>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(signup + signupScript);
            createToast(toast, (props: any) => {
              return (
                <CustomToast
                  title={"Copy Signup Widget Code"}
                  status={"Success"}
                  toast={toast}
                  toastId={props.id}
                >
                  <Text>Signup widget code copied to clipboard.</Text>
                </CustomToast>
              );
            });
          }}
        >
          Copy Code
        </Button>
      </HStack>
      <HStack alignItems={"flex-start"}>
        <SignupBox affiliateId={affiliate._id} />
        <SignupBox affiliateId={affiliate._id} step={"*FINISH"} />
      </HStack>
      <Heading as="h3" size={"lg"} mt={4}>
        Link Widget
      </Heading>
      <UnorderedList mb={5}>
        <ListItem>
          There can be multiple instances of this widget on a page
        </ListItem>
        <ListItem>It can co-exist with the signup widget above</ListItem>
        <ListItem>
          The script part can be included once in the page header or inline on
          each page if preferred, <strong>BUT</strong> must be included only
          once on a page
        </ListItem>
      </UnorderedList>
      <HStack w={"full"} alignItems={"flex-start"}>
        <pre>
          <code>
            {link}
            {linkScript}
          </code>
        </pre>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(link + linkScript);
            createToast(toast, (props: any) => {
              return (
                <CustomToast
                  title={"Copy Link Widget Code"}
                  status={"Success"}
                  toast={toast}
                  toastId={props.id}
                >
                  <Text>Link widget code copied to clipboard.</Text>
                </CustomToast>
              );
            });
          }}
        >
          Copy Code
        </Button>
      </HStack>
      <LinkBox affiliateId={affiliate._id} />
      <Heading as="h3" size={"lg"} mt={4}>
        Basic HTML Link
      </Heading>
      <UnorderedList mb={5}>
        <ListItem>
          You can use this as many times as you want on a page, no scripts
          required.
        </ListItem>
      </UnorderedList>
      <HStack w={"full"} alignItems={"flex-start"}>
        <pre>
          <code>{basicLink}</code>
        </pre>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(basicLink);
            createToast(toast, (props: any) => {
              return (
                <CustomToast
                  title={"Basic Link Code"}
                  status={"Success"}
                  toast={toast}
                  toastId={props.id}
                >
                  <Text>Basic link code copied to clipboard.</Text>
                </CustomToast>
              );
            });
          }}
        >
          Copy Code
        </Button>
      </HStack>
      <Text>
        <a href={basicLinkRaw} target="_blank" rel="noreferrer">
          Signup now at swimsmooth.guru
        </a>
      </Text>
      <Heading as="h3" size={"lg"} mt={4}>
        Basic Link (RAW)
      </Heading>
      <UnorderedList mb={5}>
        <ListItem>
          You can use this in emails, linkTree, Instagram, Youtube, etc, etc
        </ListItem>
      </UnorderedList>
      <HStack w={"full"} alignItems={"flex-start"}>
        <pre>
          <code>{basicLinkRaw}</code>
        </pre>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(basicLinkRaw);
            createToast(toast, (props: any) => {
              return (
                <CustomToast
                  title={"Copy Basic Link (RAW) Code"}
                  status={"Success"}
                  toast={toast}
                  toastId={props.id}
                >
                  <Text>Basic link (RAW) code copied to clipboard.</Text>
                </CustomToast>
              );
            });
          }}
        >
          Copy Code
        </Button>
      </HStack>
    </VStack>
  );
};

interface SignupBoxProps extends BoxProps {
  affiliateId: string;
  step?: string;
}

const SignupBox: React.FC<SignupBoxProps> = ({ affiliateId, step }) => {
  if (step) {
    return (
      <ss-affiliate-signup affiliateId={affiliateId} step={step}>
        <div
          slot="h-captcha"
          className="h-captcha"
          data-sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
        ></div>
      </ss-affiliate-signup>
    );
  }
  return (
    <ss-affiliate-signup affiliateId={affiliateId}>
      <div
        slot="h-captcha"
        className="h-captcha"
        data-sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
      ></div>
    </ss-affiliate-signup>
  );
};

interface LinkBoxProps extends BoxProps {
  affiliateId: string;
}

const LinkBox: React.FC<LinkBoxProps> = ({ affiliateId }) => {
  return <ss-affiliate-link affiliateId={affiliateId} />;
};

export { AffiliateWidgetsPage };
