const translateEquipment = (key: string): string => {
  const keys = [
    "pullbuoy",
    "paddles",
    "paddlesagility",
    "paddlesiso",
    "paddlesfreestyler",
    "fins",
    "band",
    "tempotrainerpro",
    "snorkel",
    "techtoc",
    "baton",
    "pullbuoyoptional",
    "paddlesoptional",
    "paddlesagilityoptional",
    "paddlesisooptional",
    "paddlesfreestyleroptional",
    "finsoptional",
    "bandoptional",
    "tempotrainerprooptional",
    "snorkeloptional",
    "techtocoptional",
    "batonoptional",
  ];
  const values = [
    "Pull Buoy",
    "Paddles",
    "Agility Paddles",
    "ISO Paddles",
    "Freestyler Paddles",
    "Fins",
    "Band",
    "Tempo Trainer Pro",
    "Snorkel",
    "Tech Toc",
    "Baton",
    "Pull Buoy (Optional)",
    "Paddles (Optional)",
    "Agility Paddles (Optional)",
    "ISO Paddles (Optional)",
    "Freestyler Paddles (Optional)",
    "Fins (Optional)",
    "Band (Optional)",
    "Tempo Trainer Pro (Optional)",
    "Snorkel (Optional)",
    "Tech Toc (Optional)",
    "Baton (Optional)",
  ];
  return values[keys.indexOf(key)];
};

export { translateEquipment };
