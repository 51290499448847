import { BoxProps, Text } from '@chakra-ui/react';
import { scoreColour } from '../../utils/coreHelper';

interface ScoreProps extends BoxProps {
  score: string | number;
  max: string | number;
}

const Score: React.FC<ScoreProps> = ({ score, max }) => {
  const colour = scoreColour(score, max);
  const backgroundColour = '#AFAFAF';
  return (
    <Text>
      <Text as="span" color={colour}>
        <b>{score}</b>
      </Text>
      <Text as="span" fontSize="xs" color={backgroundColour}>
        /<b>{max}</b>
      </Text>
    </Text>
  );
};

export default Score;
