import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { parseInt } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  selectUserState,
  updateUserState,
} from "../../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateUser } from "../../../DataAccess/users";
import { createToast } from "../../../utils/toastHelper";
import { CreateSupportTicket } from "../../ui/CreateSupportTicket";
import { CustomToast } from "../../ui/CustomToast";
import {
  ActivityTypeSelector,
  LaneSelector,
  LevelSelector,
  SwimTypeSelector,
  TrainingTypeSelector,
} from "../../ui/DropDowns";
import Loading from "../../ui/Loading";

const SwimmerSettingsSwimming = () => {
  const toast = useToast();
  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();

  const {
    control: controlSwimming,
    reset: resetSwimming,
    setValue: setValueSwimming,
    handleSubmit: handleSubmitSwimming,
    formState: {
      errors: errorsSwimming,
      isSubmitting: isSubmittingSwimming,
      isValid: isValidSwimming,
      isDirty: isDirtySwimming,
    },
  } = useForm({
    defaultValues: {
      swimType: "U",
      activityType: "U",
      trainingType: "U",
      level: "U",
      lane: 0,
    },
    mode: "all",
  });

  useEffect(() => {
    if (user) {
      setValueSwimming("swimType", user.data.basic.swimType);
      setValueSwimming("activityType", user.data.basic.activityType);
      setValueSwimming("trainingType", user.data.basic.trainingType);
      setValueSwimming("level", user.data.basic.level);
      setValueSwimming("lane", user.data.basic.lane);
    }
  }, [user, setValueSwimming]);

  const handleSwimming = async (data: any): Promise<void> => {
    toast.closeAll();
    if (user) {
      try {
        const updatedUser = await updateUser(user._id, {
          swimType: data.swimType,
          activityType: data.activityType,
          trainingType: data.trainingType,
          level: data.level,
          lane: parseInt(data.lane, 10),
        });
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"About Your Swimming"}
              status={"Success"}
              toast={toast}
              toastId={props.id}
            >
              <Text>Your swimming settings have been succesfully updated.</Text>
            </CustomToast>
          );
        });
        dispatch(updateUserState(updatedUser));
        resetSwimming(data);
      } catch (error: any) {
        if (user) {
          setValueSwimming("swimType", user.data.basic.swimType);
          setValueSwimming("activityType", user.data.basic.swimType);
          setValueSwimming("trainingType", user.data.basic.swimType);
          setValueSwimming("level", user.data.basic.level);
          setValueSwimming("lane", user.data.basic.lane);
        }
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Account Settings"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Unable to update swimming settings, please contact support.
              </Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmitSwimming(handleSwimming)}
      w="full"
      alignItems="flex-start"
    >
      <Heading as="h3" size="md">
        About Your Swimming
      </Heading>
      <FormControl pb={1}>
        <FormLabel>
          <em>Which</em> type of swimmer do you <em>most</em> identify with?
          {errorsSwimming.activityType && (
            <span className="formError">
              {errorsSwimming.activityType.message}
            </span>
          )}
        </FormLabel>
        <Controller
          control={controlSwimming}
          name="activityType"
          render={({ field: { ref, onChange, ...restField } }) => (
            <ActivityTypeSelector
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
              {...restField}
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          <em>Why</em> do you swim / coach?
          {errorsSwimming.trainingType && (
            <span className="formError">
              {errorsSwimming.trainingType.message}
            </span>
          )}
        </FormLabel>
        <Controller
          control={controlSwimming}
          name="trainingType"
          render={({ field: { ref, onChange, ...restField } }) => (
            <TrainingTypeSelector
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
              {...restField}
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          <em>What</em> level of swimmer would you say you are?{" "}
          <strong>
            <sup>1</sup>
          </strong>
          {errorsSwimming.level && (
            <span className="formError">{errorsSwimming.level.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSwimming}
          name="level"
          render={({ field: { ref, onChange, ...restField } }) => (
            <LevelSelector
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
              {...restField}
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          <em>What</em> is your Swim Smooth swim type?
          {errorsSwimming.swimType && (
            <span className="formError">{errorsSwimming.swimType.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSwimming}
          name="swimType"
          render={({ field: { ref, onChange, ...restField } }) => (
            <SwimTypeSelector
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
              {...restField}
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          <em>How</em> fast do you swim?{" "}
          <strong>
            <sup>1, 2</sup>
          </strong>
          {errorsSwimming.lane && (
            <span className="formError">{errorsSwimming.lane.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSwimming}
          name="lane"
          render={({ field: { ref, onChange, ...restField } }) => (
            <LaneSelector
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
              {...restField}
              placeholder={-1}
            />
          )}
        />
      </FormControl>
      <HStack w="full" pt={2}>
        <Spacer />
        <Button
          isDisabled={!isDirtySwimming || !isValidSwimming}
          isLoading={isSubmittingSwimming}
          type="submit"
          mt={5}
        >
          Change About Your Swimming
        </Button>
      </HStack>
      <Text pt={4}>
        <strong>Note 1</strong>: We may update these values when you update your
        CSS or based on your performance in activities recorded with the Swim
        Smooth watch app.
      </Text>
      <Text>
        <strong>Note 2</strong>: We often ask new swimmers this question when
        they first arrive on the pool deck. It helps us tailor the session to
        their needs and put them with swimmers of a similar pace. If you know
        your CSS pace you can select the right 'lane' based on that. If not
        think about roughly how fast you could swim each 100m of a 15 x 100m set
        and use that time to select your 'lane'. It doesn't need to be super
        precise we're only going to use it a guide.
      </Text>
    </VStack>
  );
};

export { SwimmerSettingsSwimming };
