import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  Heading,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { activityDataSummaryPacing } from "../../types/activity";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";

interface PacingScoreProps extends BoxProps {
  size: number;
  execution: activityDataSummaryPacing;
}

const PacingScore: React.FC<PacingScoreProps> = ({
  execution,
  size,
  ...rest
}) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const pathColour = useColorModeValue("#A419BC", "#E755FF");
  const textColour = useColorModeValue("#000000", "#FFFFFF");
  const trailColour = useColorModeValue("#AAAAAA", "#666666");

  if (!checkRole(accessToken, "executionScore", "activity")) {
    return <NotAuthorised functionText={"Session Pacing"} size={"small"} />;
  }

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={0} w="full">
      <GridItem>
        <Tooltip hasArrow label="Pacing Score">
          <Box style={{ width: size, height: size }} {...rest}>
            {execution.score > -1 && (
              <CircularProgressbar
                value={execution.score}
                text={`${execution.score}`}
                strokeWidth={10}
                styles={buildStyles({
                  textSize: "25px",
                  strokeLinecap: "butt",
                  trailColor: trailColour,
                  pathColor: pathColour,
                  textColor: textColour,
                })}
              />
            )}
            {execution.score === -1 && (
              <CircularProgressbar
                value={0}
                text={`-`}
                strokeWidth={10}
                styles={buildStyles({
                  textSize: "25px",
                  strokeLinecap: "butt",
                  trailColor: trailColour,
                  pathColor: pathColour,
                  textColor: textColour,
                })}
              />
            )}
          </Box>
        </Tooltip>
      </GridItem>
      <GridItem colSpan={2}>
        <VStack alignItems="flex-start">
          <Heading as="h5" size="xs">
            {execution.title}
          </Heading>
          <Text>{execution.comment.short}</Text>
        </VStack>
      </GridItem>
    </Grid>
  );
};

export { PacingScore };
