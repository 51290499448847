const createToast = (toast: any, render: any, duration?: number) => {
  if (!duration) {
    duration = 5000;
  }
  toast.closeAll();
  toast({
    duration: duration,
    position: "top-right",
    isClosable: true,
    render: render,
  });
};

const createPermaToast = (toast: any, render: any) => {
  toast.closeAll();
  toast({
    duration: null,
    position: "top-right",
    isClosable: true,
    render: render,
  });
};

const createErrorMessage = (error: any): string => {
  let errorMessage = "An error has occurred";
  if (
    error.response &&
    error.response.data &&
    error.response.data.messages.length > 0
  ) {
    errorMessage = error.response.data.messages[0].message.user
      ? error.response.data.messages[0].message.user
      : "Unable to create account, please contact support.";
  }
  return errorMessage;
};

export { createErrorMessage, createPermaToast, createToast };
