import { useContext, useEffect } from "react";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";
import { ViewTrainingTimeline } from "../../components/trainingTimeline/ViewTrainingTimeline";

const TrainingTimelinePage = () => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/training", title: "Training" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ViewTrainingTimeline />;
};

export { TrainingTimelinePage };
