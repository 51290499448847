import { InfoIcon, LockIcon } from "@chakra-ui/icons";
import {
  BoxProps,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWbssWeek } from "../../DataAccess/sessions";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { wbssWeek } from "../../types/session";
import { checkRole, getLevel } from "../../utils/authHelper";
import { formatDateLong } from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BoxWrapper } from "../ui/BoxWrapper";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";
import { WorldsBiggestSwimSquadSession } from "./WorldsBiggestSwimSquadSession";

interface WorldsBiggestSwimSquadWidgetProps extends BoxProps {}

const WorldsBiggestSwimSquadWidget: React.FC<
  WorldsBiggestSwimSquadWidgetProps
> = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const [week, setWeek] = useState<wbssWeek | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userLevel = getLevel(accessToken);
  const userLevelString =
    "a" +
    (userLevel === "Understanding" || userLevel === "Ultimate" ? "n" : "") +
    " '" +
    userLevel +
    "'";
  const sessionsServed =
    userLevel === "Routine" || userLevel === "Ultimate" ? 3 : 1;
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const getSessionLocal = async () => {
      try {
        const result = await getWbssWeek();
        setWeek(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getSessionLocal();
  }, []);

  if (!checkRole(accessToken, "widget", "wbss")) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          The Worlds Biggest Swim Squad
        </Heading>
        <NotAuthorised
          functionText={"Worlds Biggest Swim Squad"}
          size={"small"}
        />
      </BoxWrapper>
    );
  }

  if (loading) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          The Worlds Biggest Swim Squad
        </Heading>
        <Loading message="Loading The Worlds Biggest Swim Squad" />
      </BoxWrapper>
    );
  }

  if (!week) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          The Worlds Biggest Swim Squad
        </Heading>
        <Text>
          No 'Worlds Biggest Swim Squad' sessions found for this week. Please
          check back later.
        </Text>
      </BoxWrapper>
    );
  }

  return (
    <BoxWrapper>
      <Heading size="md">
        The Worlds Biggest Swim Squad
        <>
          <Icon
            cursor="pointer"
            ml={2}
            mt={-4}
            as={InfoIcon}
            onClick={onOpen}
          />
          <Drawer isOpen={isOpen} placement="left" size="md" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>About The Worlds Biggest Swim Squad</DrawerHeader>
              <DrawerBody>
                <Text mb={3}>
                  As {userLevelString} subscriber you'll get at least{" "}
                  <em>{sessionsServed}</em> session(s) per week, fresh in the
                  Guru each Monday.
                </Text>
                {sessionsServed === 3 && (
                  <>
                    <Text mb={3}>
                      Each week you'll get at least three sessions that have
                      been recently swum in one of our Swim Smooth Squads around
                      the world so even if you cannot attend one of our coaches
                      Swim Smooth squads you can be a part of the global Swim
                      Smooth Squad ... surely the biggest swim squad in the
                      world?
                    </Text>
                    <Text>
                      Combined with 'Session Roulette' there is never a need for
                      procratination again ... well for your swim sessions at
                      least!
                    </Text>
                  </>
                )}
                {sessionsServed === 1 && (
                  <>
                    <Text mb={3}>
                      Each week you get one session that has been recently swum
                      in one of our Swim Smooth Squads around the world so even
                      if you cannot attend one of our coaches Swim Smooth squads
                      you can be a part of the global Swim Smooth Squad ...
                      surely the biggest swim squad in the world?
                    </Text>
                    <Text mb={3}>
                      Upgrade to 'Routine' or higher to get at least three
                      sessions each week and gain access to the 'Worlds Boggest
                      Swim Squad Archive'.
                    </Text>
                  </>
                )}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      </Heading>
      <Text mb={5}>
        Week Commencing: {formatDateLong(week.data.commencing)}
      </Text>
      {week.data.intro && <Text mb={5}>{week.data.intro}</Text>}
      {week.data.sessions.map((wbssSession: any) => {
        return (
          <WorldsBiggestSwimSquadSession
            key={wbssSession.sessionId}
            wbssSession={wbssSession}
          />
        );
      })}
      {checkRole(accessToken, "archive", "wbss") && (
        <Button
          variant={"success"}
          w={"full"}
          mb={5}
          onClick={() => {
            navigate("/sessions/wbss", { replace: false });
          }}
        >
          Access the WBSS Archive
        </Button>
      )}
      {!checkRole(accessToken, "archive", "wbss") && (
        <Button
          variant={"success"}
          leftIcon={<LockIcon />}
          w={"full"}
          mb={5}
          onClick={() => {
            createToast(toast, (props: any) => {
              return (
                <CustomToast
                  title={"Account Settings"}
                  status={"Error"}
                  toast={toast}
                  toastId={props.id}
                >
                  <Text>
                    WBSS archive requires a higher level of subscription than
                    you currently have. Check out the pricing page for more
                    details.
                  </Text>
                  <Text>
                    You can check and upgrade your subscription level
                    <Button
                      variant={"link"}
                      onClick={() => {
                        navigate("/account/subscription");
                      }}
                    >
                      here
                    </Button>
                    .
                  </Text>
                </CustomToast>
              );
            });
          }}
        >
          Access the WBSS Archive
        </Button>
      )}
    </BoxWrapper>
  );
};

export { WorldsBiggestSwimSquadWidget };
