import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { breadcrumbLink } from "../../types/breadcrumb";
import { BreadcrumbContext } from "../context/BreadcrumbContext";
import { Footer } from "./Footer";
import { TopBanner } from "./TopBanner";
import { VersionBanner } from "./VersionBanner";

const NoHeaderLayout = (props: any) => {
  const [breadcrumbLinks, setBreadcrumbLinks] = useState<
    breadcrumbLink[] | null
  >([]);

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbLinks,
        setBreadcrumbLinks,
      }}
    >
      <VersionBanner />
      <TopBanner />
      <Box mt={5} mx={5} mb={[5, 5, 20, 20]}>
        {props.children}
      </Box>
      <Footer />
    </BreadcrumbContext.Provider>
  );
};

export { NoHeaderLayout };
