import { BoxProps, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { billing, billingSubscription } from "../../../../types/billing";
import { SingleValueDisplay } from "../../../ui/SingleValueDisplay";
import { TrialDetails } from "../TrialDetails";
import { StripePricingTable } from "../stripe/StripePricingTable";

interface SubscriptionDetailsBraintreeProps extends BoxProps {
  billing: billing;
}

const SubscriptionDetailsBraintree: React.FC<
  SubscriptionDetailsBraintreeProps
> = ({ billing }) => {
  const subscription: billingSubscription =
    billing.subscription as billingSubscription;

  return (
    <VStack w="full" alignItems="start">
      <VStack w="full" alignItems="flex-start">
        {billing.subscription && (
          <>
            <Heading as="h3" size="md">
              {billing.subscriptionDescription}
            </Heading>
            <Text>
              As a result of issues with our previous payment provider
              (Braintree) we have moved to Stripe. You had a '
              {subscription.product.name}' subscription, which was billed{" "}
              {subscription.product.price.interval === "year"
                ? "annually"
                : "monthly"}
              {", "}
              but as a result of the move this needed to be cancelled. You will
              not lose any of the time you paid for and have access until{" "}
              {new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(new Date(subscription.currentPeriodEnd))}
              . No further payments will be taken by Braintree and once your
              cancelled subscription expires you'll be able to re-subscribe
              using Stripe.
            </Text>
            <SimpleGrid
              w="full"
              columns={[1, 1, 3, 3]}
              columnGap={2}
              rowGap={2}
            >
              <SingleValueDisplay
                value={subscription.customer}
                label="Customer"
              />
              <SingleValueDisplay
                value={subscription.id}
                label="Subscription"
              />
              <SingleValueDisplay
                value={
                  subscription.cancelled
                    ? "CANCELLED"
                    : new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(new Date(subscription.currentPeriodEnd))
                }
                label="Next Billing Date"
              />
            </SimpleGrid>
          </>
        )}
        <TrialDetails trial={billing.trial} />
        <StripePricingTable />
      </VStack>
    </VStack>
  );
};

export { SubscriptionDetailsBraintree };
