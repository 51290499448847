import { BoxProps, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { billing } from "../../../../types/billing";
import { MessageDisplay } from "../../../generic/MessageDisplay";
import { SingleValueDisplay } from "../../../ui/SingleValueDisplay";
import { TrialDetails } from "../TrialDetails";
import { StripePricingTable } from "../stripe/StripePricingTable";

interface SubscriptionDetailsAppleProps extends BoxProps {
  billing: billing;
}

const SubscriptionDetailsApple: React.FC<SubscriptionDetailsAppleProps> = ({
  billing,
}) => {
  if (!billing) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not get subscription!">
          <Text>
            Unable to retrieve subscription details. Please try again later.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  // Active
  return (
    <VStack w="full" alignItems="start">
      <VStack w="full" alignItems="flex-start">
        {billing.subscription && (
          <>
            <Heading as="h3" size="md">
              {billing.subscriptionDescription}
            </Heading>
            <Text>
              Your subscription is managed through Apple subscriptions. You'll
              need to make changes to it on your Apple device. It should be
              mentioned that subscriptions are cheaper if created directly with
              Swim Smooth rather than through Apple. You can change your
              subscription to once your existing Apple subscription has expired.
            </Text>
            <SimpleGrid
              w="full"
              columns={[1, 1, 3, 3]}
              columnGap={2}
              rowGap={2}
            >
              <SingleValueDisplay
                value={billing.subscription.id}
                label="Subscription"
              />
              <SingleValueDisplay
                value={new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(new Date(billing.subscription.currentPeriodEnd))}
                label={
                  billing.subscriptionStatus !== "Expired"
                    ? "Paid Through Date"
                    : "Expired Date"
                }
              />
            </SimpleGrid>
            <TrialDetails trial={billing.trial} />
            {(billing.subscriptionStatus === "None" ||
              billing.subscriptionStatus === "Expired") && (
              <StripePricingTable />
            )}
          </>
        )}
      </VStack>
    </VStack>
  );
};

export { SubscriptionDetailsApple };
