import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  selectUserState,
  updateUserState,
} from "../../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateUser } from "../../../DataAccess/users";
import { createToast } from "../../../utils/toastHelper";
import { CreateSupportTicket } from "../../ui/CreateSupportTicket";
import { CustomToast } from "../../ui/CustomToast";
import { CountrySelector, GenderSelector } from "../../ui/DropDowns";
import Loading from "../../ui/Loading";

const AccountSettingsBasic = () => {
  const toast = useToast();

  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();

  const {
    control: controlBasic,
    reset: resetBasic,
    setValue: setValueBasic,
    handleSubmit: handleSubmitBasic,
    formState: {
      errors: errorsBasic,
      isSubmitting: isSubmittingBasic,
      isValid: isValidBasic,
      isDirty: isDirtyBasic,
    },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      country: "",
    },
    mode: "all",
  });

  useEffect(() => {
    if (user) {
      setValueBasic("firstName", user.data.basic.firstName);
      setValueBasic("lastName", user.data.basic.lastName);
      setValueBasic("gender", user.data.basic.gender);
      setValueBasic("country", user.data.basic.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleBasic = async (data: any): Promise<void> => {
    toast.closeAll();
    if (user) {
      try {
        const updatedUser = await updateUser(user._id, {
          firstName: data.firstName,
          lastName: data.lastName,
          gender: data.gender,
          country: data.country,
        });
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Account Settings"}
              status={"Success"}
              toast={toast}
              toastId={props.id}
            >
              <Text>Your account settings have been succesfully updated.</Text>
            </CustomToast>
          );
        });
        dispatch(updateUserState(updatedUser));
        resetBasic(data);
      } catch (error: any) {
        if (user) {
          setValueBasic("firstName", user.data.basic.firstName);
          setValueBasic("lastName", user.data.basic.lastName);
          setValueBasic("gender", user.data.basic.gender);
          setValueBasic("country", user.data.basic.country);
        }
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Account Settings"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Unable to update account settings, please contact support.
              </Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmitBasic(handleBasic)}
      w="full"
      alignItems="flex-start"
      mb={5}
    >
      <Heading as="h3" size="md">
        Account Settings
      </Heading>
      <FormControl pb={1}>
        <FormLabel>
          First Name
          {errorsBasic.firstName && (
            <span className="formError">{errorsBasic.firstName.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlBasic}
          rules={{ required: "First name is required" }}
          name="firstName"
          render={({ field: { ref, ...restField } }) => (
            <Input {...restField} placeholder="" />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          Last Name
          {errorsBasic.lastName && (
            <span className="formError">{errorsBasic.lastName.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlBasic}
          rules={{ required: "Last name is required" }}
          name="lastName"
          render={({ field: { ref, ...restField } }) => (
            <Input {...restField} placeholder="" />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          Gender
          {errorsBasic.gender && (
            <span className="formError">{errorsBasic.gender.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlBasic}
          rules={{ required: "Gender is required" }}
          name="gender"
          render={({ field: { ref, onChange, ...restField } }) => (
            <GenderSelector
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
              {...restField}
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          Country
          {errorsBasic.country && (
            <span className="formError">{errorsBasic.country.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlBasic}
          rules={{ required: "Country is required" }}
          name="country"
          render={({ field: { ref, onChange, ...restField } }) => (
            <CountrySelector
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
              {...restField}
              placeholder=""
            />
          )}
        />
      </FormControl>
      <HStack w="full" pt={2}>
        <Spacer />
        <Button
          isDisabled={!isDirtyBasic || !isValidBasic}
          isLoading={isSubmittingBasic}
          type="submit"
          mt={5}
        >
          Update Account Settings
        </Button>
      </HStack>
    </VStack>
  );
};

export { AccountSettingsBasic };
