import { mode } from '@chakra-ui/theme-tools';

export const Heading = {
  baseStyle: (props: any) => ({
    color: mode('ssPrimaryDark', 'ssPrimaryLight')(props),
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};
