import {
  Alert,
  AlertIcon,
  Box,
  BoxProps,
  Icon,
  Spacer,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { RiCloseCircleLine } from "react-icons/ri";
import { TbAlertTriangle, TbInfoSquareRounded } from "react-icons/tb";

interface CustomToastProps extends BoxProps {
  title: string;
  status: "Error" | "Warning" | "Success" | "Info";
  toast: any;
  toastId: number;
}

const CustomToast: React.FC<CustomToastProps> = ({
  title,
  status,
  toast,
  toastId,
  children,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  let alertColours: [IconType, string, string] = [
    TbAlertTriangle,
    "#cc0000",
    "#f48db3",
  ];
  if (status === "Warning") {
    alertColours = [TbAlertTriangle, "#ff6000", "#f9bc62"];
  } else if (status === "Success") {
    alertColours = [TbInfoSquareRounded, "#009900", "#a3e6a3"];
  } else if (status === "Info") {
    alertColours = [TbInfoSquareRounded, "#00b4ff", "#005ef9"];
  }
  return (
    <Box
      bg={boxColorMode}
      borderRadius={10}
      w={"full"}
      alignItems={"flex-start"}
    >
      <Alert
        bg={alertColours[2]}
        color={alertColours[1]}
        borderBottomWidth={2}
        borderBottomColor={alertColours[1]}
        fontSize={"large"}
        fontWeight={"bold"}
        borderTopRadius={8}
      >
        <AlertIcon color={alertColours[1]} as={alertColours[0]} />
        {title}
        <Spacer />
        <Icon
          cursor={"pointer"}
          onClick={() => {
            toast.close(toastId);
          }}
          height={6}
          width={6}
          as={RiCloseCircleLine}
        />
      </Alert>
      <VStack p={5} w={"full"} alignItems={"flex-start"}>
        {children}
      </VStack>
    </Box>
  );
};

export { CustomToast };
