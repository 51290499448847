import {
  Button,
  Center,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAffiliateByIdExtended } from "../../DataAccess/affiliates";
import { getInvitationById } from "../../DataAccess/invitations";
import { affiliate } from "../../types/affiliate";
import { invitation } from "../../types/invitation";
import { CoachBox } from "../coaches/CoachBox";
import { MessageDisplay } from "../generic/MessageDisplay";
import LoadingMulti from "../ui/LoadingMulti";
import { AffiliateTerms } from "./AffiliateTerms";
import { SignupForm } from "./SignupForm";

interface SignupProps {}

const Signup: React.FC<SignupProps> = () => {
  const [search] = useSearchParams();
  const [loadingAffiliate, setLoadingAffiliate] = useState<boolean>(true);
  const [loadingInvitation, setLoadingInvitation] = useState<boolean>(true);
  const affiliateId = search.get("affiliateId");
  const invitationId = search.get("invitationId");
  const [affiliate, setAffiliate] = useState<affiliate | null>(null);
  const [invitation, setInvitation] = useState<invitation | null>(null);
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  useEffect(() => {
    const getAffiliateByIdLocal = async () => {
      if (affiliateId) {
        try {
          const affiliateResult = await getAffiliateByIdExtended(affiliateId);
          if (affiliateResult) {
            setAffiliate(affiliateResult);
            setLoadingAffiliate(false);
          }
        } catch (error) {
          console.log(error);
          setLoadingAffiliate(false);
        }
      } else {
        setLoadingAffiliate(false);
      }
    };
    getAffiliateByIdLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateId]);

  useEffect(() => {
    const getInvitationByIdLocal = async () => {
      if (invitationId) {
        try {
          const invitationResult = await getInvitationById(invitationId);
          if (invitationResult) {
            setInvitation(invitationResult);
            setLoadingInvitation(false);
          }
        } catch (error) {
          console.log(error);
          setLoadingInvitation(false);
        }
      } else {
        setLoadingInvitation(false);
      }
    };
    getInvitationByIdLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId]);

  if (loadingAffiliate || loadingInvitation) {
    return (
      <LoadingMulti
        messages={["Checking affiliate links", "Checking for invitations"]}
      />
    );
  }

  if (invitationId && !invitation) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Invitation Not Found
        </Heading>
        <MessageDisplay status="error" title="Not Found">
          <Text>
            The invitation was not found. This could be because of a mis-typed
            address or the invitation could have expired. Please check the
            details or get in touch with the person who issued the invitation.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (affiliateId && !affiliate) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Affiliate Not Found
        </Heading>
        <MessageDisplay status="error" title="Not Found">
          <Text>
            The affiliate was not found. This could be because of a mis-typed
            address or the affiliate has left the affiliate program. Please
            check the details or get in touch with the person who issued the
            invitation.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (affiliate && affiliate?.data.widgetOnly) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Friends of Swim Smooth Program
        </Heading>
        <MessageDisplay status="error" title="Not Found">
          <Text>
            {affiliate.data.affiliateName} doesn't allow interactive signups.
            You'll need to use the Swim Smooth widget on their site.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (typeof process.env.REACT_APP_HCAPTCHA_SITE_KEY === "undefined") {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          hCaptcha Error
        </Heading>
        <MessageDisplay status="error" title="Could not get keys!">
          <Text>hCaptcha Keys not configured, please contact support.</Text>
        </MessageDisplay>
      </VStack>
    );
  }

  return (
    <SimpleGrid columns={[1, 1, 2, 2]} columnGap={10} w="full">
      <VStack spacing={2} alignItems="flex-start" w="full">
        {!affiliate && !invitation && (
          <>
            <Heading>Welcome to 'The Guru'</Heading>
            <Text>You'll need to create an account to access 'The Guru'.</Text>
            <Text>
              New accounts get a free seven day 'Routine' trial, no card details
              required.
            </Text>
            <Button onClick={() => navigate("/", { replace: false })}>
              Already have an account? Login here.
            </Button>
            <Text>Let's get you started.</Text>
            <Center w={"full"}>
              <Image src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png" />
            </Center>
          </>
        )}
        {affiliate && (
          <>
            <Heading>
              {affiliate.data.coach
                ? "Swim Smooth Certified Coach Program"
                : "Friends of Swim Smooth Program"}
            </Heading>
            <Text>
              Looks like you've been sent here by one of our{" "}
              {affiliate.data.coach ? "coaches" : "friends"}. You'll be linked
              to <em>{affiliate.data.affiliateName}</em>.
            </Text>
            {affiliate.data.logo && (
              <Image
                src={
                  colorMode === "dark"
                    ? affiliate.data.logo.dark
                    : affiliate.data.logo.light
                }
                mx={20}
                my={10}
              />
            )}
            <Button
              w={"full"}
              mt={5}
              onClick={() => {
                navigate("/affiliate/accept/" + affiliateId, {
                  replace: false,
                });
              }}
            >
              Already have an account, log in and connect here
            </Button>
            <AffiliateTerms
              parentName={affiliate.data.affiliateName}
              program={
                affiliate.data.coach
                  ? "Swim Smooth Certified Coach Program"
                  : "Friends of Swim Smooth Program"
              }
            />
            {affiliate && affiliate.data.coach && (
              <Center w={"full"} my={5}>
                <CoachBox maxWidth={400} coachData={affiliate.data.coach} />
              </Center>
            )}
            {affiliate.data.paysOut && (
              <Text>
                If you go on to subscribe this will support '
                {affiliate.data.affiliateName}'.
              </Text>
            )}
          </>
        )}
        {invitation && (
          <>
            <Heading>Swim Smooth Certified Coaches Program</Heading>
            <Text as={"span"}>
              {invitation.data.recipientName}, you've been invited to connect to{" "}
              <em>{invitation.data.senderName}</em> in the Swim Smooth Guru.
            </Text>{" "}
            {invitation.data.trial && (
              <>
                <Heading as={"h3"} size={"md"} mt={4} mb={2}>
                  Free Trial
                </Heading>
                <Text as="span">
                  <em>{invitation.data.senderName}</em> is offering you
                  {invitation.data.trial.routineDays > 0 &&
                    invitation.data.trial.understandingDays > 0 && (
                      <Text as="span">
                        {" "}
                        {invitation.data.trial.routineDays} days 'Routine'
                        access, followed by{" "}
                        {invitation.data.trial.understandingDays} days
                        'Understanding' access.
                      </Text>
                    )}
                  {invitation.data.trial.routineDays > 0 &&
                    invitation.data.trial.understandingDays === 0 && (
                      <Text as="span">
                        {" "}
                        {invitation.data.trial.routineDays} days 'Routine'
                        access.
                      </Text>
                    )}
                  {invitation.data.trial.routineDays === 0 &&
                    invitation.data.trial.understandingDays > 0 && (
                      <Text as="span">
                        {" "}
                        {invitation.data.trial.understandingDays} days
                        'Understanding' access.
                      </Text>
                    )}
                  <Text as="span">
                    {" "}
                    Once your trial has expired you'll have free forever
                    'Guidance' access.
                  </Text>
                </Text>
              </>
            )}
            <Button
              w={"full"}
              mt={5}
              onClick={() => {
                navigate("/invitation/accept/" + invitationId, {
                  replace: false,
                });
              }}
            >
              Already have an account, log in and connect here
            </Button>
            <AffiliateTerms
              parentName={invitation.data.senderName}
              program={"Swim Smooth Certified Coach Program"}
            />
            {invitation.data.coach && (
              <Center w={"full"} my={5}>
                <CoachBox maxWidth={400} coachData={invitation.data.coach} />
              </Center>
            )}
          </>
        )}
      </VStack>
      <VStack>
        <SignupForm
          affiliate={affiliate ? affiliate : null}
          invitation={invitation ? invitation : null}
        />
      </VStack>
    </SimpleGrid>
  );
};

export { Signup };
