import { Box, BoxProps, Icon, Text } from "@chakra-ui/react";
import React from "react";
interface SingleValueWithUnitDisplayProps extends BoxProps {
  label: string;
  unit: string;
  value: number | string;
}

const SingleValueWithUnitDisplay: React.FC<SingleValueWithUnitDisplayProps> = ({
  label,
  unit,
  value,
}) => {
  return (
    <Box>
      <Text>
        {value}
        <Text as="span" fontSize="xs">
          {unit}
        </Text>
      </Text>
      <Text fontSize="xs">{label}</Text>
    </Box>
  );
};

interface SingleValueWithUnitAndIndictorDisplayProps extends BoxProps {
  label: string;
  unit: string;
  value: number | string;
  indicator: React.ElementType;
  indicatorColour: string;
}

const SingleValueWithUnitAndIndictorDisplay: React.FC<
  SingleValueWithUnitAndIndictorDisplayProps
> = ({ label, unit, value, indicator, indicatorColour }) => {
  return (
    <Box>
      <Text>
        <Icon mr={1} w={3} h={3} as={indicator} color={indicatorColour} />
        {value}
        <Text as="span" fontSize="xs">
          {unit}
        </Text>
      </Text>
      <Text fontSize="xs">{label}</Text>
    </Box>
  );
};

export { SingleValueWithUnitAndIndictorDisplay, SingleValueWithUnitDisplay };
