import axios from "axios";

const reverseGeocode = async (latitude: number, longitude: number): Promise<string | null> => {
  try {
    const result = await axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&key=AIzaSyCNPmsV_T-tZGZONrlOhAKusR6yceFvdY4"
    );
    const locationResults = result.data.results;
    if (locationResults && locationResults.length > 0) {
      const countryResults = locationResults.filter((country: any) => country.types.includes("country"));
      if (countryResults.length > 0) {
        const countryComponent = countryResults[0].address_components.filter((country: any) => country.types.includes("country"));
        if (countryComponent.length > 0) {
          return countryComponent[0].short_name;
        }
      }
    }
  } catch (error: any) {
    return null;
  }
  return null;
};

const distanceBetweenPoints = (point1: [number, number], point2: [number, number], unit: "M" | "K" | "N"): number => {
  if (point1[0] === point2[0] && point1[1] === point2[1]) {
    return 0;
  } else {
    const lat1 = (Math.PI * point1[0]) / 180;
    const lat2 = (Math.PI * point2[0]) / 180;
    const theta = point1[1] - point2[1];
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

const countryToCurrency = (country: string | null): string => {
  const euro = [
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
  ];
  if (!country) {
    return "USD";
  } else if (country === "GB") {
    return "GBP";
  } else if (country === "CA") {
    return "CAD";
  } else if (country === "AU") {
    return "AUD";
  } else if (euro.includes(country)) {
    return "EUR";
  }
  return "USD";
};

export { distanceBetweenPoints, countryToCurrency, reverseGeocode };
