import {
  BoxProps,
  Button,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Heading,
  Input,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  getCoachById,
  getCoachByShortCode,
  updateCoachLocationsById,
} from "../../DataAccess/coaches";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { updateCoachesState } from "../../app/features/coach/coachesSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { breadcrumbLink } from "../../types/breadcrumb";
import { coach, coachLocation } from "../../types/coach";
import { checkRole } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { MessageDisplay } from "../generic/MessageDisplay";
import { LocationTypeSelector } from "../ui/DropDowns";
import Loading from "../ui/Loading";
import { RequiredField } from "../ui/RequiredField";

interface CoachesEditProps extends BoxProps {
  coachShortCode: string;
}

const CoachEditLocationsAdd: React.FC<CoachesEditProps> = ({
  coachShortCode,
}) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const [loadingCoach, setLoadingCoach] = useState<boolean>(true);
  const [selectedCoach, setSelectedCoach] = useState<coach>();
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getCoachLocal = async () => {
      const coachResponse = await getCoachByShortCode(coachShortCode);
      setSelectedCoach(coachResponse);
      setLoadingCoach(false);
    };
    getCoachLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [
      { href: "/coaches", title: "Coaches" },
    ];
    if (selectedCoach) {
      if (selectedCoach.data.displayName) {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title: selectedCoach.data.displayName,
        });
      } else {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title:
            selectedCoach.data.firstName + " " + selectedCoach.data.lastName,
        });
      }
      breadcrumbLinks.push({
        href: "/coaches/" + selectedCoach.data.shortCode + "/edit/locations",
        title: "Edit Locations",
      });
    }
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoach]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      description: "",
      latitude: 0,
      longitude: 0,
      type: "",
    },
    mode: "all",
  });

  const handleSave = async (data: any): Promise<void> => {
    if (selectedCoach) {
      const locations: coachLocation[] = selectedCoach.data.locations;
      locations.push({
        id: uuidv4(),
        name: data.name,
        description: data.description,
        ll: [parseFloat(data.latitude), parseFloat(data.longitude)],
        w3w: "",
        type: data.type,
      });
      await updateCoachLocationsById(selectedCoach.id, locations);
      const coachResponse = await getCoachById(selectedCoach.id);
      setSelectedCoach(coachResponse);
      dispatch(updateCoachesState(null));
      navigate("/coaches/" + selectedCoach.data.shortCode + "/edit/locations", {
        replace: false,
      });
    }
  };

  if (loadingCoach) {
    return <Loading message="Loading Coach" />;
  }

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (
    !checkRole(accessToken, "*ALL", "superadmin") &&
    user._id !== selectedCoach?.data.user
  ) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>403: Not Authorised</Heading>
        <Text>
          We're terribly sorry but it would appear that you have tried to do
          something you are not authorised to do. Could be us, could be you ...
          if you are sure you should be able to do what you are trying to do
          then get in touch using the help button at the bottom left of the
          screen.
        </Text>
      </VStack>
    );
  }

  if (
    selectedCoach &&
    selectedCoach.data.shortCode &&
    selectedCoach.data.shortCode === coachShortCode
  ) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>{selectedCoach.data.displayName} Add Location</Heading>
        <VStack
          as="form"
          onSubmit={handleSubmit(handleSave)}
          w="full"
          alignItems="flex-start"
          mb={5}
        >
          <SimpleGrid columns={[1, 1, 2, 2]} w="full" columnGap={5} rowGap={2}>
            <VStack>
              <FormControl pb={1}>
                <FormLabel>
                  Name
                  <RequiredField />
                  {errors.name && (
                    <span className="formError">{errors.name.message}</span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Name is required" }}
                  name="name"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
            </VStack>
            <VStack>
              <FormControl pb={1}>
                <FormLabel>
                  Location Type
                  <RequiredField />
                  {errors.type && (
                    <span className="formError">{errors.type.message}</span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Location type is required" }}
                  name="type"
                  render={({ field: { ref, onChange, ...restField } }) => (
                    <LocationTypeSelector
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(e)
                      }
                      {...restField}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
            </VStack>
            <GridItem colSpan={[1, 1, 2, 2]}>
              <VStack>
                <FormControl pb={1}>
                  <FormLabel>
                    Description
                    {errors.description && (
                      <span className="formError">
                        {errors.description.message}
                      </span>
                    )}
                  </FormLabel>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { ref, ...restField } }) => (
                      <Input {...restField} placeholder="" />
                    )}
                  />
                </FormControl>
              </VStack>
            </GridItem>
            <VStack>
              <FormControl pb={1}>
                <FormLabel>
                  Latitude
                  <RequiredField />
                  {errors.latitude && (
                    <span className="formError">{errors.latitude.message}</span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Latitude is required" }}
                  name="latitude"
                  render={({ field: { ref, ...restField } }) => (
                    <NumberInput {...restField} width="100%">
                      <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
                    </NumberInput>
                  )}
                />
              </FormControl>
            </VStack>
            <VStack>
              <FormControl pb={1}>
                <FormLabel>
                  Longitude
                  <RequiredField />
                  {errors.longitude && (
                    <span className="formError">
                      {errors.longitude.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Longitude is required" }}
                  name="longitude"
                  render={({ field: { ref, ...restField } }) => (
                    <NumberInput {...restField} width="100%">
                      <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
                    </NumberInput>
                  )}
                />
              </FormControl>
            </VStack>
          </SimpleGrid>
          <HStack w="full" pt={2}>
            <Button
              variant="warning"
              onClick={() =>
                navigate(
                  "/coaches/" +
                    selectedCoach.data.shortCode +
                    "/edit/locations",
                  { replace: false }
                )
              }
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              isDisabled={!isValid}
              isLoading={isSubmitting}
              type="submit"
            >
              Add Location
            </Button>
          </HStack>
        </VStack>
      </VStack>
    );
  }

  if (selectedCoach && !selectedCoach.data.shortCode) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not find coach!">
          <Text>Could not find coach {coachShortCode}.</Text>
        </MessageDisplay>
        <Button onClick={() => navigate("/coaches", { replace: false })}>
          See All Coaches
        </Button>
      </VStack>
    );
  }

  return <Loading />;
};

export { CoachEditLocationsAdd };
