import { Heading, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { BsrManualCapture } from "../../components/bsr/BsrManualCapture";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";
import { breadcrumbLink } from "../../types/breadcrumb";

const BsrEnterSingle = (props: any) => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/tools", title: "Tools" });
    breadcrumbLinks.push({
      href: "/bsr/entersingle",
      title: "BSR Manual Entry",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading>Manual Base Stroke Rate Entry</Heading>
      <BsrManualCapture />
    </VStack>
  );
};

export { BsrEnterSingle };
