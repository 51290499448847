import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import {
  createTrainingTimelineQuestions,
  trainingTimeline,
  updateTrainingTimelineQuestions,
} from "../types/trainingTimeline";

const createTrainingTimeline = async (
  ttlQs: createTrainingTimelineQuestions
) => {
  try {
    const result = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "trainingtimeline",
      ttlQs
    );
    return result.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const getTrainingTimelineCurrent =
  async (): Promise<trainingTimeline | null> => {
    try {
      const result = await axios.get(
        process.env.REACT_APP_API_ROOT_URL + "trainingtimeline/current"
      );
      return result.data.payload;
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        // This is fine as it just means we don't have one.
        return null;
      } else {
        if (error.response) {
          throw new Error(processSwimSmoothApiError(error));
        } else {
          throw error;
        }
      }
    }
  };

const getTrainingTimelineById = async (
  ttlId: string
): Promise<trainingTimeline> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "trainingtimeline/" + ttlId
    );
    return result.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const progressTrainingTimelineById = async (ttlId: string) => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "trainingtimeline/progress/" + ttlId
    );
    return result.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const slideTrainingTimelineById = async (ttlId: string) => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "trainingtimeline/slide/" + ttlId
    );
    return result.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const finishTrainingTimelineById = async (ttlId: string): Promise<void> => {
  try {
    await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "trainingtimeline/finish/" + ttlId
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const updateTrainingTimeline = async (
  ttlId: string,
  ttlQs: updateTrainingTimelineQuestions
) => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "trainingtimeline/" + ttlId,
      ttlQs
    );
    return result.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const deleteTrainingTimelineById = async (ttlId: string) => {
  try {
    await axios.delete(
      process.env.REACT_APP_API_ROOT_URL + "trainingtimeline/" + ttlId
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

export {
  createTrainingTimeline,
  deleteTrainingTimelineById,
  finishTrainingTimelineById,
  getTrainingTimelineById,
  getTrainingTimelineCurrent,
  progressTrainingTimelineById,
  slideTrainingTimelineById,
  updateTrainingTimeline,
};
