import {
  Box,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { BoxWrapper } from "../../components/ui/BoxWrapper";
import { checkRole } from "../../utils/authHelper";

const ToolsPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/tools", title: "Tools" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Tools
      </Heading>
      <SimpleGrid columns={[1, 1, 3, 3]} columnGap={5} rowGap={5} w={"full"}>
        <ToolsItem
          title={"CSS Calculator"}
          text={
            "Calculate your Critical Swim Speed (CSS) by performing a 400 / 200 time trial."
          }
          link={"/css/calcsingle"}
        />
        {accessToken && checkRole(accessToken, "manual", "css") && (
          <ToolsItem
            title={"Manual CSS Entry"}
            text={"Already know you Critical Swim Speed (CSS)? Enter it here."}
            link={"/css/entersingle"}
          />
        )}
        {accessToken && checkRole(accessToken, "tweak", "css") && (
          <ToolsItem
            title={"CSS Tweaker"}
            text={
              "Smashing those times or feeling a bit off? Tweak your CSS here."
            }
            link={"/css/tweaker"}
          />
        )}
        {accessToken && checkRole(accessToken, "manual", "bsr") && (
          <ToolsItem
            title={"Manual BSR Entry"}
            text={"Already know your Base Stroke Rate (BSR)? Enter it here."}
            link={"/bsr/entersingle"}
          />
        )}
        {accessToken && checkRole(accessToken, "tweak", "bsr") && (
          <ToolsItem
            title={"BSR Tweaker"}
            text={"Stroke rate just a little off? Tweak it here."}
            link={"/bsr/tweaker"}
          />
        )}
        {accessToken && checkRole(accessToken, "read", "fitnessAndFatigue") && (
          <ToolsItem
            title={"Swim Type Calculator"}
            text={"Coming Soon ..."}
            link={""}
          />
        )}
        {accessToken && checkRole(accessToken, "read", "fitnessAndFatigue") && (
          <ToolsItem
            title={"Stroke Rate Calculator"}
            text={"Coming Soon ..."}
            link={""}
          />
        )}
        {accessToken && checkRole(accessToken, "read", "fitnessAndFatigue") && (
          <ToolsItem
            title={"Fitness, Fatigue & Form"}
            text={"Dig deeper into your fitness here."}
            link={"/fitness"}
          />
        )}
      </SimpleGrid>
    </VStack>
  );
};

const ToolsItem = (props: any) => {
  const navigate = useNavigate();
  const iconColor = useColorModeValue("ssNeonOrangeDark", "ssNeonOrangeLight");

  return (
    <Box
      cursor={props.link ? "pointer" : "default"}
      onClick={() =>
        props.link ? navigate(props.link, { replace: false }) : null
      }
      opacity={props.link ? 1 : 0.5}
    >
      <BoxWrapper>
        <HStack w={"full"}>
          <VStack w={"full"} alignItems={"flex-start"}>
            <Heading size={"md"}>{props.title}</Heading>
            <Text>{props.text}</Text>
          </VStack>
          {props.link && (
            <Icon h={50} w={50} color={iconColor} as={FiArrowRightCircle} />
          )}
        </HStack>
      </BoxWrapper>
    </Box>
  );
};

export { ToolsPage };
