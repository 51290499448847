import { LockIcon } from "@chakra-ui/icons";
import {
  BoxProps,
  Button,
  HStack,
  Spacer,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCssManual, createCssTest } from "../../DataAccess/css";
import { getUserById } from "../../DataAccess/users";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectUserState,
  updateUserState,
} from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { cssEntry } from "../../types/css";
import { user } from "../../types/user";
import { checkRole } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";

interface CssDisplayButtonsProps extends BoxProps {
  cssResult: cssEntry;
  resetCalculation: any;
  cssUnit: string;
  setCssUnit: any;
  mode: "VIEW" | "CREATE";
}

const CssDisplayButtons: React.FC<CssDisplayButtonsProps> = ({
  cssResult,
  resetCalculation,
  cssUnit,
  setCssUnit,
  mode,
}) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { user } = useAppSelector(selectUserState);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const toggleCSSUnits = (): void => {
    setCssUnit(cssUnit === "y" ? "m" : "y");
  };

  const saveAsCurrent = async (
    user: user,
    cssResult: cssEntry
  ): Promise<void> => {
    try {
      setIsSaving(true);
      if (cssResult.test) {
        await createCssTest(
          cssResult.test.tt400,
          cssResult.test.tt200,
          cssResult.test.tt100 ? cssResult.test.tt100 : 0,
          cssResult.pool.lapLength,
          cssResult.pool.lapUnit
        );
      } else {
        await createCssManual(
          cssResult.pool.lapUnit === "y"
            ? cssResult.y.secondsPer100
            : cssResult.m.secondsPer100,
          cssResult.pool.lapLength,
          cssResult.pool.lapUnit
        );
      }
      if (user) {
        const userResponse = await getUserById(user._id);
        if (userResponse) {
          dispatch(updateUserState(userResponse));
        }
      }
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Save CSS"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Your CSS entry has been saved.</Text>
          </CustomToast>
        );
      });
      navigate("/css/history", { replace: false });
      setIsSaving(false);
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Save CSS"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to save CSS entry, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsSaving(false);
    }
  };

  const premiumOnly = () => {
    toast.closeAll();
    createToast(toast, (props: any) => {
      return (
        <CustomToast
          title={"Save CSS"}
          status={"Error"}
          toast={toast}
          toastId={props.id}
        >
          <Text>
            Save CSS and PDF export features require you to be logged in or to
            have a higher level of subscription than you currently have. Check
            out the pricing page for more details.
          </Text>
        </CustomToast>
      );
    });
  };

  return (
    <VStack align="left" width="full">
      <HStack width="full" align="left">
        {mode === "CREATE" && (
          <Button variant="warning" px={10} onClick={resetCalculation} w={350}>
            &lt;&lt; Start Again
          </Button>
        )}
        <Spacer />
        <Button px={10} onClick={toggleCSSUnits} w={400}>
          Toggle Units to {cssUnit === "m" ? "Yards" : "Metres"}
        </Button>
        <Spacer />
        {mode === "CREATE" && user && checkRole(accessToken, "save", "css") && (
          <Button
            variant="success"
            px={10}
            isLoading={isSaving}
            onClick={() => {
              saveAsCurrent(user, cssResult);
            }}
            w={350}
          >
            Save as Current
          </Button>
        )}
        {mode === "CREATE" && !checkRole(accessToken, "save", "css") && (
          <Button
            leftIcon={<LockIcon />}
            onClick={premiumOnly}
            variant="success"
            px={10}
            w={350}
          >
            Save as Current
          </Button>
        )}
      </HStack>
    </VStack>
  );
};

export { CssDisplayButtons };
