import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { selectUserState } from "../../../app/features/user/userSlice";
import { useAppSelector } from "../../../app/hooks";
import { changePassword } from "../../../DataAccess/passwords";
import { createToast } from "../../../utils/toastHelper";
import { CreateSupportTicket } from "../../ui/CreateSupportTicket";
import { CustomToast } from "../../ui/CustomToast";
import { PasswordInput } from "../../ui/FormControls";
import Loading from "../../ui/Loading";

const AccountSettingsPassword = (props: any) => {
  const toast = useToast();
  const { user } = useAppSelector(selectUserState);
  const [passwordLastChanged, setPasswordLastChanged] = useState<
    string | undefined
  >();

  const {
    control: controlPassword,
    reset: resetPassword,
    handleSubmit: handleSubmitPassword,
    formState: {
      errors: errorsPassword,
      isSubmitting: isSubmittingPassword,
      isValid: isValidPassword,
      isDirty: isDirtyPassword,
    },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
    mode: "all",
  });

  useEffect(() => {
    if (user) {
      setPasswordLastChanged(user.last?.passwordChange);
    }
  }, [user, setPasswordLastChanged]);

  const handlePassword = async (data: any): Promise<void> => {
    toast.closeAll();
    try {
      const result = await changePassword({
        currentPassword: data.currentPassword,
        password: data.newPassword,
      });
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Change Password"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Your password has been succesfully changed.</Text>
          </CustomToast>
        );
      });
      resetPassword();
      setPasswordLastChanged(result);
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Change Password"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to change password, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmitPassword(handlePassword)}
      w="full"
      alignItems="flex-start"
      mb={5}
    >
      <Heading as="h3" size="md">
        Password
      </Heading>
      <FormControl pb={1}>
        <FormLabel>
          Current Password
          {errorsPassword.currentPassword && (
            <span className="formError">
              {errorsPassword.currentPassword.message}
            </span>
          )}
        </FormLabel>
        <Controller
          control={controlPassword}
          rules={{ required: "Current Password is required" }}
          name="currentPassword"
          render={({ field: { ref, ...restField } }) => (
            <PasswordInput autoComplete="current-password" {...restField} />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          New Password
          {errorsPassword.newPassword && (
            <span className="formError">
              {errorsPassword.newPassword.message}
            </span>
          )}
        </FormLabel>
        <Controller
          control={controlPassword}
          rules={{ required: "New Password is required" }}
          name="newPassword"
          render={({ field: { ref, ...restField } }) => (
            <PasswordInput autoComplete="new-password" {...restField} />
          )}
        />
      </FormControl>
      {passwordLastChanged && (
        <Text>
          Password Last Changed:{" "}
          <em>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZoneName: "short",
            }).format(new Date(passwordLastChanged))}
          </em>
        </Text>
      )}
      <HStack w="full" pt={2}>
        <Spacer />
        <Button
          isDisabled={!isDirtyPassword || !isValidPassword}
          isLoading={isSubmittingPassword}
          type="submit"
          mt={5}
        >
          Change Password
        </Button>
      </HStack>
    </VStack>
  );
};

export { AccountSettingsPassword };
