import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MultiSelect } from "chakra-multiselect";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  getCoachById,
  getCoachByShortCode,
  updateCoachById,
} from "../../DataAccess/coaches";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { updateCoachesState } from "../../app/features/coach/coachesSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { breadcrumbLink } from "../../types/breadcrumb";
import { coach, coachLocation, servicesOptions } from "../../types/coach";
import { checkRole } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { MessageDisplay } from "../generic/MessageDisplay";
import { RegionSelector } from "../ui/DropDowns";
import { HtmlEditor } from "../ui/HtmlEditor";
import Loading from "../ui/Loading";
import { RequiredField } from "../ui/RequiredField";

interface CoachesEditProps extends BoxProps {
  coachShortCode: string;
}

const CoachEditProfile: React.FC<CoachesEditProps> = ({ coachShortCode }) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const [loadingCoach, setLoadingCoach] = useState<boolean>(true);
  const [selectedCoach, setSelectedCoach] = useState<coach>();
  const navigate = useNavigate();
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getCoachLocal = async () => {
      const coachResponse = await getCoachByShortCode(coachShortCode);
      setSelectedCoach(coachResponse);
      setLoadingCoach(false);
    };
    getCoachLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [
      { href: "/coaches", title: "Coaches" },
    ];
    if (selectedCoach) {
      if (selectedCoach.data.displayName) {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title: selectedCoach.data.displayName,
        });
      } else {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title:
            selectedCoach.data.firstName + " " + selectedCoach.data.lastName,
        });
      }
      breadcrumbLinks.push({
        href: "/coaches/" + selectedCoach.data.shortCode + "/edit",
        title: "Edit Coach Profile",
      });
    }
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoach]);

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      displayName: "",
      brandName: "",
      mainWebsiteUrl: "",
      facebook: "",
      instagram: "",
      twitter: "",
      tiktok: "",
      linkedin: "",
      bookingWebsiteUrl: "",
      email: "",
      phone: "",
      shortCode: "",
      brandLocation: "",
      services: [] as string[],
      locations: [] as coachLocation[],
      distanceLocationLat: 0,
      distanceLocationLng: 0,
      region: "",
      description: "",
    },
    mode: "all",
  });

  useEffect(() => {
    if (selectedCoach) {
      setValue("firstName", selectedCoach.data.firstName);
      setValue("lastName", selectedCoach.data.lastName);
      setValue(
        "displayName",
        selectedCoach.data.displayName ? selectedCoach.data.displayName : ""
      );
      setValue("brandName", selectedCoach.data.brandName);
      setValue(
        "email",
        selectedCoach.data.email ? selectedCoach.data.email : ""
      );
      setValue(
        "mainWebsiteUrl",
        selectedCoach.data.mainWebsite && selectedCoach.data.mainWebsite.url
          ? selectedCoach.data.mainWebsite.url
          : ""
      );
      setValue(
        "bookingWebsiteUrl",
        selectedCoach.data.bookingWebsite &&
          selectedCoach.data.bookingWebsite.url
          ? selectedCoach.data.bookingWebsite.url
          : ""
      );
      setValue(
        "facebook",
        selectedCoach.data.facebook ? selectedCoach.data.facebook : ""
      );
      setValue(
        "instagram",
        selectedCoach.data.instagram ? selectedCoach.data.instagram : ""
      );
      setValue(
        "twitter",
        selectedCoach.data.twitter ? selectedCoach.data.twitter : ""
      );
      setValue(
        "tiktok",
        selectedCoach.data.tiktok ? selectedCoach.data.tiktok : ""
      );
      setValue(
        "linkedin",
        selectedCoach.data.linkedin ? selectedCoach.data.linkedin : ""
      );
      setValue(
        "phone",
        selectedCoach.data.phone ? selectedCoach.data.phone : ""
      );
      setValue("shortCode", selectedCoach.data.shortCode);
      setValue("brandLocation", selectedCoach.data.brandLocation);
      setValue(
        "distanceLocationLat",
        selectedCoach.data.distanceLocation &&
          selectedCoach.data.distanceLocation[0]
          ? selectedCoach.data.distanceLocation[0]
          : 0
      );
      setValue(
        "distanceLocationLng",
        selectedCoach.data.distanceLocation &&
          selectedCoach.data.distanceLocation[1]
          ? selectedCoach.data.distanceLocation[1]
          : 0
      );
      setValue("region", selectedCoach.data.region);
      setValue(
        "description",
        selectedCoach.data.description ? selectedCoach.data.description : ""
      );
      setValue(
        "services",
        selectedCoach.data.services ? selectedCoach.data.services : []
      );
      setValue(
        "locations",
        selectedCoach.data.locations ? selectedCoach.data.locations : []
      );
    }
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoach]);

  const handle = async (data: any): Promise<void> => {
    if (selectedCoach) {
      // Need to destructure services
      data.services = data.services.map((s: any) => {
        if (s.value && s.label) {
          return s.value;
        }
        return s;
      });
      await updateCoachById(selectedCoach.id, data);
      const coachResponse = await getCoachById(selectedCoach.id);
      setSelectedCoach(coachResponse);
      dispatch(updateCoachesState(null));
      navigate("/coaches/" + selectedCoach.data.shortCode, { replace: false });
    }
  };

  if (loadingCoach) {
    return <Loading message="Loading Coach" />;
  }

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (
    !checkRole(accessToken, "*ALL", "superadmin") &&
    user._id !== selectedCoach?.data.user
  ) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>403: Not Authorised</Heading>
        <Text>
          We're terribly sorry but it would appear that you have tried to do
          something you are not authorised to do. Could be us, could be you ...
          if you are sure you should be able to do what you are trying to do
          then get in touch using the help button at the bottom left of the
          screen.
        </Text>
      </VStack>
    );
  }

  if (
    selectedCoach &&
    selectedCoach.data.shortCode &&
    selectedCoach.data.shortCode === coachShortCode
  ) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>{selectedCoach.data.displayName}</Heading>
        <VStack
          as="form"
          onSubmit={handleSubmit(handle)}
          w="full"
          alignItems="flex-start"
          mb={5}
        >
          <SimpleGrid columns={[1, 1, 2, 2]} w="full" columnGap={5} rowGap={2}>
            <VStack>
              <FormControl pb={1}>
                <FormLabel>
                  First Name
                  <RequiredField />
                  {errors.firstName && (
                    <span className="formError">
                      {errors.firstName.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "First name is required" }}
                  name="firstName"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Last Name
                  <RequiredField />
                  {errors.lastName && (
                    <span className="formError">{errors.lastName.message}</span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Last name is required" }}
                  name="lastName"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Display Name
                  <RequiredField />
                  {errors.displayName && (
                    <span className="formError">
                      {errors.displayName.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Display name is required" }}
                  name="displayName"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Brand Name
                  <RequiredField />
                  {errors.brandName && (
                    <span className="formError">
                      {errors.brandName.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Brand name is required" }}
                  name="brandName"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Brand Location
                  <RequiredField />
                  {errors.brandLocation && (
                    <span className="formError">
                      {errors.brandLocation.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Brand location is required" }}
                  name="brandLocation"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Distance Location
                  <RequiredField />
                  {errors.distanceLocationLat && (
                    <span className="formError">
                      {errors.distanceLocationLat.message}
                    </span>
                  )}
                  {errors.distanceLocationLng && (
                    <span className="formError">
                      {errors.distanceLocationLng.message}
                    </span>
                  )}
                </FormLabel>
                <HStack>
                  <Controller
                    control={control}
                    rules={{
                      required: "Distance location (latitude) is required",
                    }}
                    name="distanceLocationLat"
                    render={({ field: { ref, ...restField } }) => (
                      <Input {...restField} placeholder="" />
                    )}
                  />
                  <Controller
                    control={control}
                    rules={{
                      required: "Distance location (longitude) is required",
                    }}
                    name="distanceLocationLng"
                    render={({ field: { ref, ...restField } }) => (
                      <Input {...restField} placeholder="" />
                    )}
                  />
                </HStack>
              </FormControl>
            </VStack>
            <VStack>
              <FormControl pb={1}>
                <FormLabel>
                  Services
                  {errors.services && (
                    <span className="formError">{errors.services.message}</span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  name="services"
                  render={({
                    field: { ref, value, onChange, ...restField },
                  }) => (
                    <MultiSelect
                      options={servicesOptions}
                      value={value}
                      duplicates={false}
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Region
                  <RequiredField />
                  {errors.region && (
                    <span className="formError">{errors.region.message}</span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  rules={{ required: "Region is required" }}
                  name="region"
                  render={({ field: { ref, onChange, ...restField } }) => (
                    <RegionSelector
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(e)
                      }
                      {...restField}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Main Website{" "}
                  {errors.mainWebsiteUrl && (
                    <span className="formError">
                      {errors.mainWebsiteUrl.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  name="mainWebsiteUrl"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Booking Website{" "}
                  {errors.bookingWebsiteUrl && (
                    <span className="formError">
                      {errors.bookingWebsiteUrl.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  name="bookingWebsiteUrl"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Email Address
                  {errors.email && (
                    <span className="formError">{errors.email.message}</span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Phone No.
                  {errors.lastName && (
                    <span className="formError">{errors.lastName.message}</span>
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={[1, 1, 3, 3]} w="full" columnGap={5} rowGap={2}>
            <FormControl pb={1}>
              <FormLabel>
                Facebook
                {errors.facebook && (
                  <span className="formError">{errors.facebook.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                name="facebook"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>
                Instagram
                {errors.instagram && (
                  <span className="formError">{errors.instagram.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                name="instagram"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>
                Twitter
                {errors.twitter && (
                  <span className="formError">{errors.twitter.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                name="twitter"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>
                TikTok
                {errors.tiktok && (
                  <span className="formError">{errors.tiktok.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                name="tiktok"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>
                Linked In
                {errors.linkedin && (
                  <span className="formError">{errors.linkedin.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                name="linkedin"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
          </SimpleGrid>
          <FormControl pb={1}>
            <FormLabel>
              Description
              {errors.description && (
                <span className="formError">{errors.description.message}</span>
              )}
            </FormLabel>
            <Controller
              control={control}
              name="description"
              render={({ field: { ref, onChange, ...restField } }) => (
                <HtmlEditor
                  onChange={(value: string) => {
                    onChange(value);
                  }}
                  height={500}
                  {...restField}
                />
              )}
            />
          </FormControl>
          <HStack width="full" align="flex-start" mt={5}>
            <Button
              variant="warning"
              px={10}
              onClick={() =>
                navigate("/coaches/" + selectedCoach.data.shortCode, {
                  replace: false,
                })
              }
            >
              Cancel
            </Button>
            <Box width="full">&nbsp;</Box>
            <Button
              isDisabled={!isValid}
              isLoading={isSubmitting}
              type="submit"
            >
              Save Coach
            </Button>
          </HStack>
        </VStack>
      </VStack>
    );
  }

  if (selectedCoach && !selectedCoach.data.shortCode) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not find coach!">
          <Text>Could not find coach {coachShortCode}.</Text>
        </MessageDisplay>
        <Button onClick={() => navigate("/coaches", { replace: false })}>
          See All Coaches
        </Button>
      </VStack>
    );
  }

  return <Loading />;
};

export { CoachEditProfile };
