import { useParams } from "react-router-dom";
import { CoachEditLocationsAdd } from "../../components/coaches/CoachEditLocationsAdd";

const CoachEditLocationsAddPage = () => {
  const params = useParams<{ coachShortCode: string }>();

  if (!params.coachShortCode) {
    return null;
  }

  return <CoachEditLocationsAdd coachShortCode={params.coachShortCode} />;
};

export { CoachEditLocationsAddPage };
