import { FormControl, FormLabel, Switch, useId } from "@chakra-ui/react";
import React, { useCallback } from "react";

// Define the types for the component props
interface IosToggleProps {
  value?: boolean; // This will be the controlled value
  onChange?: (value: boolean) => void; // This will handle state changes
  label?: string;
  id?: string; // Optional id prop to ensure uniqueness
}

const IosToggle: React.FC<IosToggleProps> = React.memo(
  ({ value = false, onChange, label = "", id }) => {
    const uniqueId = useId("toggle");
    const toggleId = id || uniqueId;

    // Memoized callback to avoid re-creation on each render
    const handleToggle = useCallback(() => {
      onChange?.(!value);
    }, [value, onChange]);

    return (
      <FormControl
        display="inline-flex"
        alignItems="center"
        width="auto"
        role="switch"
        aria-checked={value}
        aria-labelledby={label ? `${toggleId}-label` : undefined}
        aria-describedby={label ? `${toggleId}-description` : undefined}
      >
        {label !== "" && (
          <FormLabel id={`${toggleId}-label`} htmlFor={toggleId} mb="0" mr="2">
            {label}
          </FormLabel>
        )}
        <Switch
          id={toggleId} // Use the unique id
          isChecked={value}
          onChange={handleToggle} // Memoized callback to prevent unnecessary re-renders
          size="lg" // Large size for an iOS-like look
          colorScheme="pink" // You can customize the color
          aria-checked={value} // Adds screen reader support
          aria-label={label ? label : "toggle switch"} // Fallback for accessibility if no label
        />
      </FormControl>
    );
  }
);

export default IosToggle;
