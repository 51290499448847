import {
  Avatar,
  BoxProps,
  Button,
  HStack,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { TiCancel } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import {
  deleteCoachAthlete,
  getCoachAthletesForUser,
} from "../../DataAccess/coachAthletes";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { updateCoachAthletesState } from "../../app/features/coachAthletes/coachAthletesSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { coachAthlete } from "../../types/coachAthlete";
import { checkRole, swapToken } from "../../utils/authHelper";
import { formatDateShort } from "../../utils/dateHelper";
import { getGravatarUrl } from "../../utils/gravatarHelper";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";
import { ChangeTrialMenu } from "./ChangeTrialMenu";

interface CoachAthleteItemProps extends BoxProps {
  coachAthlete: coachAthlete;
}

const CoachAthleteItem: React.FC<CoachAthleteItemProps> = ({
  coachAthlete,
}) => {
  const { access: accessToken, refresh: refreshToken } =
    useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [swapping, setSwapping] = useState<boolean>(false);
  const related =
    user?._id === coachAthlete.data.parent.id
      ? coachAthlete.data.child
      : coachAthlete.data.parent;
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Work out some stuff
  let hasSubscription = false;
  if (
    related.extended?.data.billing.source === "Stripe" ||
    related.extended?.data.billing.source === "Apple"
  ) {
    hasSubscription = true;
  }
  let subscriptionExpired = false;
  if (hasSubscription) {
    const now = new Date().getTime();
    const endDate = new Date(
      related.extended!.data.billing.subscription!.currentPeriodEnd
    ).getTime();
    if (now > endDate) {
      subscriptionExpired = true;
    }
  }
  let hasTrial = false;
  if (
    related.extended?.data.billing.trial?.understandingEndDate ||
    related.extended?.data.billing.trial?.routineEndDate
  ) {
    hasTrial = true;
  }
  let routineTrialExpired = false;
  let understandingTrialExpired = false;
  if (hasTrial) {
    const now = new Date().getTime();
    const understandingEndDate = new Date(
      related.extended!.data.billing.trial!.understandingEndDate
    ).getTime();
    const routineEndDate = new Date(
      related.extended!.data.billing.trial!.routineEndDate
    ).getTime();
    if (now > routineEndDate) {
      routineTrialExpired = true;
    }
    if (now > understandingEndDate) {
      understandingTrialExpired = true;
    }
  }

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  const deleteCoachAthleteHandler = async (
    coachAthleteId: string,
    recipientName: string
  ) => {
    toast.closeAll();
    try {
      setIsDeleting(true);
      // Do some stuff
      await deleteCoachAthlete(coachAthleteId);
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete CoachAthlete"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>CoachAthlete changed for {recipientName}</Text>
          </CustomToast>
        );
      }); // Update the list
      const updatedResponse = await getCoachAthletesForUser(user._id);
      dispatch(updateCoachAthletesState(updatedResponse));
      setIsDeleting(false);
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete CoachAthlete"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to delete coachAthlete, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsDeleting(false);
    }
  };

  if (!related.extended) {
    return (
      <Tr key={coachAthlete._id}>
        <Td>
          <Avatar
            size={"xs"}
            name={related.fullName}
            src={getGravatarUrl(related.emailAddress, 32)}
          />
        </Td>
        <Td> - </Td>
        <Td> - </Td>
        <Td>{related.emailAddress}</Td>
        <Td> - </Td>
        <Td> - </Td>
        <Td> - </Td>
        <Td isNumeric>
          <Button
            size={"xs"}
            variant={"warning"}
            isLoading={isDeleting}
            onClick={async () => {
              deleteCoachAthleteHandler(
                coachAthlete._id,
                coachAthlete.data.child.fullName
              );
            }}
          >
            Delete
          </Button>
        </Td>
      </Tr>
    );
  }

  return (
    <Tr key={coachAthlete._id}>
      <Td>{related.extended?.data.basic.firstName}</Td>
      <Td>{related.extended?.data.basic.lastName}</Td>
      <Td>{related.emailAddress}</Td>
      <Td>
        {hasTrial && (
          <HStack>
            <Text>
              {related.extended!.data.billing.trial!.routineEndDate
                ? formatDateShort(
                    related.extended!.data.billing.trial!.routineEndDate
                  )
                : ""}
            </Text>
            {routineTrialExpired && <TiCancel color={"red"} />}
          </HStack>
        )}
      </Td>
      <Td>
        <ChangeTrialMenu coachAthlete={coachAthlete} level="routine" />
      </Td>
      <Td>
        {hasTrial && (
          <HStack>
            <Text>
              {related.extended!.data.billing.trial!.understandingEndDate
                ? formatDateShort(
                    related.extended!.data.billing.trial!.understandingEndDate
                  )
                : ""}
            </Text>
            {understandingTrialExpired && <TiCancel color={"red"} />}
          </HStack>
        )}
      </Td>
      <Td>
        <ChangeTrialMenu coachAthlete={coachAthlete} level="understanding" />
      </Td>
      <Td>
        {hasSubscription &&
          related.extended.data.billing.source +
            ": " +
            related.extended.data.billing.subscription!.level}
        {hasSubscription && (
          <HStack>
            <Text>
              {formatDateShort(
                related.extended.data.billing.subscription!.currentPeriodEnd
              )}
            </Text>
            {subscriptionExpired && <TiCancel color={"red"} />}
          </HStack>
        )}
      </Td>
      <Td isNumeric>
        {checkRole(accessToken, "*ALL", "superadmin") && (
          <Button
            variant={"success"}
            size={"xs"}
            mr={2}
            onClick={async () => {
              toast.closeAll();
              try {
                if (refreshToken) {
                  setSwapping(true);
                  await swapToken(dispatch, coachAthlete.data.child.id);
                  navigate("/", { replace: false });
                } else {
                  setSwapping(false);
                  throw new Error("Unable to login as selected user");
                }
              } catch (error: any) {
                setSwapping(false);
                createToast(toast, (props: any) => {
                  return (
                    <CustomToast
                      title={"Login As"}
                      status={"Error"}
                      toast={toast}
                      toastId={props.id}
                    >
                      <Text>Unable to login as selected user</Text>
                    </CustomToast>
                  );
                });
              }
            }}
            isLoading={swapping}
          >
            Login As
          </Button>
        )}
        <Button
          size={"xs"}
          variant={"warning"}
          isLoading={isDeleting}
          onClick={async () => {
            deleteCoachAthleteHandler(
              coachAthlete._id,
              coachAthlete.data.child.fullName
            );
          }}
        >
          Delete
        </Button>
      </Td>
    </Tr>
  );
};

export { CoachAthleteItem };
