import { Button, Heading, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const PrivacyPage = () => {
  const navigate = useNavigate();
  return (
    <VStack w="full">
      <Heading>Privacy Policy</Heading>
      <ol>
        <li>
          <p>
            <b>Introduction</b>
          </p>
          <ol>
            <li>
              <p>
                We are committed to safeguarding the privacy of our website
                visitors, app users and customers for Swim Smooth services.
              </p>
            </li>
            <li>
              <p>
                This policy applies where we are acting as a data controller
                with respect to your personal data; in other words, where we
                determine the purposes and means of the processing of that
                personal data.
              </p>
            </li>
            <li>
              <p>
                We use cookies on our website. Insofar as those cookies are not
                strictly necessary for the provision of our website and
                services, we will ask you to consent to our use of cookies when
                you first visit our website.
              </p>
            </li>
            <li>
              <p>
                In this policy, "we", "us" and "our" refer to Swim Smooth
                Coaching Collective Ltd. For more information about us, see
                Section 18.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>The personal data that we collect</b>
          </p>
          <ol>
            <li>
              <p>
                In this Section we have set out the general categories of
                personal data that we process and, in the case of personal data
                that we did not obtain directly from you, information about the
                source and specific categories of that data.
              </p>
            </li>
            <li>
              <p>
                We may process information contained in or relating to any
                communication that you send to us or that we send to you ("
                <b>communication data</b>
                "). The communication data may include the communication content
                and metadata associated with the communication. Our website and
                apps will generate the metadata associated with communications
                made using contact forms.
              </p>
            </li>
            <li>
              <p>
                We may process your user account data ("
                <b>account and profile data</b>"). The account and profile data
                may include your account identifier, name, email address,
                telephone number, postal address, account creation and
                modification dates, account settings, photographs, gender,
                height, date of birth, training goals, swimming competence
                level, subscription information and marketing preferences. The
                primary source of the account and profile data is you, although
                some elements of the account and profile data may be generated
                by our services.
              </p>
            </li>
            <li>
              <p>
                We may process your personal data that are provided in the
                course of the use of our services and generated by our software
                in the course of such use ("<b>swim data</b>"). The swim data
                may include information about the date, time and duration of the
                swims, geolocation information, information collected from your
                connected devices' gyroscopes and accelerometers, heart rate
                information, along with information about your swimming
                technique. The source of the swim data is you, your connected
                devices and/or our algorithms.
              </p>
            </li>
            <li>
              <p>
                We may process information relating to transactions, including
                purchases of goods and/or services, that you enter into with us
                and/or through our website or apps ("<b>transaction data</b>").
                The transaction data may include your name, your contact
                details, limited elements of your payment card details (or other
                payment details) and the transaction details. The source of the
                transaction data is you and/or our payment services provider.
              </p>
            </li>
            <li>
              <p>
                We may process data about your use of our website, app and
                services ("<b>usage data</b>"). The usage data may include your
                IP address, geographical location, browser type and version,
                operating system, referral source, length of visit, page views
                and navigation paths, as well as information about the timing,
                frequency and pattern of your use. The source of the usage data
                is our analytics tracking system.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Purposes of processing and legal bases</b>
          </p>
          <ol>
            <li>
              <p>
                In this Section we have set out the purposes for which we may
                process personal data and the legal bases of the processing.
              </p>
            </li>
            <li>
              <p>
                <b>Operations </b>- We may process your personal data for the
                purposes of operating our website and app, the processing and
                fulfilment of orders, providing our services, supplying goods to
                customers, generating invoices, bills and other payment-related
                documentation, and credit control. The legal basis for this
                processing is our legitimate interests, namely the proper
                administration of our website, app, services and business
                generally. Some of our processing for the purposes of our
                operations will include the processing of data concerning
                health, which may be included in your swim data. Special rules
                apply to the processing of this data. Insofar as we process data
                concerning health, we will ask for your explicit consent to such
                processing.
              </p>
            </li>
            <li>
              <p>
                <b>Relationships and communications</b> - We may process
                communications data, account and profile data and transaction
                data for the purposes of managing our relationships,
                communicating with you (excluding communicating for the purposes
                of direct marketing) by email, live chat, SMS, push
                notifications, post and/or telephone, providing support services
                and complaint handling. The legal basis for this processing is
                our legitimate interests, namely communications with our
                website, app and service users, the maintenance of our
                relationships, enabling the use of our services, and the proper
                administration of our website, app, services and business
                generally.
              </p>
            </li>
            <li>
              <p>
                <b>Personalisation </b>- We may process account and profile
                data, swim data and/or usage data for the purposes of
                personalising the content and advertisements that you see
                through our website and apps to ensure that you only see
                material that is relevant to you. The legal basis for this
                processing is our legitimate interests, namely offering the best
                possible experience for our users.
              </p>
            </li>
            <li>
              <p>
                <b>Direct marketing </b>- We may process account and profile
                data and/or transaction data for the purposes of creating,
                targeting and sending direct marketing communications by email,
                SMS and/or post and making contact by telephone for
                marketing-related purposes. The legal basis for this processing
                will usually be consent; however, if we have not asked for your
                specific and express consent to direct marketing, the legal
                basis will be our legitimate interests, namely our interests in
                promoting our website, app, services and business generally.
              </p>
            </li>
            <li>
              <p>
                <b>Research and analysis </b>- We may process usage data and/or
                transaction data for the purposes of researching and analysing
                the use of our website, apps and services, as well as
                researching and analysing other interactions with our business.
                In addition, we will anonymise swim data and use the anonymised
                data for the purpose of training and improving our algorithms.
                Insofar as this involves the processing of personal data, the
                legal basis for this processing is our legitimate interests,
                namely monitoring, supporting, improving and securing our
                website, app, services and business generally.
              </p>
            </li>
            <li>
              <p>
                <b>Record keeping </b>- We may process your personal data for
                the purposes of creating and maintaining our databases, back-up
                copies of our databases and our business records generally. The
                legal basis for this processing is our legitimate interests,
                namely ensuring that we have access to all the information we
                need to properly and efficiently run our business in accordance
                with this policy.
              </p>
            </li>
            <li>
              <p>
                <b>Security </b>- We may process your personal data for the
                purposes of security and the prevention of fraud and other
                criminal activity. The legal basis of this processing is our
                legitimate interests, namely the protection of our website,
                services and business, and the protection of others.
              </p>
            </li>
            <li>
              <p>
                <b>Insurance and risk management</b> - We may process your
                personal data where necessary for the purposes of obtaining or
                maintaining insurance coverage, managing risks and/or obtaining
                professional advice. The legal basis for this processing is our
                legitimate interests, namely the proper protection of our
                business against risks.
              </p>
            </li>
            <li>
              <p>
                <b>Legal claims</b> - We may process your personal data where
                necessary for the establishment, exercise or defence of legal
                claims, whether in court proceedings or in an administrative or
                out-of-court procedure. The legal basis for this processing is
                our legitimate interests, namely the protection and assertion of
                our legal rights, your legal rights and the legal rights of
                others.
              </p>
            </li>
            <li>
              <p>
                <b>Legal compliance and vital interests</b> - We may also
                process your personal data where such processing is necessary
                for compliance with a legal obligation to which we are subject
                or in order to protect your vital interests or the vital
                interests of another natural person.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Providing your personal data to others</b>
          </p>
          <ol>
            <li>
              <p>
                We may disclose your personal data to our insurers and/or
                professional advisers insofar as reasonably necessary for the
                purposes of obtaining or maintaining insurance coverage,
                managing risks, obtaining professional advice.
              </p>
            </li>
            <li>
              <p>
                Your personal data held in our services database will be stored
                on the servers of our hosting services providers, currently
                Google, Inc (using the Google Cloud Platform).
              </p>
            </li>
            <li>
              <p>
                Financial transactions relating to our services are handled by
                our payment services providers, Stripe. We will share
                transaction data with our payment services providers only to the
                extent necessary for the purposes of processing your payments,
                refunding such payments and dealing with complaints and queries
                relating to such payments and refunds. You can find information
                about the payment services providers' privacy policies and
                practices at{" "}
                <a href="https://stripe.com/gb/privacy">
                  https://stripe.com/gb/privacy
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                In addition to the specific disclosures of personal data set out
                in this Section 4, we may disclose your personal data where such
                disclosure is necessary for compliance with a legal obligation
                to which we are subject, or in order to protect your vital
                interests or the vital interests of another natural person. We
                may also disclose your personal data where such disclosure is
                necessary for the establishment, exercise, or defence of legal
                claims, whether in court proceedings or in an administrative or
                out-of-court procedure.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>International transfers of your personal data</b>
          </p>
          <ol>
            <li>
              <p>
                The hosting facilities for our services, provided by Google, are
                situated in jurisdictions across Europe, Australia, North
                America, and Asia. Transfers to each of these countries will be
                protected by appropriate safeguards, including the use of
                standard data protection clauses adopted or approved by the
                competent data protection authorities.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Retaining and deleting personal data</b>
          </p>
          <ol>
            <li>
              <p>
                This Section sets out our data retention policies and
                procedures, which are designed to help ensure that we comply
                with our legal obligations in relation to the retention and
                deletion of personal data.
              </p>
            </li>
            <li>
              <p>
                Personal data that we process for any purpose or purposes shall
                not be kept for longer than is necessary for that purpose or
                those purposes.
              </p>
            </li>
            <li>
              <p>We will retain your personal data as follows:</p>
              <ol>
                <li>
                  <p>
                    communication data will be retained for a minimum period of
                    6 years following the date of the communication in question,
                    and for a maximum period of 7 years following that date;
                  </p>
                </li>
                <li>
                  <p>
                    account and profile data will be retained for a minimum
                    period of 6 years following the date of closure of the
                    relevant account, and for a maximum period of 7 years
                    following that date;
                  </p>
                </li>
                <li>
                  <p>
                    swim data will be retained for a minimum period of 12 months
                    following the date of termination of the relevant contract,
                    and for a maximum period of 24 months following that date
                    (although, if the data is anonymised in accordance with
                    Section 3.6 before the end of the retention period, the
                    anonymised data may be retained indefinitely);
                  </p>
                </li>
                <li>
                  <p>
                    transaction data will be retained for a minimum period of 10
                    years following the date of the transaction, and for a
                    maximum period of 11 years following that date; and
                  </p>
                </li>
                <li>
                  <p>
                    usage data will be retained for 36 months following the date
                    of collection.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Notwithstanding the other provisions of this Section, we may
                retain your personal data where such retention is necessary for
                compliance with a legal obligation to which we are subject, or
                in order to protect your vital interests or the vital interests
                of another natural person.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Security of personal data</b>
          </p>
          <ol>
            <li>
              <p>
                We will take appropriate technical and organisational
                precautions to secure your personal data and to prevent the
                loss, misuse or alteration of your personal data.
              </p>
            </li>
            <li>
              <p>
                We will store your personal data on secure servers, personal
                computers and mobile devices, and in secure manual
                record-keeping systems.
              </p>
            </li>
            <li>
              <p>
                Data that is sent from your web browser or app to our web
                server, or from our web server to your web browser or app, will
                be protected using encryption technology.
              </p>
            </li>
            <li>
              <p>
                You should ensure that your password is not susceptible to being
                guessed, whether by a person or a computer program. You are
                responsible for keeping the password you use for accessing our
                website and apps confidential and we will not ask you for your
                password (except when you log in).
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Your rights</b>
          </p>
          <ol>
            <li>
              <p>
                In this Section, we have listed the rights that you have under
                data protection law.
              </p>
            </li>
            <li>
              <p>Your principal rights under data protection law are:</p>
              <ol>
                <li>
                  <p>
                    <b>the right to access</b> - you can ask for copies of your
                    personal data;
                  </p>
                </li>
                <li>
                  <p>
                    <b>the right to rectification</b> - you can ask us to
                    rectify inaccurate personal data and to complete incomplete
                    personal data;
                  </p>
                </li>
                <li>
                  <p>
                    <b>the right to erasure</b> - you can ask us to erase your
                    personal data (note that this is different from asking to
                    end a subscription, and we will retain your personal data
                    after the end of a subscription for the purposes of
                    continuing to provide free services to you and in case you
                    purchase a subscription in future, subject of course to your
                    data protection rights);
                  </p>
                </li>
                <li>
                  <p>
                    <b>the right to restrict processing</b> - you can ask us to
                    restrict the processing of your personal data;
                  </p>
                </li>
                <li>
                  <p>
                    <b>the right to object to processing</b> - you can object to
                    the processing of your personal data;
                  </p>
                </li>
                <li>
                  <p>
                    <b>the right to data portability</b> - you can ask that we
                    transfer your personal data to another organisation or to
                    you;
                  </p>
                </li>
                <li>
                  <p>
                    <b>the right to complain to a supervisory authority</b> -
                    you can complain about our processing of your personal data;
                    and
                  </p>
                </li>
                <li>
                  <p>
                    <b>the right to withdraw consent</b> - to the extent that
                    the legal basis of our processing of your personal data is
                    consent, you can withdraw that consent.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                These rights are subject to certain limitations and exceptions.
                You can learn more about the rights of data subjects by visiting{" "}
                <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
                  https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                You may exercise any of your rights in relation to your personal
                data by written notice to us, using the contact details set out
                below.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Third party websites</b>
          </p>
          <ol>
            <li>
              <p>
                Our website includes hyperlinks to, and details of, third party
                websites.
              </p>
            </li>
            <li>
              <p>
                In general we have no control over, and are not responsible for,
                the privacy policies and practices of third parties.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Personal data of children</b>
          </p>
          <ol>
            <li>
              <p>Our services are targeted at persons over the age of 16.</p>
            </li>
            <li>
              <p>
                If we have reason to believe that we hold personal data of a
                person under that age in our databases, we will delete that
                personal data.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Updating information</b>
          </p>
          <ol>
            <li>
              <p>
                Please let us know if the personal information that we hold
                about you needs to be corrected or updated.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>About cookies</b>
          </p>
          <ol>
            <li>
              <p>
                A cookie is a file containing an identifier (a string of letters
                and numbers) that is sent by a web server to a web browser and
                is stored by the browser. The identifier is then sent back to
                the server each time the browser requests a page from the
                server.
              </p>
            </li>
            <li>
              <p>
                Cookies may be either "persistent" cookies or "session" cookies:
                a persistent cookie will be stored by a web browser and will
                remain valid until its set expiry date, unless deleted by the
                user before the expiry date; a session cookie, on the other
                hand, will expire at the end of the user session, when the web
                browser is closed.
              </p>
            </li>
            <li>
              <p>
                Cookies may not contain any information that personally
                identifies a user, but personal data that we store about you may
                be linked to the information stored in and obtained from
                cookies.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Cookies that we use</b>
          </p>
          <ol>
            <li>
              <p>We may use cookies for the following purposes:</p>
              <ol>
                <li>
                  <p>
                    <b>authentication and status</b> - we use cookies to
                    identify you when you visit our website and as you navigate
                    our website, and to help us determine if you are logged into
                    our website;
                  </p>
                </li>
                <li>
                  <p>
                    <b>shopping cart</b> - we use cookies to maintain the state
                    of your shopping cart as you navigate our website;
                  </p>
                </li>
                <li>
                  <p>
                    <b>personalisation</b> - we use cookies to store information
                    about your preferences and to personalise our website for
                    you;
                  </p>
                </li>
                <li>
                  <p>
                    <b>security</b> - we use cookies as an element of the
                    security measures used to protect user accounts, including
                    preventing fraudulent use of login credentials, and to
                    protect our website and services generally;
                  </p>
                </li>
                <li>
                  <p>
                    <b>advertising</b> - we use cookies to help us to display
                    advertisements that will be relevant to you;
                  </p>
                </li>
                <li>
                  <p>
                    <b>analysis</b> - we use cookies to help us to analyse the
                    use and performance of our website and services; and
                  </p>
                </li>
                <li>
                  <p>
                    <b>cookie consent</b> - we use cookies to store your
                    preferences in relation to the use of cookies more
                    generally.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Cookies used by our service providers</b>
          </p>
          <ol>
            <li>
              <p>
                We use the Google Analytics suite of products. Google Analytics
                gathers information about the use of our website and apps and
                may use cookies. The information gathered is used for analysis
                (to create reports about the use of our website and apps) and
                for communications including, but not limited to, marketing
                communications. You can find out more about Google's use of
                information by visiting{" "}
                <a href="https://policies.google.com">
                  https://policies.google.com
                </a>{" "}
                and you can review Google's privacy policy at{" "}
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                We use vimeo for our video content delivery. You can find out
                more about Vimeo's use of information by visiting{" "}
                <a href="https://vimeo.com/privacy">
                  https://vimeo.com/privacy
                </a>{" "}
                and Vimeo's cookie policy at{" "}
                <a href="https://vimeo.com/cookie_policy">
                  https://vimeo.com/cookie_policy
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                We use Gravatar to present your user avatar. Gravatar is a
                company owned and operated bu Automattic. You can find out more
                about Automatics use of information by visiting{" "}
                <a href="https://automattic.com/privacy/">
                  https://automattic.com/privacy/
                </a>{" "}
                and view Gravatar's privacy policy at{" "}
                <a href="https://support.gravatar.com/account/data-privacy/">
                  https://support.gravatar.com/account/data-privacy/
                </a>
                .
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Managing cookies</b>
          </p>
          <ol>
            <li>
              <p>
                Most web browsers allow you to refuse to accept cookies and to
                delete cookies. The methods for doing so vary from browser to
                browser, and from version to version. You can however obtain
                up-to-date information about blocking and deleting cookies via
                these links:
              </p>
              <ol>
                <li>
                  <p>
                    <a href="https://support.google.com/chrome/answer/95647">
                      https://support.google.com/chrome/answer/95647
                    </a>{" "}
                    (Chrome);
                  </p>
                </li>
                <li>
                  <p>
                    <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                      https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                    </a>{" "}
                    (Firefox);
                  </p>
                </li>
                <li>
                  <p>
                    <a href="https://help.opera.com/en/latest/security-and-privacy/">
                      https://help.opera.com/en/latest/security-and-privacy/
                    </a>{" "}
                    (Opera);
                  </p>
                </li>
                <li>
                  <p>
                    <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
                      https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
                    </a>{" "}
                    (Internet Explorer);
                  </p>
                </li>
                <li>
                  <p>
                    <a href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                      https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                    </a>{" "}
                    (Safari); and
                  </p>
                </li>
                <li>
                  <p>
                    <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
                      https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
                    </a>{" "}
                    (Edge).
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Blocking cookies</b>
          </p>
          <ol>
            <li>
              <p>
                Blocking all cookies will have a negative impact upon the
                usability of many websites and apps.
              </p>
            </li>
            <li>
              <p>
                If you block cookies, you will not be able to use all the
                features on our website and apps.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Marketing & Advertising</b>
          </p>
          <ol>
            <li>
              <p>
                Your user data may be used for advertising purposes, including
                creating Custom Audiences, Lookalike Audiences on advertising
                platforms, to track site usage (eg. analytics), to store your
                user settings (eg. timezone, notification preferences), and to
                personalize your content (eg. advertising, language)
              </p>
            </li>
            <li>
              <p>
                You can opt out of marketing and advertising use of your data in
                the{" "}
                <Button
                  variant={"link"}
                  onClick={() => {
                    navigate("/account/settings/account");
                  }}
                >
                  account settings
                </Button>{" "}
                page of The Swim Smooth Guru.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Amendments</b>
          </p>
          <ol>
            <li>
              <p>
                We may update this policy from time to time by publishing a new
                version on our website and in our app.
              </p>
            </li>
            <li>
              <p>
                You should check this page occasionally to ensure you are happy
                with any changes to this policy.
              </p>
            </li>
            <li>
              <p>
                We may notify you of significant changes to this policy by email
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Our details</b>
          </p>
          <ol>
            <li>
              <p>Our full legal name is Swim Smooth Limited.</p>
            </li>
            <li>
              <p>
                We are registered in England and Wales under registration number
                14802092, and our registered office is at Manor Cottage, Hunton,
                Bedale, North Yorkshire, England, DL8 1PX.
              </p>
            </li>
            <li>
              <p>You can contact us:</p>
              <ol>
                <li>
                  <p>by post, to the postal address given above;</p>
                </li>
                <li>
                  <p>using our website and app contact forms;</p>
                </li>
                <li>
                  <p>
                    by telephone, on the contact number published on our website
                    and in our apps; or
                  </p>
                </li>
                <li>
                  <p>
                    by email, using the email address published on our website
                    and in our apps.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Data protection registration</b>
          </p>
          <ol>
            <li>
              <p>
                We are registered as a data controller with the UK Information
                Commissioner's Office.
              </p>
            </li>
            <li>
              <p>
                Our data protection registration number is{" "}
                <a
                  target="_blank"
                  href="https://ico.org.uk/ESDWebPages/Entry/ZB702331"
                  rel="noreferrer"
                >
                  ZB702331
                </a>
                .
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </VStack>
  );
};

export { PrivacyPage };
