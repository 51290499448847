import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { invitation } from "../../../types/invitation";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface InvitationsState {
  lastUpdated: string;
  invitations: invitation[];
}

// Define an initial state
const initialState: InvitationsState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
  invitations: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const invitationsSlice = createSlice({
  name: "invitationsState",
  initialState,
  reducers: {
    updateInvitationsState(state, action: PayloadAction<invitation[] | null>) {
      if (action.payload) {
        state.lastUpdated = new Date().toISOString();
        state.invitations = action.payload;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.invitations = [];
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateInvitationsState =
  invitationsSlice.actions.updateInvitationsState;

export const selectInvitationsState = (state: RootState): InvitationsState => {
  return state.invitationsState;
};

// Export default the slice reducer
export default invitationsSlice.reducer;
