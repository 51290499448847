import { Icon, useColorModeValue } from "@chakra-ui/react";

const SSMenuIcon = (props: any) => {
  const iconColourLive = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  return (
    <Icon
      boxSize={props.iconOnly ? 6 : 5}
      color={iconColourLive}
      as={props.icon}
      cursor="pointer"
    />
  );
};

export { SSMenuIcon };
