import {
  Heading,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { BoxWrapper } from "../../components/ui/BoxWrapper";

const latestVersion = "v4.4.12";
const ReleaseLogPage = () => {
  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Release Log
      </Heading>
      <SimpleGrid columns={[1, 1, 2, 2]} gap={2}>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            11<sup>th</sup> December 2024, v4.4.12
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Correct display of Stroke Insight videos.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            9<sup>th</sup> December 2024, v4.4.11
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Certified coach authority issue fix
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            9<sup>th</sup> December 2024, v4.4.10
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Apple activity layout issue
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5th<sup>th</sup> December 2024, v4.4.9
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated activity layout
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Streamlining of refresh process
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Force change of expired password
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fix issue with change email address function
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fix issue with display of some videos on mobile devices
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            11<sup>th</sup> November 2024, v4.4.8
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              FIT file upload function
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Partner management (EndureIQ, British Triathlon, World Triathlon)
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            8<sup>th</sup> November 2024, v4.4.7
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Update Garmin activities to include enhanced features when
              recorded using the Swim Smooth IQ app
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Render Stroke Insights&#8471; when recorded using the Swim Smooth
              IQ app
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            1<sup>st</sup> October 2024, v4.4.6
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Removed incorrectly displayed graphs, that rely on Stroke
              Insights(p), in Garmin activities
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Ensure reload option is displayed when waiting for user summary
              build to finish
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            20<sup>th</sup> September 2024, v4.4.5
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Small changes to WBSS
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            19<sup>th</sup> September 2024, v4.4.4
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Swim Development Plan
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            18<sup>th</sup> September 2024, v4.4.3
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Rottnest 2025 Training Plan
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated landing pages & simplified signup process
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Enhanced onboarding & reboarding processes
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Training Peaks groundwork
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Admin functions
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            26<sup>th</sup> July 2024, v4.4.2
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Bulk upload for coaches
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> July 2024, v4.4.1
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Update to linked users list for certified coaches
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            19<sup>th</sup> July 2024, v4.4.0
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Introduction of tiered trials.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Corrected privacy policy (payment provider Stripe not Braintree)
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            16<sup>th</sup> July 2024, v4.3.22
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improved visuals for completed training plan sessions
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Allow 'last week' training plan sessions to be marked as complete
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            4<sup>th</sup> July 2024, v4.3.21
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Replace fallback session images
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Componentise coach badges
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            4<sup>th</sup> July 2024, v4.3.20
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Change storage for coaches to not store geo-located list as
              geolocation may change
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Add debug menu for admin users
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            3<sup>rd</sup> July 2024, v4.3.19
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated coaches list, added search
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated coach management to allow trainee and provisional coaches
              to update their profiles
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            20<sup>th</sup> June 2024, v4.3.18
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated 'Friends of Swim Smooth' and 'Certified Coach' programs
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated widget managements
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated widgets to support 'resrticted' partners
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            23<sup>rd</sup> May 2024, v4.3.17
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Removed hCaptcha from login page
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Removed Best Fest Plans until 2025
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with invitation acceptance
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            17<sup>th</sup> May 2024, v4.3.16
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with selecting Lane 0 on onboarding
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              World Triathlon & British Triathlon links
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Rewording of affiliate program
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            12<sup>th</sup> May 2024, v4.3.15
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Emergency Fix ... issue with cpatcha protected endpoints
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            9<sup>th</sup> May 2024, v4.3.14
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added global coaching team and non-geographic coaches
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Stopped non-geographical coaches being presented as 'closest'
              coach
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            2<sup>nd</sup> May 2024, v4.3.13
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Worlds Biggest Swim Squad Archive
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Pricing table update - new items
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> April 2024, v4.3.12
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated CSS calculator to better reflect m/y changes.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            12<sup>th</sup> March 2024, v4.3.11
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Affiliate program updates for existing users.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Password reset changes
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            29<sup>th</sup> February 2024, v4.3.10
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Coaches Tools, Affiliate Program and Widget Editor
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Best Fest 2024 Training Plans
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            21<sup>th</sup> February 2024, v4.3.9
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fix CSS & BSR permissions
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Clarify CSS & BSR access levels
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> January 2024, v4.3.8
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fix to 'public mode' CSS calculator
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fix to tools page in 'public mode'
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Remove fitness page from 'public mode'
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            21<sup>th</sup> January 2024, v4.3.7
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Worlds Biggest Swim Squad Launch
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            19<sup>th</sup> January 2024, v4.3.6
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Better handle delay associated with generating user summary
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            18<sup>th</sup> January 2024, v4.3.5
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with seconds displaying incorrectly on activity edit
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            12<sup>th</sup> January 2024, v4.3.4
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed authorties on activity display
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added WBSS widget ready for launch
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            8<sup>th</sup> January 2024, v4.3.3
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Removed upgrade banners
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated print limit error messaging to make it clear this is why
              PDF cannot be created
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with linking activties to training plan sessions where
              week was created prior to upgrade
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            29<sup>th</sup> December 2023, v4.3.2
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Changes to caching to ensure changes are shown in a timely manner
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            29<sup>th</sup> December 2023, v4.3.1
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fix for activities not linking from training plans
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5<sup>th</sup> December 2023, v4.3.0
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Major overhaul of subscripitons, levels and authorities
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Changed payment provider to Stripe
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Retired all Braintree subscriptions
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added relationships (Mentor, Coach, Client, Squady & Friend) ...
              limited functionality at present.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5<sup>th</sup> October 2023, v4.2.57
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Rottnest 2024 Training Plan
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            17<sup>th</sup> August 2023, v4.2.56
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              CSS Calculator and Entry Clarity
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              BSR Tweaker Direction Correction
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Braintree Payment Gateway Issue Fix
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            28<sup>th</sup> July 2023, v4.2.55
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              CSS Tweaker Updates
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            26<sup>th</sup> July 2023, v4.2.54
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Payment verification decision changes
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            26<sup>th</sup> July 2023, v4.2.53
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Ineos changes
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            3<sup>rd</sup> June 2023, v4.2.52
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Add confirmation to generate sessions button to avoid accidental
              generation
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updates to virtual squad rendering
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            30<sup>th</sup> June 2023, v4.2.51
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Changes to training timeline generation process
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            28<sup>th</sup> June 2023, v4.2.50
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Pace formatter issue fixed for issue with single digit
              milliseconds
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            28<sup>th</sup> June 2023, v4.2.49
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Tweaks to the tweaker layout (css & bsr)
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Bug fixes to the tweakers (css & bsr)
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Allow access to CSS PDF for 'Tweaked' entries
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fix crash error when session PDF limit exceeded
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            22<sup>th</sup> June 2023, v4.2.48
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Bug fixes & layout changes
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Coaches: mentor display
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            16<sup>th</sup> June 2023, v4.2.47
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              PDF Url fix
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Go to progress page on login
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            15<sup>th</sup> June 2023, v4.2.46
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              'Your Coach' / 'Nearest Coach' / 'Head Coach' widget
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Enhanced virtual squad widget
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Payment integration updates
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Progress page layout changes
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            14<sup>th</sup> June 2023, v4.2.45
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Braintree integration 2099 errors where customer is un-enrolled
              from 3DS
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            9<sup>th</sup> June 2023, v4.2.44
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Your Coach
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Responsiveness on connections page
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            6<sup>th</sup> June 2023, v4.2.43
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              PDF scaling issue fix
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            2<sup>nd</sup> June 2023, v4.2.42
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Session Roulette updates
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              General Bug Fixes
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Package Updates
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            26<sup>th</sup> May 2023, v4.2.41
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Session Roulette V1
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> May 2023, v4.2.40
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Changed transaction list to not show button when invoice is not
              available
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> May 2023, v4.2.39
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with BSR entry when user has no previous BSR
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            24<sup>th</sup> May 2023, v4.2.38
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue in sessions where user has no CSS pace
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            22<sup>nd</sup> May 2023, v4.2.37
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Session PDF download changes
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Session navigation issue resolved
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            20<sup>th</sup> May 2023, v4.2.36
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Video rendering where no title or description exists
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            19<sup>th</sup> May 2023, v4.2.35
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Enhanced session types, now from Contenfful
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Cache virtual squad session
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            18<sup>th</sup> May 2023, v4.2.34
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Spelling mistakes!
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improved video rendering in library
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Show '5 Flames' in training plans to bring inline with sessions
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Virtual Squad
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            17<sup>th</sup> May 2023, v4.2.33
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Sessions to swim in fault fixers
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            13<sup>th</sup> May 2023, v4.2.32
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Changes to training plan rendering
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            13<sup>th</sup> May 2023, v4.2.32
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Changes to training plan rendering
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            12<sup>th</sup> May 2023, v4.2.31
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Issue with onboarding on Chromebook fixed
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            10<sup>th</sup> May 2023, v4.2.30
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added basic pricing page.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            9<sup>th</sup> May 2023, v4.2.29
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Garmin Connection Fixes
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            6<sup>th</sup> May 2023, v4.2.28
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              UltraSwim 33.3 Cobranding
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5<sup>th</sup> May 2023, v4.2.27
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Removed email address validation on login
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5<sup>th</sup> May 2023, v4.2.26
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Issue with manual activity sTSS when no CSS fixed
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5<sup>th</sup> May 2023, v4.2.25
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              UltraSwim 33.3
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            27<sup>th</sup> April 2023, v4.2.24
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Remove 'identity' naming
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Coach badges on detail
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            26<sup>th</sup> April 2023, v4.2.23
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              General bug fixes
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Legacy URLs
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Addition of 'Head Coach' level in coaches
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> April 2023, v4.2.22
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              General bug fixes
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated terms and conditions & invoice
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            24<sup>th</sup> April 2023, v4.2.21
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improvements to geo-location and 'nearest coach' selection
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Temporarilly removed region selector on coaches
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            14<sup>th</sup> April 2023, v4.2.20
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Sessions prints now available in standard, larg(er) print and
              minimal layouts
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Routing changes to support legacy urls
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fix issue with coaches links entered without protocol (assummes
              https://)
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            12<sup>th</sup> April 2023, v4.2.19
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Coach managment features (admin only)
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Sorting on coaches list
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            10<sup>th</sup> April 2023, v4.2.18
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Changed validation trigger to 'all' on all forms
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            6<sup>th</sup> April 2023, v4.2.17
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Coach location management
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            31<sup>st</sup> March 2023, v4.2.16
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improvements to coach rendering
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            30<sup>th</sup> March 2023, v4.2.15
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improved PDF naming and download for sessions
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improved PDF naming and download for transactions
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            29<sup>th</sup> March 2023, v4.2.14
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed navigation issue on last week and next week plan sessions
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            30<sup>th</sup> January 2023, v4.2.13
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              New & Updated Icons
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> January 2023, v4.2.12
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improved images for library lists
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Missing dryland PDF
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Update user summary on progress screen refresh
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5<sup>th</sup> January 2023, v4.2.11
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Esteve videos
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            4<sup>th</sup> January 2023, v4.2.10
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with optional equipment in sessions
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with link buttons in sessions
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Passive hCaptcha catered for
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Esteve images
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            4<sup>th</sup> January 2023, v4.2.9
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Onboarding changes to support iOS compatibility
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              BSR and CSS changes to support iOS compatibility
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            12<sup>th</sup> December 2022, v4.2.8
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Layout bug fixes
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Session fixes where no CSS / BSR present
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            8<sup>th</sup> December 2022, v4.2.7
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improvements to certified coaches experience
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            8<sup>th</sup> December 2022, v4.2.6
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Bug fixes
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed a bug related to HCaptcha handling when moving between
              captcha enabled screens
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            7<sup>th</sup> December 2022, v4.2.5
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Better error handling on create account screen
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Responsiveness on onboarding screen
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            6<sup>th</sup> December 2022, v4.2.4
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Captcha and additional 'script kiddy' protections on public facing
              forms
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5<sup>th</sup> December 2022, v4.2.3
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Responsive layout improvements
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            2<sup>nd</sup> December 2022, v4.2.2
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Responsive layout improvements
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            1<sup>st</sup> December 2022, v4.2.1
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Responsive layout improvements
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Changes to CSS and BSR tweakers
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Contentful changes for 'embedded content'
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            30<sup>th</sup> November 2022, v4.2.0
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Responsive layout (mobile / tablet / web mode)
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            27<sup>th</sup> November 2022, v4.1.3
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Info boxes added
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Video layout
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> November 2022, v4.1.2
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Session intensity improvements
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            25<sup>th</sup> November 2022, v4.1.1
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              QA Testing November 2022 - Batch 5c
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Braintree improvements around failed 3DS checks
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Tawk.to client added in for support
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            22<sup>nd</sup> November 2022, v4.1.0
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              QA Testing November 2022 - Batch 5b
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Overhauled Braintree payments flow to be fully up to date with the
              latest PSD2 guidelines
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Updated Braintree drop-in client
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            22<sup>nd</sup> November 2022, v4.0.29
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              QA Testing November 2022 - Batch 5a
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Garmin Integration
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            18<sup>th</sup> November 2022, v4.0.28 &amp; v4.0.28(a|b)
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              QA Testing November 2022 - Batch 4
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Onboarding
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              CSS & BSR Tweaker pages
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Admin Functions
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            11<sup>th</sup> November 2022, v4.0.27
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              QA Testing Changes November 2022 - Batch 3
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            10<sup>th</sup> November 2022, v4.0.26
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              QA Testing Changes November 2022 - Batch 2
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            10<sup>th</sup> November 2022, v4.0.25
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              QA Testing Changes November 2022 - Batch 1
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            9<sup>th</sup> November 2022, v4.0.24
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with CSS Tweaker when no CSS value present
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed issue with BSR Tweaker when no BSR value present
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Non-Finite date problem on progress page
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            9<sup>th</sup> November 2022, v4.0.23
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Edit Training Timeline
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            7<sup>th</sup> November 2022, v4.0.22
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Edit Activity (Manual)
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Edit Activity (Wearable)
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            5<sup>th</sup> November 2022, v4.0.21
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Link training plan session to activity
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Link session to activity
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Create manual activity
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            20<sup>th</sup> October 2022, v4.0.20
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Delete Training Timeline
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            20<sup>th</sup> October 2022, v4.0.19
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Create Training Timeline
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            19<sup>th</sup> October 2022, v4.0.18
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              View Training Timeline
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Finish Training Timeline
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            17<sup>th</sup> October 2022, v4.0.17
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Content type 'video' rendering
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Content type 'article' rendering
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Content type 'faultFixer' rendering
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Content type 'series' rendering
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              CSS integration with logged in user
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              BSR integration with logged in user
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Separated account and swimmer settings
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added tweakers to the progress page for logged in users
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            30<sup>th</sup> September 2022, v4.0.16
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added Content Areas and Categories, from Contentful.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            28<sup>th</sup> September 2022, v4.0.15
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed logout issue.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            28<sup>th</sup> September 2022, v4.0.14
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Icons ... lots of icons.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Expanded data on session swims.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added swim count to sessions.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            27<sup>th</sup> September 2022, v4.0.13
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added Session Scaling.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added Session PDF (including scaling).
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            24<sup>th</sup> September 2022, v4.0.12
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added Session Types.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added Session List.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added Session Detail.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            17<sup>th</sup> September 2022, v4.0.11
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Fixed pace and duration display.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            16<sup>th</sup> September 2022, v4.0.10
          </Heading>
          <List>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Added Open Water mode map.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            15<sup>th</sup> September 2022, v4.0.9
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Stroke insights on activities.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Swim Types on activities.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            14<sup>th</sup> September 2022, v4.0.8
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Ensure <em>ALL</em> global state is cleared on logout.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            14<sup>th</sup> September 2022, v4.0.7
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Progress page added.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Activities Added.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Authentication and Authorisation checks improved.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Header updated to check authentication and authorisation properly.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              State moved to global store where appropriate.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              User context removed.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            9<sup>th</sup> June 2022, v4.0.6
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Coach Finder added.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              CSS Tweaker added.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            6<sup>th</sup> June 2022, v4.0.5
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Handle Apple subscriptions better.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Describe invoice status correctly.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            30<sup>th</sup> May 2022, v4.0.4
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Remove vault manager from Braintree drop in client due to
              subscriptions being cancelled when removing the payment method.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            30<sup>th</sup> May 2022, v4.0.3
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Switch environment processing.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            30<sup>th</sup> May 2022, v4.0.2
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Improve refresh token use to avoid unnecesary user logout.
            </ListItem>
          </List>
        </BoxWrapper>
        <BoxWrapper>
          <Heading as="h3" size="sm">
            28<sup>th</sup> May 2022, v4.0.1
          </Heading>
          <List spacing={1}>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              First release of identity platform.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Subscription creation and card management added.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              User settings management added.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              Pool settings management added.
            </ListItem>
            <ListItem>
              <ListIcon color="green" as={MdOutlineCheckCircleOutline} mb={1} />
              CSS calculator added.
            </ListItem>
          </List>
        </BoxWrapper>
      </SimpleGrid>
    </VStack>
  );
};

export { latestVersion, ReleaseLogPage };
