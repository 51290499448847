import {
  Button,
  HStack,
  Heading,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SessionEditorListPage = () => {
  const navigate = useNavigate();
  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Session Editor
      </Heading>
      <HStack>
        <Button
          onClick={() =>
            navigate("/admin/sessions/editor/*NEW", { replace: false })
          }
        >
          Create New Session
        </Button>
        <Button>Create WBSS Set</Button>
      </HStack>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Sections</Th>
            <Th isNumeric>L1</Th>
            <Th isNumeric>L2</Th>
            <Th isNumeric>L3</Th>
            <Th isNumeric>L4</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Classic Goldilocks</Td>
            <Td>Published, Draft</Td>
            <Td>CSS, Classics</Td>
            <Td isNumeric>2200</Td>
            <Td isNumeric>2500</Td>
            <Td isNumeric>2800</Td>
            <Td isNumeric>3100</Td>
            <Td>
              <Button size={"sm"} mr={2}>
                Edit
              </Button>
              <Button size={"sm"} variant={"warning"}>
                Delete
              </Button>
            </Td>
          </Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Sections</Th>
            <Th isNumeric>L1</Th>
            <Th isNumeric>L2</Th>
            <Th isNumeric>L3</Th>
            <Th isNumeric>L4</Th>
            <Th>Actions</Th>
          </Tr>
        </Tfoot>
      </Table>
    </VStack>
  );
};

export { SessionEditorListPage };
