import { mode } from "@chakra-ui/theme-tools";

export const SingleSelectDropdown = {
  baseStyle: {
    button: (props: any) => ({
      width: "full",
      height: "40px",
      justifyContent: "space-between",
      color: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      bg: mode("ssBoxBackgroundLight", "ssBoxBackgroundDark")(props),
      borderWidth: "1px",
      borderColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      borderRadius: "6px",
      fontWeight: 100,
      _focus: {
        borderColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
        boxShadow: "none",
      },
      _hover: {
        backgroundColor: "ssBoxBackgroundLight",
      },
      _active: {
        backgroundColor: "ssBoxBackgroundLight",
      },
    }),
    dropdown: (props: any) => ({
      p: 1,
      color: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      backgroundColor: mode(
        "ssBoxBackgroundLight",
        "ssBoxBackgroundDark"
      )(props),
      borderWidth: "1px",
      borderColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      borderRadius: "6px",
      _focus: {
        borderColor: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      },
    }),
    clearIcon: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      backgroundColor: "gray.200", // Light gray background for the clear icon
      color: "gray.600",
      marginLeft: "8px",
      cursor: "pointer",
      _hover: {
        backgroundColor: "gray.300", // Darker gray on hover
      },
    },
    searchInput: {
      m: 0,
      borderColor: "ssPrimaryDark",
      bg: "white",
    },
    option: (props: any) => ({
      cursor: "pointer",
      m: 0,
      py: 1,
      pl: 5,
      color: mode("ssPrimaryDark", "ssPrimaryLight")(props),
      bg: "transparent",
      _hover: {
        bg: mode("ssNeonOrangeDark", "ssNeonOrangeLight")(props),
      },
    }),
    highlightedOption: (props: any) => ({
      cursor: "pointer",
      fontWeight: 500,
      m: 0,
      py: 1,
      pl: 5,
      color: mode("ssNeonOrangeDark", "ssNeonOrangeLight")(props),
      bg: "transparent",
    }),
  },
};
