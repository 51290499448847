import { BoxProps, useColorModeValue } from "@chakra-ui/react";
import GaugeChart from "react-gauge-chart";
import { trainingBalance } from "../../types/training";

interface TrainingBalanceGraphProps extends BoxProps {
  subscriber: boolean;
  trainingBalance: trainingBalance | null | undefined;
}
const colours = [
  "#8D9FB4",
  "#35B0F4",
  "#6FCEEC",
  "#38A169",
  "#FF9B63",
  "#E45225",
];
const disabledColours = [
  "#DDDDDD",
  "#CCCCCC",
  "#BBBBBB",
  "#AAAAAA",
  "#999999",
  "#888888",
];
const chartStyle = {
  height: 125,
  width: 250,
};
const TrainingBalanceGraph: React.FC<TrainingBalanceGraphProps> = ({
  subscriber,
  trainingBalance,
}) => {
  const colorMode = useColorModeValue("#000000", "#ffffff");

  if (!subscriber || !trainingBalance) {
    return (
      <GaugeChart
        id="trainingBalanceChart"
        percent={0}
        formatTextValue={() => {
          return "";
        }}
        style={chartStyle}
        arcPadding={0.01}
        arcsLength={[22.2, 22.2, 16.6, 16.6, 16.6, 5]}
        colors={disabledColours}
        cornerRadius={1}
        needleColor={disabledColours[0]}
        needleBaseColor={disabledColours[0]}
        textColor={colorMode}
      />
    );
  }

  const percent = !isNaN(trainingBalance.score)
    ? (trainingBalance.score + 45) / 90
    : 50;

  if (trainingBalance.colour === "#35FC86") {
    trainingBalance.colour = "#38A169";
  }

  return (
    <GaugeChart
      id="trainingBalanceChart"
      percent={percent}
      formatTextValue={() => {
        return trainingBalance?.score ? trainingBalance.score.toString() : "0";
      }}
      style={chartStyle}
      arcPadding={0.01}
      arcsLength={[22.2, 22.2, 16.6, 16.6, 16.6, 5]}
      colors={colours}
      cornerRadius={1}
      needleColor={trainingBalance.colour}
      needleBaseColor={trainingBalance.colour}
      textColor={colorMode}
    />
  );
};

export { TrainingBalanceGraph };
