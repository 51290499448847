import { useParams } from "react-router-dom";
import { CoachEditLocations } from "../../components/coaches/CoachEditLocations";

const CoachEditLocationsPage = () => {
  const params = useParams<{ coachShortCode: string }>();

  if (!params.coachShortCode) {
    return null;
  }

  return <CoachEditLocations coachShortCode={params.coachShortCode} />;
};

export { CoachEditLocationsPage };
