import { BoxProps } from "@chakra-ui/react";

interface GarminIQLogoProps extends BoxProps {
  height: number;
  width: number;
  colour?: string;
  opacity?: number;
}

const GarminIQLogo: React.FC<GarminIQLogoProps> = ({
  height,
  width,
  colour,
  opacity,
}) => {
  if (!colour) {
    colour = "#002140";
  }
  if (!opacity) {
    opacity = 1;
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 342 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          fill={colour}
          d="M158.6,70.5h1.3v1.7c0.7-1,1.7-1.9,3.3-1.9c2,0,4.1,1.6,4.1,4.4v0c0,2.8-2,4.4-4.1,4.4
			c-1.6,0-2.6-0.9-3.3-1.8v4.3h-1.3V70.5z M165.9,74.8L165.9,74.8c0-2-1.4-3.3-3-3.3c-1.6,0-3.1,1.3-3.1,3.3v0c0,2,1.5,3.3,3.1,3.3
			C164.6,78.1,165.9,76.9,165.9,74.8z M168.9,74.8L168.9,74.8c0-2.4,1.9-4.5,4.5-4.5c2.6,0,4.4,2,4.4,4.4v0c0,2.4-1.9,4.5-4.5,4.5
			C170.7,79.3,168.9,77.2,168.9,74.8z M176.5,74.8L176.5,74.8c0-1.9-1.4-3.4-3.2-3.4c-1.9,0-3.1,1.5-3.1,3.3v0
			c0,1.8,1.4,3.3,3.2,3.3C175.2,78.1,176.5,76.6,176.5,74.8z M178.8,70.5h1.4l2.2,6.9l2.3-6.9h1.1l2.3,6.9l2.2-6.9h1.3l-3,8.6h-1.1
			l-2.3-6.7l-2.3,6.7h-1.1L178.8,70.5z M196.9,78.1c1.2,0,2-0.5,2.7-1.2l0.8,0.7c-0.9,1-1.9,1.6-3.5,1.6c-2.3,0-4.3-1.8-4.3-4.5
			c0-2.5,1.7-4.5,4.1-4.5c2.5,0,4,2,4,4.5c0,0.1,0,0.2,0,0.4h-6.8C194.1,77.1,195.4,78.1,196.9,78.1z M199.4,74.3
			c-0.1-1.6-1-2.9-2.7-2.9c-1.5,0-2.6,1.2-2.8,2.9H199.4z M202.8,70.5h1.3v2.2c0.6-1.4,1.9-2.4,3.5-2.4v1.4h-0.1
			c-1.9,0-3.4,1.3-3.4,3.9v3.4h-1.3V70.5z M212.7,78.1c1.2,0,2-0.5,2.7-1.2l0.8,0.7c-0.9,1-1.9,1.6-3.5,1.6c-2.3,0-4.3-1.8-4.3-4.5
			c0-2.5,1.7-4.5,4.1-4.5c2.5,0,4,2,4,4.5c0,0.1,0,0.2,0,0.4h-6.8C209.9,77.1,211.2,78.1,212.7,78.1z M215.2,74.3
			c-0.1-1.6-1-2.9-2.7-2.9c-1.5,0-2.6,1.2-2.8,2.9H215.2z M218,74.8L218,74.8c0-2.8,2-4.5,4.1-4.5c1.6,0,2.6,0.9,3.3,1.8V67h1.3
			v12.1h-1.3v-1.7c-0.7,1-1.7,1.9-3.3,1.9C220.1,79.2,218,77.6,218,74.8z M225.5,74.8L225.5,74.8c0-2-1.5-3.3-3.1-3.3
			c-1.7,0-3,1.2-3,3.3v0c0,2,1.4,3.3,3,3.3C223.9,78.1,225.5,76.8,225.5,74.8z M235.4,77.4v1.6h-1.3V67h1.3v5.2
			c0.7-1,1.7-1.9,3.3-1.9c2,0,4.1,1.6,4.1,4.4v0c0,2.8-2,4.4-4.1,4.4C237.1,79.2,236.1,78.4,235.4,77.4z M241.5,74.8L241.5,74.8
			c0-2-1.4-3.3-3-3.3c-1.6,0-3.1,1.3-3.1,3.3v0c0,2,1.5,3.3,3.1,3.3C240.1,78.1,241.5,76.9,241.5,74.8z M244,81.3l0.4-1
			c0.4,0.2,0.8,0.3,1.3,0.3c0.8,0,1.2-0.4,1.8-1.6l-3.9-8.5h1.4l3.1,7.1l2.7-7.1h1.4l-3.6,8.8c-0.7,1.8-1.6,2.4-2.8,2.4
			C245.2,81.7,244.6,81.6,244,81.3z M258.6,73.3L258.6,73.3c0-3.2,2.3-6,5.8-6c2,0,3.2,0.6,4.3,1.5l-0.8,1c-0.9-0.8-1.9-1.3-3.5-1.3
			c-2.6,0-4.4,2.2-4.4,4.7v0c0,2.8,1.8,4.8,4.6,4.8c1.3,0,2.5-0.5,3.3-1.2V74h-3.5v-1.2h4.8v4.6c-1.1,1-2.7,1.8-4.6,1.8
			C260.9,79.3,258.6,76.6,258.6,73.3z M271.2,76.6L271.2,76.6c0-1.9,1.5-2.8,3.7-2.8c1.1,0,1.9,0.1,2.6,0.4v-0.3
			c0-1.5-0.9-2.3-2.5-2.3c-1,0-1.8,0.2-2.6,0.6l-0.4-1c0.9-0.4,1.9-0.7,3.1-0.7c1.2,0,2.1,0.3,2.8,0.9c0.6,0.6,0.9,1.4,0.9,2.5v5.2
			h-1.3v-1.3c-0.6,0.8-1.6,1.5-3.1,1.5C272.8,79.2,271.2,78.3,271.2,76.6z M277.5,75.9v-0.8c-0.6-0.2-1.5-0.4-2.5-0.4
			c-1.6,0-2.5,0.7-2.5,1.7v0c0,1.1,1,1.7,2.1,1.7C276.3,78.2,277.5,77.3,277.5,75.9z M281.3,70.5h1.3v2.2c0.6-1.4,1.9-2.4,3.5-2.4
			v1.4H286c-1.9,0-3.4,1.3-3.4,3.9v3.4h-1.3V70.5z M287.8,70.5h1.3v1.4c0.6-0.8,1.3-1.6,2.8-1.6c1.4,0,2.3,0.7,2.7,1.7
			c0.6-0.9,1.5-1.7,3-1.7c2,0,3.2,1.3,3.2,3.4v5.3h-1.3v-5c0-1.7-0.8-2.6-2.2-2.6c-1.3,0-2.4,1-2.4,2.6v4.9h-1.3v-5
			c0-1.6-0.8-2.5-2.2-2.5c-1.4,0-2.4,1.1-2.4,2.7v4.9h-1.3V70.5z M303.4,67.2h1.5v1.4h-1.5V67.2z M303.5,70.5h1.3v8.5h-1.3V70.5z
			 M307.6,70.5h1.3V72c0.6-0.9,1.5-1.7,2.9-1.7c2.1,0,3.3,1.4,3.3,3.4v5.3h-1.3v-5c0-1.6-0.9-2.6-2.4-2.6c-1.5,0-2.6,1.1-2.6,2.7
			v4.9h-1.3V70.5z"
        />
        <path
          fill={colour}
          d="M58.7,38.2l-4.3,0c1.5-7.6,8.2-13.4,16.3-13.4c7.9,0,14.7,5.9,15.9,13.3h-4.3c-1.2-5.3-6-9.2-11.7-9.2
			C65,28.9,60.1,32.9,58.7,38.2z M82.3,43.8c-1.4,5.3-6.2,9.2-12,9.2c-5.7,0-10.4-3.9-11.7-9.2l-4.3,0c1.2,7.6,7.8,13.3,15.9,13.3
			c8.1,0,14.9-5.8,16.4-13.4H82.3z M230.9,47.2c-1.4,3.6-5.2,5.7-10.4,5.7c-6.6,0-12-5.4-12-11.9c0-6.6,5.4-11.9,12-11.9
			c5.2,0,8.8,2.2,10.4,5.6c0.3,0.7,0.4,1,1.1,1c0.7,0,2.4,0,2.9,0c0.5,0,0.7-0.6,0.6-0.9c-1.6-5.9-7.4-9.8-15-9.8
			c-8.9,0-16.2,7.2-16.2,16c0,8.8,7.3,16,16.2,16c7.5,0,13.3-3.9,15-9.7c0.1-0.3,0-1-0.6-1c-0.6,0-2.1,0-2.8,0
			C231.3,46.3,231,46.9,230.9,47.2z M194.7,48.9c0.6,0,1.3,0,2.2,0c1.4,0,1.1,0.8,0.9,1.2c-1.8,4.2-7.1,6.9-13.9,6.9
			c-8.9,0-16.2-7.2-16.2-16c0-8.8,7.2-16,16.2-16c4.8,0,9.2,1.9,12.2,5.2c2.8,3.1,4.1,7.2,3.9,11.9c0,0.3-0.1,0.9-1.3,0.9
			c-0.9,0-23.3,0-26.2,0c-0.3,0-0.3,0.3-0.3,0.3c0.2,4.5,5.3,9.6,11.6,9.6c4,0,6.8-1,8.5-2.7C193.3,49.3,193.5,48.9,194.7,48.9z
			 M172.4,38.9c2.7,0,22.4,0,23,0c0.5,0,0.4-0.4,0.4-0.4c-1.1-3.8-5.2-9.5-11.8-9.5c-6.4,0-10.7,5.6-11.8,9.5
			C172.1,38.6,172,38.9,172.4,38.9z M44.6,47.2c-1.4,3.6-5.2,5.7-10.4,5.7c-6.6,0-12-5.4-12-11.9c0-6.6,5.4-11.9,12-11.9
			c5.2,0,8.8,2.2,10.4,5.6c0.3,0.7,0.4,1,1.1,1c0.7,0,2.3,0,2.9,0c0.6,0,0.7-0.6,0.6-0.9c-1.6-5.9-7.4-9.8-15-9.8
			C25.2,25,18,32.1,18,41c0,8.8,7.2,16,16.2,16c7.5,0,13.3-3.9,15-9.7c0.1-0.3,0-1-0.6-1c-0.6,0-2.1,0-2.8,0
			C44.9,46.3,44.7,46.9,44.6,47.2z M251.4,26.6H246V19c0-0.4-0.4-0.8-0.8-0.8h-2.5c-0.4,0-0.8,0.4-0.8,0.8v27.5c0,5.2,3.4,9,8.8,9.9
			c0.3,0,0.9,0.1,0.9-0.8c0-1.1,0-1.7,0-2.4c0-0.7-0.6-0.9-0.9-1c-3-0.8-4.6-2.8-4.6-5.7V30.7h5.3c0.4,0,0.7-0.3,0.7-0.7v-2.7
			C252.1,26.9,251.7,26.6,251.4,26.6z M107.6,25c-3.1,0-5.7,0.6-7.9,1.7c-1,0.5-1.7,0.9-3.6,2.5c0,0-4.6,4.6-4.6,11.7
			c0,1.9,0,14.2,0,14.2c0,0.5,0.4,1,1,1h2.2c0.5,0,1-0.4,1-1V41c0-1.9,0.5-3.9,1.5-5.9c1.1-2.2,3.9-6,10.5-6c4.9,0,8.5,2.1,10.5,6
			c1,2,1.6,3.9,1.6,5.9l0,14.2c0,0.5,0.4,1,1,1h2.2c0.5,0,1-0.4,1-1V41C123.8,26.5,112.5,25,107.6,25z M146.5,25
			c-3.1,0-5.7,0.6-7.9,1.7c-1,0.5-1.7,0.9-3.6,2.5c0,0-4.6,4.6-4.6,11.7c0,1.9,0,14.2,0,14.2c0,0.5,0.4,1,1,1h2.2c0.5,0,1-0.4,1-1
			V41c0-1.9,0.5-3.9,1.5-5.9c1.1-2.2,3.9-6,10.5-6c4.9,0,8.5,2.1,10.5,6c1,2,1.6,3.9,1.6,5.9l0,14.2c0,0.5,0.4,1,1,1h2.2
			c0.5,0,1-0.4,1-1V41C162.7,26.5,151.4,25,146.5,25z"
        />
        <path
          fill={colour}
          d="M276,27.4l0,27.9c0,0.5-0.4,0.8-0.8,0.8h-2.4c-0.5,0-0.8-0.4-0.8-0.8l0-27.9
			c0-0.5,0.4-0.8,0.8-0.8h2.4C275.6,26.6,276,27,276,27.4z M304.1,44.7l-1.7,1.7c-0.3,0.3-0.3,0.9,0,1.2l10,10
			c0.3,0.3,0.9,0.3,1.2,0l1.7-1.7c0.3-0.3,0.3-0.9,0-1.2l-10-10C304.9,44.3,304.4,44.3,304.1,44.7z M302.8,52.7
			c-0.2-0.2-0.5-0.3-0.8-0.2c-1.1,0.3-2.2,0.5-3.4,0.5c-6.7,0-12.1-5.4-12.1-12c0-6.6,5.4-12,12.1-12c6.7,0,12.1,5.4,12.1,12
			c0,1.2-0.2,2.4-0.5,3.5c-0.1,0.3,0,0.6,0.2,0.8l2.1,2.1c0.4,0.4,1.1,0.3,1.3-0.2c0.8-1.9,1.2-4,1.2-6.1c0-8.9-7.3-16.2-16.4-16.2
			c-9,0-16.4,7.2-16.4,16.2c0,8.9,7.3,16.2,16.4,16.2c2.1,0,4.1-0.4,6-1.1c0.5-0.2,0.7-0.9,0.3-1.3L302.8,52.7z M316.8,30.7h0.6
			v-3.6h1.3v-0.5h-3.2v0.5h1.3V30.7z M319.6,30.7h0.6v-3.6h0l1.4,3.6h0.4l1.4-3.6h0v3.6h0.6v-4.1h-1l-1.2,3.1l-1.2-3.1h-1V30.7z"
        />
      </g>
    </svg>
  );
};

export { GarminIQLogo };
