import { useParams } from "react-router-dom";
import { CoachEditSessions } from "../../components/coaches/CoachEditSessions";

const CoachEditSessionsPage = () => {
  const params = useParams<{ coachShortCode: string }>();

  if (!params.coachShortCode) {
    return null;
  }

  return <CoachEditSessions coachShortCode={params.coachShortCode} />;
};

export { CoachEditSessionsPage };
