import { VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { ActivityCreate } from "../../components/activities/ActivityCreate";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";

const ActivityCreatePage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks = [
      { href: "/activities", title: "Activities" },
      { href: "/activities/create", title: "Create Activity" },
    ];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <ActivityCreate />
    </VStack>
  );
};

export { ActivityCreatePage };
