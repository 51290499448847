import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { user } from "../../../types/user";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface UserState {
  lastUpdated: string;
  user: user | null;
}

// Define an initial state
const initialState: UserState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
  user: null,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const userSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    updateUserState(state, action: PayloadAction<user | null>) {
      if (action.payload) {
        state.lastUpdated = new Date().toISOString();
        state.user = action.payload;
      } else {
        state.lastUpdated = new Date("1970-01-01T00:00:00.000").toISOString();
        state.user = null;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateUserState } = userSlice.actions;

export const selectUserState = (state: RootState): UserState => {
  return state.userState;
};

// Export default the slice reducer
export default userSlice.reducer;
