import { LinkIcon } from "@chakra-ui/icons";
import { Box, BoxProps, Icon, Tooltip } from "@chakra-ui/react";
import { activityDataCompletion } from "../../types/activity";

interface ActivityLinkedIconProps extends BoxProps {
  completion: activityDataCompletion | undefined;
}

const ActivityLinkedIcon: React.FC<ActivityLinkedIconProps> = ({
  completion,
}) => {
  if (completion && completion.id && completion.goalSession) {
    return (
      <Tooltip
        hasArrow
        label={
          "Linked to training plan session: " + completion.session.data.name
        }
      >
        <Box>
          <Icon as={LinkIcon} color="grey" />
        </Box>
      </Tooltip>
    );
  } else if (completion && completion.id && completion.session) {
    return (
      <Tooltip
        hasArrow
        label={"Linked to session: " + completion.session.data.name}
      >
        <Box>
          <Icon as={LinkIcon} color="grey" />
        </Box>
      </Tooltip>
    );
  }

  return null;
};

export { ActivityLinkedIcon };
