import { Navigate, useParams } from "react-router-dom";
import { LibraryEntityDirect } from "../../components/library/LibraryEntityDirect";

const LibraryEntityDirectPage = () => {
  const { entity } = useParams<{ entity: string }>();

  if (!entity) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  return <LibraryEntityDirect entityId={entity} />;
};

export { LibraryEntityDirectPage };
