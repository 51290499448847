import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  BoxProps,
  Button,
  HStack,
  Icon,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { TiTick, TiTimes } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { updateSessionState } from "../../app/features/session/sessionSlice";
import { updateTrainingTimelineState } from "../../app/features/trainingTimeline/trainingTimelineSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createCompletion } from "../../DataAccess/completions";
import { getSessionById } from "../../DataAccess/sessions";
import { getTrainingTimelineCurrent } from "../../DataAccess/trainingTimelines";
import { trainingTimelineDataWeekDataSession } from "../../types/trainingTimeline";

interface OtherWeekSessionProps extends BoxProps {
  session: trainingTimelineDataWeekDataSession;
  past: boolean;
}

const OtherWeekSession: React.FC<OtherWeekSessionProps> = ({
  session,
  past,
}) => {
  const { user } = useAppSelector(selectUserState);
  const [isCompleting, setIsCompleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const completeRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pink = useColorModeValue("ssNeonPinkLight", "ssNeonPinkDark");
  const green = useColorModeValue("ssNeonGreenLight", "ssNeonGreenDark");

  const markCompleteNoActivityHandler = async (
    goalSessionId: string,
    sessionId: string,
    targetDistance: number
  ) => {
    setIsCompleting(true);
    await createCompletion(
      user ? user._id : "",
      null,
      sessionId,
      goalSessionId
    );
    const ttl = await getTrainingTimelineCurrent();
    dispatch(updateTrainingTimelineState(ttl));
    setIsCompleting(false);
  };

  const markCompleteNewHandler = async (
    goalSessionId: string,
    sessionId: string,
    targetDistance: number
  ) => {
    setIsCompleting(true);
    const sessionResult = await getSessionById(sessionId, targetDistance);
    dispatch(
      updateSessionState({
        session: sessionResult,
        targetDistance: targetDistance,
        requestedDistance: targetDistance,
      })
    );
    setIsCompleting(false);
    navigate(
      "/activities/create?goalSessionId=" +
        goalSessionId +
        "&sessionId=" +
        sessionId,
      { replace: false }
    );
  };

  const markCompleteExistingHandler = async (
    goalSessionId: string,
    sessionId: string,
    targetDistance: number
  ) => {
    setIsCompleting(true);
    const sessionResult = await getSessionById(sessionId, targetDistance);
    dispatch(
      updateSessionState({
        session: sessionResult,
        targetDistance: targetDistance,
        requestedDistance: targetDistance,
      })
    );
    setIsCompleting(false);
    navigate(
      "/activities/link?goalSessionId=" +
        goalSessionId +
        "&sessionId=" +
        sessionId,
      { replace: false }
    );
  };

  const viewSession = () => {
    navigate(
      "/sessions/id/" +
        session.selectedSession.id +
        "/" +
        session.selectedSession.data.scaled.distance +
        "/" +
        session.requestedSession.targetDistance,
      { replace: false }
    );
  };

  return (
    <HStack mb={4}>
      <Box h="60px" w="60px" mr={1} cursor="pointer" onClick={viewSession}>
        {session.complete && (
          <VStack h="60px" w="60px" alignItems="flex-start">
            <Image borderRadius={5} src={session.image} h="60px" w="60px" />
            <Box
              h="60px"
              w="60px"
              mt={-68}
              bgColor={"rgba(53,252,134,0.25)"}
              borderRadius={5}
            >
              <Icon as={TiTick} color={green} h="30px" w="30px" />
            </Box>
          </VStack>
        )}
        {!session.complete && (
          <VStack h="60px" w="60px" alignItems="flex-start">
            <Image borderRadius={5} src={session.image} h="60px" w="60px" />
            {past && (
              <Box
                h="60px"
                w="60px"
                mt={-68}
                bgColor={"rgba(231,85,255,0.25)"}
                borderRadius={5}
              >
                <Icon as={TiTimes} color={pink} h="30px" w="30px" />
              </Box>
            )}
          </VStack>
        )}
      </Box>
      <VStack alignItems="flex-start" w="full">
        <Text lineHeight="none">
          <b>{session.title}</b>
        </Text>
        <Text lineHeight="none" noOfLines={1}>
          {session.name}
        </Text>
      </VStack>
      {past && !session.complete && (
        <>
          <Button variant="success" isLoading={isCompleting} onClick={onOpen}>
            <Icon h={5} w={5} as={TiTick} />
          </Button>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={completeRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Complete Training Plan Session?
                </AlertDialogHeader>
                <AlertDialogBody>
                  <VStack w="full">
                    <Text mb={4}>
                      Would you like to link this plan session to existing
                      activity (from a wearable), create a new manual activity
                      or not create an activity at all?
                    </Text>
                    <Button
                      w="full"
                      variant="success"
                      px={20}
                      isLoading={isCompleting}
                      onClick={() =>
                        markCompleteExistingHandler(
                          session._id,
                          session.selectedSession.id,
                          session.requestedSession.targetDistance
                        )
                      }
                      ml={3}
                    >
                      Complete & Link to an Existing Activity
                    </Button>
                    <Button
                      w="full"
                      variant="success"
                      px={20}
                      isLoading={isCompleting}
                      onClick={() =>
                        markCompleteNewHandler(
                          session._id,
                          session.selectedSession.id,
                          session.requestedSession.targetDistance
                        )
                      }
                      ml={3}
                    >
                      Complete & Create a New Activity
                    </Button>
                    <Button
                      w="full"
                      px={20}
                      isLoading={isCompleting}
                      onClick={() =>
                        markCompleteNoActivityHandler(
                          session._id,
                          session.selectedSession.id,
                          session.requestedSession.targetDistance
                        )
                      }
                      ml={3}
                    >
                      Complete with NO Activity
                    </Button>
                  </VStack>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    variant={"warning"}
                    w="full"
                    ref={completeRef}
                    onClick={onClose}
                  >
                    Don't Complete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </HStack>
  );
};

export { OtherWeekSession };
