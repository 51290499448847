import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { TempoTrainerReadout } from "../svg/TempoTrainerReadout";
import Vimeo from "../ui/Vimeo";

interface SessionCarouselProps {
  carousel: any;
}

const SessionCarousel: React.FC<SessionCarouselProps> = ({ carousel }) => {
  const navigate = useNavigate();
  return (
    <Box mt={5}>
      <Swiper
        autoHeight={true}
        pagination={{
          type: "bullets",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="setSwiper"
      >
        {carousel.data.pages.map((page: any, pageIndex: number) => {
          return (
            <SwiperSlide key={pageIndex}>
              <Box mx={50} pb={35}>
                {page.map((element: any, elementIndex: number) => {
                  if (element.type === "heading") {
                    return (
                      <Heading key={elementIndex} size="small">
                        {element.data}
                      </Heading>
                    );
                  } else if (element.type === "text") {
                    return (
                      <Text key={elementIndex} mb={1}>
                        {element.data}
                      </Text>
                    );
                  } else if (element.type === "link") {
                    return (
                      <Button key={elementIndex} variant="link" mb={1}>
                        {element.data.text}
                      </Button>
                    );
                  } else if (element.type === "video") {
                    return (
                      <Box key={elementIndex} mb={2}>
                        <Vimeo video={element.data} />
                      </Box>
                    );
                  } else if (element.type === "beeper") {
                    return (
                      <Center key={elementIndex}>
                        <TempoTrainerReadout data={element.data} />
                      </Center>
                    );
                  } else if (element.type === "button") {
                    return (
                      <Button
                        key={elementIndex}
                        onClick={() => {
                          if (
                            element.data.type === "appLink" &&
                            element.data.target === "setcss"
                          ) {
                            navigate("/css/calcsingle", { replace: false });
                          } else if (
                            element.data.type === "appLink" &&
                            element.data.target === "setstrokerate"
                          ) {
                            navigate("/bsr/entersingle", { replace: false });
                          }
                        }}
                        mb={1}
                      >
                        {element.data.text}
                      </Button>
                    );
                  }
                  return (
                    <Text key={elementIndex} mb={3}>
                      {JSON.stringify(element)}
                    </Text>
                  );
                })}
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export { SessionCarousel };
