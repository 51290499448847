import { Box, BoxProps, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { GarminIQLogo } from "../svg/GarminIQLogo";
import { GarminLogo } from "../svg/GarminLogo";
import { SwimSmoothLogoWide } from "../svg/SwimSmoothLogoWide";

interface ActivityProviderProps extends BoxProps {
  size: number;
  provider: string;
}

const ActivityProvider: React.FC<ActivityProviderProps> = ({
  provider,
  size,
}) => {
  const logoColour = useColorModeValue("#002140", "#78DFFF");
  if (provider === "SWIMSMOOTH") {
    return (
      <Tooltip hasArrow label="Source: Swim Smooth iOS App">
        <Box mr={1}>
          <SwimSmoothLogoWide
            height={size}
            width={size * 2.5}
            colour={logoColour}
          />
        </Box>
      </Tooltip>
    );
  } else if (provider === "SWIMSMOOTHIQ") {
    return (
      <Tooltip hasArrow label="Source: Swim Smooth IQ App powered by Garmin">
        <Box mr={-1}>
          <GarminIQLogo height={size} width={size * 3} colour={logoColour} />
        </Box>
      </Tooltip>
    );
  } else if (provider === "GARMIN") {
    return (
      <Tooltip hasArrow label="Source: Garmin Connect">
        <Box mr={1}>
          <GarminLogo height={size} width={size * 2} colour={logoColour} />
        </Box>
      </Tooltip>
    );
  }
  return (
    <Tooltip hasArrow label="Source: Manual">
      <span>
        <Box>&nbsp;</Box>
      </span>
    </Tooltip>
  );
};

export { ActivityProvider };
