import {
  Box,
  BoxProps,
  HStack,
  Icon,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { TiTick } from "react-icons/ti";
import { trainingTimelineDataWeekDataSession } from "../../types/trainingTimeline";

interface OtherWeekSessionProps extends BoxProps {
  session: trainingTimelineDataWeekDataSession;
}

const OtherWeekEvent: React.FC<OtherWeekSessionProps> = ({ session }) => {
  const goalColorMode = useColorModeValue(
    "ssNeonGreenDark",
    "ssNeonGreenLight"
  );

  return (
    <HStack mb={4} cursor="pointer">
      <Box h="60px" w="60px" mr={1}>
        <Image
          borderRadius={5}
          src={session.image}
          h="60px"
          w="60px"
          mr={2}
          opacity={session.complete ? 0.25 : 1}
        />
        {session.complete && (
          <VStack
            mt="-60px"
            h="60px"
            w="60px"
            borderRadius={5}
            alignItems="flex-start"
          >
            <Icon as={TiTick} color="green" h="20px" w="20px" />
          </VStack>
        )}
        {!session.complete && (
          <VStack mt="-60px" h="60px" w="60px">
            {/* <!-- Spanner --> */}
          </VStack>
        )}
      </Box>
      <VStack w="full" alignItems="full">
        <Text color={goalColorMode}>
          <b>{session.title}</b>
        </Text>
        <Text lineHeight="none">{session.name}</Text>
      </VStack>
    </HStack>
  );
};

export { OtherWeekEvent };
