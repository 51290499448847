import { useEffect, useState } from "react";
import { getUserById } from "../../DataAccess/users";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectUserState,
  updateUserState,
} from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { user } from "../../types/user";
import { Onboarding } from "../account/onboarding/Onboarding";
import Loading from "../ui/Loading";

export type PublicRouteProps = {
  outlet: JSX.Element;
};

const PublicRoute = ({ outlet }: PublicRouteProps) => {
  const { userId } = useAppSelector(selectAuthState);
  const { user, lastUpdated: lastUpdatedUser } =
    useAppSelector(selectUserState);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [loadingOnboarding, setLoadingOnboarding] = useState<boolean>(true);
  const [onboarded, setOnboarded] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let userResponse: user | null;
    const getUserLocal = async () => {
      try {
        if (userId) {
          userResponse = await getUserById(userId);
          if (userResponse) {
            dispatch(updateUserState(userResponse));
          }
          setLoadingUser(false);
        } else {
          setLoadingUser(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (userId && lastUpdatedUser) {
      const test = new Date(lastUpdatedUser).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!user || anHourAgo > test) {
        getUserLocal();
      } else {
        setLoadingUser(false);
      }
    } else if (userId) {
      getUserLocal();
    } else {
      setLoadingUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.processes && user.processes.onboardingLastDate) {
      const onboardingDate = new Date(user.processes.onboardingLastDate);
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      if (onboardingDate <= sixMonthsAgo) {
        setOnboarded(false);
      }
      setOnboarded(true);
      setLoadingOnboarding(false);
    } else {
      setLoadingOnboarding(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loadingUser) {
    return <Loading message="Loading User" />;
  }

  if (loadingOnboarding) {
    return <Loading message="Checking Onboarding Status" />;
  }

  if (user) {
    if (!onboarded) {
      return <Onboarding />;
    }
  }

  return outlet;
};

export { PublicRoute };
