import { InfoIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { BsrTweaker } from "../../components/bsr/BsrTweaker";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";
import { breadcrumbLink } from "../../types/breadcrumb";

const BsrTweakerPage = (props: any) => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/tools", title: "Tools" });
    breadcrumbLinks.push({
      href: "/bsr/tweaker",
      title: "BSR Tweaker",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading>
        Base Stroke Rate Tweaker
        <>
          <Icon cursor="pointer" ml={2} mt={-8} as={InfoIcon} onClick={onOpen} w={5} h={5} />
          <Drawer isOpen={isOpen} placement="left" size="md" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>About The BSR Tweaker</DrawerHeader>
              <DrawerBody>
                <Heading mb={2}>BSR and the 'tweaker'</Heading>
                <Text mb={2}>Your base Stroke Rate (BSR) is how many arm strokes you take per minute when swimming at a strong pace.</Text>
                <Text mb={2}>
                  To find your base stroke rate, ask a friend or coach to count how many strokes you take in 15 seconds (counting both arms) and multiply by
                  four. Or swim with a Tempo Trainer Pro in mode 3 and adjust up or down until it feels about your normal stroke rate.
                </Text>
                <Text mb={2}>So go on, get Tweaking!</Text>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      </Heading>
      <Text>
        It’s entirely natural that your base stroke rate (BSR) will change over time. Use this tweaker to tweak your BSR up or down depending on where your
        rhythm is at when swimming at CSS pace.
      </Text>
      <BsrTweaker />
    </VStack>
  );
};

export { BsrTweakerPage };
