import {
  Box,
  BoxProps,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

interface ActivitiesPerWeekSelectorProps extends BoxProps {
  type: string | null;
  subType: string | null;
  activitiesPerWeekInput: number | null;
  setActivitiesPerWeekInput: any;
}

const ActivitiesPerWeekSelector: React.FC<ActivitiesPerWeekSelectorProps> = ({
  type,
  subType,
  activitiesPerWeekInput,
  setActivitiesPerWeekInput,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const selectedBoxColorMode = useColorModeValue(
    "ssNeonOrangeLight",
    "ssNeonOrangeDark"
  );

  const isSelected = (lane: number): string => {
    if (activitiesPerWeekInput === lane) {
      return selectedBoxColorMode;
    }
    return boxColorMode;
  };

  let apwOptions = [1, 2, 3, 4, 5, 6];
  if (type === "Group" && subType === "Basics") {
    apwOptions = [1, 2, 3];
  }

  return (
    <SimpleGrid columns={[2, 2, 6, 6]} spacing={3} w="full">
      {apwOptions.map((apw: any) => {
        return (
          <Box
            key={apw}
            bg={boxColorMode}
            borderWidth={3}
            borderColor={isSelected(apw)}
            borderRadius={5}
            px={5}
            py={2}
            cursor="pointer"
            onClick={() => {
              setActivitiesPerWeekInput(apw);
            }}
          >
            <Text fontSize="lg">
              <b>{apw}</b>
            </Text>
            <Text color="gray">{apw} activities per week</Text>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export { ActivitiesPerWeekSelector };
