import {
  Box,
  BoxProps,
  Heading,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { trainingTimeline } from "../../types/trainingTimeline";
import { user } from "../../types/user";
import { OtherWeekEvent } from "./OtherWeekEvent";
import { OtherWeekSession } from "./OtherWeekSession";

interface LastWeekProps extends BoxProps {
  user: user;
  trainingTimeline: trainingTimeline;
}

const LastWeek: React.FC<LastWeekProps> = ({ user, trainingTimeline }) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );

  return (
    <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
      <VStack w="full" mb={2}>
        {!trainingTimeline.data.weeks.has.lastWeek && (
          <Heading as="h4" size="md">
            Hey, {user.data.basic.firstName}
          </Heading>
        )}
        {trainingTimeline.data.weeks.has.lastWeek && (
          <Heading as="h4" size="md">
            Last Week
          </Heading>
        )}
      </VStack>
      {trainingTimeline.data.weeks.has.lastWeek && (
        <>
          <Heading as="h5" size="sm" mb={2}>
            {trainingTimeline.data.weeks.lastWeek?.comment.short}
          </Heading>
          <Text mb={5} noOfLines={1}>
            {trainingTimeline.data.weeks.thisWeek?.comment.long.map(
              (line: string) => {
                return line;
              }
            )}
          </Text>
          <Heading as="h5" size="sm" mb={2}>
            Sessions
          </Heading>
          {trainingTimeline.data.weeks.lastWeek?.sessions.map(
            (session: any, sessionIndex: number) => {
              if (session.type === "Session") {
                return (
                  <OtherWeekSession
                    key={session._id + "" + sessionIndex}
                    session={session}
                    past={true}
                  />
                );
              } else if (session.type === "Event") {
                return (
                  <OtherWeekEvent
                    key={session._id + "" + sessionIndex}
                    session={session}
                  />
                );
              }
              return <Text>Error</Text>;
            }
          )}
        </>
      )}
      {!trainingTimeline.data.weeks.has.lastWeek && (
        <>
          <Text mb={2}>Welcome to your shiny new plan, isn't it exciting.</Text>
          <Text mb={2}>
            Each week we'll select sessions based on your goal, settings and
            your current activity levels.
          </Text>
          <Text mb={2}>
            Sessions for 'next week' get generated the weekend before the week
            is due to commence.
          </Text>
          <Text mb={2}>
            <b>Enjoy</b>
          </Text>
        </>
      )}
    </Box>
  );
};

export { LastWeek };
