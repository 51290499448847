import { BoxProps, useColorModeValue } from "@chakra-ui/react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { fitnessSummaryHistory } from "../../types/fitness";
import { interp2 } from "../../utils/coreHelper";
import { CustomTooltipDate } from "../ui/CustomTooltipDate";

interface FitnessAndFatigueGraphProps extends BoxProps {
  data: fitnessSummaryHistory[];
  locked: boolean;
}

const dateFormatter = (value: any) => {
  return new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "short",
    day: "2-digit",
  }).format(new Date(value));
};
const todayDateFormatter = (date: Date) => {
  const [re] = date.toISOString().split("T");
  return re;
};

const FitnessAndFatigueGraph: React.FC<FitnessAndFatigueGraphProps> = ({
  data,
  locked,
}) => {
  const labelColorMode = useColorModeValue("#000000", "#FFFFFF");
  let fitnessColour = useColorModeValue("#0491DF", "#35B0F4"); // Blue
  let fatigueColour = useColorModeValue("#A419BC", "#E755FF"); // Pink
  let formColour = useColorModeValue("#00B24E", "#35FC86"); // Green
  let sTssColour = useColorModeValue("#FF7A30", "#FF9B63"); // Orange
  let todayColour = useColorModeValue("#000000", "#FFFFFF"); // Grey
  if (locked) {
    fitnessColour = "#CCCCCC";
    fatigueColour = "#CCCCCC";
    formColour = "#CCCCCC";
    sTssColour = "#CCCCCC";
    todayColour = "#CCCCCC";
  }
  const today = new Date();
  const xAxisIntervals = [10, 50, 125, 250];
  const strokeWidths = [3, 2, 1, 1];
  const dataLengths = [50, 500, 1000, 2500];
  const xAxisInterval = Math.floor(
    interp2(dataLengths, xAxisIntervals, data.length)
  );
  const strokeWidth = Math.floor(
    interp2(dataLengths, strokeWidths, data.length)
  );

  return (
    <>
      <ResponsiveContainer width="100%" height={350}>
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          syncId="anyId"
        >
          <XAxis
            dataKey="dates"
            allowDataOverflow={false}
            interval={xAxisInterval}
            tickFormatter={dateFormatter}
          />
          <YAxis
            label={{
              value: "Fitness, Fatigue & Form",
              angle: -90,
              position: "outsideLeft",
              fill: labelColorMode,
              dx: -20,
            }}
            orientation="left"
            domain={[
              (dataMin: number) => Math.floor(dataMin / 2) * 2,
              (dataMax: number) => Math.ceil(dataMax / 2) * 2,
            ]}
            scale="linear"
            type="number"
            yAxisId={0}
          />
          <CartesianGrid strokeDasharray="7 7" stroke="#CCCCCC" />
          {locked ? null : <Tooltip content={<CustomTooltipDate />} />}
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <Line
            type="basis"
            dot={false}
            dataKey="fitness"
            strokeWidth={strokeWidth}
            stroke={fitnessColour}
            activeDot={{ r: 3 }}
            name={"Fitness"}
            yAxisId={0}
          />
          <Line
            type="basis"
            dot={false}
            dataKey="fatigue"
            strokeWidth={strokeWidth}
            stroke={fatigueColour}
            activeDot={{ r: 3 }}
            name="Fatigue"
            yAxisId={0}
          />
          <Line
            type="basis"
            dot={false}
            dataKey="form"
            strokeWidth={strokeWidth}
            stroke={formColour}
            activeDot={{ r: 3 }}
            name="Form"
            yAxisId={0}
          />
          <Bar
            type="basis"
            barSize={15}
            dataKey="stss"
            fill={sTssColour}
            name="sTSS"
            legendType="none"
            hide={true}
            yAxisId={0}
          />
          <ReferenceLine
            x={todayDateFormatter(today)}
            stroke={todayColour}
            label={{
              value: "Today",
              angle: -90,
              fill: labelColorMode,
              dx: -10,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height={200}>
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          syncId="anyId"
        >
          <XAxis
            dataKey="dates"
            interval={xAxisInterval}
            allowDataOverflow={false}
            tickFormatter={dateFormatter}
          />
          <YAxis
            label={{
              value: "sTSS",
              angle: -90,
              position: "outsideRight",
              fill: labelColorMode,
              dx: -20,
            }}
          />
          <CartesianGrid strokeDasharray="7 7" stroke="#CCCCCC" />
          {locked ? null : <Tooltip content={<CustomTooltipDate />} />}
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <Bar
            type="basis"
            barSize={15}
            dataKey="stss"
            fill={sTssColour}
            name="sTSS"
          />
          <ReferenceLine
            x={todayDateFormatter(today)}
            stroke={todayColour}
            label={{
              value: "Today",
              angle: -90,
              fill: labelColorMode,
              dx: -10,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export { FitnessAndFatigueGraph };
