import { BoxProps, HStack, useColorModeValue } from "@chakra-ui/react";
import { sessionDataSetSwimDataIcon } from "../../types/session";
import { SessionStrokeIcon } from "../svg/SessionStrokeIcon";

interface StrokeIdenitifierProps extends BoxProps {
  icon: sessionDataSetSwimDataIcon;
  size?: number;
}

const StrokeIdentifier: React.FC<StrokeIdenitifierProps> = ({ icon, size }) => {
  if (typeof size === "undefined") {
    size = 40;
  }
  const boxColorMode = useColorModeValue(
    "rgba(0,0,0,0.1)",
    "rgba(255,255,255,0.1)"
  );
  return (
    <HStack p={3} borderLeftRadius={10} bg={boxColorMode}>
      <SessionStrokeIcon icon={icon} size={size} />
    </HStack>
  );
};

export { StrokeIdentifier };
