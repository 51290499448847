import {
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  ListItem,
  Text,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import * as contentful from "contentful";
import { useContext, useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectAreasState,
  updateAreasState,
} from "../../app/features/library/areasSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { checkLevelInArray, getLevelInSentence } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

interface LibraryAreaProps {
  areaId: string;
}

const LibraryArea: React.FC<LibraryAreaProps> = ({ areaId }) => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const { areas, lastUpdated } = useAppSelector(selectAreasState);
  const [area, setArea] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    const breadcrumbLinks = [
      { href: "/library", title: "Library" },
      { href: "/library/" + areaId, title: area ? area.fields.title : "" },
    ];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    const getEntriesLocal = async () => {
      const entries = await client.getEntries({
        content_type: "area",
      });
      dispatch(
        updateAreasState({
          areas: entries.items.sort((a: any, b: any) => {
            return a.fields.order - b.fields.order;
          }),
        })
      );
      setArea(areas.filter((a: any) => a.sys.id === areaId)[0]);
      setLoading(false);
    };
    if (lastUpdated) {
      const test = new Date(lastUpdated).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!areas || areas.length === 0 || anHourAgo > test) {
        getEntriesLocal();
      } else {
        setArea(areas.filter((a: any) => a.sys.id === areaId)[0]);
        setLoading(false);
      }
    } else {
      getEntriesLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return null;
  }

  return (
    <HStack w={"full"} alignItems={"flex-start"}>
      <Image mr={5} h={10} src={area.fields.icon.fields.file.url} />
      <VStack w={"full"} alignItems={"flex-start"}>
        <Heading as="h2" size="xl" mb={4}>
          {area.fields.title}
        </Heading>
        <HStack key={area.sys.id} pb={10} alignItems="flex-start">
          <VStack alignItems="flex-start" w="full">
            <Text>{area.fields.shortDescription}</Text>
            {area.fields.categories.map((c: any) => {
              if (!checkLevelInArray(accessToken, c.fields.levels)) {
                return (
                  <Button
                    minWidth={0}
                    key={c.sys.id}
                    onClick={() => {
                      createToast(toast, (props: any) => {
                        return (
                          <CustomToast
                            title={"Upgrade Required"}
                            status={"Warning"}
                            toast={toast}
                            toastId={props.id}
                          >
                            {c.fields.levels && (
                              <>
                                <Text>
                                  '{c.fields.title}' requires one of the
                                  following subscriptions,
                                </Text>
                                <UnorderedList>
                                  {c.fields.levels.map((l: string) => {
                                    return <ListItem>{l}</ListItem>;
                                  })}
                                </UnorderedList>
                                <Text>
                                  You currently have{" "}
                                  {getLevelInSentence(accessToken)}.
                                </Text>
                              </>
                            )}
                            <Button
                              w={"full"}
                              mt={2}
                              onClick={() => {
                                navigate("/account/subscription", {
                                  replace: false,
                                });
                                toast.closeAll();
                              }}
                            >
                              Upgrade Now
                            </Button>
                          </CustomToast>
                        );
                      });
                    }}
                    variant="link"
                  >
                    <Icon
                      h={15}
                      w={15}
                      mr={2}
                      as={FaLock}
                      color={intensityColorMode}
                    />
                    {c.fields.title}
                  </Button>
                );
              }
              return (
                <Button
                  minWidth={0}
                  key={c.sys.id}
                  onClick={() => {
                    navigate("/library/" + area.sys.id + "/" + c.sys.id, {
                      replace: false,
                    });
                  }}
                  variant="link"
                >
                  {c.fields.title}
                </Button>
              );
            })}
          </VStack>
        </HStack>
      </VStack>
    </HStack>
  );
};

export { LibraryArea };
