import { Heading, ListItem, UnorderedList, VStack } from "@chakra-ui/react";

interface AffiliateTermsProps {
  parentName: string;
  program: string;
}

const AffiliateTerms: React.FC<AffiliateTermsProps> = ({ parentName, program }) => {
  return (
    <VStack w={"full"} alignItems={"flex-start"}>
      <Heading as={"h3"} size={"md"} mt={5}>
        {program} Terms & Conditions
      </Heading>
      <UnorderedList>
        <ListItem>
          You will be linked to <em>{parentName}</em> who will have access to your data including:
          <UnorderedList>
            <ListItem>Activity Data</ListItem>
            <ListItem>Stroke Insights&#8471;</ListItem>
            <ListItem>Training Plan Data & Progress</ListItem>
            <ListItem>Current CSS & History</ListItem>
            <ListItem>Current BSR & History</ListItem>
            <ListItem>Fitness, Fatigue & Form Data</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>Where applicable free access is provided in the form of a trial.</ListItem>
        <ListItem>
          You can disconnect from <em>{parentName}</em> at any time but will lose any remaining free access.
        </ListItem>
        <ListItem>
          <em>{parentName}</em> or Swim Smooth may end your free access at any time.
        </ListItem>
        <ListItem>
          All free access is dependant on <em>{parentName}</em> remaining a part of the {program}.
        </ListItem>
        <ListItem>Free access is not avaliable to existing subscribers.</ListItem>
      </UnorderedList>
    </VStack>
  );
};

export { AffiliateTerms };
