import { Image } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface imageSliderProps {
  slides: slide[];
  width: number;
  height: number;
}

interface slide {
  src: string;
  focalPoint?: string;
  alt?: string;
  caption?: string;
}

// If you want to use your own Selectors look up the Advancaed Story book examples
const ImageSlider = ({ slides, width, height }: imageSliderProps) => {
  return (
    <Carousel showThumbs={false} width={width} infiniteLoop>
      {slides.map((slide: slide, index: number) => {
        const focalPoint =  slide.focalPoint ? slide.focalPoint : 'face';
        return (
          <div key={index} onClick={() => window.open(slide.src)}>
            <Image key={'carousel' + index} src={slide.src + '?w=' + width + '&h=' + height + '&f=' + focalPoint + '&fit=fill'} alt={slide.alt} />
          </div>
        );
      })}
    </Carousel>
  );
};

export default ImageSlider;
