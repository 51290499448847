import { Box, BoxProps } from '@chakra-ui/react';
import { scoreColour } from '../../utils/coreHelper';
import Score from './Score';

interface FilledBarProps extends BoxProps {
  width: number;
  score: number;
  max: number;
  showScore?: boolean;
}

const FilledBar: React.FC<FilledBarProps> = ({ width, score, max, showScore }) => {
  if (typeof showScore === 'undefined') {
    showScore = true;
  }
  const fillWidth: number = max > 0 ? (score / max) * 100 : 0;
  const fillWidthString: string = fillWidth + '%';
  const colour = scoreColour(score, max);
  const backgroundColour = '#AFAFAF';
  return (
    <>
      {showScore && <Score score={score} max={max} />}
      <Box bg={backgroundColour} h={1} w={width} borderRadius={3}>
        <Box bg={colour} h={1} borderRadius={3} w={fillWidthString}></Box>
      </Box>
    </>
  );
};

export default FilledBar;
