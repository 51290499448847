import {
  Button,
  HStack,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { getCssHistory } from "../../DataAccess/css";
import { cssEntry } from "../../types/css";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import Loading from "../ui/Loading";
import { CssListItem } from "./CssListItem";

const CssHistory = () => {
  const [loadingCss, setLoadingCss] = useState<boolean>(true);

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);

  const [cssHistory, setCssHistory] = useState<cssEntry[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getCssHistoryLocal = async () => {
      if (
        checkRole(accessToken, "history", "css") ||
        checkRole(accessToken, "latest", "css")
      ) {
        const cssHistory = await getCssHistory();
        setCssHistory(
          cssHistory.map((e: any) => {
            const date = new Date(e.date);
            e.dateN = date.getTime();
            return e;
          })
        );
      } else {
        console.log("Not getting CSS history");
      }
      setLoadingCss(false);
    };
    getCssHistoryLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (
    !checkRole(accessToken, "latest", "css") &&
    !checkRole(accessToken, "history", "css")
  ) {
    return <NotAuthorised functionText={"Save Current CSS"} size={"full"} />;
  }

  if (loadingCss) {
    return <Loading message="Loading CSS History" />;
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <TableContainer w="full">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th isNumeric>Pace / 100{user.data.basic.defaultPool.lapUnit}</Th>
              <Th>Type</Th>
              <Th>Pool</Th>
              <Th>Engine</Th>
              <Th>Pacing</Th>
              <Th isNumeric>Actions</Th>
            </Tr>
          </Thead>
          {checkRole(accessToken, "history", "css") && (
            <Tbody>
              {cssHistory.length === 0 && (
                <Tr>
                  <Td textAlign={"center"} colSpan={7}>
                    No CSS Entries Found
                  </Td>
                </Tr>
              )}
              {cssHistory.map((cssEntry: cssEntry, index: Number) => {
                return (
                  <CssListItem
                    user={user}
                    cssEntry={cssEntry}
                    key={cssEntry._id}
                    setCssHistory={setCssHistory}
                  />
                );
              })}
            </Tbody>
          )}
          {!checkRole(accessToken, "history", "css") &&
            checkRole(accessToken, "latest", "css") && (
              <Tbody>
                {cssHistory.length === 0 && (
                  <Tr>
                    <Td textAlign={"center"} colSpan={7}>
                      No CSS Entries Found
                    </Td>
                  </Tr>
                )}
                {cssHistory.map((cssEntry: cssEntry, index: number) => {
                  if (index === 0) {
                    return (
                      <CssListItem
                        user={user}
                        cssEntry={cssEntry}
                        key={cssEntry._id}
                        setCssHistory={setCssHistory}
                      />
                    );
                  } else {
                    return (
                      <CssListItem
                        user={user}
                        cssEntry={cssEntry}
                        key={cssEntry._id}
                        setCssHistory={setCssHistory}
                        blurred={true}
                      />
                    );
                  }
                })}
              </Tbody>
            )}
          <Tfoot>
            <Tr>
              <Th>Date</Th>
              <Th isNumeric>Pace / 100{user.data.basic.defaultPool.lapUnit}</Th>
              <Th>Type</Th>
              <Th>Pool</Th>
              <Th>Engine</Th>
              <Th>Pacing</Th>
              <Th isNumeric>Actions</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <HStack w="full" alignItems="flex-start" pt={2}>
        <Spacer />
        {checkRole(accessToken, "manual", "css") && (
          <Button
            onClick={() => {
              navigate("/css/entersingle", { replace: false });
            }}
          >
            Add Manual CSS
          </Button>
        )}
        <Button
          onClick={() => {
            navigate("/css/calcsingle", { replace: false });
          }}
        >
          Calculate CSS from Test
        </Button>
      </HStack>
    </VStack>
  );
};

export { CssHistory };
