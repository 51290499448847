import { BoxProps, Image, Text, VStack } from "@chakra-ui/react";
import { billing } from "../../../../types/billing";

interface SubscriptionDetailsPartnerProps extends BoxProps {
  billing: billing;
}

const SubscriptionDetailsPartner: React.FC<SubscriptionDetailsPartnerProps> = ({
  billing,
}) => {
  return (
    <VStack w="full" alignItems="start">
      <Text>
        You are currently on a '{billing.level}' level plan provided by PARTNER.
      </Text>
      <Image src="https://images.ctfassets.net/50b15ahactsg/6IVVf0WN9iWjXo3VR3swgl/791f672e746060c1b29e6631d2e5ddeb/eiqLogoLight.png" />
    </VStack>
  );
};

export { SubscriptionDetailsPartner };
