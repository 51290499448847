import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Heading,
  Icon,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFitness } from "../../DataAccess/training";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectFitnessState,
  updateFitnessState,
} from "../../app/features/training/fitnessSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fitnessSummaryHistory } from "../../types/fitness";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BoxWrapper } from "../ui/BoxWrapper";
import LoadingMulti from "../ui/LoadingMulti";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import Vimeo from "../ui/Vimeo";
import { FitnessAndFatigueGraph } from "./FitnessAndFatigueGraph";

interface FitnessAndFatigueProps extends BoxProps {
  subscriber: boolean;
}

const FitnessAndFatigue: React.FC<FitnessAndFatigueProps> = ({
  subscriber,
}) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const { fitness, view } = useAppSelector(selectFitnessState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getFitnessAndFatigueLocal = async () => {
      try {
        const fitness = await getFitness();
        dispatch(
          updateFitnessState({
            fitness: fitness,
            view: view,
          })
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    if (checkRole(accessToken, "read", "fitnessAndFatigue")) {
      getFitnessAndFatigueLocal();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <FFWrapper>
        <LoadingMulti
          messages={[
            "Getting Activities",
            "Calculating Fitness & Fatigue",
            "Checking Training Balance",
            "Checking Form",
          ]}
        />
      </FFWrapper>
    );
  }

  if (!subscriber) {
    return (
      <FFWrapper>
        <NotAuthorised functionText={"Fitness Tracking"} size={"small"} />
      </FFWrapper>
    );
  }

  if (
    !fitness ||
    (fitness && fitness.history && fitness.history.length === 0)
  ) {
    return (
      <FFWrapper>
        <Text>
          You don't have any fitness data yet. Get swimming to see your
          progress.
        </Text>
        <FFDummy />
      </FFWrapper>
    );
  }

  return (
    <FFWrapper>
      <HStack w={"full"} alignItems={"flex-start"}>
        <Spacer />
        <Box pr={8}>
          <SingleValueDisplay
            label={"Current Fitness"}
            value={fitness.fitness}
          />
        </Box>
        <Box pr={8}>
          <SingleValueDisplay
            label={"Current Fatigue"}
            value={fitness.fatigue}
          />
        </Box>
        <Box pr={8}>
          <SingleValueDisplay label={"Current Form"} value={fitness.form} />
        </Box>
        <Spacer />
      </HStack>
      <FitnessAndFatigueGraph
        data={fitness.history.slice(-70)}
        locked={false}
      />
      <Center pt={5}>
        <Button
          onClick={() => {
            navigate("/fitness", { replace: false });
          }}
        >
          View Full Fitness Analysis
        </Button>
      </Center>
    </FFWrapper>
  );
};

const FFWrapper: React.FC<BoxProps> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <BoxWrapper>
      <Heading mb={5} size="md">
        Fitness (Last Four Weeks)
        <>
          <Icon
            cursor="pointer"
            ml={2}
            mt={-4}
            as={InfoIcon}
            onClick={onOpen}
          />
          <Drawer isOpen={isOpen} placement="left" size="md" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
                About Fitness, Fatigue &amp; Training Stress
              </DrawerHeader>
              <DrawerBody>
                <FFExplainer />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      </Heading>
      {children}
    </BoxWrapper>
  );
};

const FFExplainer = () => {
  return (
    <>
      <Heading mb={2}>Fitness &amp; Fatigue</Heading>
      <Text mb={2}>
        The fitness prediction chart gives a day by day indication of your
        fitness and fatigue levels. It's updated daily from your training data.
      </Text>
      <Text mb={2}>
        Fitness takes weeks and months to change significantly, but fatigue
        changes more quickly. As you would expect, fatigue increases immediately
        after a tough training session but will quickly drop after a few easy
        days.
      </Text>
      <Text mb={2}>
        The fitness and fatigue numbers are arbitrary but the trend is
        important. If your fitness level increases over a few weeks, you can be
        confident you are getting faster. Take a break from training and you'll
        see it fade away.
      </Text>
      <Text mb={2}>
        Looking back over months, or years, of training is very insightful, you
        can see how you gained from good blocks of training and how time off set
        your fitness back. Improving your swim fitness is all about consistency:
        over-do things and get sick or injured, this chart will show the impact
        on your fitness.
      </Text>
      <Text mb={2}>
        A model such as this is never 100% accurate but it does give you a
        valuable "big picture" insight into the effectiveness of your training
        and your fatigue levels day to day.
      </Text>
      <Box my={4}>
        <Vimeo
          video={{
            id: "454422523",
            title: "Fitness Prediction",
            duration: 0,
            thumbnail: "",
          }}
        />
      </Box>
      <Box>
        <Vimeo
          video={{
            id: "165982561",
            title: "How the Fitness Tracker Works",
            duration: 0,
            thumbnail: "",
          }}
        />
      </Box>
      <Heading mt={5} mb={2}>
        Training Stress
      </Heading>
      <Text mb={2}>
        sTSS is your 'Swimming Training Stress Score' - a single number that
        sums up the workload of a single training session. Swim further or swim
        at a higher intensity and the resultant sTSS number will be higher.
      </Text>
      <Text mb={2}>
        As a guide, a typical CSS session comes in around 50-60 sTSS and Red
        Mist session around 70-80.
      </Text>
      <Text mb={2}>
        Use this sTSS 'skyscraper' chart to keep an eye the consistency of your
        training over time. Too many gaps and your fitness will plateau but
        equally too many high bars in short order and you risk burnout or
        injury.
      </Text>
      <Text mb={2}>
        Other analysis such as the Fitness Prediction chart and Training Balance
        use the sTSS numbers to perform further calculations on your progress.
      </Text>
    </>
  );
};

const FFDummy = () => {
  const todayDateFormatter = (value: any) => {
    const [re] = new Date(value).toISOString().split("T");
    return re;
  };

  const today = new Date();
  let d0 = new Date();
  d0.setDate(today.getDate() - 4);
  let d1 = new Date();
  d1.setDate(today.getDate() - 3);
  let d2 = new Date();
  d2.setDate(today.getDate() - 2);
  let d3 = new Date();
  d3.setDate(today.getDate() - 1);
  let d4 = new Date();
  d4.setDate(today.getDate());
  let d5 = new Date();
  d5.setDate(today.getDate() + 1);
  let d6 = new Date();
  d6.setDate(today.getDate() + 2);
  let d7 = new Date();
  d7.setDate(today.getDate() + 3);
  let d8 = new Date();
  d8.setDate(today.getDate() + 4);
  let d9 = new Date();
  d9.setDate(today.getDate() + 5);

  const dummyDataArray = [
    [d0, 0.86, 0.26, 0],
    [d1, 1.05, 1.43, 9],
    [d2, 1.65, 5.03, 28.4],
    [d3, 1.65, 4.36, 0],
    [d4, 1.61, 3.78, 0],
    [d5, 1.57, 3.28, 0],
    [d6, 1.53, 2.84, 0],
    [d7, 1.5, 2.46, 0],
    [d8, 1.46, 2.14, 0],
    [d9, 1.43, 1.85, 10],
  ];
  const dummyData: fitnessSummaryHistory[] = dummyDataArray.map((f: any) => {
    return {
      dates: todayDateFormatter(f[0]),
      fitness: f[1],
      fatigue: f[2],
      form: f[1] - f[2],
      stss: f[3],
      estimated: false,
    };
  });

  return <FitnessAndFatigueGraph data={dummyData} locked={true} />;
};

export { FitnessAndFatigue };
