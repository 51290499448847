import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Pricing from "../pricing/Pricing";

interface notAuthorisedProps {
  title?: string;
  functionText?: string;
  size: "small" | "full";
}

const NotAuthorised: React.FC<notAuthorisedProps> = ({
  title,
  functionText,
  size,
}) => {
  const navigate = useNavigate();
  return (
    <VStack w={"full"}>
      {title && <Heading>{title}</Heading>}
      <Text>
        You don't currently have access to{" "}
        <em>{functionText ? functionText : "this function"}</em>, why not have a
        look at our subscription levels to enhance your Swim Smooth experience.
      </Text>
      {size === "full" && <Pricing />}
      {size === "small" && (
        <Box w={"full"} pt={4} pb={4}>
          <Button
            w="full"
            onClick={() => {
              navigate("/account/subscription", { replace: false });
            }}
          >
            Upgrade Now
          </Button>
        </Box>
      )}
    </VStack>
  );
};

export { NotAuthorised };
