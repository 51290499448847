// navigation.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

let navigate: any = null;

export const setNavigate = (nav: any) => {
  navigate = nav;
};

export const redirectToLogin = () => {
  if (navigate) {
    navigate("/login");
  }
};

// Component to set up navigate
export const NavigationSetter = () => {
  const nav = useNavigate();

  useEffect(() => {
    setNavigate(nav);
  }, [nav]);

  return null;
};
