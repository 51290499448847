const lPadNumber = (number: number, length: number = 2, padWith: string = '0'): string => {
  return number.toString().padStart(length, padWith);
};

const rPadNumber = (number: number, length: number = 2, padWith: string = '0'): string => {
  return number.toString().padEnd(length, padWith);
};

const dp = (x: number, places: number): number => {
  places = typeof places === 'undefined' ? 2 : places;
  const factorOfTen = Math.pow(10, places);
  return Math.round(x * factorOfTen) / factorOfTen;
};

const ucFirst = (string: string): string => {
  if (string && string.length > 1) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else if (string && string.length === 1) {
    return string.toUpperCase();
  }
  return '';
};

const ucName = (string: string): string => {
  if (string && string.length > 1) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } else if (string && string.length === 1) {
    return string.toUpperCase();
  }
  return '';
};

const scoreColour = (score: number | string, max: number | string): string => {
  if (typeof score !== 'number' || typeof max !== 'number' || isNaN(score) || isNaN(max)) {
    return '#AFAFAF';
  }
  const percentage = max > 0 ? (score / max) * 100 : 0;
  if (percentage < 20) {
    return '#E45225';
  } else if (percentage < 40) {
    return '#F3A320';
  } else if (percentage < 60) {
    return '#EEF20D';
  } else if (percentage < 80) {
    return '#A9EA3A';
  } else {
    return '#35FC86';
  }
};

const interp2 = (xAxis: any, yAxis: any, xVal: number): number => {
  let x1, x2, y1, y2;

  for (let i = 0; i < xAxis.length; i++) {
    if (xVal <= xAxis[i]) {
      x2 = xAxis[i];
      y2 = yAxis[i];
      if (i === 0) {
        return y2;
      } else {
        x1 = xAxis[i - 1];
        y1 = yAxis[i - 1];
        return interpolate(x1, x2, y1, y2, xVal);
      }
    }
  }

  return yAxis.slice(-1)[0];
};

const interp3 = (table: any, xVal: number, yVal: number): number => {
  const xAxis: number[] = table.x;
  const yAxis: number[] = table.y;
  const zArray: number[][] = table.z;
  let xi1: number | undefined;
  let xi2: number | undefined;
  let yi1: number | undefined;
  let yi2: number | undefined;

  for (let i = 0; i < xAxis.length; i++) {
    if (xVal <= xAxis[i]) {
      xi2 = i;
      xi1 = xi2 === 0 ? 0 : xi2 - 1;
      break;
    }
  }
  for (let i = 0; i < yAxis.length; i++) {
    if (yVal <= yAxis[i]) {
      yi2 = i;
      yi1 = yi2 === 0 ? 0 : yi2 - 1;
      break;
    }
  }

  if (!xi1) {
    xi1 = xi2 = xAxis.length - 1;
  }
  if (!xi2) {
    xi2 = xAxis.length - 1;
  }
  if (!yi1) {
    yi1 = yi2 = yAxis.length - 1;
  }
  if (!yi2) {
    yi2 = yAxis.length - 1;
  }

  const x1 = xAxis[xi1];
  const x2 = xAxis[xi2];
  const y1 = yAxis[yi1];
  const y2 = yAxis[yi2];
  const z1 = zArray[yi1][xi1];
  const z2 = zArray[yi1][xi2];
  const z3 = zArray[yi2][xi1];
  const z4 = zArray[yi2][xi2];

  const row1 = interpolate(x1, x2, z1, z2, xVal);
  const row2 = interpolate(x1, x2, z3, z4, xVal);
  return interpolate(y1, y2, row1, row2, yVal);
};

const interpolate = (x1: number, x2: number, y1: number, y2: number, x: number): number => {
  if (x1 === x2) {
    return y1;
  } else {
    return ((y2 - y1) / (x2 - x1)) * (x - x1) + y1;
  }
};

const printableNumber = (n: number): string => {
  return n >= 0 ? '+' + Math.abs(n) : '-' + Math.abs(n);
};

export { lPadNumber, rPadNumber, dp, ucFirst, ucName, scoreColour, interp2, interp3, printableNumber };
