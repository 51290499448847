import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { session } from "../../../types/session";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface SessionState {
  session: session | null;
  targetDistance: number | null;
  requestedDistance: number | null;
}

// Define an initial state
const initialState: SessionState = {
  session: null,
  targetDistance: null,
  requestedDistance: null,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const sessionSlice = createSlice({
  name: "sessionState",
  initialState,
  reducers: {
    updateSessionState(
      state,
      action: PayloadAction<Partial<SessionState> | null>
    ) {
      if (action.payload) {
        state.session =
          action.payload.session === null
            ? null
            : action.payload.session
            ? action.payload.session
            : state.session;
        state.targetDistance =
          action.payload.targetDistance === null
            ? null
            : action.payload.targetDistance
            ? action.payload.targetDistance
            : state.targetDistance;
        state.requestedDistance =
          action.payload.requestedDistance === null
            ? null
            : action.payload.requestedDistance
            ? action.payload.requestedDistance
            : state.requestedDistance;
      } else {
        state.session = initialState.session;
        state.targetDistance = initialState.targetDistance;
        state.requestedDistance = initialState.requestedDistance;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateSessionState = sessionSlice.actions.updateSessionState;

export const selectSessionState = (state: RootState): Partial<SessionState> =>
  state.sessionState;

// Export default the slice reducer
export default sessionSlice.reducer;
