import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { updateUser } from "../../../DataAccess/users";
import {
  selectUserState,
  updateUserState,
} from "../../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createToast } from "../../../utils/toastHelper";
import { CreateSupportTicket } from "../../ui/CreateSupportTicket";
import { CustomToast } from "../../ui/CustomToast";
import { PoolLengthSelector, PoolUnitSelector } from "../../ui/DropDowns";
import Loading from "../../ui/Loading";

const AccountSettingsPool = () => {
  const toast = useToast();
  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();

  const {
    control: controlPool,
    reset: resetPool,
    setValue: setValuePool,
    handleSubmit: handleSubmitPool,
    formState: {
      errors: errorsPool,
      isSubmitting: isSubmittingPool,
      isValid: isValidPool,
      isDirty: isDirtyPool,
    },
  } = useForm({
    defaultValues: {
      poolLength: 50,
      poolUnit: "",
    },
    mode: "all",
  });

  useEffect(() => {
    if (user) {
      setValuePool("poolLength", user.data.basic.defaultPool.lapLength);
      setValuePool("poolUnit", user.data.basic.defaultPool.lapUnit);
    }
  }, [user, setValuePool]);

  const handlePool = async (data: any): Promise<void> => {
    toast.closeAll();
    if (user) {
      try {
        const updatedUser = await updateUser(user?._id, {
          defaultPool: {
            lapLength: parseInt(data.poolLength, 10),
            lapUnit: data.poolUnit,
          },
        });
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Change Pool Settings"}
              status={"Success"}
              toast={toast}
              toastId={props.id}
            >
              <Text>Your pool settings have been changed.</Text>
            </CustomToast>
          );
        });
        dispatch(updateUserState(updatedUser));
        resetPool(data);
      } catch (error: any) {
        if (user) {
          setValuePool("poolLength", user.data.basic.defaultPool.lapLength);
          setValuePool("poolUnit", user.data.basic.defaultPool.lapUnit);
        }
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Change Pool Settings"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Unable to change pool settings, please contact support.
              </Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmitPool(handlePool)}
      w="full"
      alignItems="flex-start"
      mb={5}
    >
      <Heading as="h3" size="md">
        Pool Settings
      </Heading>
      <FormControl pb={1}>
        <FormLabel>
          What length pool do you usually swim in?
          {errorsPool.poolLength && (
            <span className="formError">{errorsPool.poolLength.message}</span>
          )}
          {errorsPool.poolUnit && (
            <span className="formError">{errorsPool.poolUnit.message}</span>
          )}
        </FormLabel>
        <HStack w="full" alignItems="flex-start">
          <Controller
            control={controlPool}
            rules={{ required: "Pool Length is required" }}
            name="poolLength"
            render={({ field: { ref, onChange, ...restField } }) => (
              <PoolLengthSelector
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(e)
                }
                {...restField}
                placeholder=""
              />
            )}
          />
          <Controller
            control={controlPool}
            rules={{ required: "Pool Unit is required" }}
            name="poolUnit"
            render={({ field: { ref, onChange, ...restField } }) => (
              <PoolUnitSelector
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(e)
                }
                {...restField}
                placeholder=""
              />
            )}
          />
        </HStack>
      </FormControl>
      <HStack w="full" pt={2}>
        <Spacer />
        <Button
          isDisabled={!isDirtyPool || !isValidPool}
          isLoading={isSubmittingPool}
          type="submit"
          mt={5}
        >
          Change Pool
        </Button>
      </HStack>
    </VStack>
  );
};

export { AccountSettingsPool };
