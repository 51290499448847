import axios, { AxiosError } from "axios";
interface swimSmoothApiErrorMessage {
  severity: string;
  message: swimSmoothApiErrorMessage;
}
interface swimSmoothApiErrorMessage {
  user: string;
  developer: string;
  field: string;
  identifier: string;
}

const processSwimSmoothApiError = (error: AxiosError | Error): string => {
  let errorString = "Something went wrong";

  if (axios.isAxiosError(error)) {
    // Handle Axios-specific error properties
    if (error.response && error.response.data && error.response.data.messages) {
      errorString = error.response.data.messages.reduce(
        (previous: string, message: swimSmoothApiErrorMessage) => {
          if (message.message.user) {
            return (previous += message.message.user + "\n");
          }
          return previous;
        },
        ""
      );
    } else if (error.message) {
      // If no messages, fall back to Axios error message
      errorString = error.message;
    }
  } else if (error instanceof Error) {
    // Handle general JavaScript errors
    errorString = error.message;
  }

  return errorString
    ? errorString
    : "An error has occurred, please contact support.";
};

export { processSwimSmoothApiError };
