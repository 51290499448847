import {
  Box,
  BoxProps,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { checkRole } from "../../utils/authHelper";
import { dp } from "../../utils/coreHelper";
import { formatDuration } from "../../utils/dateHelper";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import { SingleValueWithUnitDisplay } from "../ui/SingleValueWithUnitDisplay";
import { ActivityDetailSwimLap } from "./ActivityDetailSwimLap";
import { PacingScoreGraph } from "./PacingScoreGraph";

interface ActivityDetailSwimProps extends BoxProps {
  swim: any;
  swimIndex: number;
  lapUnit: string;
}

const ActivityDetailSwim: React.FC<ActivityDetailSwimProps> = ({
  swim,
  swimIndex,
  lapUnit,
}) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );

  const navigate = useNavigate();
  const authHeartRate = checkRole(accessToken, "heartRate", "activity");
  const authSTSS = checkRole(accessToken, "sTSS", "activity");
  const authStrokeRate = checkRole(accessToken, "strokeRate", "activity");
  const authPacingScore = checkRole(accessToken, "executionScore", "activity");

  const [expanded, setExpanded] = useState<boolean>(false);

  const upgradeAction = () => {
    navigate("/account/subscription", { replace: false });
  };

  const sizedTable = useBreakpointValue({
    base: (
      <TableContainer>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th isNumeric></Th>
              <Th isNumeric>Dist.</Th>
              <Th></Th>
              <Th isNumeric>SR</Th>
              <Th>Intensity</Th>
              <Th isNumeric>Split</Th>
            </Tr>
          </Thead>
          <Tbody>
            {swim.laps &&
              swim.laps.map((lap: any, lapIndex: number) => {
                return (
                  <ActivityDetailSwimLap
                    lap={lap}
                    lapIndex={lapIndex}
                    swimIndex={swimIndex}
                  />
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    ),
    md: (
      <TableContainer>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th isNumeric>Lap</Th>
              <Th isNumeric>Distance</Th>
              <Th isNumeric>Total</Th>
              <Th>Stroke</Th>
              <Th>Pace</Th>
              <Th isNumeric>Stroke Rate</Th>
              <Th isNumeric>Split</Th>
              <Th>Intensity</Th>
            </Tr>
          </Thead>
          <Tbody>
            {swim.laps &&
              swim.laps.map((lap: any, lapIndex: number) => {
                return (
                  <ActivityDetailSwimLap
                    lap={lap}
                    lapIndex={lapIndex}
                    swimIndex={swimIndex}
                  />
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    ),
  });

  if (swim.type === "rest" && swim.duration > 0) {
    return (
      <Grid
        key={"swim" + swimIndex}
        templateColumns="repeat(7, 1fr)"
        gap={2}
        mt={4}
        mb={4}
        w="full"
      >
        <GridItem mt={3} colSpan={[2, 2, 3, 3]}>
          <Divider />
        </GridItem>
        <GridItem colSpan={[3, 3, 1, 1]}>
          <Center>{formatDuration(swim.duration, true)} Rest</Center>
        </GridItem>
        <GridItem mt={3} colSpan={[2, 2, 3, 3]}>
          <Divider />
        </GridItem>
      </Grid>
    );
  }
  if (!expanded) {
    return (
      <Grid
        key={"swim" + swimIndex}
        templateColumns={[
          "repeat(4, 1fr)",
          "repeat(4, 1fr)",
          "repeat(9, 1fr)",
          "repeat(9, 1fr)",
        ]}
        gap={3}
        w="full"
      >
        <GridItem>
          <SingleValueWithUnitDisplay
            label="Distance"
            value={swim.distances.total}
            unit={lapUnit}
          />
        </GridItem>
        <GridItem>
          <SingleValueDisplay
            label="Active Time"
            value={formatDuration(swim.timings.breakdown.active, true)}
          />
        </GridItem>
        <GridItem>
          <SingleValueDisplay
            label="sTSS"
            value={
              authSTSS ? (
                dp(swim.stss, 2).toFixed(2)
              ) : (
                <Button onClick={upgradeAction} size={"xs"}>
                  Upgrade
                </Button>
              )
            }
          />
        </GridItem>
        <GridItem>
          <SingleValueDisplay
            label="Intensity"
            value={
              authStrokeRate ? (
                swim.intensity && swim.intensity !== "unknown" ? (
                  swim.intensity
                ) : (
                  "-"
                )
              ) : (
                <Button onClick={upgradeAction} size={"xs"}>
                  Upgrade
                </Button>
              )
            }
          />
        </GridItem>
        <GridItem>
          <SingleValueDisplay
            label="Avg. Heart Rate"
            value={
              authHeartRate ? (
                swim.hr.summary.avg > 0 ? (
                  swim.hr.summary.avg
                ) : (
                  "-"
                )
              ) : (
                <Button onClick={upgradeAction} size={"xs"}>
                  Upgrade
                </Button>
              )
            }
          />
        </GridItem>
        <GridItem>
          <SingleValueWithUnitDisplay
            label="Avg. Pace"
            value={formatDuration(swim.pace.avg, true)}
            unit={"/100" + lapUnit}
          />
        </GridItem>
        <GridItem>
          <SingleValueDisplay
            label="Avg. Stroke Rate"
            value={
              authStrokeRate ? (
                swim.cadence.avg > -1 ? (
                  swim.cadence.avg
                ) : (
                  "-"
                )
              ) : (
                <Button onClick={upgradeAction} size={"xs"}>
                  Upgrade
                </Button>
              )
            }
          />
        </GridItem>
        {authPacingScore && (
          <GridItem>
            <PacingScoreGraph score={swim.execution.score} size={40} />
          </GridItem>
        )}
        <GridItem textAlign="right" colSpan={[4, 4, 1, 1]}>
          <Button onClick={() => setExpanded(true)} w={125} size={"sm"} mr={5}>
            Show Laps
          </Button>
        </GridItem>
      </Grid>
    );
  }
  return (
    <Grid
      key={"swim" + swimIndex}
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(3, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={2}
      mb={4}
      w="full"
    >
      <GridItem>
        <Grid
          key={"swim" + swimIndex}
          templateColumns="repeat(2, 1fr)"
          gap={3}
          w="full"
        >
          <GridItem>
            <SingleValueWithUnitDisplay
              label="Distance"
              value={swim.distances.total}
              unit={lapUnit}
            />
          </GridItem>
          <GridItem>
            <SingleValueDisplay
              label="Active Time"
              value={formatDuration(swim.timings.breakdown.active, true)}
            />
          </GridItem>
          <GridItem>
            <SingleValueDisplay
              label="sTSS"
              value={
                authSTSS ? (
                  dp(swim.stss, 2).toFixed(2)
                ) : (
                  <Button onClick={upgradeAction} size={"xs"}>
                    Upgrade
                  </Button>
                )
              }
            />
          </GridItem>
          <GridItem>
            <SingleValueDisplay
              label="Intensity"
              value={
                authStrokeRate ? (
                  swim.intensity && swim.intensity !== "unknown" ? (
                    swim.intensity
                  ) : (
                    "-"
                  )
                ) : (
                  <Button onClick={upgradeAction} size={"xs"}>
                    Upgrade
                  </Button>
                )
              }
            />
          </GridItem>
          <GridItem>
            <SingleValueDisplay
              label="Avg. Heart Rate"
              value={
                authHeartRate ? (
                  swim.hr.summary.avg > 0 ? (
                    swim.hr.summary.avg
                  ) : (
                    "-"
                  )
                ) : (
                  <Button onClick={upgradeAction} size={"xs"}>
                    Upgrade
                  </Button>
                )
              }
            />
          </GridItem>
          <GridItem>
            <SingleValueWithUnitDisplay
              label="Avg. Pace"
              value={formatDuration(swim.pace.avg, true)}
              unit={"/100" + lapUnit}
            />
          </GridItem>
          {swim.cadence.avg > -1 && (
            <GridItem>
              <SingleValueDisplay
                label="Avg. Stroke Rate"
                value={
                  authStrokeRate ? (
                    swim.cadence.avg > -1 ? (
                      swim.cadence.avg
                    ) : (
                      "-"
                    )
                  ) : (
                    <Button onClick={upgradeAction} size={"xs"}>
                      Upgrade
                    </Button>
                  )
                }
              />
            </GridItem>
          )}
          {authPacingScore && (
            <GridItem>
              <PacingScoreGraph score={swim.execution.score} size={40} />
            </GridItem>
          )}
        </Grid>
      </GridItem>
      <GridItem colSpan={[1, 1, 2, 2]}>
        {sizedTable}
        <Box textAlign="right" mt={4}>
          <Button onClick={() => setExpanded(false)} w={125} size={"sm"} mr={5}>
            Hide Laps
          </Button>
        </Box>
      </GridItem>
    </Grid>
  );
};

export { ActivityDetailSwim };
