import { BoxProps, Button, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { createToast } from "../../utils/toastHelper";
import { CustomToast } from "./CustomToast";

interface ChickenButtonProps extends BoxProps {
  label: string;
  variant?: string;
}

const ChickenButton: React.FC<ChickenButtonProps> = ({
  label,
  variant,
  ...rest
}) => {
  const [chickens, setChickens] = useState<number>(1);
  const toast = useToast();

  const handleChicken = () => {
    toast.closeAll();
    let string: string;
    let level: "Error" | "Warning" | "Success" | "Info" = "Error";
    if (chickens < 5) {
      string =
        "There is a chicken right, it came from an egg ... but where did the egg come from? A chicken obviously ... but where did the chicken comes from?";
      for (let i = 0; i < chickens; i++) {
        if (i % 2 === 1) {
          string +=
            " A chicken obviously ... but where did the chicken come from? ";
          string += " An egg obviously ... but where did the egg come from? ";
          string +=
            " A chicken obviously ... but where did the chicken come from? ";
          string += " An egg obviously ... but where did the egg come from? ";
          string +=
            " A chicken obviously ... but where did the chicken come from? ";
        } else {
          string += " An egg obviously ... but where did the egg come from? ";
          string +=
            " A chicken obviously ... but where did the chicken come from? ";
          string += " An egg obviously ... but where did the egg come from? ";
          string +=
            " A chicken obviously ... but where did the chicken come from? ";
          string += " An egg obviously ... but where did the egg come from? ";
        }
      }
      if (chickens % 2 === 1) {
        string += " A chicken obviously ... but where did ";
      } else {
        string += " An egg obviously ... but where did ";
      }
      string += "(I'll stop there if you stop clicking that button!)";
      level = "Success";
    } else if (chickens === 5) {
      string = "A dinosaur alright ... the chicken evolved from a dinosaur!";
      level = "Info";
    } else if (chickens === 6) {
      string =
        "Okay ... maybe some kind of omnipotent being did it. Honestly I just don't know but ninja punching that button isn't going to help you work it out!";
      level = "Warning";
    } else if (chickens === 7) {
      string = "I'm going to stop listening!";
      level = "Warning";
    } else if (chickens === 8) {
      string = "Please stop. please?";
      level = "Warning";
    } else if (chickens === 9) {
      string = "Bye bye now!";
      level = "Error";
    } else {
      return;
    }
    setChickens(chickens + 1);
    createToast(
      toast,
      (props: any) => {
        return (
          <CustomToast
            title={"Working on it!"}
            status={level}
            toast={toast}
            toastId={props.id}
          >
            <Text>{string}</Text>
          </CustomToast>
        );
      },
      60000
    );
  };

  return (
    <Button
      {...(rest.w && { w: rest.w })}
      {...(variant && { variant: variant })}
      isDisabled={chickens >= 10}
      onClick={handleChicken}
    >
      {label}
    </Button>
  );
};

export { ChickenButton };
