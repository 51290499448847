import { Box, BoxProps, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { activityDataSummaryTimings } from '../../types/activity';

interface ActivityLocationTypeProps extends BoxProps {
  size: number;
  locationType: string;
  timings: activityDataSummaryTimings;
}

const ActivityLocationType: React.FC<ActivityLocationTypeProps> = ({ locationType, size, timings, ...rest }) => {
  const pathColour = useColorModeValue('#FF7A30', '#FF9B63');
  const textColour = useColorModeValue('#000000', '#FFFFFF');
  const trailColour = useColorModeValue('#AAAAAA', '#666666');
  const activePercent = (timings.breakdown.active / timings.total) * 100;

  let locationText = '-';
  if (locationType === 'Pool') {
    locationText = 'Pool';
  } else if (locationType === 'Open') {
    locationText = 'OW';
  } else if (locationType === 'Endless') {
    locationText = 'Endless';
  }

  return (
    <Tooltip hasArrow label="Location & Active vs Rest">
      <Box style={{ width: size, height: size }} {...rest}>
        <CircularProgressbar value={activePercent} text={locationText} strokeWidth={10} styles={buildStyles({ textSize: '25px', strokeLinecap: 'butt', trailColor: trailColour, pathColor: pathColour, textColor: textColour })} />
      </Box>
    </Tooltip>
  );
};

export { ActivityLocationType };
