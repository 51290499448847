import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { Guidance } from "../../components/progress/Guidance";
import { Routine } from "../../components/progress/Routine";
import { Ultimate } from "../../components/progress/Ultimate";
import { Understanding } from "../../components/progress/Understanding";
import { BoxWrapper } from "../../components/ui/BoxWrapper";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import Loading from "../../components/ui/Loading";
import { getTraining, refreshTraining } from "../../DataAccess/training";
import { training } from "../../types/training";
import { checkRole, getLevel } from "../../utils/authHelper";
import { formatDate, formatDateTime } from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";

const ProgressPage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [level, setLevel] = useState<string>();
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();
  const toast = useToast();

  const [loadingTraining, setLoadingTraining] = useState<boolean>(true);
  const [training, setTraining] = useState<training | null>();

  const refreshTrainingLocal = async () => {
    try {
      setLoadingTraining(true);
      const training = await refreshTraining();
      setTraining(training.data);
      setLoadingTraining(false);
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Update user summary"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>{error.message}</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setLoadingTraining(false);
    }
  };

  const getTrainingLocal = async () => {
    try {
      const training = await getTraining();
      setTraining(training.data);
      setLoadingTraining(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/progress", title: "Progress" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (level && level !== "Guidance") {
      console.log("Level", level);
      getTrainingLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  useEffect(() => {
    if (accessToken) {
      setLevel(getLevel(accessToken));
    }
  }, [accessToken]);

  if (!user) {
    return <Loading message="Loading User" />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={2}>
        Hey {user ? user.data.basic.firstName : null}, welcome to Swim Smooth!
      </Heading>
      {level && level !== "Guidance" && (
        <SimpleGrid columns={[1, 1, 2, 2]} columnGap={5} rowGap={2} w={"full"}>
          <Box
            pt={3}
            textAlign={["center", "center", "left", "left"]}
            justifyContent={["center", "center", "left", "left"]}
          >
            {training && training.complete && training.generated && (
              <Text>
                <b>Summary Last Updated:</b>{" "}
                {formatDateTime(training.generated)}
                <Icon
                  ml={1}
                  as={IoMdRefresh}
                  cursor={"pointer"}
                  color={loadingTraining ? "grey" : "green"}
                  onClick={refreshTrainingLocal}
                />
              </Text>
            )}
            {training &&
              training.hasOwnProperty("complete") &&
              !training.complete &&
              training.generated && (
                <Text>
                  <b>Summary Last Updated:</b> Generating (started:{" "}
                  {formatDateTime(training.generated)})
                </Text>
              )}
            {training && !training.hasOwnProperty("complete") && (
              <Text>
                <b>Summary Last Updated:</b> {formatDate(training.date)}
              </Text>
            )}
          </Box>
          <HStack justifyContent={["center", "center", "right", "right"]}>
            {checkRole(accessToken, "create", "activity") && (
              <Button
                onClick={() => {
                  navigate("/activities/upload/fit", { replace: false });
                }}
              >
                Upload FIT File
              </Button>
            )}
            {checkRole(accessToken, "create", "activity") && (
              <Button
                onClick={() => {
                  navigate("/activities/create", { replace: false });
                }}
              >
                Create Activity
              </Button>
            )}
          </HStack>
        </SimpleGrid>
      )}
      {loadingTraining && level && level !== "Guidance" && (
        <BoxWrapper>
          <Heading mb={5} size="md" color={"red"}>
            Building User Summaries
          </Heading>
          <Text>
            We're busy in the background generating your up to date user
            summaries. This is usually a quick process but can take several
            minutes on larger accounts. You can continue to use the Guru as
            normal whilst your user summaries data builds.
          </Text>
        </BoxWrapper>
      )}
      {level === "Ultimate" && <Ultimate training={training} />}
      {level === "Routine" && <Routine training={training} />}
      {level === "Understanding" && <Understanding training={training} />}
      {level === "Guidance" && <Guidance training={training} />}
    </VStack>
  );
};

export { ProgressPage };
