import {
  BoxProps,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { YourCoach } from "../../components/coaches/YourCoach";
import Loading from "../../components/ui/Loading";
import { training } from "../../types/training";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { BoxWrapper } from "../ui/BoxWrapper";

interface GuidanceProps extends BoxProps {
  training: training | null | undefined;
}

const Guidance: React.FC<GuidanceProps> = ({ training }) => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/progress", title: "Progress" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Loading User" />;
  }

  return (
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(12, 1fr)",
        "repeat(12, 1fr)",
      ]}
      gap={2}
    >
      <GridItem colSpan={[1, 1, 3, 3]}>
        <YourCoach />
      </GridItem>
      <GridItem colSpan={[1, 1, 9, 9]}>
        <BoxWrapper>
          <Heading mb={5} size="md">
            The Power & Insight of Swim Smooth coaching at your fingertips.
          </Heading>
          <Text mb={2}>
            The Swim Smooth GURU is your friendly, smart, step-by-step training
            program designed to <em>Guide</em> you, build your{" "}
            <em>Understanding</em>, help you establish your daily swimming
            <em>Routine</em> remotely, and give you the <em>Ultimate</em> swim
            coaching experience whoever and wherever you are with our team of
            real-life, human coaches. Access anytime, anywhere, & using the
            smart device you already own.
          </Text>
          <Text>
            Hey there {user.data.basic.firstName}, and welcome to the Swim
            Smooth Guru. You're currently using the always free{" "}
            <em>Guidance</em> version of the Guru. Have a look at upgrading to
            gain access to our full suite of tools.
          </Text>
          <Center w={"full"} mt={3} mb={2}>
            <Button
              onClick={() => navigate("/pricing", { replace: false })}
              size={"lg"}
            >
              Upgrade Now
            </Button>
          </Center>
          <Heading as={"h3"} size={"md"} mt={3} mb={2}>
            Stroke Insights&#8471;
          </Heading>
          <Text>
            Utilize tools like Stroke Insights&#8471; (soon on both Apple &
            Garmin), CSS Tweaking and Intelligent Session Scaling to identify
            areas for improvement and assign goals which are specific,
            measurable, attainable, relevant and time based. The GURU offers
            personalized feedback to help you refine your technique and swim
            specific fitness to achieve your swimming goals more quickly.
          </Text>
          <Heading as={"h3"} size={"md"} mt={3} mb={2}>
            Training Plans
          </Heading>
          <Text>
            Tailored to your Swim Type and goals, the GURU’s training plans
            adapt as you improve, ensuring your workouts remain challenging and
            effective. It’s like having a personal coach who evolves with you,
            offering precise direction and support.
          </Text>
          <Heading as={"h3"} size={"md"} mt={3} mb={2}>
            Articles & Videos
          </Heading>
          <Text>
            With its comprehensive library of expertly curated articles &
            videos, the GURU offers focused, in-depth tutorials that cover every
            aspect of swimming technique. Unlike the distractions of YouTube,
            our systemized collection provides clear, actionable insights,
            enabling you to make immediate and impactful improvements in your
            stroke without the hassle of sifting through unrelated content.
          </Text>
          <Heading as={"h3"} size={"md"} mt={3} mb={2}>
            Swim Types
          </Heading>
          <Text>
            Developed from our world-renowned Swim Smooth coaching methods, the
            GURU stands out by integrating our unique Swim Types system. This
            approach delivers personalized advice and training tailored
            specifically to your swimming style, ensuring you maximize both
            efficiency and enjoyment in every session. Unlike other programs,
            the GURU adapts to you, making your swimming experience truly
            individualized and effective.
          </Text>
          <Heading as={"h3"} size={"md"} mt={3} mb={2}>
            Coaching
          </Heading>
          <Text>
            Stay motivated with structured plans and progress tracking, designed
            to keep you focused and committed to your swimming journey. The GURU
            provides the framework and inspiration you need to reach new heights
            in your swimming performance.
          </Text>
        </BoxWrapper>
      </GridItem>
    </Grid>
  );
};

export { Guidance };
