import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";

const disconnectGarmin = async (): Promise<void> => {
  try {
    await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "garmin/disconnect",
      {}
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const getGarminRequestToken = async (): Promise<string> => {
  try {
    const apiResponse = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "garmin/request-token"
    );
    return apiResponse.data.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const setGarminAccessToken = async (
  userId: string,
  token: string,
  verifier: string
): Promise<void> => {
  try {
    await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "garmin/access-token",
      {
        userId: userId,
        verifier: verifier,
        token: token,
      }
    );
  } catch (error: any) {
    console.log(error);
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

export { disconnectGarmin, getGarminRequestToken, setGarminAccessToken };
