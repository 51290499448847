import { Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { AccountSettingsPool } from "../../components/account/settings/AccountSettingsPool";
import { SwimmerSettingsSwimming } from "../../components/account/settings/SwimmerSettingsSwimming";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { breadcrumbLink } from "../../types/breadcrumb";

const SwimmerSettingsPage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/account", title: "Account" });
    breadcrumbLinks.push({ href: "/account/settings", title: "Settings" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Swimmer Settings
      </Heading>
      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        spacing={10}
        p={{ sm: 10, md: 0 }}
        w="full"
      >
        <VStack>
          <AccountSettingsPool />
        </VStack>
        <SwimmerSettingsSwimming />
      </SimpleGrid>
    </VStack>
  );
};

export { SwimmerSettingsPage };
