import { auditSummary } from "./core";

export interface coach {
  id: string;
  auditSummary: auditSummary;
  data: coachData;
}

export interface coachData {
  tags: string[];
  user: string;
  shortCode: string;
  firstName: string;
  lastName: string;
  displayName: string;
  brandName: string;
  brandLocation: string;
  region: "uk" | "europe" | "americas" | "asiaoceania" | "africa" | "other";
  distanceLocation: [number, number];
  description: string;
  images: coachImage[];
  locations: coachLocation[];
  services: string[];
  // categorisation
  certified: boolean;
  regional: boolean;
  global: boolean;
  // Optional
  mainWebsite?: coachUrl;
  bookingWebsite?: coachUrl;
  email?: string;
  phone?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  tiktok?: string;
  linkedin?: string;
  // Generated
  distanceFromYou?: number;
  me?: boolean;
  myCoach?: boolean;
}

export interface createCoach {
  firstName: string;
  lastName: string;
  region: "uk" | "europe" | "americas" | "asiaoceania" | "africa";
  shortCode: string;
  // Optional
  user?: string;
}

export interface updateCoach {
  firstName: string;
  lastName: string;
  displayName: string;
  brandName: string;
  brandLocation: string;
  region: "uk" | "europe" | "americas" | "asiaoceania" | "africa";
  // Optional
  description?: string;
  distanceLocation?: [number, number];
  mainWebsite?: string;
  bookingWebsite?: string;
  email?: string;
  phone?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  tiktok?: string;
  linkedin?: string;
}

export interface coachUrl {
  url: string;
  display: string;
}

export interface coachImage {
  src: string;
  focalPoint?: string;
  alt?: string;
  caption?: string;
}

export interface coachLocation {
  id: string;
  type: "pool" | "endlesspool" | "openwater" | "office" | "other";
  name: string;
  description: string;
  w3w: string;
  ll: [number, number];
}

export interface coachSession {
  title: string;
  slots: coachSessionSlots[];
  description: string;
  bookingLink?: string;
}

export interface coachSessionSlots {
  day: number;
  startTime: number;
  duration: number;
  location: string;
}

export const servicesOptions = [
  {
    label: "Video Analysis & Stroke Correction",
    value: "Video Analysis & Stroke Correction",
  },
  { label: "Squad Sessions", value: "Squad Sessions" },
  { label: "Squad Sessions (Pool)", value: "Squad Sessions (Pool)" },
  {
    label: "Squad Sessions (Open Water)",
    value: "Squad Sessions (Open Water)",
  },
  { label: "1 to 1 Sessions", value: "1 to 1 Sessions" },
  { label: "1 to 1 Sessions (Pool)", value: "1 to 1 Sessions (Pool)" },
  {
    label: "1 to 1 Sessions (Open Water)",
    value: "1 to 1 Sessions (Open Water)",
  },
  { label: "Group Sessions", value: "Group Sessions" },
  { label: "Group Sessions (Pool)", value: "Group Sessions (Pool)" },
  {
    label: "Group Sessions (Open Water)",
    value: "Group Sessions (Open Water)",
  },
  { label: "Swim Training Camps", value: "Swim Training Camps" },
  { label: "Triathlon Training Camps", value: "Triathlon Training Camps" },
  { label: "Triathlon Coaching", value: "Triathlon Coaching" },
  { label: "Learn to Swim", value: "Learn to Swim" },
  { label: "Swim Clinics", value: "Swim Clinics" },
  { label: "Open Water Workshops", value: "Open Water Workshops" },
  { label: "Coach Education", value: "Coach Education" },
  { label: "Junior Programme", value: "Junior Programme" },
  { label: "Swim Holidays", value: "Swim Holidays" },
  { label: "Online Video Analysis", value: "Online Video Analysis" },
];
