import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { activity } from "../../../types/activity";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface ActivityState {
  activity: activity | null;
}

// Define an initial state
const initialState: ActivityState = {
  activity: null,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const activitySlice = createSlice({
  name: "activityState",
  initialState,
  reducers: {
    updateActivityState(state, action: PayloadAction<activity | null>) {
      if (action.payload) {
        state.activity = action.payload;
      } else {
        state.activity = initialState.activity;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateActivityState } = activitySlice.actions;

export const selectActivityState = (state: RootState): ActivityState => {
  return state.activityState;
};

// Export default the slice reducer
export default activitySlice.reducer;
