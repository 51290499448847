import { Heading, Text, VStack } from "@chakra-ui/react";

interface AffiliateNotSetupProps {}

const AffiliateNotSetup: React.FC<AffiliateNotSetupProps> = () => {
  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Affiliate / Event Profile Not Configured
      </Heading>
      <Text>
        Your affiliate / event profile is not configured. You need to book an
        appiontment with Jon to get this done. Please head to{" "}
        <a
          href={"https://calendly.com/allarsj/swim-smooth-meet-with-jon-allars"}
          target={"_blank"}
          rel="noreferrer"
        >
          https://calendly.com/allarsj/swim-smooth-meet-with-jon-allars
        </a>{" "}
        to book a meeting with Jon to sort this.
      </Text>
    </VStack>
  );
};

export { AffiliateNotSetup };
