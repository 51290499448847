import { BoxProps } from "@chakra-ui/react";

interface StravaLogoProps extends BoxProps {
  height: number;
  width: number;
  colour?: string;
  opacity?: number;
}

const StravaLogo: React.FC<StravaLogoProps> = ({ height, width, colour, opacity }) => {
  if (!colour) {
    colour = "#002140";
  }
  if (!opacity) {
    opacity = 1;
  }
  return (
    <svg width={width} height={height} viewBox="0 0 95.3 41.39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          fill={colour}
          d="M14.62,34.11a14.15,14.15,0,0,1-4.31-.64,9.54,9.54,0,0,1-3.44-1.91l2.7-3.21a8,8,0,0,0,2.59,1.36,9.31,9.31,0,0,0,2.7.41,2.13,2.13,0,0,0,1-.17,0.53,0.53,0,0,0,.3-0.47v0a0.63,0.63,0,0,0-.44-0.54,7.69,7.69,0,0,0-1.65-.45q-1.27-.26-2.43-0.61a8.35,8.35,0,0,1-2-.88,4.27,4.27,0,0,1-1.39-1.36,3.69,3.69,0,0,1-.52-2v0a4.78,4.78,0,0,1,.42-2,4.57,4.57,0,0,1,1.23-1.62,5.85,5.85,0,0,1,2-1.08,8.9,8.9,0,0,1,2.75-.39A12.87,12.87,0,0,1,18,19a9.18,9.18,0,0,1,3,1.55l-2.46,3.41a7.57,7.57,0,0,0-2.28-1.13,7.93,7.93,0,0,0-2.26-.36,1.56,1.56,0,0,0-.83.17,0.51,0.51,0,0,0-.27.45v0a0.62,0.62,0,0,0,.41.52,7,7,0,0,0,1.6.45,22.37,22.37,0,0,1,2.64.62,7.8,7.8,0,0,1,2,.94A4.16,4.16,0,0,1,20.83,27a3.81,3.81,0,0,1,.46,1.94v0a4.69,4.69,0,0,1-.48,2.14,4.57,4.57,0,0,1-1.34,1.61,6.35,6.35,0,0,1-2.09,1A9.87,9.87,0,0,1,14.62,34.11Z"
        />
        <path fill={colour} d="M25.46,23H21V18.72H35V23H30.51V33.83H25.46V23Z" />
        <path
          fill={colour}
          d="M35.67,18.72H43a10.1,10.1,0,0,1,3.33.46,5.54,5.54,0,0,1,2.1,1.26,4.61,4.61,0,0,1,1,1.55,5.48,5.48,0,0,1,.35,2v0a4.77,4.77,0,0,1-.8,2.8,5.5,5.5,0,0,1-2.18,1.81l3.52,5.14H44.64l-2.85-4.32H40.72v4.32H35.67V18.72Zm7.23,7.19a2.32,2.32,0,0,0,1.42-.39,1.28,1.28,0,0,0,.52-1.08v0a1.23,1.23,0,0,0-.52-1.09,2.44,2.44,0,0,0-1.4-.36h-2.2v3H42.9Z"
        />
        <polygon fill={colour} points="79.61 27.33 82.91 33.83 87.75 33.83 79.61 17.77 71.48 33.83 76.32 33.83 79.61 27.33" />
        <polygon fill={colour} points="56.98 27.33 60.27 33.83 65.11 33.83 56.98 17.77 48.85 33.83 53.69 33.83 56.98 27.33" />
        <polygon fill={colour} points="68.3 25.22 65.01 18.72 60.17 18.72 68.3 34.77 76.43 18.72 71.59 18.72 68.3 25.22" />
        <path
          fill={colour}
          d="M10.87,10.95l1,0.2a2.42,2.42,0,0,1-.69,1.53,2.08,2.08,0,0,1-1.53.58,2.24,2.24,0,0,1-1.74-.74,3.21,3.21,0,0,1-.68-2.22,3,3,0,0,1,.71-2.12,2.26,2.26,0,0,1,1.7-.75A2.1,2.1,0,0,1,11.11,8a2.23,2.23,0,0,1,.7,1.44l-1,.15A1.23,1.23,0,0,0,9.65,8.29a1.28,1.28,0,0,0-1,.5,2.35,2.35,0,0,0-.41,1.52,2.51,2.51,0,0,0,.4,1.56,1.26,1.26,0,0,0,1,.52Q10.7,12.39,10.87,10.95Z"
        />
        <path
          fill={colour}
          d="M16.46,13.09a2.51,2.51,0,0,1-1.8,0,2.23,2.23,0,0,1-1.32-1.44,4.25,4.25,0,0,1-.2-1.36,3,3,0,0,1,.71-2.12,2.26,2.26,0,0,1,1.7-.75,2.24,2.24,0,0,1,1.69.76A3,3,0,0,1,18,10.3a4.22,4.22,0,0,1-.2,1.36,2.33,2.33,0,0,1-.54.93A2.25,2.25,0,0,1,16.46,13.09Zm-1.93-1.22a1.29,1.29,0,0,0,2.06,0A2.56,2.56,0,0,0,17,10.3a2.34,2.34,0,0,0-.41-1.49,1.28,1.28,0,0,0-2,0,2.33,2.33,0,0,0-.41,1.48A2.56,2.56,0,0,0,14.53,11.87Z"
        />
        <path fill={colour} d="M19.6,13.14V7.54h1.1l1.68,3.9h0l1.66-3.9h1.09v5.6H24.2V9.29h0L22.5,13.14H22.26L20.57,9.29h0v3.85H19.6Z" />
        <path
          fill={colour}
          d="M27.1,13.14V7.54h2.23A2,2,0,0,1,30.72,8a1.65,1.65,0,0,1,.53,1.3,1.65,1.65,0,0,1-.51,1.28,2,2,0,0,1-1.39.47H28v2.08H27.1Zm0.94-3h1.27A1,1,0,0,0,30,9.95a0.81,0.81,0,0,0,.27-0.64A0.81,0.81,0,0,0,30,8.63a1.1,1.1,0,0,0-.69-0.22H28v1.78Z"
        />
        <path fill={colour} d="M35.47,13.14l-0.34-1H32.87l-0.33,1H31.61l2-5.6h0.77l2,5.6H35.47ZM34,8.91l-0.85,2.46h1.7L34,8.91h0Z" />
        <path fill={colour} d="M38.24,13.14V8.41H36.57V7.54h4.28V8.41H39.19v4.73H38.24Z" />
        <path fill={colour} d="M42.29,13.14V7.54h0.94v5.6H42.29Z" />
        <path
          fill={colour}
          d="M45.23,13.14V7.54h2.3A1.65,1.65,0,0,1,48.73,8,1.41,1.41,0,0,1,49.18,9a1.17,1.17,0,0,1-.65,1.08v0a1.6,1.6,0,0,1,.6.48,1.3,1.3,0,0,1,.25.82q0,1.71-2.06,1.71h-2.1Zm0.94-3.3h1.23A0.93,0.93,0,0,0,48,9.65a0.64,0.64,0,0,0,.22-0.52A0.66,0.66,0,0,0,48,8.59a0.94,0.94,0,0,0-.62-0.18H46.18V9.83Zm0,2.43h1.4a1.7,1.7,0,0,0,.34-0.06,0.7,0.7,0,0,0,.28-0.14,0.72,0.72,0,0,0,.17-0.25,1,1,0,0,0,.07-0.39,0.66,0.66,0,0,0-.31-0.65,2.16,2.16,0,0,0-1-.16h-1v1.65Z"
        />
        <path fill={colour} d="M51,13.14V7.54H52v4.73h2.35v0.87H51Z" />
        <path fill={colour} d="M55.76,13.14V7.54h3.49V8.41H56.7V9.85H59v0.84H56.7v1.58h2.54v0.87H55.76Z" />
        <path fill={colour} d="M64.28,13.14l-1.07-5.6h1l0.76,3.94h0l1.33-3.94h0.54l1.32,3.94h0L68.9,7.54h0.94l-1.07,5.6h-1L66.53,9.3h0l-1.24,3.84h-1Z" />
        <path fill={colour} d="M71.45,13.14V7.54h0.94v5.6H71.45Z" />
        <path fill={colour} d="M75.5,13.14V8.41H73.83V7.54h4.28V8.41H76.45v4.73H75.5Z" />
        <path fill={colour} d="M79.51,13.14V7.54h0.94v2.3h2.11V7.54h0.94v5.6H82.57V10.66H80.46v2.48H79.51Z" />
      </g>
    </svg>
  );
};

export { StravaLogo };
