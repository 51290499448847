import { BoxProps, Heading, Text } from "@chakra-ui/react";
import { selectUserState } from "../../../app/features/user/userSlice";
import { useAppSelector } from "../../../app/hooks";
import { billingTrial } from "../../../types/billing";
import { formatDateLong } from "../../../utils/dateHelper";

interface TrialDetailsProps extends BoxProps {
  trial: billingTrial | undefined;
}

const TrialDetails: React.FC<TrialDetailsProps> = ({ trial }) => {
  const { user } = useAppSelector(selectUserState);
  if (trial && user?.data.billing.source === "None") {
    let activeRoutine = false,
      activeUnderstanding = false;
    const now = new Date().getTime();
    const routineEndDate = trial.routineEndDate
      ? new Date(trial.routineEndDate).getTime()
      : null;
    const understandingEndDate = trial.understandingEndDate
      ? new Date(trial.understandingEndDate).getTime()
      : null;
    if (routineEndDate && routineEndDate > now) {
      activeRoutine = true;
    }
    if (understandingEndDate && understandingEndDate > now) {
      activeUnderstanding = true;
    }
    return (
      <>
        <Heading as="h3" size="md">
          Your Free Trial
        </Heading>
        <Text>
          {activeRoutine && activeUnderstanding && (
            <Text as="span">
              {" "}
              You have 'Routine' access until{" "}
              {formatDateLong(trial.routineEndDate)} after which you'll drop{" "}
              <em>down</em> to 'Understanding' access until{" "}
              {formatDateLong(trial.understandingEndDate)}.
            </Text>
          )}
          {activeRoutine && !activeUnderstanding && (
            <Text as="span">
              {" "}
              You have 'Routine' access until{" "}
              {formatDateLong(trial.routineEndDate)}.
            </Text>
          )}
          {!activeRoutine && activeUnderstanding && (
            <Text as="span">
              {" "}
              You have 'Understanding' access until{" "}
              {formatDateLong(trial.understandingEndDate)}.
            </Text>
          )}
          {(activeRoutine || activeUnderstanding) && (
            <Text as="span">
              {" "}
              Once your trial has expired you'll drop <em>down</em> to our basic
              'Guidance' level.
            </Text>
          )}
          {!activeRoutine && !activeUnderstanding && (
            <Text as="span">
              {" "}
              Your free trial has expired you'll always have our basic
              'Guidance' level access.
            </Text>
          )}
          <Text>
            Your free trial{" "}
            {activeRoutine || activeUnderstanding ? "is" : "was"} provided by{" "}
            <em>{trial.provider}</em>.
          </Text>
        </Text>
      </>
    );
  }
};

export { TrialDetails };
