import {
  Box,
  BoxProps,
  Button,
  Center,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getActivitiesForLinking } from "../../DataAccess/activities";
import {
  selectActivitiesForLinkingState,
  updateActivitiesForLinkingState,
} from "../../app/features/activity/activitiesForLinkingSlice";
import { loadSize } from "../../app/features/activity/activitiesSlice";
import { updateActivityState } from "../../app/features/activity/activitySlice";
import { selectSessionState } from "../../app/features/session/sessionSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { activity } from "../../types/activity";
import Loading from "../ui/Loading";
import { ActivityListItemForLinking } from "./ActivityListItemForLinking";

interface ActivityLinkProps extends BoxProps {}

const ActivityLink: React.FC<ActivityLinkProps> = () => {
  const [search] = useSearchParams();
  const sessionId = search.get("sessionId");
  const goalSessionId = search.get("goalSessionId");
  const [loadingLinkable, setLoadingLinkable] = useState<boolean>(true);
  const { session } = useAppSelector(selectSessionState);

  const { user } = useAppSelector(selectUserState);

  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { activities, lastActivity, lastLoad } = useAppSelector(
    selectActivitiesForLinkingState
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getActivitiesLocal = async () => {
      const { activities, last } = await getActivitiesForLinking();
      dispatch(
        updateActivitiesForLinkingState({
          lastActivity: last,
          lastLoad: activities.length,
          activities: activities,
        })
      );
      setLoadingLinkable(false);
    };
    getActivitiesLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextPage = async () => {
    setLoadingMore(true);
    dispatch(updateActivityState(null));
    const { activities: newActivities, last } = await getActivitiesForLinking(
      lastActivity
    );
    const combinedActivities = [...activities, ...newActivities];
    dispatch(
      updateActivitiesForLinkingState({
        lastActivity: last,
        lastLoad: newActivities.length,
        activities: combinedActivities,
      })
    );
    setLoadingLinkable(false);
    setLoadingMore(false);
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (loadingLinkable) {
    return <Loading message="Loading linkable activities" />;
  }

  if (!session) {
    return <Loading message="Error Loading Session" />;
  }

  return (
    <VStack alignItems="flex-start" w="full">
      <Heading as="h2" size="xl" mb={4}>
        Link Activity
      </Heading>
      {sessionId && session && sessionId === session.id && (
        <Box
          bg="seagreen"
          w="full"
          px={5}
          py={2}
          alignItems="center"
          borderRadius={5}
          mb={4}
        >
          <Text>
            Linking to {session.data.scaled.distance}
            {session.data.unit} session &#8220;{session.data.name}&#8221;{" "}
            {goalSessionId && " from your training plan"}
          </Text>
        </Box>
      )}
      <Box w="full">
        {activities.map((activity: activity) => {
          return (
            <ActivityListItemForLinking
              key={activity.id}
              userId={user._id}
              sessionId={session.id}
              goalSessionId={goalSessionId ? goalSessionId : ""}
              activity={activity}
              targetDistance={session.data.scaled.distance}
            />
          );
        })}
        {lastLoad === loadSize && (
          <Center>
            <Button isLoading={loadingMore} onClick={nextPage}>
              Load More
            </Button>
          </Center>
        )}
      </Box>
    </VStack>
  );
};

export { ActivityLink };
