import {
  Box,
  BoxProps,
  HStack,
  Icon,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { TiTick } from "react-icons/ti";
import { trainingTimelineDataWeekDataSession } from "../../types/trainingTimeline";

interface ThisWeekSessionProps extends BoxProps {
  session: trainingTimelineDataWeekDataSession;
  lapUnit: string;
}

const ThisWeekEvent: React.FC<ThisWeekSessionProps> = ({
  session,
  lapUnit,
}) => {
  const goalColorMode = useColorModeValue(
    "ssNeonGreenDark",
    "ssNeonGreenLight"
  );

  return (
    <HStack mb={4}>
      <Box h="160px" w="160px" mr={3}>
        <Image
          borderRadius={5}
          src={session.image}
          h="160px"
          w="160px"
          mr={2}
          opacity={session.complete ? 0.25 : 1}
        />
        {session.complete && (
          <VStack
            mt="-160px"
            h="160px"
            w="160px"
            borderRadius={5}
            alignItems="flex-start"
          >
            <Icon as={TiTick} color="green" h="40px" w="40px" />
          </VStack>
        )}
        {!session.complete && (
          <VStack mt="-160px" h="160px" w="160px">
            {/* <!-- Spanner --> */}
          </VStack>
        )}
      </Box>
      <VStack w="full" alignItems="full">
        <Text color={goalColorMode}>
          <b>{session.title}</b>
        </Text>
        <Text lineHeight="none">{session.name}</Text>
      </VStack>
    </HStack>
  );
};

export { ThisWeekEvent };
