import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { coachAthlete } from "../types/coachAthlete";

const createCoachAthlete = async (
  recipientName: string,
  recipientEmailAddress: string,
  routineDays: number,
  understandingDays: number,
  upgradeToPremiumMailingList: string
): Promise<coachAthlete> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "coachathletes";
    const postData = {
      recipientName: recipientName,
      recipientEmailAddress: recipientEmailAddress,
      routineDays: routineDays,
      understandingDays: understandingDays,
      upgradeToPremiumMailingList: upgradeToPremiumMailingList,
    };
    const coachAthletesResponse = await axios.post(url, postData);
    return coachAthletesResponse.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const changeCoachAthleteTrial = async (
  coachAthleteId: string,
  level: string,
  addDays: number
): Promise<coachAthlete> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "coachathletes/trial";
    const postData = {
      coachAthleteId: coachAthleteId,
      level: level,
      addDays: addDays,
    };
    const coachAthletesResponse = await axios.patch(url, postData);
    return coachAthletesResponse.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const deleteCoachAthlete = async (coachAthleteId: string): Promise<void> => {
  try {
    let url =
      process.env.REACT_APP_API_ROOT_URL + "coachathletes/" + coachAthleteId;
    await axios.delete(url);
    return;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const getCoachAthletesForUser = async (
  userId: string
): Promise<coachAthlete[]> => {
  try {
    let url =
      process.env.REACT_APP_API_ROOT_URL + "coachathletes/user/" + userId;
    const coachAthletesResponse = await axios.get(url);
    return coachAthletesResponse.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

export {
  changeCoachAthleteTrial,
  createCoachAthlete,
  deleteCoachAthlete,
  getCoachAthletesForUser,
};
