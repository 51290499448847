import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { affiliate, affiliateUpdate } from "../types/affiliate";

const getAffiliateById = async (
  affiliateId: string
): Promise<affiliate | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "affiliates/id/" + affiliateId
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const getAffiliateByIdExtended = async (
  affiliateId: string
): Promise<affiliate | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL +
        "affiliates/id/" +
        affiliateId +
        "/extended"
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const getAffiliateByUserId = async (
  userId: string
): Promise<affiliate | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "affiliates/userid/" + userId
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const updateAffiliate = async (
  affiliateId: string,
  affiliateData: affiliateUpdate
): Promise<affiliate | null> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "affiliates/" + affiliateId,
      affiliateData
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const acceptAffiliation = async (affiliateId: string): Promise<void> => {
  try {
    let url =
      process.env.REACT_APP_API_ROOT_URL + "affiliates/accept/" + affiliateId;
    await axios.patch(url);
    return;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

export {
  acceptAffiliation,
  getAffiliateById,
  getAffiliateByIdExtended,
  getAffiliateByUserId,
  updateAffiliate,
};
