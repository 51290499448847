import { BoxProps, Button } from "@chakra-ui/react";

interface CreateSupportTicketProps extends BoxProps {}

const CreateSupportTicket: React.FC<CreateSupportTicketProps> = ({
  ...rest
}) => {
  return (
    <Button
      onClick={() => {
        window
          .open("https://swimsmooth.freshdesk.com/support/tickets/new")
          ?.focus();
      }}
    >
      Create Support Ticket
    </Button>
  );
};

export { CreateSupportTicket };
