import {
  Box,
  BoxProps,
  Button,
  Center,
  Divider,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { getWbssHistory } from "../../DataAccess/sessions";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  loadSize,
  selectWbssWeeksState,
  updateWbssWeeksState,
} from "../../app/features/session/wbssWeeksSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { wbssSession, wbssWeek } from "../../types/session";
import { checkRole } from "../../utils/authHelper";
import { formatDateLong, formatDateTime } from "../../utils/dateHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import Loading from "../ui/Loading";
import { WorldsBiggestSwimSquadListItem } from "./WorldsBiggestSwimSquadListItem";

interface WorldsBiggestSwimSquadArchiveProps extends BoxProps {}

const WorldsBiggestSwimSquadArchive: React.FC<
  WorldsBiggestSwimSquadArchiveProps
> = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const { weeks, lastUpdated, lastWeek, lastLoad } =
    useAppSelector(selectWbssWeeksState);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const dividerColorMode = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  const dispatch = useAppDispatch();

  const getWbssHistoryLocal = async (lastWeekId?: string) => {
    try {
      const { weeks: newWeeks, last } = await getWbssHistory(lastWeekId);
      if (lastWeekId) {
        dispatch(
          updateWbssWeeksState({
            lastWeek: last,
            lastLoad: weeks.length,
            weeks: [...weeks, ...newWeeks],
          })
        );
      } else {
        dispatch(
          updateWbssWeeksState({
            lastWeek: last,
            lastLoad: newWeeks.length,
            weeks: newWeeks,
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    if (lastUpdated) {
      const test = new Date(lastUpdated).getTime();
      const aDayAgo = new Date().getTime() - 1000 * 60 * 60 * 24;
      if (!weeks || weeks.length === 0 || aDayAgo > test) {
        getWbssHistoryLocal();
      } else {
        setLoading(false);
      }
    } else {
      getWbssHistoryLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextPage = async () => {
    setLoadingMore(true);
    await getWbssHistoryLocal(lastWeek);
  };

  if (!checkRole(accessToken, "archive", "wbss")) {
    return (
      <NotAuthorised
        functionText={"Worlds Biggest Swim Squad Archive"}
        size={"full"}
      />
    );
  }

  if (loading) {
    return (
      <VStack w={"full"}>
        <Heading mb={5}>The Worlds Biggest Swim Squad Archive</Heading>
        <Loading message="Loading The Worlds Biggest Swim Squad Archive" />
      </VStack>
    );
  }

  return (
    <VStack w={"full"}>
      <Heading mb={5}>The Worlds Biggest Swim Squad</Heading>
      <SimpleGrid columns={[1, 1, 2, 2]} columnGap={5} rowGap={2} w={"full"}>
        <Box
          pt={3}
          textAlign={["center", "center", "left", "left"]}
          justifyContent={["center", "center", "left", "left"]}
        >
          <Text>
            <b>Last Updated:</b> {formatDateTime(lastUpdated)}
          </Text>
        </Box>
        <HStack justifyContent={["center", "center", "right", "right"]}>
          <Button
            variant="success"
            onClick={() => {
              getWbssHistoryLocal();
            }}
            isLoading={loading}
          >
            <Icon as={IoMdRefresh} />
          </Button>
        </HStack>
      </SimpleGrid>
      <Text mb={3}>
        At least three sessions each week that have been recently swum in one of
        our Swim Smooth Squads around the world so even if you cannot attend one
        of our coaches Swim Smooth squads you can be a part of the global Swim
        Smooth Squad ... surely the biggest swim squad in the world?
      </Text>
      {weeks.map((w: wbssWeek, index: number) => {
        return (
          <Box key={index} alignItems={"flex-start"} w={"full"}>
            <Heading as={"h3"} size={"md"}>
              Week Commencing:{" "}
              <Box fontWeight={"light"} as={"span"}>
                {formatDateLong(w.data.commencing)}
              </Box>
            </Heading>
            {w.data.intro && <Text>{w.data.intro}</Text>}
            <SimpleGrid
              columns={[1, 1, 3, 3]}
              gap={[2, 2, 4, 4]}
              w="full"
              mt={5}
            >
              {w.data.sessions.map((s: wbssSession) => {
                return (
                  <WorldsBiggestSwimSquadListItem
                    key={s.sessionId}
                    session={s}
                  />
                );
              })}
            </SimpleGrid>
            <Divider
              my={5}
              size={"xl"}
              borderWidth={"1px"}
              borderColor={dividerColorMode}
            />
          </Box>
        );
      })}
      {lastLoad === loadSize && (
        <Center>
          <Button isLoading={loadingMore} onClick={nextPage}>
            Load More
          </Button>
        </Center>
      )}
      {lastLoad !== loadSize && (
        <Center>
          <Text>All Worlds Biggest Swim Squad Weeks Loaded</Text>
        </Center>
      )}
    </VStack>
  );
};

export { WorldsBiggestSwimSquadArchive };
