import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";

const getForgottenPassword = async (
  email: string,
  hToken: string
): Promise<void> => {
  try {
    await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/forgot/get",
      {
        email: email,
        hToken: hToken,
        captchaSource: "H",
      }
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const getForgottenPasswordAdmin = async (email: string): Promise<string> => {
  try {
    const result = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/forgot/admin/get",
      {
        email: email,
      }
    );
    if (result.data.success) {
      return result.data.payload;
    }
    throw new Error("Unable to get password link");
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const resetPasswordAdmin = async (id: string): Promise<string> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "password/forgot/reset",
      {
        id: id,
      }
    );
    if (result.data.success) {
      return result.data.payload;
    }
    throw new Error("Unable to reset password");
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const verifyForgottenPassword = async (otc: string): Promise<any> => {
  try {
    const verification = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/forgot/verify",
      {
        otc: otc,
      }
    );
    return verification.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const changeForgottenPassword = async (
  otc: string,
  password: string
): Promise<void> => {
  try {
    await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/forgot/change",
      {
        otc: otc,
        password: password,
      }
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const forcedChangePassword = async (
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  try {
    await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/forcedchange",
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
      }
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

const changePassword = async (data: any): Promise<any> => {
  try {
    const result = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/change",
      {
        currentpassword: data.currentPassword,
        password: data.password,
      }
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error));
    } else {
      throw error;
    }
  }
};

export {
  changeForgottenPassword,
  changePassword,
  forcedChangePassword,
  getForgottenPassword,
  getForgottenPasswordAdmin,
  resetPasswordAdmin,
  verifyForgottenPassword,
};
