import { Button, Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";
import { breadcrumbLink } from "../../types/breadcrumb";

const AccountPage = () => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/account", title: "Account" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Your Account
      </Heading>
      <SimpleGrid columns={{ sm: 1, md: 2 }} gap={3} w="full">
        <Button onClick={() => navigate("/account/settings/account", { replace: false })}>Account Settings</Button>
        <Button onClick={() => navigate("/account/settings/swimmer", { replace: false })}>Swimmer Settings</Button>
        <Button onClick={() => navigate("/css/history", { replace: false })}>Critical Swim Speed</Button>
        <Button onClick={() => navigate("/bsr/history", { replace: false })}>Base Stroke Rate</Button>
        <Button onClick={() => navigate("/account/connections", { replace: false })}>Connections</Button>
        <Button onClick={() => navigate("/account/subscription", { replace: false })}>Subscription</Button>
      </SimpleGrid>
    </VStack>
  );
};

export { AccountPage };
