import { BoxProps, Grid, GridItem } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { YourCoach } from "../../components/coaches/YourCoach";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { SessionRoulette } from "../../components/sessions/SessionRoulette";
import { FitnessAndFatigue } from "../../components/training/FitnessAndFatigue";
import { TrainingBalance } from "../../components/training/TrainingBalance";
import { TrainingSummary } from "../../components/training/TrainingSummary";
import Loading from "../../components/ui/Loading";
import { training } from "../../types/training";
import { checkRole } from "../../utils/authHelper";
import { WorldsBiggestSwimSquadWidget } from "../wbss/WorldsBiggestSwimSquadWidget";

interface RoutineProps extends BoxProps {
  training: training | null | undefined;
}

const Routine: React.FC<RoutineProps> = ({ training }) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const { user } = useAppSelector(selectUserState);

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/progress", title: "Progress" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Loading User" />;
  }

  return (
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(12, 1fr)",
        "repeat(12, 1fr)",
      ]}
      gap={2}
    >
      <GridItem colSpan={[1, 1, 3, 3]}>
        <YourCoach />
      </GridItem>
      <GridItem colSpan={[1, 1, 3, 3]}>
        <TrainingBalance training={training} />
      </GridItem>
      <GridItem colSpan={[1, 1, 3, 3]}>
        <TrainingSummary training={training} />
      </GridItem>
      <GridItem colSpan={[1, 1, 3, 3]}>
        <WorldsBiggestSwimSquadWidget />
      </GridItem>
      <GridItem colSpan={[1, 1, 3, 3]}>
        <SessionRoulette />
      </GridItem>
      <GridItem colSpan={[1, 1, 9, 9]}>
        <FitnessAndFatigue
          subscriber={checkRole(accessToken, "read", "fitnessAndFatigue")}
        />
      </GridItem>
    </Grid>
  );
};

export { Routine };
