import {
  BoxProps,
  Heading,
  Spinner,
  VStack,
  useInterval,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface LoadingMultiProps extends BoxProps {
  messages: string[];
}

const LoadingMulti: React.FC<LoadingMultiProps> = ({ messages }) => {
  const [message, setMessage] = useState<string>("Loading");
  const [index, setIndex] = useState<number>(0);

  useInterval(() => {
    let newIndex = index + 1;
    if (newIndex < messages.length) {
      setIndex(newIndex);
    }
  }, 5000);

  useEffect(() => {
    setMessage(messages[index]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <VStack w="full">
      <Heading as="h2" size="m" mb={4}>
        {message}
      </Heading>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="ssPrimaryLight"
        color="ssPrimaryDark"
        size="xl"
      />
    </VStack>
  );
};

export default LoadingMulti;
