import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createCoachProfile } from "../../DataAccess/coaches";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { updateCoachesState } from "../../app/features/coach/coachesSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { breadcrumbLink } from "../../types/breadcrumb";
import { checkRole } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import { RegionSelector } from "../ui/DropDowns";
import Loading from "../ui/Loading";
import { RequiredField } from "../ui/RequiredField";

interface CoachesCreateProps extends BoxProps {}

const CoachCreateProfile: React.FC<CoachesCreateProps> = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const toast = useToast();
  const navigate = useNavigate();
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [
      { href: "/coaches", title: "Coaches" },
    ];
    breadcrumbLinks.push({
      href: "/coaches/create",
      title: "Create Coach Profile",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      region: "",
      shortCode: "",
      user: "",
    },
    mode: "all",
  });

  const handle = async (data: any): Promise<void> => {
    toast.closeAll();
    try {
      await createCoachProfile(data);
      dispatch(updateCoachesState(null));
      navigate("/coaches", { replace: false });
    } catch (e: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Create coach profile"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              Unable to ccreate coach profile, please contact support.
            </Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (!checkRole(accessToken, "*ALL", "superadmin")) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>403: Not Authorised</Heading>
        <Text>
          We're terribly sorry but it would appear that you have tried to do
          something you are not authorised to do. Could be us, could be you ...
          if you are sure you should be able to do what you are trying to do
          then get in touch using the help button at the bottom left of the
          screen.
        </Text>
      </VStack>
    );
  }

  return (
    <VStack w="full" alignItems="left">
      <Heading>Create Coach</Heading>
      <VStack
        as="form"
        onSubmit={handleSubmit(handle)}
        w="full"
        alignItems="flex-start"
        mb={5}
      >
        <SimpleGrid columns={[1, 1, 2, 2]} w="full" columnGap={5} rowGap={2}>
          <VStack>
            <FormControl pb={1}>
              <FormLabel>
                First Name
                <RequiredField />
                {errors.firstName && (
                  <span className="formError">{errors.firstName.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                rules={{ required: "First name is required" }}
                name="firstName"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>
                Last Name
                <RequiredField />
                {errors.lastName && (
                  <span className="formError">{errors.lastName.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                rules={{ required: "Last name is required" }}
                name="lastName"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
          </VStack>
          <VStack>
            <FormControl pb={1}>
              <FormLabel>
                Short Code
                <RequiredField />
                {errors.shortCode && (
                  <span className="formError">{errors.shortCode.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                rules={{ required: "Short code is required" }}
                name="shortCode"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
            <FormControl pb={1}>
              <FormLabel>
                Region
                <RequiredField />
                {errors.region && (
                  <span className="formError">{errors.region.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                rules={{ required: "Region is required" }}
                name="region"
                render={({ field: { ref, onChange, ...restField } }) => (
                  <RegionSelector
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(e)
                    }
                    {...restField}
                    placeholder=""
                  />
                )}
              />
            </FormControl>

            <FormControl pb={1}>
              <FormLabel>
                User ID (leave blank and tell Jon if unknown)
                {errors.user && (
                  <span className="formError">{errors.user.message}</span>
                )}
              </FormLabel>
              <Controller
                control={control}
                name="user"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
          </VStack>
        </SimpleGrid>
        <HStack width="full" align="flex-start" mt={5}>
          <Button
            variant="warning"
            px={10}
            onClick={() => navigate("/coaches", { replace: false })}
          >
            Cancel
          </Button>
          <Box width="full">&nbsp;</Box>
          <Button isDisabled={!isValid} isLoading={isSubmitting} type="submit">
            Create Coach
          </Button>
        </HStack>
      </VStack>
    </VStack>
  );
};

export { CoachCreateProfile };
