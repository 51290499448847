import { Badge, BoxProps, HStack, VStack } from "@chakra-ui/react";

interface CoachBadgesProps extends BoxProps {
  tags: string[];
  myCoach: boolean | undefined;
}

const CoachBadges: React.FC<CoachBadgesProps> = ({ tags, myCoach }) => {
  if (tags) {
    return (
      <VStack alignItems="center" mt={5}>
        {tags.includes("Head Coach") && (
          <Badge colorScheme="blue">Head Coach</Badge>
        )}
        {tags.includes("Mentor") && (
          <Badge colorScheme="blue">Coach Educator / Mentor</Badge>
        )}
        {tags.includes("Certified Coach") && (
          <Badge colorScheme="blue">Certified Coach</Badge>
        )}
        {tags.includes("Assistant Coach") && (
          <Badge colorScheme="pink">Assistant Coach</Badge>
        )}
        {tags.includes("Provisional Coach") && (
          <Badge colorScheme="pink">Provisional Coach</Badge>
        )}
        {tags.includes("Trainee Coach") && (
          <Badge colorScheme="pink">Trainee Coach</Badge>
        )}
        <HStack>
          {myCoach && <Badge colorScheme="green">My Coach</Badge>}
        </HStack>
      </VStack>
    );
  }
  return null;
};

export { CoachBadges };
