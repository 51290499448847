import {
  Button,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Input,
  ListItem,
  OrderedList,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { NotAuthorised } from "../../components/generic/NotAuthorised";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import { checkRole } from "../../utils/authHelper";
import {
  createErrorMessage,
  createPermaToast,
  createToast,
} from "../../utils/toastHelper";

const UploadFitPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const inputFile = useRef<any>(null);
  const toast = useToast();

  const {
    control: controlBasic,
    handleSubmit: handleSubmitBasic,
    reset: resetBasic,
    formState: { isSubmitting: isSubmittingBasic, isValid: isValidBasic },
  } = useForm({
    defaultValues: {
      fit: "",
    },
    mode: "all",
  });

  if (!checkRole(accessToken, "uploadFit", "activity")) {
    return <NotAuthorised size={"full"} functionText={"upload FIT files"} />;
  }

  const handleUpload = async (data: any): Promise<void> => {
    toast.closeAll();
    try {
      // Do some stuff
      const formData = new FormData();
      formData.append("fit", data.fit, data.fit.name);

      const response = await axios.post(
        process.env.REACT_APP_API_ROOT_URL + "files/activity/fit",
        formData
      );
      // handle the response
      createPermaToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"FIT File Upload"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>FIT file uploaded, it may take some time to process.</Text>
            {response.data.messages.length > 0 && (
              <OrderedList>
                {response.data.messages.map((message: string) => {
                  return <ListItem>{message}</ListItem>;
                })}
              </OrderedList>
            )}
          </CustomToast>
        );
      });
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      resetBasic();
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"FIT File Upload"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>{createErrorMessage(error)}</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      resetBasic();
    }
  };

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        Upload FIT File
      </Heading>
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={1}
        mb={10}
        as="form"
        onSubmit={handleSubmitBasic(handleUpload)}
        w="full"
        alignItems="flex-start"
      >
        <GridItem colSpan={5}>
          <FormControl>
            <Controller
              control={controlBasic}
              rules={{ required: true }}
              name="fit"
              render={({ field: { ref, value, onChange, ...restField } }) => (
                <Input
                  {...restField}
                  onChange={(event) => {
                    if (event && event.target && event.target.files) {
                      onChange(event.target.files[0]);
                    }
                  }}
                  ref={inputFile}
                  type="file"
                  placeholder="FIT File"
                />
              )}
            />
            <Text ml={1} color={"gray"} fontSize={"xs"}>
              The uploaded file must be a FIT file, under 5Mb and conforming to
              the FIT specification. PLEASE NOTE: If the activity you are
              uploading already exists this process will create a duplicate
              activity.
            </Text>
          </FormControl>
        </GridItem>
        <Button
          isDisabled={!isValidBasic}
          isLoading={isSubmittingBasic}
          type="submit"
        >
          Upload
        </Button>
      </Grid>
    </VStack>
  );
};

export { UploadFitPage };
