import { BoxProps, Text, useColorModeValue } from '@chakra-ui/react';
import { formatPace } from '../../utils/dateHelper';

interface PaceBandsSVGProps extends BoxProps {
  bands: any;
  unit?: string;
}

const PaceBandsSVG: React.FC<PaceBandsSVGProps> = ({ bands, unit, ...rest }) => {
  if (typeof unit === 'undefined') {
    unit = 'm';
  }

  const svgBaseColour = useColorModeValue('black', 'white');

  const paceBreaks = [];
  for (const p of bands) {
    if (unit === 'y') {
      if (p.upper.y < 360) {
        paceBreaks.push([formatPace(p.upper.y), formatPace(p.upper.y / 2), formatPace(p.upper.y / 4)]);
      }
    } else {
      if (p.upper.m < 360) {
        paceBreaks.push([formatPace(p.upper.m), formatPace(p.upper.m / 2), formatPace(p.upper.m / 4)]);
      }
    }
  }

  if (paceBreaks.length < 5) {
    return <Text>Unable to render pace bands</Text>;
  }

  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 595.28 78.18" xmlSpace="preserve">
      <style>{".st2{font-size:12px;font-family:'DINPro-Bold'}.st3{font-size:8px;font-family:'DINPro-Regular'}"}</style>
      <path fill={svgBaseColour} d="M154.84 10.38h.25v36h-.25zM226.84 10.38h.25v36h-.25zM298.84 10.38h.25v36h-.25zM370.84 10.38h.25v36h-.25zM442.84 10.38h.25v36h-.25z" />
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1={519.995} y1={35.879} x2={86.629} y2={35.879}>
        <stop
          offset={0}
          style={{
            stopColor: '#be1622',
          }}
        />
        <stop
          offset={0.2}
          style={{
            stopColor: '#e6332a',
          }}
        />
        <stop
          offset={0.4}
          style={{
            stopColor: '#e94e1b',
          }}
        />
        <stop
          offset={0.6}
          style={{
            stopColor: '#f39200',
          }}
        />
        <stop
          offset={0.8}
          style={{
            stopColor: '#fcea10',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#3aaa35',
          }}
        />
      </linearGradient>
      <path
        d="M522.46 37.38h-438c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h438c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"
        style={{
          opacity: 0.8,
          fill: 'url(#SVGID_1_)',
        }}
      />
      <text transform="translate(106.158 28.379)" className="st2" fill={svgBaseColour}>
        {'Easy'}
      </text>
      <text transform="translate(172.482 28.379)" className="st2" fill={svgBaseColour}>
        {'Steady'}
      </text>
      <text transform="translate(239.358 28.379)" className="st2" fill={svgBaseColour}>
        {'Red Mist'}
      </text>
      <text transform="translate(324.15 28.379)" className="st2" fill={svgBaseColour}>
        {'CSS'}
      </text>
      <text transform="translate(384.732 28.379)" className="st2" fill={svgBaseColour}>
        {'VO2max'}
      </text>
      <text transform="translate(462.125 28.379)" className="st2" fill={svgBaseColour}>
        {'Sprint'}
      </text>
      <text transform="translate(86.629 57.379)" className="st3" fill={svgBaseColour}>
        {'/100' + unit}
      </text>
      <text transform="translate(167.9 57.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[0][0] : ''}
      </text>
      <text transform="translate(239.9 57.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[1][0] : ''}
      </text>
      <text transform="translate(311.9 57.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[2][0] : ''}
      </text>
      <text transform="translate(383.9 57.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[3][0] : ''}
      </text>
      <text transform="translate(455.9 57.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[4][0] : ''}
      </text>
      <text transform="translate(86.629 67.379)" className="st3" fill={svgBaseColour}>
        {'/50' + unit}
      </text>
      <text transform="translate(167.9 67.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[0][1] : ''}
      </text>
      <text transform="translate(239.9 67.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[1][1] : ''}
      </text>
      <text transform="translate(311.9 67.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[2][1] : ''}
      </text>
      <text transform="translate(383.9 67.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[3][1] : ''}
      </text>
      <text transform="translate(455.9 67.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[4][1] : ''}
      </text>
      <text transform="translate(86.629 77.379)" className="st3" fill={svgBaseColour}>
        {'/25' + unit}
      </text>
      <text transform="translate(167.9 77.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[0][2] : ''}
      </text>
      <text transform="translate(239.9 77.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[1][2] : ''}
      </text>
      <text transform="translate(311.9 77.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[2][2] : ''}
      </text>
      <text transform="translate(383.9 77.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[3][2] : ''}
      </text>
      <text transform="translate(455.9 77.379)" textAnchor="end" className="st3" fill={svgBaseColour}>
        {paceBreaks[0][0] ? paceBreaks[4][2] : ''}
      </text>
    </svg>
  );
};

export { PaceBandsSVG };
