import { Route, Routes } from "react-router-dom";
import { AccountPage } from "../../pages/account/Account";
import { AccountCreatePage } from "../../pages/account/AccountCreate";
import { AccountPrivacyPage } from "../../pages/account/AccountPrivacy";
import { AccountSettingsPage } from "../../pages/account/AccountSettings";
import { AccountSubscribePage } from "../../pages/account/AccountSubscribe";
import { AccountSubscriptionPage } from "../../pages/account/AccountSubscription";
import { AccountSubscriptionConfirmedPage } from "../../pages/account/AccountSubscriptionConfirmed";
import { AffiliateWidgetsPage } from "../../pages/account/AffiliateWidgets";
import { SwimmerSettingsPage } from "../../pages/account/SwimmerSettings";
import { ActivitiesPage } from "../../pages/activities/Activities";
import { ActivityCreatePage } from "../../pages/activities/ActivityCreate";
import { ActivityDetailPage } from "../../pages/activities/ActivityDetail";
import { ActivityEditPage } from "../../pages/activities/ActivityEdit";
import { ActivityLinkPage } from "../../pages/activities/ActivityLink";
import { UploadFitPage } from "../../pages/activities/UploadFit";
import { AdminToolsPage } from "../../pages/admin/AdminTools";
import { InsightsDebugPage } from "../../pages/admin/InsightsDebug";
import { UserLookupPage } from "../../pages/admin/UserLookup";
import { AffiliateAcceptPage } from "../../pages/affiliates/accept";
import { LoginPage } from "../../pages/auth/Login";
import { NewUserPage } from "../../pages/auth/NewUser";
import { BsrHistoryPage } from "../../pages/bsr/bsrHistory";
import { BsrEnterSingle } from "../../pages/bsr/bsrManualCapture";
import { BsrTweakerPage } from "../../pages/bsr/bsrTweaker";
import { CoachAthletesPage } from "../../pages/coachAthletes/CoachAthletes";
import { CoachPage } from "../../pages/coaches/Coach";
import { CoachCreateProfilePage } from "../../pages/coaches/CoachCreateProfile";
import { CoachEditLocationsPage } from "../../pages/coaches/CoachEditLocations";
import { CoachEditLocationsAddPage } from "../../pages/coaches/CoachEditLocationsAdd";
import { CoachEditLocationsEditPage } from "../../pages/coaches/CoachEditLocationsEdit";
import { CoachEditProfilePage } from "../../pages/coaches/CoachEditProfile";
import { CoachEditSessionsPage } from "../../pages/coaches/CoachEditSessions";
import { CoachesPage } from "../../pages/coaches/Coaches";
import { ConnectionsPage } from "../../pages/connections/Connections";
import { ConnectionsGarminOAuthPage } from "../../pages/connections/ConnectionsGarminOAuth";
import { CssHistoryPage } from "../../pages/css/cssHistory";
import { CssEnterSingle } from "../../pages/css/cssManualCapture";
import { CssCalcSingle } from "../../pages/css/cssTestCapture";
import { CssTweakerPage } from "../../pages/css/cssTweaker";
import { ViewCssPage } from "../../pages/css/viewCss";
import { InvitationAcceptPage } from "../../pages/invitations/accept";
import { LibraryPage } from "../../pages/library/Library";
import { LibraryAreaPage } from "../../pages/library/LibraryArea";
import { LibraryCategoryPage } from "../../pages/library/LibraryCategory";
import { LibraryEntityPage } from "../../pages/library/LibraryEntity";
import { LibraryEntityDirectPage } from "../../pages/library/LibraryEntityDirect";
import { ForgottenPasswordPage } from "../../pages/password/ForgottenPassword";
import { ResetPasswordPage } from "../../pages/password/ResetPassword";
import { ProgressPage } from "../../pages/progress/Progress";
import { SessionPage } from "../../pages/sessions/Session";
import { SessionEditorEditPage } from "../../pages/sessions/SessionEditorEdit";
import { SessionEditorListPage } from "../../pages/sessions/SessionEditorList";
import { SessionLinkPage } from "../../pages/sessions/SessionLink";
import { SessionTypesPage } from "../../pages/sessions/SessionTypes";
import { SessionsPage } from "../../pages/sessions/Sessions";
import { NotFoundPage } from "../../pages/static/NotFound";
import { PricingPage } from "../../pages/static/Pricing";
import { PrivacyPage } from "../../pages/static/Privacy";
import { TermsPage } from "../../pages/static/Terms";
import { FitnessPage } from "../../pages/tools/Fitness";
import { ToolsPage } from "../../pages/tools/Tools";
import { TrainingTimelinePage } from "../../pages/trainingTimeline/trainingTimeline";
import { TrainingTimelineSettingsPage } from "../../pages/trainingTimeline/trainingTimelineSettings";
import { ReleaseLogPage } from "../../pages/versioning/ReleaseLog";
import { ProtectedRoute, ProtectedRouteProps } from "../auth/ProtectedRoute";
import { PublicRoute } from "../auth/PublicRoute";
import { BaseLayout } from "../layouts/BaseLayout";
import { Debug } from "../special/Debug";
import { Esteve } from "../special/Esteve";
import { Logout } from "../special/Logout";
import { WorldsBiggestSwimSquadArchive } from "../wbss/WorldsBiggestSwimSquadArchive";

const Router = () => {
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/",
  };

  return (
    <Routes>
      {/* Landing Pages */}
      <Route index element={<PublicRoute outlet={<LoginPage />} />} />
      <Route path="/login" element={<PublicRoute outlet={<LoginPage />} />} />
      <Route path="/newuser" element={<NewUserPage />} />
      {/* Public Routes */}
      <Route path="/logout" element={<Logout />} />
      <Route
        path="/debug"
        element={
          <BaseLayout>
            <PublicRoute outlet={<Debug />} />
          </BaseLayout>
        }
      />
      <Route
        path="/pricing"
        element={
          <BaseLayout>
            <PublicRoute outlet={<PricingPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/esteve"
        element={
          <BaseLayout>
            <PublicRoute outlet={<Esteve />} />
          </BaseLayout>
        }
      />
      <Route
        path="/account/create"
        element={
          <BaseLayout>
            <PublicRoute outlet={<AccountCreatePage />} />{" "}
          </BaseLayout>
        }
      />
      <Route
        path="/forgot-password"
        element={<PublicRoute outlet={<ForgottenPasswordPage />} />}
      />
      <Route
        path="/password/forgot"
        element={<PublicRoute outlet={<ForgottenPasswordPage />} />}
      />
      <Route
        path="/password/reset"
        element={
          <BaseLayout>
            <PublicRoute outlet={<ResetPasswordPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/coaches"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CoachesPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/coaches/create"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CoachCreateProfilePage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/coaches/:coachShortCode"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CoachPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/coaches/:coachShortCode/edit/profile"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CoachEditProfilePage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/coaches/:coachShortCode/edit/locations"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CoachEditLocationsPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/coaches/:coachShortCode/edit/locations/add"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CoachEditLocationsAddPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/coaches/:coachShortCode/edit/locations/edit/:locationId"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CoachEditLocationsEditPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/coaches/:coachShortCode/edit/sessions"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CoachEditSessionsPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/terms"
        element={
          <BaseLayout>
            <PublicRoute outlet={<TermsPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/terms-conditions"
        element={
          <BaseLayout>
            <PublicRoute outlet={<TermsPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/privacy"
        element={
          <BaseLayout>
            <PublicRoute outlet={<PrivacyPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <BaseLayout>
            <PublicRoute outlet={<PrivacyPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/releases/log"
        element={
          <BaseLayout>
            <PublicRoute outlet={<ReleaseLogPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/tools"
        element={
          <BaseLayout>
            <PublicRoute outlet={<ToolsPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/css/calcsingle"
        element={
          <BaseLayout>
            <PublicRoute outlet={<CssCalcSingle />} />
          </BaseLayout>
        }
      />
      <Route
        path="/css/entersingle"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<CssEnterSingle />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/admintools"
        element={
          <BaseLayout>
            <PublicRoute outlet={<AdminToolsPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/admin/insights/debug"
        element={
          <BaseLayout>
            <PublicRoute outlet={<InsightsDebugPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/admin/insights/debug/:activityId"
        element={
          <BaseLayout>
            <PublicRoute outlet={<InsightsDebugPage />} />
          </BaseLayout>
        }
      />
      <Route
        path="/fitness"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<FitnessPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/css/history"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<CssHistoryPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/css/tweaker"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<CssTweakerPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/css/:cssEntryId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ViewCssPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/bsr/entersingle"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<BsrEnterSingle />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/bsr/tweaker"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<BsrTweakerPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/bsr/history"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<BsrHistoryPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/dash"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ProgressPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/progress"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ProgressPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/training"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TrainingTimelinePage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/training/settings"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TrainingTimelineSettingsPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/activities"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ActivitiesPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/activities/create"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ActivityCreatePage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/activities/upload/fit"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<UploadFitPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/activities/link"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ActivityLinkPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/activities/:activityId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ActivityDetailPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/activities/:activityId/edit"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ActivityEditPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionTypesPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions/wbss"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<WorldsBiggestSwimSquadArchive />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions/link/:linkActivityId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionLinkPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions/id/:sessionId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions/id/:sessionId/:targetDistance/:requestedDistance"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions/:sessionType"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionsPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions/:sessionType/:sessionId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions/:sessionType/:sessionId/:targetDistance"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/sessions/:sessionType/:sessionId/:targetDistance/:requestedDistance"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/library"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<LibraryPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/library/direct/:entity"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<LibraryEntityDirectPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/library/:area"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<LibraryAreaPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/library/:area/:category"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<LibraryCategoryPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/library/:area/:category/:entity"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<LibraryEntityPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AccountPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/settings/account"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AccountSettingsPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/settings/privacy"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AccountPrivacyPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/settings/swimmer"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SwimmerSettingsPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/subscription"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AccountSubscriptionPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/subscription/subscribe"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AccountSubscribePage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/subscription/confirmed/:checkoutSessionId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AccountSubscriptionConfirmedPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/connections"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ConnectionsPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/connections/garmin/oauth"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<ConnectionsGarminOAuthPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/account/coachathletes"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<CoachAthletesPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/invitation/accept/:invitationId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<InvitationAcceptPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/affiliate/accept/:affiliateId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AffiliateAcceptPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/affiliate/widgets"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<AffiliateWidgetsPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/admin/sessions/editor"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionEditorListPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/admin/sessions/editor/:sessionId"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<SessionEditorEditPage />}
            />
          </BaseLayout>
        }
      />
      <Route
        path="/admin/user/lookup"
        element={
          <BaseLayout>
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<UserLookupPage />}
            />
          </BaseLayout>
        }
      />
      {/* Default Routes */}
      <Route
        path="*"
        element={
          <BaseLayout>
            <NotFoundPage />
          </BaseLayout>
        }
      />
    </Routes>
  );
};
export { Router };
