import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { affiliate } from "../../../types/affiliate";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface AffiliateState {
  lastUpdated: string;
  affiliate: affiliate | null;
}

// Define an initial state
const initialState: AffiliateState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
  affiliate: null,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const affiliateSlice = createSlice({
  name: "affiliateState",
  initialState,
  reducers: {
    updateAffiliateState(state, action: PayloadAction<affiliate | null>) {
      if (action.payload) {
        state.lastUpdated = new Date().toISOString();
        state.affiliate = action.payload;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.affiliate = initialState.affiliate;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateAffiliateState } = affiliateSlice.actions;

export const selectAffiliateState = (state: RootState): AffiliateState => {
  return state.affiliateState;
};

// Export default the slice reducer
export default affiliateSlice.reducer;
