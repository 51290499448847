import { Navigate, useParams } from "react-router-dom";
import { LibraryEntity } from "../../components/library/LibraryEntity";

const LibraryEntityPage = () => {
  const { area, category, entity } = useParams<{
    area: string;
    category: string;
    entity: string;
  }>();

  if (!area) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  if (!category) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  if (!entity) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  return (
    <LibraryEntity areaId={area} categoryId={category} entityId={entity} />
  );
};

export { LibraryEntityPage };
