import { Box, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { updateSessionRouletteTakenState } from "../../app/features/session/sessionRouletteSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { session } from "../../types/session";
import { SessionListItemSmall } from "./SessionListItemSmall";

interface RouletteSessionProps {
  session: session;
}

const RouletteSession: React.FC<RouletteSessionProps> = ({ session }) => {
  const innerBoxColorMode = useColorModeValue(
    "ssInnerBoxBackgroundLight",
    "ssInnerBoxBackgroundDark"
  );
  const borderColorMode = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (!user) {
    return null;
  }

  return (
    <Box
      cursor="pointer"
      borderWidth={2}
      pt={1}
      pb={3}
      borderRadius={7}
      borderColor={borderColorMode}
      bg={innerBoxColorMode}
      onClick={() => {
        dispatch(updateSessionRouletteTakenState(true));
        navigate("/sessions/id/" + session.id, { replace: false });
      }}
    >
      <SessionListItemSmall user={user} session={session} />
    </Box>
  );
};

export { RouletteSession };
