import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserById } from "../../DataAccess/users";
import {
  selectAuthState,
  updateAuthState,
} from "../../app/features/auth/authSlice";
import {
  selectUserState,
  updateUserState,
} from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ForceChangePasswordPage } from "../../pages/password/ForceChangePassword";
import { checkToken, clearState } from "../../utils/authHelper";
import { Onboarding } from "../account/onboarding/Onboarding";
import Loading from "../ui/Loading";

export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

const ProtectedRoute = ({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const [authorisationChecked, setAuthorisationChecked] =
    useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [onboarded, setOnboarded] = useState<boolean>(false);
  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const {
    access: accessToken,
    refresh: refreshToken,
    forceChangePassword,
  } = useAppSelector(selectAuthState);

  // Save the requested url so that we can forward there after login if needed
  const requestedLocation = useLocation();

  const checkTokenLocal = async () => {
    try {
      if (!accessToken) {
        // We don't have an access token, clear everything
        clearState(dispatch, true);
        setAuthenticated(false);
        return navigate(authenticationPath, {
          replace: false,
          state: { requestedLocation },
        });
      }
      // Check the token (and get a new one if expired)
      const authCheckResponse = await checkToken(accessToken, refreshToken);
      // Write tokens and user state
      if (authCheckResponse && authCheckResponse.authenticated) {
        let userResponse = user;
        // If we are here we are authenticated
        if (authCheckResponse.updated) {
          // Something has changed so update it
          dispatch(
            updateAuthState({
              access: authCheckResponse.accessToken,
              refresh: authCheckResponse.refreshToken,
              admin: null,
              userId: authCheckResponse.userId,
            })
          );
          // We've got a new token so lets get the user again
          userResponse = await getUserById(authCheckResponse.userId);
          dispatch(updateUserState(userResponse));
        }
        // If we don't have a user then get it
        if (!userResponse) {
          userResponse = await getUserById(authCheckResponse.userId);
          dispatch(updateUserState(userResponse));
        }
        // At this point we have checked autentication
        setAuthorisationChecked(true);
        // Check onboarding status
        if (
          userResponse &&
          userResponse.processes &&
          userResponse.processes.onboardingLastDate
        ) {
          const onboardingDate = new Date(
            userResponse.processes.onboardingLastDate
          );
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
          if (onboardingDate <= sixMonthsAgo) {
            setOnboarded(false);
          } else {
            setOnboarded(true);
          }
        } else {
          setOnboarded(false);
        }
        // Finally we are all authenticated
        setAuthenticated(true);
      } else {
        // If we are here we've failed to authenticate, so clear down, log out and head to the login page
        clearState(dispatch, true);
        return navigate(authenticationPath, {
          replace: false,
          state: { requestedLocation },
        });
      }
    } catch (error: any) {
      // If we are here we've failed to authenticate, so clear down, log out and head to the login page
      console.log(error);
      clearState(dispatch, true);
      return navigate(authenticationPath, {
        replace: false,
        state: { requestedLocation },
      });
    }
  };

  useEffect(() => {
    checkTokenLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkTokenLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedLocation]);

  if (!authorisationChecked) {
    return <Loading message="Authenticating User" />;
  }

  if (!authenticated) {
    return <Loading message="Checking User" />;
  }

  if (authenticated && forceChangePassword) {
    return <ForceChangePasswordPage />;
  }

  if (authenticated && !onboarded) {
    return <Onboarding />;
  }

  return outlet;
};

export { ProtectedRoute };
