import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { breadcrumbLink, breadcrumbProps } from "../../types/breadcrumb";

const SwimSmoothBreadcrumb = ({ links }: breadcrumbProps) => {
  if (links) {
    return (
      <Breadcrumb width="full" alignItems="left" mx={5} mt={2}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>
        {links.map((link: breadcrumbLink, index: number) => {
          return (
            <BreadcrumbItem key={"bc" + index}>
              <BreadcrumbLink href={link.href}>{link.title}</BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    );
  }

  return null;
};

export default SwimSmoothBreadcrumb;
