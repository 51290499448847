import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { session } from "../../../types/session";
import { RootState } from "../../store";

export const loadSize = 25;
// Define the state of the slice as an object
export interface SessionsState {
  lastUpdated: string;
  lastLoad: number;
  lastSession: string;
  sessionType: string;
  sessions: session[];
}

export interface SessionsStateUpdate {
  lastSession: string;
  lastLoad: number;
  sessionType: string;
  sessions: session[];
}

// Define an initial state
const initialState: SessionsState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
  lastLoad: loadSize,
  lastSession: "",
  sessionType: "",
  sessions: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const sessionsSlice = createSlice({
  name: "sessionsState",
  initialState,
  reducers: {
    updateSessionsState(
      state,
      action: PayloadAction<SessionsStateUpdate | null>
    ) {
      if (action.payload) {
        state.lastUpdated = new Date().toISOString();
        state.lastSession = action.payload.lastSession;
        state.lastLoad = action.payload.lastLoad;
        state.sessionType = action.payload.sessionType;
        state.sessions = action.payload.sessions;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.lastSession = initialState.lastSession;
        state.lastLoad = initialState.lastLoad;
        state.sessionType = initialState.sessionType;
        state.sessions = initialState.sessions;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateSessionsState = sessionsSlice.actions.updateSessionsState;

export const selectSessionsState = (state: RootState): SessionsState => {
  return state.sessionsState;
};

// Export default the slice reducer
export default sessionsSlice.reducer;
