import { BoxProps, Text, VStack } from "@chakra-ui/react";
import { billing } from "../../../../types/billing";
import { TrialDetails } from "../TrialDetails";
import { StripePricingTable } from "../stripe/StripePricingTable";

interface SubscriptionDetailsFreeProps extends BoxProps {
  billing: billing;
}

const SubscriptionDetailsFree: React.FC<SubscriptionDetailsFreeProps> = ({
  billing,
}) => {
  return (
    <VStack w="full" alignItems="start">
      <Text>
        You are currently on a free '{billing.level}' level. This may be a
        permanent arrangement or a short term one, either way, if you are
        enjoying the Guru and want to support Swim Smooth, the development of
        the Guru, and the methodology that underpins it you can subscribe below.
      </Text>
      <TrialDetails trial={billing.trial} />
      <StripePricingTable />
    </VStack>
  );
};

export { SubscriptionDetailsFree };
