import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  changeCoachAthleteTrial,
  getCoachAthletesForUser,
} from "../../DataAccess/coachAthletes";
import { updateCoachAthletesState } from "../../app/features/coachAthletes/coachAthletesSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { coachAthlete } from "../../types/coachAthlete";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

interface ChangeTrialMenuProps {
  coachAthlete: coachAthlete;
  level: string;
}

const ChangeTrialMenu: React.FC<ChangeTrialMenuProps> = ({
  coachAthlete,
  level,
}) => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUserState);

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  const extendHandler = async (days: number) => {
    toast.closeAll();
    try {
      // Do some stuff
      await changeCoachAthleteTrial(coachAthlete._id, level, days);
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Relationship extended</Text>
          </CustomToast>
        );
      });
      // Update the list
      const updatedResponse = await getCoachAthletesForUser(user._id);
      dispatch(updateCoachAthletesState(updatedResponse));
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              Unable to add / extend coachAthlete, please contact support.
            </Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  return (
    <Menu>
      <MenuButton size={"xs"} mr={2} as={Button} variant={"success"}>
        +
      </MenuButton>
      <MenuList>
        {level === "understanding" && (
          <MenuItem
            onClick={async () => {
              await extendHandler(365);
            }}
          >
            +365 days {level}
          </MenuItem>
        )}
        <MenuItem
          onClick={async () => {
            await extendHandler(90);
          }}
        >
          +90 days {level}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await extendHandler(60);
          }}
        >
          +60 days {level}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await extendHandler(30);
          }}
        >
          +30 days {level}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await extendHandler(14);
          }}
        >
          +14 days {level}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await extendHandler(7);
          }}
        >
          +7 days {level}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await extendHandler(-1);
          }}
        >
          Remove Trial
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export { ChangeTrialMenu };
