import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { coach, coachLocation, createCoach, updateCoach } from "../types/coach";

const createCoachProfile = async (coach: createCoach): Promise<void> => {
  try {
    await axios.post(process.env.REACT_APP_API_ROOT_URL + "coaches", coach);
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const getCoaches = async (): Promise<coach[]> => {
  try {
    const coachesResponse = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "coaches"
    );
    return coachesResponse.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const getCoachesByRegion = async (region: string): Promise<coach[]> => {
  try {
    const coachesResponse = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "coaches/region/" + region
    );
    return coachesResponse.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const getCoachByShortCode = async (coachShortCode: string): Promise<coach> => {
  try {
    const coachesResponse = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "coaches/shortcode/" + coachShortCode
    );
    return coachesResponse.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const getCoachById = async (coachId: string): Promise<coach> => {
  try {
    const coachesResponse = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "coaches/id/" + coachId
    );
    return coachesResponse.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const updateCoachById = async (
  coachId: string,
  coach: updateCoach
): Promise<coach> => {
  try {
    const updateResponse = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "coaches/" + coachId,
      coach
    );
    return updateResponse.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const updateCoachLocationsById = async (
  coachId: string,
  coachLocations: coachLocation[]
): Promise<coach> => {
  try {
    const updateResponse = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "coaches/" + coachId + "/locations",
      coachLocations
    );
    return updateResponse.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

export {
  createCoachProfile,
  getCoachById,
  getCoachByShortCode,
  getCoaches,
  getCoachesByRegion,
  updateCoachById,
  updateCoachLocationsById,
};
