import { useParams } from "react-router-dom";
import { CoachDetail } from "../../components/coaches/CoachDetail";

const CoachPage = () => {
  const params = useParams<{ coachShortCode: string }>();

  if (!params.coachShortCode) {
    return null;
  }

  return <CoachDetail coachShortCode={params.coachShortCode} />;
};

export { CoachPage };
