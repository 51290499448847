import axios from "axios";
import { ConcurrencyManager } from "axios-concurrency";
import { updateAuthState } from "./app/features/auth/authSlice";
import { refresh } from "./DataAccess/oauth";
import { redirectToLogin } from "./navigation";
import { checkToken, clearState } from "./utils/authHelper";

const excludedUrls = [
  process.env.REACT_APP_API_ROOT_URL + "oauth/",
  process.env.REACT_APP_API_ROOT_URL + "oauth/token",
  process.env.REACT_APP_API_ROOT_URL + "oauth/refresh",
];

const MAX_CONCURRENT_REQUESTS = 2;
let manager: any;
let accessTokenPromise: Promise<string> | null = null;
let refreshTokenPromise: Promise<string> | null = null;

export const axiosMiddleware =
  (store: any, history: any) => (next: any) => (action: any) => {
    setInterceptors(store, history);
    return next(action);
  };

export const setInterceptors = (store: any, history: any) => {
  if (!store) return;

  // Attach the concurrency manager if it hasn't been attached already
  if (!manager) {
    manager = ConcurrencyManager(axios, MAX_CONCURRENT_REQUESTS);
  }

  // Add a request interceptor
  axios.interceptors.request.use(async (config) => {
    if (
      config.url &&
      config.url.startsWith(process.env.REACT_APP_API_ROOT_URL as string) &&
      !excludedUrls.includes(config.url)
    ) {
      const { authState } = store.getState();
      const accessToken = authState.access;
      if (accessToken) {
        try {
          if (!accessTokenPromise) {
            accessTokenPromise = handleTokenCheck(store);
          }
          const newAccessToken = await accessTokenPromise;
          if (!newAccessToken) {
            clearState(store.dispatch, true); // Redux State
            redirectToLogin();
            throw new axios.Cancel(
              "No valid token available, request canceled."
            );
          }
          config.headers.Authorization = `Bearer ${newAccessToken}`;
        } catch (error) {
          clearState(store.dispatch, true); // Redux State
          redirectToLogin();
          throw new axios.Cancel("No valid token available, request canceled.");
        } finally {
          accessTokenPromise = null; // Reset the promise after refresh
        }
      }
    }

    // Add client id and secret headers if talking to our API
    if (
      config.url &&
      config.url.startsWith(process.env.REACT_APP_API_ROOT_URL as string)
    ) {
      config.headers["ss-client"] = process.env.REACT_APP_CLIENT_ID;
      config.headers["ss-client-secret"] = process.env.REACT_APP_CLIENT_SECRET;
    }

    return config;
  });

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      // If the request has been cancelled by us we don't retry
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }
      const originalRequest = error.config;
      if (!originalRequest || excludedUrls.includes(originalRequest.url)) {
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          if (!refreshTokenPromise) {
            refreshTokenPromise = handleTokenRefresh(store);
          }

          const newAccessToken = await refreshTokenPromise;
          if (!newAccessToken) {
            throw new axios.Cancel(
              "No valid token available, request canceled."
            );
          }
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          return axios(originalRequest);
        } catch (tokenRefreshError) {
          // If refresh fails, clear user data and redirect to login
          clearState(store.dispatch, true); // Redux State
          redirectToLogin();
          return Promise.reject(tokenRefreshError);
        } finally {
          refreshTokenPromise = null; // Reset the promise after refresh
        }
      }

      return Promise.reject(error);
    }
  );
};

const handleTokenRefresh = async (store: any): Promise<string> => {
  const { dispatch } = store;
  const { authState } = store.getState();
  const refreshToken = authState.refresh;

  if (!refreshToken) {
    throw new Error("No refresh token available.");
  }

  const refreshResult = await refresh(refreshToken);
  const newAccessToken = refreshResult.data.access_token;
  const forceChangePassword = refreshResult.data.forceChangePassword;
  dispatch(
    updateAuthState({
      access: newAccessToken,
      forceChangePassword: forceChangePassword,
    })
  );

  return newAccessToken;
};

const handleTokenCheck = async (store: any): Promise<string> => {
  const { dispatch } = store;
  const { authState } = store.getState();
  const accessToken = authState.access;
  const refreshToken = authState.refresh;

  if (!accessToken) {
    throw new Error("No access token available.");
  }

  let currentAccessToken = accessToken;

  // Check the token (and get a new one if expired)
  const authCheckResponse = await checkToken(accessToken, refreshToken);
  // Write tokens and user state
  if (authCheckResponse && authCheckResponse.authenticated) {
    // If we are here we are authenticated
    if (authCheckResponse.updated) {
      currentAccessToken = authCheckResponse.accessToken;
      // Something has changed so update it
      dispatch(
        updateAuthState({
          access: authCheckResponse.accessToken,
        })
      );
    }
  } else {
    // If we are here we've failed to authenticate, so clear down, log out and head to the login page
    currentAccessToken = "";
    clearState(dispatch, true);
  }

  return currentAccessToken;
};
