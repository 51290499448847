import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Input,
  List,
  ListItem,
  Text,
  useColorMode,
  useOutsideClick,
  useStyleConfig,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface dropdownOption {
  value: string | number;
  label: string;
  category?: string;
}

interface SingleSelectDropdownProps {
  options: dropdownOption[];
  name: string;
  value?: string | number;
  onChange?: (value: string | number | null) => void;
  canClear?: boolean;
}

const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({
  options,
  name,
  value,
  onChange,
  canClear = true,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState<dropdownOption | null>(
    null
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  // Use Chakra's useColorMode hook to access current color mode
  const { colorMode } = useColorMode();

  // Get styles from the Chakra theme using useStyleConfig
  const styles = useStyleConfig("SingleSelectDropdown", { colorMode }) as any; // Fetches styles from the theme

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm)
      ),
    [options, searchTerm]
  );

  const categories = useMemo(
    () =>
      Array.from(
        new Set(filteredOptions.map((option) => option.category || ""))
      ).filter(Boolean),
    [filteredOptions]
  );

  const flattenedOptions = useMemo(
    () =>
      filteredOptions.reduce<dropdownOption[]>(
        (acc, option) => acc.concat(option),
        []
      ),
    [filteredOptions]
  );

  useEffect(() => {
    if (value && (!selectedOption || selectedOption.value !== value)) {
      const initialOption = options.find((option) => option.value === value);
      if (initialOption) {
        setSelectedOption(initialOption);
        onChange?.(value);
      }
    }
  }, [value, options, selectedOption, onChange]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
    setHighlightedIndex(-1);
  }, []);

  const handleSelect = useCallback(
    (option: dropdownOption) => {
      setSelectedOption(option);
      setIsDropdownOpen(false);
      setSearchTerm("");
      setHighlightedIndex(-1);
      onChange?.(option.value);
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    },
    [onChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < flattenedOptions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : flattenedOptions.length - 1
        );
      } else if (e.key === "Enter" && highlightedIndex >= 0) {
        e.preventDefault();
        handleSelect(flattenedOptions[highlightedIndex]);
      } else if (e.key === "Escape") {
        setIsDropdownOpen(false);
        setHighlightedIndex(-1);
      }
    },
    [flattenedOptions, highlightedIndex, handleSelect]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedOption(null);
    setSearchTerm("");
    setHighlightedIndex(-1);
    onChange?.(null);
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [onChange]);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsDropdownOpen(false),
  });

  useEffect(() => {
    if (isDropdownOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDropdownOpen]);

  return (
    <Box width="full" position="relative" ref={dropdownRef}>
      <Button
        ref={buttonRef}
        sx={styles.button({ colorMode })} // Apply button styles from the theme
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        rightIcon={isDropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      >
        <Flex align="center" justify="space-between" width="full">
          {selectedOption && selectedOption.label
            ? selectedOption.label
            : "Select an option"}

          {/* Clear Icon styled like chakra-multiselect */}
          {canClear && selectedOption && (
            <Box
              as="span"
              role="button"
              tabIndex={0}
              aria-label="Clear selection"
              onClick={(e: any) => {
                e.stopPropagation(); // Prevent the dropdown from opening when clearing
                handleClearSelection();
              }}
              sx={styles.clearIcon}
            >
              <CloseIcon boxSize={3} /> {/* Adjust size of CloseIcon */}
            </Box>
          )}
        </Flex>
      </Button>

      <Collapse in={isDropdownOpen} animateOpacity>
        <Box sx={styles.dropdown({ colorMode })}>
          <Input
            ref={inputRef}
            placeholder="Search options..."
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            sx={styles.searchInput} // Apply search input styles from the theme
          />

          <List maxHeight="200px" overflowY="auto">
            {categories.length <= 0 && (
              <Box key={"*ALL"}>
                {filteredOptions.map((option, index: number) => (
                  <ListItem
                    key={option.value}
                    sx={
                      highlightedIndex === index
                        ? styles.highlightedOption({ colorMode })
                        : styles.option({ colorMode })
                    } // Apply option styles from the theme
                    onClick={() => handleSelect(option)}
                  >
                    {option.label}
                  </ListItem>
                ))}
              </Box>
            )}
            {categories.length > 0 &&
              categories.map((category) => (
                <Box key={category}>
                  <Text fontWeight="bold" px={2} py={1}>
                    {category}
                  </Text>
                  {filteredOptions
                    .filter((option) => option.category === category)
                    .map((option, index: number) => (
                      <ListItem
                        key={option.value}
                        sx={
                          highlightedIndex === index
                            ? styles.highlightedOption
                            : styles.option
                        } // Apply option styles from the theme
                        onClick={() => handleSelect(option)}
                      >
                        {option.label}
                      </ListItem>
                    ))}
                </Box>
              ))}
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SingleSelectDropdown;
