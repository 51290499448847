import { InfoIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";
import { CssTweaker } from "../../components/css/CssTweaker";
import { breadcrumbLink } from "../../types/breadcrumb";

const CssTweakerPage = (props: any) => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/tools", title: "Tools" });
    breadcrumbLinks.push({
      href: "/css/tweaker",
      title: "CSS Tweaker",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading>
        Critical Swim Speed Tweaker
        <>
          <Icon cursor="pointer" ml={2} mt={-8} as={InfoIcon} onClick={onOpen} w={5} h={5} />
          <Drawer isOpen={isOpen} placement="left" size="md" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>About The CSS Tweaker</DrawerHeader>
              <DrawerBody>
                <Heading mb={2}>CSS and the 'tweaker'</Heading>
                <Text mb={2}>
                  Your CSS (“Critical Swim Speed”) is a measure of your current fitness level and is something we use throughout the Swim Smooth Guru to setyour
                  training intensity accurately.
                </Text>
                <Text mb={2}>
                  Initially take the CSS Test to determine your CSS pace and analyse your fitness and pacing abilities in full. Once you've saved your CSS pace
                  this will automatically update your training paces in the training plans and sessions throughout the Swim Smooth Guru.
                </Text>
                <Text mb={2}>
                  Don't shy away from doing this - the information you will gain from doing one will be invaluable to your forward progress. Don't be put off if
                  you're a little embarrassed about your fitness right now, it's only a stake in the ground to work forwards from.
                </Text>
                <Text mb={2}>
                  Conventionally you'd repeat the CSS test every 4-6 weeks, but now with the CSS Tweaker you're in more “real-time” control and as you gain
                  experience and feel your fitness improving, you can gradually speed up your training pace using the CSS Tweaker by what you deem to be the
                  appropriate amount. Had a great session and totally nailed all your intervals and then some? Hit the Heroic button for more of a challenge
                  next time around!
                </Text>
                <Text mb={2}>
                  Of course we've also built in the functionality that you can slow things down too if you're not quite hitting your mark or have had an
                  extended time away from the pool. Both scenarios mean that your CSS test result is but a starting point and removes the concern that you might
                  have the wrong CSS selected from a dubious CSS test result. As you complete each key session and tweak away, your training paces will become
                  more and more accurate as you'll be responding in real time to how you're feeling. It's a great new responsive way to train and with
                  experience will really open up a whole new level of potential improvement for you.
                </Text>
                <Text mb={2}>
                  You can even double or triple tap the same button for a greater increase / decrease if necessary or just use the manual input if you feel
                  you're totally on the money with where you're at. Doing so updates all future sessions with your new targets automatically so you can just get
                  on and view-print-swim with confidence!
                </Text>
                <Text mb={2}>
                  Don't like a change you've made in the CSS Tweaker? Just don't save it and all will remain as it was last set. Tweaking is very easy to do and
                  avoids the need to perform the CSS test as regularly as you might have conventionally done - which takes up valuable training time - and also
                  eradicates any concerns about the efficacy of such a simple test for your threshold pace (believe us, we get a LOT of emails on this topic!).
                </Text>
                <Text mb={2}>So go on, get Tweaking!</Text>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      </Heading>
      <Text>
        Just completed a CSS or Red Mist session? Hit one of the buttons below and tell us how you felt to tweak your CSS pace up / down for your next session.
        This will ensure you're keeping things Fresh 'n' Fruity!
      </Text>
      <CssTweaker />
    </VStack>
  );
};

export { CssTweakerPage };
