import { InfoIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  BoxProps,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { LatLngBoundsExpression } from "leaflet";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Circle,
  LayerGroup,
  LayersControl,
  MapContainer,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import { useNavigate } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { removeActivityFromState } from "../../app/features/activity/activitiesSlice";
import {
  selectActivityState,
  updateActivityState,
} from "../../app/features/activity/activitySlice";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { updateTrainingTimelineState } from "../../app/features/trainingTimeline/trainingTimelineSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import {
  deleteActivityById,
  getActivityAnalysisById,
  getActivityById,
} from "../../DataAccess/activities";
import { deleteCompletionById } from "../../DataAccess/completions";
import { activityAnalysis } from "../../types/activity";
import { commentLong } from "../../types/core";
import { checkRole } from "../../utils/authHelper";
import {
  formatDateTime,
  formatDuration,
  formatPace,
  formatTzAwareDateTime,
  formatTzAwareDateTimeShort,
} from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";
import { MessageDisplay } from "../generic/MessageDisplay";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BetaBadge } from "../ui/BetaBadge";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import { CustomTooltipHR } from "../ui/CustomTooltipHR";
import { CustomTooltipPace } from "../ui/CustomTooltipPace";
import { CustomTooltipText } from "../ui/CustomTooltipText";
import { CustomTooltipTime } from "../ui/CustomTooltipTime";
import Loading from "../ui/Loading";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import { SingleValueWithUnitDisplay } from "../ui/SingleValueWithUnitDisplay";
import Vimeo from "../ui/Vimeo";
import { ActivityDetailSwim } from "./ActivityDetailSwim";
import { ActivityLocationType } from "./ActivityLocationType";
import { ActivityProvider } from "./ActivityProvider";
import { PacingScore } from "./PacingScore";
import { StrokeInsights } from "./StrokeInsights";
import { SwimTypes } from "./SwimTypes";

interface ActivityDetailProps extends BoxProps {
  activityId: string;
}

const timeFormatter = (value: any) => {
  return formatDuration(value);
};

const SetBounds = (props: any) => {
  const locations = props.locations;
  const bounds: LatLngBoundsExpression = [];
  const map = useMap();

  if (locations.length > 1) {
    for (let i = 0; i < locations.length; i = i + 50) {
      bounds.push([locations[i].lat, locations[i].lng]);
    }

    if (bounds.length > 0) {
      map.fitBounds(bounds, { padding: [25, 25] });
    }
  }
  return null;
};

const ActivityDetail: React.FC<ActivityDetailProps> = ({ activityId }) => {
  const toast = useToast();
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const labelColorMode = useColorModeValue("#000000", "#FFFFFF");
  const dataColorMode = useColorModeValue("#A419BC", "#E755FF");
  const rawDataColorMode = useColorModeValue("#FF7A30", "#FF9B63");
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAnalysis, setLoadingAnalysis] = useState<boolean>(true);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const { activity } = useAppSelector(selectActivityState);
  const [activityAnalysis, setActivityAnalysis] =
    useState<activityAnalysis | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteRef = useRef(null);
  const [isUnlinking, setIsUnlinking] = useState(false);
  const {
    isOpen: isUnlinkOpen,
    onOpen: onUnlinkOpen,
    onClose: onUnlinkClose,
  } = useDisclosure();
  const {
    isOpen: isPacingExplainerOpen,
    onOpen: onPacingExplainerOpen,
    onClose: onPacingExplainerClose,
  } = useDisclosure();

  const unlinkRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const authIntensity = checkRole(accessToken, "intensity", "activity");
  const authHeartRate = checkRole(accessToken, "heartRate", "activity");
  const authSTSS = checkRole(accessToken, "sTSS", "activity");
  const authStrokeRate = checkRole(accessToken, "strokeRate", "activity");
  const authLapPace = checkRole(accessToken, "lapPace", "activity");

  const upgradeAction = () => {
    navigate("/account/subscription", { replace: false });
  };

  useEffect(() => {
    const getActivityLocal = async (activityId: string) => {
      try {
        const activity = await getActivityById(activityId);
        if (activity) {
          dispatch(updateActivityState(activity));
          setLoading(false);
        }
      } catch (error) {
        dispatch(updateActivityState(null));
        setLoading(false);
      }
    };
    if (activityId && (!activity || activityId !== activity.id)) {
      getActivityLocal(activityId);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  useEffect(() => {
    const getActivityAnalysisLocal = async () => {
      try {
        if (activity) {
          const activityAnalysisResponse = await getActivityAnalysisById(
            activity.id
          );
          setActivityAnalysis(activityAnalysisResponse);
          setLoadingAnalysis(false);
        } else {
          setLoadingAnalysis(false);
        }
      } catch (error) {
        setLoadingAnalysis(false);
      }
    };
    getActivityAnalysisLocal();
  }, [activity]);

  useEffect(() => {
    if (activity) {
      const breadcrumbLinks = [
        { href: "/activities", title: "Activities" },
        {
          href: "/activities/" + activity.id,
          title:
            formatTzAwareDateTimeShort(
              activity.data.summary.dates.startDate.local,
              activity.data.summary.dates.offset,
              activity.data.summary.dates.timezone
            ) +
            " " +
            activity.data.name,
        },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    } else {
      const breadcrumbLinks = [
        { href: "/activities", title: "Activities" },
        { href: "/activities/" + activityId, title: "Activity" },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  if (loading) {
    return <Loading message="Loading activity details" />;
  }

  if (loadingAnalysis) {
    return <Loading message="Loading activity analysis" />;
  }

  if (!activity) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Activity Details
        </Heading>
        <MessageDisplay status="error" title="Could not get activity!">
          <Text>
            Unable to retrieve activity. This could mean the activity you have
            requested does not exist or that you are not authorised to see it.
          </Text>
        </MessageDisplay>
        <Button
          onClick={() => {
            navigate("/activities", { replace: false });
          }}
        >
          See all Activities
        </Button>
      </VStack>
    );
  }

  const deleteActivityHandler = async () => {
    try {
      setIsDeleting(true);
      await deleteActivityById(activity.id);
      dispatch(removeActivityFromState(activity.id));
      dispatch(updateActivityState(null));
      navigate("/activities", { replace: false });
      setIsDeleting(false);
      onClose();
    } catch (error) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete Activity"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to delete activity, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsDeleting(false);
      onClose();
    }
  };

  const unlinkActivityHandler = async () => {
    try {
      if (activity.data.completion && activity.data.completion.id) {
        setIsUnlinking(true);
        await deleteCompletionById(activity.data.completion.id);
        const activityResponse = await getActivityById(activity.id);
        if (activityResponse) {
          dispatch(updateActivityState(activityResponse));
          // Force training plan reload
          dispatch(updateTrainingTimelineState(null));
        }
        setIsUnlinking(false);
        onUnlinkClose();
      } else {
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Unlink Activity"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>Unable to unlink activity, please contact support.</Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
        setIsUnlinking(false);
        onUnlinkClose();
      }
    } catch (error) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Unlink Activity"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to unlink activity, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsUnlinking(false);
      onUnlinkClose();
    }
  };

  if (activity.source.provider === "MANUAL") {
    return (
      <VStack alignItems="flex-start" w="full">
        <HStack w="full" alignItems="flex-start">
          <Box w="full">
            <Text>
              {formatDateTime(activity.data.summary.dates.startDate.local)}
            </Text>
            <Heading as="h2" size="xl" mt={-1}>
              {activity.data.name}
            </Heading>
            <Box>
              {activity.data.location.type === "Pool" && (
                <Text fontSize="sm">
                  {activity.data.location.name
                    ? activity.data.location.name
                    : "Pool"}{" "}
                  ({activity.data.location.lapLength}
                  {activity.data.location.lapUnit})
                </Text>
              )}
              {activity.data.location.type === "Open" && (
                <Text fontSize="sm">
                  {activity.data.location.name
                    ? activity.data.location.name
                    : "Open Water"}
                </Text>
              )}
              {activity.data.location.type === "Endless" && (
                <Text fontSize="sm">
                  {activity.data.location.name
                    ? activity.data.location.name
                    : "Endless Pool"}
                </Text>
              )}
            </Box>

            <HStack py={2}>
              <Button
                onClick={() => {
                  navigate("/activities/" + activity.id + "/edit");
                }}
              >
                Edit
              </Button>
              {activity.data.completion &&
                activity.data.completion.session &&
                activity.data.completion.session.id && (
                  <Button
                    onClick={() => {
                      navigate(
                        "/sessions/id/" + activity.data.completion?.session.id
                      );
                    }}
                  >
                    View Session
                  </Button>
                )}
              {(!activity.data.completion ||
                (activity.data.completion &&
                  activity.data.completion.session &&
                  !activity.data.completion.session.id)) && (
                <Button
                  onClick={() => {
                    navigate("/sessions/link/" + activity.id, {
                      replace: false,
                    });
                  }}
                >
                  Link Session
                </Button>
              )}
              {activity.data.completion &&
                activity.data.completion.session &&
                activity.data.completion.session.id && (
                  <>
                    <Button
                      variant="warning"
                      px={10}
                      isLoading={isUnlinking}
                      onClick={onUnlinkOpen}
                    >
                      Unlink Activity
                    </Button>
                    <AlertDialog
                      isOpen={isUnlinkOpen}
                      leastDestructiveRef={unlinkRef}
                      onClose={onClose}
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Unlink Activity
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                          </AlertDialogBody>

                          <AlertDialogFooter>
                            <Button ref={unlinkRef} onClick={onUnlinkClose}>
                              Back
                            </Button>
                            <Button
                              variant="warning"
                              px={20}
                              isLoading={isUnlinking}
                              onClick={unlinkActivityHandler}
                              ml={3}
                            >
                              Unlink Activity
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </>
                )}
              <>
                <Button
                  variant="warning"
                  px={10}
                  isLoading={isDeleting}
                  onClick={onOpen}
                >
                  Delete Activity
                </Button>
                <AlertDialog
                  isOpen={isOpen}
                  leastDestructiveRef={deleteRef}
                  onClose={onClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete Activity
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button ref={deleteRef} onClick={onClose}>
                          Back
                        </Button>
                        <Button
                          variant="warning"
                          px={20}
                          isLoading={isDeleting}
                          onClick={deleteActivityHandler}
                          ml={3}
                        >
                          Delete Activity
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
            </HStack>
          </Box>
          <ActivityProvider size={100} provider={activity.source.provider} />
        </HStack>
        <Box bg={boxColorMode} p={3} borderRadius={10} height="100%" w="full">
          <Heading as="h4" size="sm" mb={5}>
            Swim Summary
          </Heading>
          <SimpleGrid columns={[2, 2, 4, 4]} columnGap={4} w="full">
            <SingleValueWithUnitDisplay
              label="Distance"
              value={activity.data.summary.distances.total}
              unit={activity.data.location.lapUnit}
            />
            <SingleValueDisplay
              label="Duration"
              value={formatDuration(activity.data.summary.timings.total)}
            />
            <SingleValueWithUnitDisplay
              label="Avg. Pace"
              value={formatPace(activity.data.summary.pace.avg)}
              unit={"/100" + activity.data.location.lapUnit}
            />
            {authSTSS && (
              <SingleValueDisplay
                label="sTSS"
                value={activity.data.summary.stss}
              />
            )}
          </SimpleGrid>
        </Box>
        {activity.data.comment && (
          <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
            <Text>{parse(activity.data.comment)}</Text>
          </Box>
        )}
      </VStack>
    );
  }

  return (
    <VStack alignItems="flex-start" w="full">
      <HStack w="full" alignItems="flex-start">
        <Box w="full">
          <Text>
            {formatTzAwareDateTime(
              activity.data.summary.dates.startDate.local,
              activity.data.summary.dates.offset,
              activity.data.summary.dates.timezone
            ) +
              " (" +
              activity.data.summary.dates.timezone +
              ")"}
          </Text>
          <Heading as="h2" size="xl" mt={-1}>
            {activity.data.name}
          </Heading>
          <Box>
            {activity.data.location.type === "Pool" && (
              <Text fontSize="sm">
                {activity.data.location.name
                  ? activity.data.location.name
                  : "Pool"}{" "}
                ({activity.data.location.lapLength}
                {activity.data.location.lapUnit})
              </Text>
            )}
            {activity.data.location.type === "Open" && (
              <Text fontSize="sm">
                {activity.data.location.name
                  ? activity.data.location.name
                  : "Open Water"}
              </Text>
            )}
            {activity.data.location.type === "Endless" && (
              <Text fontSize="sm">
                {activity.data.location.name
                  ? activity.data.location.name
                  : "Endless Pool"}
              </Text>
            )}
          </Box>
        </Box>
        <ActivityProvider size={100} provider={activity.source.provider} />
      </HStack>
      <HStack py={2}>
        <Button
          onClick={() => {
            navigate("/activities/" + activity.id + "/edit");
          }}
        >
          Edit
        </Button>
        {activity.data.completion &&
          activity.data.completion.session &&
          activity.data.completion.session.id && (
            <Button
              onClick={() => {
                navigate(
                  "/sessions/id/" + activity.data.completion?.session.id
                );
              }}
            >
              View Session
            </Button>
          )}
        {(!activity.data.completion ||
          (activity.data.completion &&
            activity.data.completion.session &&
            !activity.data.completion.session.id)) && (
          <Button
            onClick={() => {
              navigate("/sessions/link/" + activity.id, { replace: false });
            }}
          >
            Link Session
          </Button>
        )}
        {activity.data.completion &&
          activity.data.completion.session &&
          activity.data.completion.session.id && (
            <>
              <Button
                variant="warning"
                px={10}
                isLoading={isUnlinking}
                onClick={onUnlinkOpen}
              >
                Unlink Activity
              </Button>
              <AlertDialog
                isOpen={isUnlinkOpen}
                leastDestructiveRef={unlinkRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Unlink Activity
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={unlinkRef} onClick={onUnlinkClose}>
                        Back
                      </Button>
                      <Button
                        variant="warning"
                        px={20}
                        isLoading={isUnlinking}
                        onClick={unlinkActivityHandler}
                        ml={3}
                      >
                        Unlink Activity
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </>
          )}
        <>
          <Button
            variant="warning"
            px={10}
            isLoading={isDeleting}
            onClick={onOpen}
          >
            Delete Activity
          </Button>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={deleteRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Activity
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={deleteRef} onClick={onClose}>
                    Back
                  </Button>
                  <Button
                    variant="warning"
                    px={20}
                    isLoading={isDeleting}
                    onClick={deleteActivityHandler}
                    ml={3}
                  >
                    Delete Activity
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      </HStack>
      <Grid templateColumns="repeat(12, 1fr)" gap={2} w="full">
        <GridItem colSpan={[12, 12, 3, 3]}>
          <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
            <Heading as="h4" size="sm" mb={5}>
              Timings (Active vs Rest)
            </Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={0} w="full">
              <GridItem>
                <ActivityLocationType
                  size={150}
                  mx={5}
                  locationType={activity.data.location.type}
                  timings={activity.data.summary.timings}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <VStack alignItems="flex-start">
                  <GridItem>
                    <SingleValueDisplay
                      label="Total"
                      value={formatDuration(
                        activity.data.summary.timings.total
                      )}
                    />
                  </GridItem>
                  <GridItem>
                    <SingleValueDisplay
                      label="Active"
                      value={formatDuration(
                        activity.data.summary.timings.breakdown.active
                      )}
                    />
                  </GridItem>
                  <GridItem>
                    <SingleValueDisplay
                      label="Rest"
                      value={formatDuration(
                        activity.data.summary.timings.breakdown.rest
                      )}
                    />
                  </GridItem>
                </VStack>
              </GridItem>
            </Grid>
          </Box>
        </GridItem>
        <GridItem colSpan={[12, 12, 5, 5]}>
          <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
            <Heading mb={5} as={"h4"} size="sm">
              Session Pacing
              <>
                <Icon
                  cursor="pointer"
                  ml={2}
                  mt={-4}
                  as={InfoIcon}
                  onClick={onPacingExplainerOpen}
                />
                <Drawer
                  isOpen={isPacingExplainerOpen}
                  placement="left"
                  size="md"
                  onClose={onPacingExplainerClose}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>About Pacing Score</DrawerHeader>
                    <DrawerBody>
                      <PacingExplainer
                        text={activity.data.summary.execution.comment.long}
                      />
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
              <BetaBadge />
            </Heading>
            <PacingScore
              size={150}
              execution={activity.data.summary.execution}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={[12, 12, 4, 4]}>
          <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
            <Heading as="h4" size="sm" mb={5}>
              Swim Summary
            </Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={2} w="full">
              <GridItem>
                <SingleValueWithUnitDisplay
                  label="Distance"
                  value={activity.data.summary.distances.total}
                  unit={activity.data.location.lapUnit}
                />
              </GridItem>
              <GridItem>
                <SingleValueDisplay
                  label="Calories"
                  value={activity.data.summary.original.energy}
                />
              </GridItem>
              <GridItem>
                <SingleValueDisplay
                  label="Avg. Heart Rate"
                  value={
                    authHeartRate ? (
                      activity.data.hr.summary.avg
                    ) : (
                      <Button onClick={upgradeAction} size={"xs"}>
                        Upgrade
                      </Button>
                    )
                  }
                />
              </GridItem>
              <GridItem>
                <SingleValueDisplay
                  label="Avg. Strokes / Min"
                  value={
                    authStrokeRate ? (
                      activity.data.summary.cadence.avg
                    ) : (
                      <Button onClick={upgradeAction} size={"xs"}>
                        Upgrade
                      </Button>
                    )
                  }
                />
              </GridItem>
              <GridItem>
                <SingleValueWithUnitDisplay
                  label="Avg. Pace"
                  value={formatPace(activity.data.summary.pace.avg)}
                  unit={"/100" + activity.data.location.lapUnit}
                />
              </GridItem>
              <GridItem>
                <SingleValueDisplay
                  label="sTSS"
                  value={
                    authSTSS ? (
                      activity.data.summary.stss
                    ) : (
                      <Button onClick={upgradeAction} size={"xs"}>
                        Upgrade
                      </Button>
                    )
                  }
                />
              </GridItem>
            </Grid>
          </Box>
        </GridItem>
        {activity.data.comment && (
          <GridItem colSpan={12}>
            <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
              <Text>{parse(activity.data.comment)}</Text>
            </Box>
          </GridItem>
        )}
      </Grid>
      <Grid templateColumns="repeat(12, 1fr)" gap={2} w="full">
        <GridItem colSpan={12}>
          <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
            <Heading as="h4" size="sm" mb={5}>
              Activity BreakDown
            </Heading>
            {activity.data.swims.map((swim: any, swimIndex: number) => {
              return (
                <ActivityDetailSwim
                  swim={swim}
                  swimIndex={swimIndex}
                  lapUnit={activity.data.location.lapUnit}
                />
              );
            })}
          </Box>
        </GridItem>
        {activity.data.location.type === "Open" &&
          activity.data.gps &&
          activity.data.gps.length > 2 && (
            <GridItem colSpan={12}>
              <MapContainer scrollWheelZoom={false} style={{ height: "400px" }}>
                <LayersControl position="topright">
                  <LayersControl.BaseLayer
                    name="Open Street Map"
                    checked={true}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Google - Satelite">
                    <TileLayer
                      url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                      maxZoom={20}
                      subdomains={["mt1", "mt2", "mt3"]}
                    />
                  </LayersControl.BaseLayer>
                  {/* <LayersControl.BaseLayer name="NASA GIBS">
                  <TileLayer url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg" attribution="&copy; NASA Blue Marble, image service by OpenGeo" maxNativeZoom={8} />
                </LayersControl.BaseLayer> */}
                  <LayersControl.Overlay name="Swim Track" checked={true}>
                    <LayerGroup>
                      <Polyline
                        pathOptions={{ color: dataColorMode }}
                        positions={activity.data.gps}
                      />
                      <Circle
                        center={[
                          activity.data.gps[0].lat,
                          activity.data.gps[0].lng,
                        ]}
                        pathOptions={{ color: "#35FC86", fillColor: "#35FC86" }}
                        radius={2}
                      />
                      <Circle
                        center={[
                          activity.data.gps[activity.data.gps.length - 1].lat,
                          activity.data.gps[activity.data.gps.length - 1].lng,
                        ]}
                        pathOptions={{ color: "red", fillColor: "red" }}
                        radius={2}
                      />
                    </LayerGroup>
                  </LayersControl.Overlay>
                  {activity.data.gpsraw && activity.data.gpsraw.length > 2 && (
                    <LayersControl.Overlay name="Swim Track (RAW)">
                      <LayerGroup>
                        <Polyline
                          pathOptions={{ color: rawDataColorMode }}
                          positions={activity.data.gpsraw}
                        />
                        <Circle
                          center={[
                            activity.data.gps[0].lat,
                            activity.data.gps[0].lng,
                          ]}
                          pathOptions={{
                            color: "#35FC86",
                            fillColor: "#35FC86",
                          }}
                          radius={2}
                        />
                        <Circle
                          center={[
                            activity.data.gps[activity.data.gps.length - 1].lat,
                            activity.data.gps[activity.data.gps.length - 1].lng,
                          ]}
                          pathOptions={{ color: "red", fillColor: "red" }}
                          radius={2}
                        />
                      </LayerGroup>
                    </LayersControl.Overlay>
                  )}
                </LayersControl>
                <SetBounds locations={activity.data.gps} />
              </MapContainer>
            </GridItem>
          )}
        {activityAnalysis &&
          activityAnalysis.swimtelligence.insightGroups.length > 0 && (
            <GridItem colSpan={12}>
              <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
                <Heading as="h4" size="sm" mb={5}>
                  Stroke Insights
                  <BetaBadge />
                </Heading>
                <StrokeInsights activityAnalysis={activityAnalysis} />
              </Box>
            </GridItem>
          )}
      </Grid>
      <SimpleGrid columns={[1, 1, 3, 3]} columnGap={2} rowGap={2} w="full">
        <SwimTypes
          loading={loadingAnalysis}
          activity={activity}
          activityAnalysis={activityAnalysis}
        />
        <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
          <Heading as="h4" size="sm" mb={5}>
            Distance Breakdown
          </Heading>
          {authIntensity && (
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={[
                  {
                    i: "Easy",
                    v: activity.data.summary.distances.intensity.Easy,
                  },
                  {
                    i: "Steady",
                    v: activity.data.summary.distances.intensity.Steady,
                  },
                  {
                    i: "Red Mist",
                    v: activity.data.summary.distances.intensity.RedMist,
                  },
                  {
                    i: "CSS",
                    v: activity.data.summary.distances.intensity.CSS,
                  },
                  {
                    i: "VO2Max",
                    v: activity.data.summary.distances.intensity.VO2Max,
                  },
                  {
                    i: "Sprint",
                    v: activity.data.summary.distances.intensity.Sprint,
                  },
                  {
                    i: "Unknown",
                    v: activity.data.summary.distances.intensity.unknown,
                  },
                ]}
                syncId="intensities"
                margin={{
                  top: 0,
                  right: 20,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="i"
                  interval={0}
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <YAxis
                  dataKey="v"
                  label={{
                    value:
                      activity.data.location.lapUnit === "y"
                        ? "Yards"
                        : "Metres",
                    angle: -90,
                    position: "outsideLeft",
                    fill: labelColorMode,
                    dx: -10,
                  }}
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <Tooltip content={<CustomTooltipText />} />
                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                />
                <Bar
                  type="basis"
                  dataKey="v"
                  fill={dataColorMode}
                  name={"Distance at Intensity"}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
          {!authIntensity && (
            <NotAuthorised functionText={"Distance Breakdown"} size={"small"} />
          )}
        </Box>
        <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
          <Heading as="h4" size="sm" mb={5}>
            Active Time Breakdown
          </Heading>
          {authIntensity && (
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={[
                  {
                    i: "Easy",
                    v: activity.data.summary.timings.intensity.Easy,
                  },
                  {
                    i: "Steady",
                    v: activity.data.summary.timings.intensity.Steady,
                  },
                  {
                    i: "Red Mist",
                    v: activity.data.summary.timings.intensity.RedMist,
                  },
                  {
                    i: "CSS",
                    v: activity.data.summary.timings.intensity.CSS,
                  },
                  {
                    i: "VO2Max",
                    v: activity.data.summary.timings.intensity.VO2Max,
                  },
                  {
                    i: "Sprint",
                    v: activity.data.summary.timings.intensity.Sprint,
                  },
                  {
                    i: "Unknown",
                    v: activity.data.summary.timings.intensity.unknown,
                  },
                ]}
                syncId="intensities"
                margin={{
                  top: 0,
                  right: 20,
                  left: 15,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="i"
                  interval={0}
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <YAxis
                  dataKey="v"
                  tickFormatter={timeFormatter}
                  label={{
                    value: "Time",
                    angle: -90,
                    position: "outsideLeft",
                    fill: labelColorMode,
                    dx: -25,
                  }}
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <Tooltip content={<CustomTooltipTime />} />
                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                />
                <Bar
                  type="basis"
                  dataKey="v"
                  fill={dataColorMode}
                  name="Time at Intensity"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
          {!authIntensity && (
            <NotAuthorised
              functionText={"Active Time Breakdown"}
              size={"small"}
            />
          )}
        </Box>
        <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
          <Heading as="h4" size="sm" mb={5}>
            Heart Rate
          </Heading>
          {authHeartRate && (
            <ResponsiveContainer width="100%" height={250}>
              <LineChart
                data={activity.data.hr.trace.filter((item: any) => {
                  return item.t > 0;
                })}
                margin={{
                  top: 0,
                  right: 20,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="t"
                  tickFormatter={timeFormatter}
                  interval={300}
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <YAxis
                  dataKey="v"
                  label={{
                    value: "Beats Per Minute",
                    angle: -90,
                    position: "outsideLeft",
                    fill: labelColorMode,
                    dx: -10,
                  }}
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <CartesianGrid strokeDasharray="7 7" stroke="#CCCCCC" />
                <Tooltip content={<CustomTooltipHR />} />
                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                />
                <Line
                  type="basis"
                  dot={false}
                  dataKey="v"
                  strokeWidth={2}
                  stroke={dataColorMode}
                  activeDot={{ r: 3 }}
                  name="Heart Rate"
                />
              </LineChart>
            </ResponsiveContainer>
          )}
          {!authHeartRate && (
            <NotAuthorised functionText={"Heart Rate"} size={"small"} />
          )}
        </Box>
        <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
          <Heading as="h4" size="sm" mb={5}>
            Stroke Rate Distribution
          </Heading>
          {authStrokeRate && (
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={activity.data.strokeRateHistogram.bins.map(
                  (item: any, index: number) => {
                    return {
                      bin: item,
                      value: activity.data.strokeRateHistogram.values[index],
                    };
                  }
                )}
                margin={{
                  top: 0,
                  right: 20,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="bin"
                  interval={
                    activity.data.strokeRateHistogram.bins.length > 40 ? 3 : 1
                  }
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <YAxis hide={true} />
                <Tooltip content={<CustomTooltipText />} />
                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                />
                <Bar
                  type="value"
                  dataKey="value"
                  fill={dataColorMode}
                  name="No. of Laps"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
          {!authStrokeRate && (
            <NotAuthorised
              functionText={"Stroke Rate Distribution"}
              size={"small"}
            />
          )}
        </Box>
        <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
          <Heading as="h4" size="sm" mb={5}>
            Lap Pace
          </Heading>
          {authLapPace && (
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={activity.data.summary.lapPaces.map(
                  (item: any, index: number) => {
                    return {
                      bin: index + 1,
                      value: item,
                    };
                  }
                )}
                margin={{
                  top: 0,
                  right: 20,
                  left: 15,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="bin"
                  interval={
                    activity.data.summary.lapPaces.length > 60
                      ? activity.data.summary.lapPaces.length > 100
                        ? 20
                        : 5
                      : 1
                  }
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <YAxis
                  dataKey="value"
                  tickFormatter={timeFormatter}
                  label={{
                    value:
                      activity.data.location.lapUnit === "y"
                        ? "Lap Pace / 100y"
                        : "Lap Pace / 100m",
                    angle: -90,
                    position: "outsideLeft",
                    fill: labelColorMode,
                    dx: -25,
                  }}
                  stroke={labelColorMode}
                  fontSize="8pt"
                />
                <Tooltip content={<CustomTooltipPace />} />
                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                />
                <Bar
                  type="value"
                  dataKey="value"
                  fill={dataColorMode}
                  name="Lap Pace"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
          {!authLapPace && (
            <NotAuthorised functionText={"Lap Pace"} size={"small"} />
          )}
        </Box>
      </SimpleGrid>
      {(process.env.REACT_APP_ENV === "alpha" ||
        checkRole(accessToken, "*ALL", "superadmin")) && (
        <Box bg={boxColorMode} p={3} borderRadius={10} w={"full"} height="100%">
          <Heading as="h4" size="sm" mb={5}>
            The 'Overglider' Zone
          </Heading>
          <pre>
            <code>{JSON.stringify(activity.data.meta, null, 2)}</code>
          </pre>
        </Box>
      )}
    </VStack>
  );
};

interface ActivityLinkProps extends BoxProps {
  text: commentLong[];
}

const PacingExplainer: React.FC<ActivityLinkProps> = ({ text }) => {
  return (
    <>
      <Heading mb={2}>Pacing Score</Heading>
      <Text>Formerly 'Execution Score'</Text>
      {text.map((t: commentLong, index: number) => {
        if (t.type === "text") {
          return <Text key={index}>{t.data}</Text>;
        } else if (t.type === "video") {
          return (
            <Box key={index}>
              <Vimeo video={t.data} />
            </Box>
          );
        } else {
          return (
            <Text key={index}>
              <code>
                <pre>{JSON.stringify(t, null, 2)}</pre>
              </code>
            </Text>
          );
        }
      })}
    </>
  );
};

export { ActivityDetail };
