import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaDownLong, FaUpLong } from "react-icons/fa6";
import { MdOutlineTrendingFlat } from "react-icons/md";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { getBsrCurrent } from "../../DataAccess/bsr";
import { getCssCurrent } from "../../DataAccess/css";
import { bsrEntry } from "../../types/bsr";
import { cssEntry } from "../../types/css";
import { training, trainingSummaryPeriod } from "../../types/training";
import { checkRole } from "../../utils/authHelper";
import { dp } from "../../utils/coreHelper";
import {
  formatDurationLong,
  formatPace,
  formatPaceChange,
} from "../../utils/dateHelper";
import { BsrTweaker } from "../bsr/BsrTweaker";
import { CssTweaker } from "../css/CssTweaker";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BoxWrapper } from "../ui/BoxWrapper";
import Loading from "../ui/Loading";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import {
  SingleValueWithUnitAndIndictorDisplay,
  SingleValueWithUnitDisplay,
} from "../ui/SingleValueWithUnitDisplay";

interface TrainingSummaryProps extends BoxProps {
  training: training | null | undefined;
}

const TrainingSummary: React.FC<TrainingSummaryProps> = ({ training }) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const subscriber = checkRole(accessToken, "read", "trainingSummary");

  if (!subscriber) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          Training Summary
        </Heading>
        <VStack>
          <NotAuthorised functionText={"Training Summary"} size={"small"} />
        </VStack>
      </BoxWrapper>
    );
  }

  if (!training) {
    return (
      <BoxWrapper>
        <Loading message="Loading training data" />
      </BoxWrapper>
    );
  }

  if (!training.summary) {
    return (
      <BoxWrapper>
        <Loading message="Loading training data" />
      </BoxWrapper>
    );
  }

  return (
    <BoxWrapper>
      <Heading mb={5} size="md">
        Training Summary
      </Heading>

      <Tabs mt={5} isFitted isLazy size="sm">
        <TabList>
          <Tab>Seven Days</Tab>
          <Tab>Four Weeks</Tab>
          <Tab>Twelve Weeks</Tab>
          <Tab>All Time</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TrainingSummaryPeriod
              trainingSummaryPeriod={training.summary.sevenDays}
            />
          </TabPanel>
          <TabPanel>
            <TrainingSummaryPeriod
              trainingSummaryPeriod={training.summary.fourWeeks}
            />
          </TabPanel>
          <TabPanel>
            <TrainingSummaryPeriod
              trainingSummaryPeriod={training.summary.twelveWeeks}
            />
          </TabPanel>
          <TabPanel>
            <TrainingSummaryPeriod
              trainingSummaryPeriod={training.summary.allTime}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <CssBsrSummary training={training} />
    </BoxWrapper>
  );
};

interface TrainingSummaryPeriodProps extends BoxProps {
  trainingSummaryPeriod: trainingSummaryPeriod | null | undefined;
}

const TrainingSummaryPeriod: React.FC<TrainingSummaryPeriodProps> = ({
  trainingSummaryPeriod,
}) => {
  const { user } = useAppSelector(selectUserState);

  if (!user) {
    return null;
  }

  if (!trainingSummaryPeriod) {
    trainingSummaryPeriod = {
      activities: 0,
      distance: {
        m: 0,
        y: 0,
      },
      laps: 0,
      stss: 0,
      swims: 0,
      time: 0,
    };
  }

  let distance =
    user.data.basic.defaultPool.lapUnit === "y"
      ? trainingSummaryPeriod.distance.y
      : trainingSummaryPeriod.distance.m;
  let displayDistance = distance;
  let displayUnit = user.data.basic.defaultPool.lapUnit
    ? user.data.basic.defaultPool.lapUnit
    : "m";
  if (distance > 9999) {
    if (displayUnit === "m") {
      displayDistance = dp(displayDistance / 1000, 1);
      displayUnit = "km";
    } else {
      displayDistance = dp(displayDistance / 1760, 1);
      displayUnit = "miles";
    }
  }
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={2}>
      <GridItem>
        <SingleValueDisplay
          label="No. of Activities"
          value={trainingSummaryPeriod.activities}
        />
      </GridItem>
      <GridItem>
        <SingleValueWithUnitDisplay
          label="Distance"
          value={displayDistance ? displayDistance : 0}
          unit={displayUnit}
        />
      </GridItem>
      <GridItem>
        <SingleValueDisplay
          label="Time"
          value={formatDurationLong(trainingSummaryPeriod.time)}
        />
      </GridItem>
    </Grid>
  );
};

interface CssBsrSummaryProps extends BoxProps {
  training: training | null | undefined;
}

const CssBsrSummary: React.FC<CssBsrSummaryProps> = ({ training }) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );

  if (
    !checkRole(accessToken, "summary", "css") &&
    !checkRole(accessToken, "summary", "bsr")
  ) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          CSS & BSR Summary
        </Heading>
        <VStack>
          <NotAuthorised
            functionText={"Full CSS Tools & History / Full BSR Tools & History"}
            size={"small"}
          />
        </VStack>
      </BoxWrapper>
    );
  }

  if (!training) {
    return (
      <BoxWrapper>
        <Loading message="Loading CSS & BSR data" />
      </BoxWrapper>
    );
  }

  return (
    <Box>
      <Heading size="md" mb={2}>
        CSS Summary
      </Heading>
      <CurrentCss />
      <Box my={4}>
        <CssTweaker small />
      </Box>
      <Heading mt={5} size="md" mb={2}>
        BSR Summary
      </Heading>
      <CurrentBsr />
      <Box my={4}>
        <BsrTweaker small />
      </Box>
    </Box>
  );
};

interface CurrentCssBsrProps {}

const CurrentCss: React.FC<CurrentCssBsrProps> = () => {
  const { user } = useAppSelector(selectUserState);
  const [cssEntry, setCssEntry] = useState<cssEntry | null>();
  const greenIndicator = useColorModeValue(
    "ssNeonGreenDark",
    "ssNeonGreenLight"
  );
  const redIndicator = useColorModeValue("red.500", "red.500");
  const orangeIndicator = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );

  useEffect(() => {
    const getCssAndBsrCurrentLocal = async () => {
      const currentCSS = await getCssCurrent();
      setCssEntry(currentCSS);
    };
    getCssAndBsrCurrentLocal();
  }, []);

  if (!user) {
    return null;
  }

  const lane =
    user.data.basic.lane && user.data.basic.lane >= 0
      ? user.data.basic.lane
      : 2;
  const unit = user.data.basic.defaultPool.lapUnit;

  return (
    <VStack w={"full"}>
      <Grid templateColumns="repeat(6, 1fr)" gap={2} w={"full"}>
        <GridItem colSpan={3}>
          {/* CSS */}
          <SingleValueWithUnitDisplay
            label={
              cssEntry && cssEntry.type === "defaulted"
                ? "Defaulted CSS for lane " + lane
                : "Current CSS"
            }
            unit={"/100" + unit}
            value={
              cssEntry
                ? unit === "y"
                  ? formatPace(cssEntry.y.secondsPer100)
                  : formatPace(cssEntry.m.secondsPer100)
                : "- "
            }
          />
        </GridItem>
        <GridItem colSpan={3}>
          {/* CSS Change */}
          <SingleValueWithUnitAndIndictorDisplay
            label="CSS Improvement (12 Weeks)"
            value={
              cssEntry && cssEntry.change
                ? formatPaceChange(cssEntry.change)
                : " - "
            }
            unit={"/100" + unit}
            indicator={
              cssEntry && cssEntry.change && cssEntry.change > 0
                ? FaUpLong
                : cssEntry && cssEntry.change && cssEntry.change < 0
                ? FaDownLong
                : MdOutlineTrendingFlat
            }
            indicatorColour={
              cssEntry && cssEntry.change && cssEntry.change > 0
                ? redIndicator
                : cssEntry && cssEntry.change && cssEntry.change < 0
                ? greenIndicator
                : orangeIndicator
            }
          />
        </GridItem>
      </Grid>
    </VStack>
  );
};

const CurrentBsr: React.FC<CurrentCssBsrProps> = () => {
  const { user } = useAppSelector(selectUserState);
  const [bsrEntry, setBsrEntry] = useState<bsrEntry | null>();
  const neutralIndicator = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");

  useEffect(() => {
    const getCssAndBsrCurrentLocal = async () => {
      const currentBsr = await getBsrCurrent();
      setBsrEntry(currentBsr);
    };
    getCssAndBsrCurrentLocal();
  }, []);

  if (!user) {
    return null;
  }

  return (
    <VStack w={"full"}>
      <Grid templateColumns="repeat(6, 1fr)" gap={2} w={"full"}>
        <GridItem colSpan={3}>
          {/* BSR */}
          <SingleValueWithUnitDisplay
            label="Current BSR"
            value={bsrEntry && bsrEntry.spm ? bsrEntry.spm : "- "}
            unit="spm"
          />
        </GridItem>
        <GridItem colSpan={3}>
          {/* BSR Change */}
          <SingleValueWithUnitAndIndictorDisplay
            label="BSR Improvement (12 Weeks)"
            value={
              bsrEntry && bsrEntry.change && bsrEntry.change > 0
                ? "+" + bsrEntry.change
                : bsrEntry && bsrEntry.change && bsrEntry.change < 0
                ? bsrEntry.change
                : "- "
            }
            unit="spm"
            indicator={
              bsrEntry && bsrEntry.change && bsrEntry.change < 0
                ? FaDownLong
                : bsrEntry && bsrEntry.change && bsrEntry.change > 0
                ? FaUpLong
                : MdOutlineTrendingFlat
            }
            indicatorColour={neutralIndicator}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
};

export { TrainingSummary };
