import { Text } from "@chakra-ui/react";
import { selectUserState } from "../../../app/features/user/userSlice";
import { useAppSelector } from "../../../app/hooks";
import Loading from "../../ui/Loading";
import { SubscriptionDetailsApple } from "./apple/SubscriptionDetailsApple";
import { SubscriptionDetailsBraintree } from "./braintree/SubscriptionDetailsBraintree";
import { SubscriptionDetailsFree } from "./free/SubscriptionDetailsFree";
import { SubscriptionDetailsNone } from "./none/SubscriptionDetailsNone";
import { SubscriptionDetailsPartner } from "./partner/SubscriptionDetailsPartner";
import { SubscriptionDetailsStripe } from "./stripe/SubscriptionDetailsStripe";

const SubscriptionDetails = (props: any) => {
  const { user } = useAppSelector(selectUserState);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  // Guidance is always free
  if (user.data.billing.source === "Free") {
    return <SubscriptionDetailsFree billing={user.data.billing} />;
  } else if (user.data.billing.source === "Partner") {
    return <SubscriptionDetailsPartner billing={user.data.billing} />;
  } else if (user.data.billing.source === "Braintree") {
    return <SubscriptionDetailsBraintree billing={user.data.billing} />;
  } else if (user.data.billing.source === "Apple") {
    return <SubscriptionDetailsApple billing={user.data.billing} />;
  } else if (user.data.billing.source === "Stripe") {
    return <SubscriptionDetailsStripe billing={user.data.billing} />;
  } else if (user.data.billing.source === "None") {
    return <SubscriptionDetailsNone billing={user.data.billing} />;
  } else if (user.data.billing.source === "Manual") {
    return (
      <Text>
        You are <em>special</em>, at least that's what your mum told me. If you
        need assistance with your <em>specialness</em> you'll need to give us a
        shout at hello@swimsmooth.com.
      </Text>
    );
  } else {
    return (
      <pre>
        <code>{JSON.stringify(user.data.billing, null, 2)}</code>
      </pre>
    );
  }
};

export { SubscriptionDetails };
