import { BoxProps } from "@chakra-ui/react";

interface SwimSmoothLogoProps extends BoxProps {
  height: number;
  width: number;
  colour?: string;
  opacity?: number;
}

const SwimSmoothLogo: React.FC<SwimSmoothLogoProps> = ({ height, width, colour, opacity }) => {
  if (!colour) {
    colour = "#002140";
  }
  if (!opacity) {
    opacity = 1;
  }
  return (
    <svg width={width} height={height} viewBox="0 0 1080 1080" fill={colour} fillRule="evenodd" clipRule="evenodd" xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path d="M749.359 153.905C762.639 161.129 769.226 164.741 769.651 167.291C769.97 169.097 769.226 171.009 767.845 172.178C765.933 173.877 758.177 172.39 742.773 169.309C593.719 139.775 420.337 181.633 301.562 285.853C300.606 286.703 299.65 287.447 298.481 288.403C295.931 290.315 293.169 289.997 291.788 288.934C290.407 287.872 289.557 285.11 290.407 282.666C290.832 281.285 291.151 280.435 291.682 279.479C299.119 263.756 310.805 241.658 311.23 224.66C311.442 217.967 311.336 206.493 298.587 216.267C172.588 313.051 104.382 457.323 110.757 615.513C110.757 618.594 107.145 619.125 106.613 616.15C102.47 592.672 100.239 568.874 100.027 545.395C96.5208 230.29 439.354 -14.6968 749.359 153.905Z" />
        <path d="M330.565 926.474C317.285 919.25 310.699 915.638 310.274 913.088C309.955 911.282 310.699 909.37 312.08 908.201C313.992 906.502 321.747 907.989 337.152 911.07C486.205 940.498 659.587 898.746 778.363 794.526C779.319 793.676 780.275 792.932 781.444 791.976C783.993 790.064 786.756 790.382 788.137 791.445C789.518 792.507 790.368 795.269 789.518 797.713C789.093 799.094 788.774 799.944 788.243 800.9C780.806 816.623 769.12 838.721 768.695 855.719C768.482 862.412 768.589 873.886 781.337 864.112C907.337 767.222 975.542 622.95 969.168 464.76C969.168 461.679 972.78 461.148 973.311 464.122C977.455 487.601 979.686 511.399 979.898 534.878C983.404 850.089 640.464 1095.08 330.565 926.474Z" />
        <path d="M631.328 313.9C633.028 304.233 632.496 298.708 629.734 295.415C627.184 292.44 622.616 291.165 614.436 291.165C598.181 291.165 593.719 295.415 590.638 314.113C588.832 323.356 589.257 328.88 592.019 332.174C594.569 335.254 599.244 336.529 607.53 336.529C623.147 336.529 628.247 331.642 631.328 313.9Z" />
        <path d="M606.468 345.878C596.694 345.878 590.001 344.391 586.707 341.416L554.517 525.635H592.657L625.166 341.416C620.916 344.497 614.648 345.878 606.468 345.878Z" />
        <path d="M417.469 307.632C410.669 299.558 398.664 295.202 382.835 295.202C369.13 295.202 358.719 298.283 350.751 304.445C342.358 311.138 337.046 321.231 334.602 335.467L327.909 372.544C323.872 396.342 333.434 405.903 349.688 418.864L350.113 419.183C363.181 429.488 369.767 434.588 367.536 447.336L361.162 483.458C359.781 490.894 357.337 493.869 352.344 493.869C349.794 493.869 347.988 493.232 346.926 491.957C345.332 490.045 345.12 486.857 346.076 481.545L354.257 434.694H315.798L307.299 482.289C304.643 497.588 306.449 508.849 312.93 516.498C319.729 524.678 331.628 528.609 349.263 528.609C379.223 528.609 393.034 516.498 398.452 485.37L405.782 445.105C409.607 421.095 400.258 411.959 384.641 399.104L383.578 398.254L383.154 397.935C367.855 384.974 364.243 381.893 366.049 371.588L371.786 338.973C373.167 330.899 375.823 327.393 380.604 327.393C382.941 327.393 384.641 328.03 385.703 329.305C387.191 331.111 387.509 334.298 386.553 339.185L379.01 381.043H415.556L423.418 338.123C425.755 325.268 423.737 314.963 417.469 307.632Z" />
        <path d="M490.667 415.04L483.974 443.937L457.308 525.953H420.231L431.705 340.566H467.826L458.583 433.738L452.315 468.584L462.089 433.738L492.155 340.566H516.59L512.765 433.738L509.047 468.266L516.271 433.738L541.981 340.566H577.783L521.052 525.953H484.293L486.843 443.937L490.667 415.04Z" />
        <path d="M770.395 346.41C766.039 341.31 759.346 338.654 750.315 338.654C739.054 338.654 729.918 345.241 723.331 358.202L722.906 358.946L722.8 358.096C721.206 345.241 713.132 338.654 698.683 338.654C686.997 338.654 677.754 345.347 671.38 358.521L670.955 359.477L670.849 358.415L669.893 340.885H640.252L607.743 525.741H644.926L669.574 384.337C671.167 376.369 673.186 372.119 679.029 372.119C681.048 372.119 682.535 372.651 683.491 373.819C684.979 375.625 685.297 378.813 684.341 384.443L659.694 525.741H696.877L721.843 384.337C723.118 375.732 725.881 372.119 730.98 372.119C733.105 372.119 734.698 372.651 735.654 373.925C737.142 375.732 737.461 378.919 736.611 384.231L711.645 525.847H749.466C750.422 520.11 769.863 409.409 774.75 379.344C777.513 364.152 776.025 353.103 770.395 346.41Z" />
        <path d="M811.828 574.186C810.872 575.355 810.022 576.736 809.278 578.117C810.128 576.736 810.978 575.461 811.828 574.186Z" />
        <path d="M852.517 569.936C848.693 565.368 842.743 563.137 834.35 563.137C822.239 563.137 812.89 569.83 807.366 582.579L806.516 584.597L806.941 582.366L819.583 511.08H782.4L740.329 749.799H777.513L802.16 609.67C803.966 597.771 807.897 596.178 811.934 596.178C813.953 596.178 815.546 596.815 816.609 597.984C818.096 599.79 818.521 603.083 817.671 607.864L792.705 749.799H830.526L856.342 601.489C858.998 586.404 857.723 576.098 852.517 569.936Z" />
        <path d="M318.029 531.797C311.23 523.722 299.225 519.367 283.395 519.367C269.69 519.367 259.279 522.448 251.311 528.609C242.918 535.303 237.606 545.395 235.163 559.631L228.47 596.709C224.432 620.506 233.994 630.068 250.249 643.029L250.673 643.348C263.741 653.653 270.328 658.752 268.097 671.501L261.722 707.622C260.341 715.059 257.898 718.034 252.905 718.034C250.355 718.034 248.549 717.396 247.486 716.121C245.893 714.209 245.68 711.022 246.636 705.71L254.817 658.858H216.358L207.859 706.454C205.203 721.752 207.009 733.013 213.49 740.662C220.289 748.843 232.188 752.774 249.824 752.774C279.783 752.774 293.594 740.663 299.012 709.534L306.343 669.27C310.167 645.26 300.818 636.123 285.201 623.268L284.351 622.525L283.82 621.994C268.522 609.032 264.91 605.951 266.716 595.646L272.452 563.031C273.834 554.957 276.49 551.451 281.27 551.451C283.608 551.451 285.307 552.088 286.37 553.363C287.857 555.169 288.176 558.356 287.22 563.243L279.677 605.102H316.223L324.085 562.181C326.209 549.432 324.191 539.233 318.029 531.797Z" />
        <path d="M469.101 570.574C464.745 565.474 458.052 562.818 449.022 562.818C437.76 562.818 428.624 569.405 422.037 582.366L421.612 583.11L421.506 582.26C419.912 569.405 411.838 562.818 397.39 562.818C385.703 562.818 376.461 569.511 370.086 582.685L369.661 583.641L369.555 582.579L368.599 565.049H339.064L306.555 749.905H343.739L368.386 608.501C369.98 600.533 371.998 596.284 377.842 596.284C379.86 596.284 381.348 596.815 382.304 597.983C383.791 599.79 384.11 602.977 383.154 608.607L358.506 749.905H395.69L420.656 608.501C421.931 599.896 424.693 596.284 429.792 596.284C431.917 596.284 433.511 596.815 434.467 598.09C435.954 599.896 436.273 603.083 435.423 608.395L410.457 750.011H448.278C449.234 744.275 468.676 633.574 473.563 603.508C476.219 588.316 474.732 577.267 469.101 570.574Z" />
        <path d="M569.497 575.567C562.379 567.068 550.905 562.818 535.394 562.818C510.109 562.818 492.367 578.117 487.799 603.827L470.376 702.735C467.507 718.565 469.738 731.526 476.963 740.131C483.974 748.524 495.342 752.774 510.64 752.774C536.988 752.774 554.092 738.431 558.554 712.403L576.296 613.176C579.164 597.24 576.721 584.172 569.497 575.567ZM540.069 607.97L522.964 706.878C521.37 715.271 518.29 719.096 513.19 719.096C510.959 719.096 509.259 718.458 508.091 717.077C506.391 715.059 505.966 711.447 506.922 706.453L524.345 607.226C525.62 599.152 528.595 595.646 533.8 595.646C536.244 595.646 537.944 596.284 539.112 597.665C540.706 599.471 541.025 602.764 540.069 607.97Z" />
        <path d="M660.437 712.403L678.179 613.176C680.941 597.24 678.604 584.172 671.38 575.567C664.262 567.068 652.788 562.818 637.277 562.818C611.992 562.818 594.25 578.117 589.682 603.827L572.259 702.735C569.39 718.565 571.621 731.526 578.846 740.131C585.858 748.524 597.225 752.774 612.523 752.774C638.977 752.774 655.975 738.431 660.437 712.403ZM641.952 607.97L624.847 706.772C623.254 715.165 620.173 718.99 615.073 718.99C612.842 718.99 611.142 718.352 609.974 716.971C608.274 714.953 607.849 711.34 608.805 706.347L626.228 607.12C627.503 599.046 630.478 595.54 635.684 595.54C638.127 595.54 639.827 596.177 640.996 597.558C642.589 599.471 642.908 602.764 641.952 607.97Z" />
        <path d="M728.961 712.722C724.181 712.722 721.206 712.403 719.612 710.384C717.913 708.366 717.7 704.86 718.869 698.061L735.654 602.977V602.764H735.867H753.715L760.302 564.943H742.56H742.241L742.348 564.624L747.341 536.684H720.887C719.294 541.996 717.063 546.776 714.938 551.132C712.176 556.338 708.776 560.269 704.101 563.562C699.852 566.112 696.027 567.068 692.415 567.705L686.147 602.658H699.427H699.746L699.639 602.977L680.941 708.472C677.967 725.258 678.923 735.351 684.022 741.406C688.909 747.143 697.515 749.693 713.026 749.693H727.687L733.955 712.509H728.961V712.722Z" />
      </g>
    </svg>
  );
};

export { SwimSmoothLogo };
