import { BoxProps, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { coach } from "../../types/coach";
import { CoachBox } from "./CoachBox";

interface CoachesListProps extends BoxProps {
  title: string;
  coaches: coach[];
  size: "small" | "standard" | "large";
  global: boolean;
}

const CoachesList: React.FC<CoachesListProps> = ({
  title,
  coaches,
  size,
  global,
}) => {
  let columns = [1, 2, 3, 4];
  if (size === "small") {
    columns = [1, 2, 4, 6];
  } else if (size === "large") {
    columns = [2, 2, 2, 2];
  }
  return (
    <VStack w="full" mb={5}>
      {coaches.length > 0 && (
        <>
          <Heading mt={2}>{title}</Heading>
          <SimpleGrid columns={columns} columnGap={3} rowGap={3} w="full">
            {coaches.map((coach: coach) => {
              return (
                <CoachBox
                  key={coach.id}
                  coachData={coach.data}
                  global={global}
                />
              );
            })}
          </SimpleGrid>
          {coaches.length === 0 && (
            <Text py={2}>No matching coaches found</Text>
          )}
        </>
      )}
      {coaches.length === 0 && (
        <>
          <Heading mt={2}>{title}</Heading>
          {coaches.length === 0 && (
            <Text py={2}>No matching coaches found</Text>
          )}
        </>
      )}
    </VStack>
  );
};

export { CoachesList };
