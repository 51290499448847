import { dp, printableNumber } from './coreHelper';

const getBeeperSettingForCSS = (defaultPool: any, css: any, offset: any, beepInterval: any): any => {
  beepInterval = beepInterval || getDefaultBeeperDistance('CSS', defaultPool);
  let unitsPerSecond = css.data.m.unitsPerSecond;
  if (defaultPool.lapUnit === 'y') {
    unitsPerSecond = css.data.y.unitsPerSecond;
  }
  let mode = 1;
  const u100Time = 100 / unitsPerSecond;
  const u100TimeWithOffset = u100Time + parseInt(offset, 10);
  const newCSSUnitsPerSecond = u100TimeWithOffset / 100;
  let beeperValue = newCSSUnitsPerSecond * beepInterval;
  let beeperValueText;
  let beeperSecondsPer100 = newCSSUnitsPerSecond * 100;

  // If we've gone over the mode 1 threshold, we must switch to mode 2
  if (beeperValue > 99.99) {
    mode = 2;
    beeperValueText = formatBeeperValue(beeperValue, 2);
  } else {
    beeperValueText = formatBeeperValue(beeperValue, 1);
  }
  const text = 'CSS ' + printableNumber(offset);

  return {
    value: beeperValue,
    settings: {
      mode: mode,
      interval: beepInterval,
      unit: defaultPool.lapUnit,
      setting: beeperValueText,
      secondsPer100: dp(beeperSecondsPer100, 2),
    },
    text: text,
  };
};

const getBeeperSettingForRMCycle = (defaultPool: any, css: any, offset: any, beepInterval: any): any => {
  offset = parseInt(offset, 10);
  beepInterval = beepInterval || getDefaultBeeperDistance('RM', defaultPool);
  let unitsPerSecond = css.data.m.unitsPerSecond;
  if (defaultPool.lapUnit === 'y') {
    unitsPerSecond = css.data.y.unitsPerSecond;
  }
  let mode = 2;
  const u100Time = 100 / unitsPerSecond;
  const u100TimeRM = u100Time / (100 / beepInterval);
  const u100TimeRMWithOffset = u100TimeRM + offset;
  const beeperValue = Math.ceil(u100TimeRMWithOffset);

  const text = 'RM Cycle ' + offset;

  return {
    value: beeperValue,
    pace: u100TimeRMWithOffset * (100 / beepInterval),
    settings: {
      mode: mode,
      interval: beepInterval,
      unit: defaultPool.lapUnit,
      setting: formatBeeperValue(beeperValue, 2),
      seconds: beeperValue,
    },
    text: text,
  };
};

const formatBeeperValue = (value: any, mode: any): any => {
  if (mode === 1) {
    return formatB1(value);
  } else if (mode === 2) {
    return formatB2(value);
  } else if (mode === 3) {
    return formatB3(value);
  }
  return '00:00';
};

const formatB1 = (value: any): any => {
  let inter = dp(value, 2).toString().split('.');
  const seconds = inter[0] ? inter[0].padStart(2, '0') : '00';
  const millis = inter[1] ? inter[1].padEnd(2, '0') : '00';
  return seconds + ':' + millis;
};

const formatB2 = (value: any): any => {
  const inter0 = ~~((value % 3600) / 60);
  const inter1 = (value % 60).toFixed(0);
  const minutes = inter0 ? inter0.toString().padStart(2, '0') : '00';
  const seconds = inter1 ? inter1.toString().padStart(2, '0') : '00';
  return minutes + ':' + seconds;
};

const formatB3 = (value: any): any => {
  let inter = Math.ceil(value);
  if (inter > 120) {
    inter = Math.round(inter / 5) * 5;
  }
  return inter.toString();
};

const getDefaultBeeperDistance = (type: any, defaultPool: any): any => {
  let baseDistance = 25;
  switch (type) {
    case 'CSS':
      // Mode 1 - CSS (keep with the beeper)
      switch (defaultPool.lapLength) {
        case 20:
          baseDistance = 20;
          break;
        case 25:
          baseDistance = 25;
          break;
        case 33:
          baseDistance = 33.3;
          break;
        case 50:
          baseDistance = 25;
          break;
        default:
          baseDistance = 25;
      }
      break;
    case 'RM':
      // Mode 2 - Red Mist (Beat the beeper)
      switch (defaultPool.lapLength) {
        case 20:
          baseDistance = 40;
          break;
        case 25:
          baseDistance = 50;
          break;
        case 33:
          baseDistance = 33.3;
          break;
        case 50:
          baseDistance = 50;
          break;
        default:
          baseDistance = 50;
      }
  }

  return baseDistance;
};

export { getBeeperSettingForCSS, getBeeperSettingForRMCycle, formatBeeperValue, formatB1, formatB2, formatB3, getDefaultBeeperDistance };
