import { createIcon } from "@chakra-ui/icons";

// using `path`
const CssIcon = createIcon({
  displayName: "Critical Swim Speed",
  viewBox: "0 0 1024 1024",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      fill="currentColor"
      d="M71.08,910h881.85c24.35,0,44.09,14.33,44.09,32v24c0,4.42-4.94,8-11.02,8H38.01c-6.09,0-11.02-3.58-11.02-8v-24
	C26.98,924.33,46.73,910,71.08,910z"
    />,
    <g>
      <path
        fill="currentColor"
        d="M181.33,871.46c-47.44,0-83.46-27.5-113.18-78.59C25.28,719.2,26.99,620.96,26.99,515.85S25.28,312.5,68.15,238.83
		c29.72-51.08,65.74-78.59,113.18-78.59c77.17,0,139.48,73.68,154.34,227.91H233.92c-6.29-39.3-18.86-74.66-52.02-74.66
		c-18.29,0-32.01,12.77-39.44,29.47c-9.15,20.63-15.43,45.19-15.43,172.89s6.29,152.27,15.43,172.89
		c7.43,16.7,21.15,29.47,39.44,29.47c33.15,0,45.73-35.37,52.02-74.66h101.75C320.81,797.79,258.5,871.46,181.33,871.46z"
      />
      <path
        fill="currentColor"
        d="M504.29,871.46c-62.31,0-113.18-19.64-154.34-92.34l64.02-110.02c21.15,36.34,58.31,49.12,90.89,49.12
		c39.44,0,58.3-22.59,58.3-62.87c0-16.7-2.29-30.46-8-41.26c-5.14-8.84-13.72-15.72-26.86-18.67l-49.16-11.79
		c-36.01-8.84-63.45-29.47-81.74-61.89c-18.86-33.4-28.01-80.55-28.01-140.47c0-127.71,56.02-221.03,148.63-221.03
		c58.3,0,102.32,23.58,137.19,83.5L592.32,351.8c-25.72-44.21-59.45-41.26-77.17-41.26c-34.87,0-49.16,34.38-49.16,64.83
		c0,8.84,1.71,21.61,8,32.42c5.14,8.84,13.72,17.68,28.01,20.63l49.16,11.79c36.58,8.84,62.88,28.49,80.03,57.96
		c21.72,36.34,30.3,88.41,30.3,153.24C661.48,793.86,590.03,871.46,504.29,871.46z"
      />
      <path
        fill="currentColor"
        d="M839.82,871.46c-62.31,0-113.18-19.64-154.34-92.34L749.5,669.1c21.15,36.34,58.31,49.12,90.89,49.12
		c39.44,0,58.3-22.59,58.3-62.87c0-16.7-2.29-30.46-8-41.26c-5.14-8.84-13.72-15.72-26.86-18.67l-49.16-11.79
		c-36.01-8.84-63.45-29.47-81.74-61.89c-18.86-33.4-28.01-80.55-28.01-140.47c0-127.71,56.02-221.03,148.62-221.03
		c58.3,0,102.32,23.58,137.19,83.5L927.85,351.8c-25.72-44.21-59.45-41.26-77.17-41.26c-34.87,0-49.16,34.38-49.16,64.83
		c0,8.84,1.72,21.61,8,32.42c5.14,8.84,13.72,17.68,28.01,20.63l49.16,11.79c36.58,8.84,62.88,28.49,80.03,57.96
		c21.72,36.34,30.3,88.41,30.3,153.24C997.02,793.86,925.56,871.46,839.82,871.46z"
      />
    </g>,
    <path
      fill="currentColor"
      d="M952.92,114H71.08c-24.35,0-44.09-14.33-44.09-32V58c0-4.42,4.94-8,11.02-8h947.98c6.09,0,11.02,3.58,11.02,8v24
	C997.02,99.67,977.27,114,952.92,114z"
    />,
  ],
});

const BsrIcon = createIcon({
  displayName: "Base Stroke Rate",
  viewBox: "0 0 1024 1024",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      fill="currentColor"
      d="M71.08,910h881.85c24.35,0,44.09,14.33,44.09,32v24c0,4.42-4.94,8-11.02,8H38.01c-6.09,0-11.02-3.58-11.02-8v-24
	C26.98,924.33,46.73,910,71.08,910z"
    />,
    <g>
      <path
        fill="currentColor"
        d="M192.23,864.69H25.48V156.95h158.67c79.61,0,122.1,82.5,122.1,204.77c0,80.52-30.12,125.25-41.95,140.16
		c15.6,17.89,47.33,54.67,47.33,154.07C311.64,791.13,260.54,864.69,192.23,864.69z M178.24,313.01h-58.63v115.3h58.63
		c25.82,0,33.89-29.82,33.89-57.65C212.13,342.83,204.06,313.01,178.24,313.01z M183.08,584.37h-63.47v124.25h63.47
		c25.82,0,34.42-32.8,34.42-62.62C217.51,616.18,208.9,584.37,183.08,584.37z"
      />
      <path
        fill="currentColor"
        d="M485.92,870.65c-58.63,0-106.5-19.88-145.23-93.44l60.24-111.33c19.9,36.78,54.86,49.7,85.52,49.7
		c37.11,0,54.86-22.86,54.86-63.62c0-16.9-2.15-30.81-7.53-41.75c-4.84-8.95-12.91-15.9-25.28-18.89l-46.26-11.93
		c-33.89-8.95-59.7-29.82-76.92-62.62c-17.75-33.8-26.36-81.51-26.36-142.14c0-129.22,52.71-223.65,139.85-223.65
		c54.86,0,96.28,23.85,129.09,84.49l-59.17,109.34c-24.2-44.73-55.94-41.75-72.61-41.75c-32.81,0-46.26,34.79-46.26,65.6
		c0,8.95,1.61,21.87,7.53,32.8c4.84,8.95,12.91,17.89,26.36,20.88l46.26,11.93c34.42,8.95,59.17,28.83,75.3,58.65
		c20.44,36.78,28.51,89.46,28.51,155.07C633.84,792.12,566.6,870.65,485.92,870.65z"
      />
      <path
        fill="currentColor"
        d="M889.33,864.69l-67.77-263.41h-31.74v263.41h-94.13V156.95h151.68c84.98,0,130.7,111.33,130.7,227.63
		c0,96.42-32.27,157.05-62.39,184.89l82.83,295.22H889.33z M843.07,313.01h-53.25v143.14h53.25c25.82,0,40.88-34.79,40.88-71.57
		S868.89,313.01,843.07,313.01z"
      />
    </g>,
    <path
      fill="currentColor"
      d="M952.92,114H71.08c-24.35,0-44.09-14.33-44.09-32V58c0-4.42,4.94-8,11.02-8h947.98c6.09,0,11.02,3.58,11.02,8v24
	C997.02,99.67,977.27,114,952.92,114z"
    />,
  ],
});

export { CssIcon, BsrIcon };
