import { LatLngBoundsExpression } from "leaflet";
import { useMap } from "react-leaflet";
import { coachLocation } from "../../types/coach";

const SetBounds = (props: any) => {
  const locations = props.locations;
  const bounds: LatLngBoundsExpression = [];
  const map = useMap();

  if (locations.length > 1) {
    locations.forEach((location: coachLocation) => {
      bounds.push(location.ll);
    });

    if (bounds.length > 0) {
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }
  return null;
};

export { SetBounds };
