import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Define the state of the slice as an object
export interface CountryState {
  country: string | null;
  currency: string | null;
  lastUpdated: string;
}

export interface CountryStateUpdate {
  country: string | null;
  currency: string | null;
}

// Define an initial state
const initialState: CountryState = {
  country: null,
  currency: null,
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
};

// Create a slice containing the configuration of the state
// and the reducers functions
const countrySlice = createSlice({
  name: "countryState",
  initialState,
  reducers: {
    updateCountryState(
      state,
      action: PayloadAction<CountryStateUpdate | null>
    ) {
      if (action.payload) {
        state.country = action.payload.country;
        state.currency = action.payload.currency;
        state.lastUpdated = new Date().toISOString();
      } else {
        state.country = initialState.country;
        state.currency = initialState.currency;
        state.lastUpdated = initialState.lastUpdated;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateCountryState } = countrySlice.actions;

export const selectCountryState = (state: RootState): CountryState => {
  return state.countryState;
};

// Export default the slice reducer
export default countrySlice.reducer;
