import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
  HStack,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectUserState } from "../../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { deleteUser } from "../../../DataAccess/users";
import { clearState } from "../../../utils/authHelper";
import { formatDateTime } from "../../../utils/dateHelper";
import { createToast } from "../../../utils/toastHelper";
import { CreateSupportTicket } from "../../ui/CreateSupportTicket";
import { CustomToast } from "../../ui/CustomToast";
import Loading from "../../ui/Loading";

const AccountSettingsDelete = () => {
  const toast = useToast();
  const { user } = useAppSelector(selectUserState);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const deleteRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = async (): Promise<void> => {
    toast.closeAll();
    if (user) {
      try {
        setIsDeleting(true);
        await deleteUser();
        // LOGOUT
        try {
          clearState(dispatch, true);
          // Go back to landing page
          setIsDeleting(false);
          onClose();
          navigate("/", { replace: false });
        } catch (error) {
          setIsDeleting(false);
          onClose();
        }
      } catch (error: any) {
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Request Delete Account"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Unable to request account deletion, please contact support.
              </Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (user && user.processes && user.processes.scheduledDeletion) {
    const scheduledDeletion = new Date(user.processes.scheduledDeletion);
    if (scheduledDeletion > new Date()) {
      return (
        <VStack w="full" alignItems="flex-start" mb={5}>
          <Heading as="h3" size="md" color="red">
            Request Account Deletion
          </Heading>
          <Text>
            Your account is scheduled to be deleted on{" "}
            {formatDateTime(scheduledDeletion)}
          </Text>
          <Text>
            If you did not request this or you have changed your mind please
            contact support urgently. Once deletion has completed it{" "}
            <em>cannot</em> be reversed.
          </Text>
        </VStack>
      );
    } else if (scheduledDeletion < new Date()) {
      return (
        <VStack w="full" alignItems="flex-start" mb={5}>
          <Heading as="h3" size="md" color="red">
            Request Account Deletion (Failed)
          </Heading>
          <Text>
            Your account was scheduled to be deleted on{" "}
            {formatDateTime(scheduledDeletion)}
          </Text>
          <Text>
            If you did not request this or you have changed your mind please
            contact support urgently. Once deletion has completed it{" "}
            <em>cannot</em> be reversed.
          </Text>
        </VStack>
      );
    }
  }

  if (
    user.data.billing.subscriptionStatus === "Active" ||
    user.data.billing.subscriptionStatus === "Cancelled"
  ) {
    return (
      <VStack w="full" alignItems="flex-start" mb={5}>
        <Heading as="h3" size="md" color="red">
          Request Account Deletion
        </Heading>
        <Text>
          You cannot request an account deletion whilst you have an active
          subscription. If your account must be deleted sooner than this please
          contact support.
        </Text>
        <Text>{user.data.billing.subscriptionDescription}</Text>
      </VStack>
    );
  }

  return (
    <VStack w="full" alignItems="flex-start" mb={5}>
      <Heading as="h3" size="md" color="red">
        Request Account Deletion
      </Heading>
      <Text>
        By requesting an account deletion we schedule a deletion task which will
        perform the following actions:
      </Text>
      <UnorderedList>
        <ListItem>
          Disconnect any connected accounts (Garmin, Strava, etc)
        </ListItem>
        <ListItem>
          Remove your email address from <em>ALL</em> Swim Smooth HQ mailing
          lists
        </ListItem>
        <ListItem>
          <em>Irreversibly</em> delete <em>ALL</em> data related to your account
        </ListItem>
      </UnorderedList>
      <Text>
        The task will be delayed by 48 hours just in case you change your mind.
        Once complete this task <em>CANNOT</em> be reversed.
      </Text>
      <HStack w="full">
        <Spacer />
        <Button variant="warning" isLoading={isDeleting} onClick={onOpen}>
          Request Account Deletion
        </Button>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={deleteRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Account
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={deleteRef} onClick={onClose}>
                  Back
                </Button>
                <Button
                  variant="warning"
                  px={20}
                  isLoading={isDeleting}
                  onClick={handleDelete}
                  ml={3}
                >
                  Request Account Deletion
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </HStack>
    </VStack>
  );
};

export { AccountSettingsDelete };
