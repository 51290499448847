"use client";

import {
  Box,
  Button,
  HStack,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { getLevel } from "../../utils/authHelper";

interface PriceProps {
  name: string;
  description: string;
  annual: number;
  monthly: number;
  features: string[];
  featured: boolean;
}

const Price: React.FC<PriceProps> = ({
  name,
  description,
  annual,
  monthly,
  features,
  featured,
}) => {
  const featuredColour = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  const featuredTextColour = useColorModeValue("white", "ssPrimaryDark");
  const currentColour = useColorModeValue(
    "ssNeonGreenDark",
    "ssNeonGreenLight"
  );
  const primaryColor = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  const currentTextColour = useColorModeValue("white", "ssPrimaryDark");
  const innerBoxColorMode = useColorModeValue(
    "ssInnerBoxBackgroundLight",
    "ssInnerBoxBackgroundDark"
  );
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );

  return (
    <VStack
      key={name}
      borderWidth="1px"
      borderColor={primaryColor}
      borderRadius={"xl"}
      rowGap={0}
      position="relative"
    >
      <Box py={2} px={5} justifyContent="center">
        {name !== getLevel(accessToken) && featured && (
          <Box
            position="absolute"
            top="-16px"
            left="50%"
            style={{ transform: "translate(-50%)" }}
          >
            <Text
              textTransform="uppercase"
              bg={featuredColour}
              px={3}
              py={1}
              color={featuredTextColour}
              fontSize="sm"
              fontWeight="700"
              rounded="xl"
            >
              Most Popular
            </Text>
          </Box>
        )}
        {name === getLevel(accessToken) && (
          <Box
            position="absolute"
            top="-16px"
            left="50%"
            style={{ transform: "translate(-50%)" }}
          >
            <Text
              textTransform="uppercase"
              bg={currentColour}
              px={3}
              py={1}
              color={currentTextColour}
              fontSize="sm"
              fontWeight="700"
              rounded="xl"
            >
              Current Level
            </Text>
          </Box>
        )}
        <Text fontWeight="500" fontSize="2xl" pt={5}>
          {name}
        </Text>
        <Text fontSize="md" minHeight={220}>
          {description}
        </Text>
        <HStack justifyContent="center" mt={2}>
          <Text fontSize="2xl" fontWeight="900">
            {annual > 0 && "£" + annual}
            {annual === 0 && "Free"}
            {annual < 0 && "Coming"}
          </Text>
          <Text fontSize="xl" fontWeight="200">
            {annual > 0 && " / year"}
          </Text>
        </HStack>
        <HStack justifyContent="center" mt={-1} mb={5}>
          <Text fontSize="l" fontWeight="600">
            {monthly > 0 && "£" + monthly}
            {monthly === 0 && "Forever"}
            {monthly < 0 && "Soon"}
          </Text>
          <Text fontSize="m" fontWeight="200">
            {monthly > 0 && " / month"}
          </Text>
        </HStack>
      </Box>
      <VStack
        bg={innerBoxColorMode}
        py={2}
        borderBottomRadius={12}
        flexGrow="1"
        w="full"
      >
        <List w="full" spacing={2} px={5} text-align="start">
          {features.map((f: string, index: number) => {
            return (
              <ListItem m={0} key={index} fontSize={"sm"}>
                <ListIcon as={FaCheckCircle} color={currentColour} mb={0.5} />
                {f}
              </ListItem>
            );
          })}
        </List>
      </VStack>
    </VStack>
  );
};

export default function Pricing() {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectUserState);
  return (
    <Box pt={10}>
      <SimpleGrid columns={[1, 1, 4, 4]} columnGap={5} rowGap={5}>
        <Price
          name="Guidance"
          description="Gain FREE access to our essential library of Swim Smooth drills to guide you on your path to a more efficient freestyle stroke"
          annual={0}
          monthly={0}
          features={["Essential Drills", "Basic CSS Calculator"]}
          featured={false}
        />
        <Price
          name="Understanding"
          description="Enhance your understanding of how to improve all aspects of your swimming including technique, training and open water adaptation and follow along with a training session from our virtual squad each week"
          annual={19.99}
          monthly={1.99}
          features={[
            "All of 'Guidance'",
            "Full Drill Library",
            "Enhanced CSS Calculator",
            "Save Current CSS",
            "Manual CSS Entry",
            "Manual BSR Entry",
            "Basic Content Library",
            "Basic Session Library",
            "1 WBSS Session / Week",
          ]}
          featured={false}
        />
        <Price
          name="Routine"
          description="Build your weekly routine with access to the full suite of features designed to motivate, inspire and keep you on track as you develop your skills and fitness to become the best swimmer you can be"
          annual={89.99}
          monthly={8.99}
          features={[
            "All of 'Understanding'",
            "Fitness Tracking",
            "Training Summary",
            "Training Balance Tracking",
            "Stroke Insights (Apple & Selected Garmin Devices)",
            "Full Content Library",
            "Full Session Library",
            "Full CSS Tools & History",
            "Full BSR Tools & History",
            "Session Roulette",
            "Dynamic Training Plans",
            "At least 3 WBSS Sessions / Week",
            "WBSS Archive",
            "Garmin Connectivity",
            "Strava Connectivity (Coming Soon)",
          ]}
          featured={true}
        />
        <Price
          name="Ultimate"
          description="Get the ultimate coaching experience with a certified Swim Smooth Coach providing you with your own bespoke training plan within the Guru, keeping you accountable and on track with your goals"
          annual={-1}
          monthly={-1}
          features={["All of 'Routine'", "Real World Coaching"]}
          featured={false}
        />
      </SimpleGrid>
      {!user && (
        <Box w={"full"} pt={4} pb={4}>
          <Button
            w="full"
            onClick={() => {
              navigate("/account/create", { replace: false });
            }}
          >
            Create an account to get started
          </Button>
        </Box>
      )}
      {user && (
        <Box w={"full"} pt={4} pb={4}>
          <Button
            w="full"
            onClick={() => {
              navigate("/account/subscription", { replace: false });
            }}
          >
            Upgrade Now
          </Button>
        </Box>
      )}
    </Box>
  );
}
