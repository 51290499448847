import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Footer: ComponentStyleConfig = {
  parts: ["main"],
  baseStyle: (props: any) => ({
    main: {
      backgroundColor: mode(
        "rgba(255,255,255,0.95)",
        "rgba(0,0,0,0.95)"
      )(props),
      py: 2,
      px: 5,
      position: ["static", "static", "fixed", "fixed"],
      left: 0,
      bottom: 0,
      right: 0,
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};
