import { VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  selectUserState,
  updateUserState,
} from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loading from "../../components/ui/Loading";
import { setGarminAccessToken } from "../../DataAccess/garmin";
import { getUserById } from "../../DataAccess/users";
import { user } from "../../types/user";

const ConnectionsGarminOAuthPage = () => {
  const [search] = useSearchParams();
  const garminOAuthToken = search.get("oauth_token");
  const garminOAuthVerifier = search.get("oauth_verifier");

  const { user } = useAppSelector(selectUserState);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const setGarminAccessTokenLocal = async () => {
      try {
        if (user && garminOAuthToken && garminOAuthVerifier) {
          await setGarminAccessToken(
            user._id,
            garminOAuthToken,
            garminOAuthVerifier
          );
          const userResponse: user | null = await getUserById(user._id);
          if (userResponse) {
            dispatch(updateUserState(userResponse));
          }
          navigate("/account/connections", { replace: false });
        }
      } catch (error) {
        console.log(error);
        navigate("/account/connections", { replace: false });
      }
    };
    if (user && garminOAuthToken && garminOAuthVerifier) {
      setGarminAccessTokenLocal();
    } else {
      navigate("/account/connections", { replace: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <Loading message={"Storing Garmin Connection Tokens"} />
    </VStack>
  );
};

export { ConnectionsGarminOAuthPage };
