import { VStack } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ActivityDetail } from '../../components/activities/ActivityDetail';
import { BreadcrumbContext, breadcrumbContextType } from '../../components/context/BreadcrumbContext';

const ActivityDetailPage = () => {
  const { activityId } = useParams<{ activityId: string }>();
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks = [
      { href: '/activities', title: 'Activities' },
      { href: '/activities/' + activityId, title: 'Activity' },
    ];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!activityId) {
    return <Navigate to={{ pathname: '/activities' }} />;
  }

  return (
    <VStack w="full">
      <ActivityDetail activityId={activityId} />
    </VStack>
  );
};

export { ActivityDetailPage };
