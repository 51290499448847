import md5 from "md5";

const getGravatarUrl = (emailAddress: string, size?: number): string => {
  if (!size) {
    size = 200;
  }
  return (
    "//www.gravatar.com/avatar/" +
    md5(emailAddress) +
    "?s=" +
    size +
    "&r=pg&d=404"
  );
};

export { getGravatarUrl };
