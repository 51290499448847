import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { activity } from "../../../types/activity";
import { RootState } from "../../store";

export const loadSize = 10;
// Define the state of the slice as an object
export interface ActivitiesForLinkingState {
  lastUpdated: string;
  lastLoad: number;
  lastActivity: string;
  activities: activity[];
}

export interface ActivitiesForLinkingStateUpdate {
  lastActivity: string;
  lastLoad: number;
  activities: activity[];
}

// Define an initial state
const initialState: ActivitiesForLinkingState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
  lastLoad: loadSize,
  lastActivity: "",
  activities: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const activitiesForLinkingSlice = createSlice({
  name: "activitiesForLinkingState",
  initialState,
  reducers: {
    updateActivitiesForLinkingState(
      state,
      action: PayloadAction<ActivitiesForLinkingStateUpdate | null>
    ) {
      if (action.payload) {
        state.lastUpdated = new Date().toISOString();
        state.lastLoad = action.payload.lastLoad;
        state.lastActivity = action.payload.lastActivity;
        state.activities = action.payload.activities;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.lastLoad = initialState.lastLoad;
        state.lastActivity = initialState.lastActivity;
        state.activities = initialState.activities;
      }
    },
    removeActivityFromState(state, action: PayloadAction<string>) {
      state.activities = state.activities.filter(
        (activity: activity) => activity.id !== action.payload
      );
    },
  },
});

// Export each reducers function defined in createSlice
export const updateActivitiesForLinkingState =
  activitiesForLinkingSlice.actions.updateActivitiesForLinkingState;
export const removeActivityFromState =
  activitiesForLinkingSlice.actions.removeActivityFromState;

export const selectActivitiesForLinkingState = (
  state: RootState
): ActivitiesForLinkingState => {
  return state.activitiesForLinkingState;
};

// Export default the slice reducer
export default activitiesForLinkingSlice.reducer;
