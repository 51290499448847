import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BiDownArrowCircle, BiUpArrowCircle } from "react-icons/bi";
import { TempoTrainer } from "../svg/TempoTrainer";

interface SwimDescriptionProps extends BoxProps {
  text: any;
}

const SwimDescription: React.FC<SwimDescriptionProps> = ({ text }) => {
  if (text.type === "beeperanddrill") {
    if (text.split) {
      if (text.data.userHasBase) {
        return (
          <Grid templateColumns="repeat(2, 0.5fr)" w="full">
            <GridItem>
              <HStack mb={2}>
                <Icon h={5} w={5} color="grey" mr={2} as={BiUpArrowCircle} />
                <VStack alignItems="flex-start">
                  <Box mr={5} display="inline-block">
                    {text.data.up.drill}
                  </Box>
                  <HStack>
                    <Box mr={4} display="inline-block">
                      {text.data.up.beeper.instruction}
                    </Box>
                    <TempoTrainer height={22} width={22} />
                    <Box display="inline-block">
                      Mode {text.data.up.beeper.mode} /{" "}
                      {text.data.up.beeper.actual.setting}
                    </Box>
                  </HStack>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack mb={2}>
                <Icon h={5} w={5} color="grey" mr={2} as={BiDownArrowCircle} />
                <VStack alignItems="flex-start">
                  <Box mr={5} display="inline-block">
                    {text.data.down.drill}
                  </Box>
                  <HStack>
                    <Box mr={4} display="inline-block">
                      {text.data.down.beeper.instruction}
                    </Box>
                    <TempoTrainer height={22} width={22} />
                    <Box display="inline-block">
                      Mode {text.data.down.beeper.mode} /{" "}
                      {text.data.down.beeper.actual.setting}
                    </Box>
                  </HStack>
                </VStack>
              </HStack>
            </GridItem>
          </Grid>
        );
      }
      return (
        <Grid templateColumns="repeat(2, 0.5fr)" w="full">
          <GridItem>
            <HStack mb={2}>
              <Icon h={5} w={5} color="grey" mr={2} as={BiUpArrowCircle} />
              <VStack alignItems="flex-start">
                <Box mr={5} display="inline-block">
                  {text.data.up.drill}
                </Box>
                <HStack>
                  <Box mr={4} display="inline-block">
                    {text.data.up.beeper.instruction}
                  </Box>
                </HStack>
              </VStack>
            </HStack>
          </GridItem>
          <GridItem>
            <HStack mb={2}>
              <Icon h={5} w={5} color="grey" mr={2} as={BiDownArrowCircle} />
              <VStack alignItems="flex-start">
                <Box mr={5} display="inline-block">
                  {text.data.down.drill}
                </Box>
                <HStack>
                  <Box mr={4} display="inline-block">
                    {text.data.down.beeper.instruction}
                  </Box>
                </HStack>
              </VStack>
            </HStack>
          </GridItem>
        </Grid>
      );
    }
    if (text.data.userHasBase) {
      return (
        <HStack>
          <HStack alignItems="flex-start">
            <Box mr={10} display="inline-block">
              {text.data.drill}
            </Box>
            <Box mr={4} display="inline-block">
              {text.data.beeper.instruction}
            </Box>
            <TempoTrainer height={22} width={22} />
            <Box display="inline-block">
              Mode {text.data.beeper.mode} / {text.data.beeper.actual.setting}
            </Box>
          </HStack>
        </HStack>
      );
    }
    return <Text>{text.data.instruction}</Text>;
  } else if (text.type === "beeper") {
    // Beeper can't be split so no point checking
    if (text.data.userHasBase) {
      return (
        <HStack alignItems="flex-start">
          <Box mr={4} display="inline-block">
            {text.data.instruction}
          </Box>
          <TempoTrainer height={22} width={22} />
          <Box display="inline-block">
            Mode {text.data.mode} / {text.data.actual.setting}
          </Box>
        </HStack>
      );
    }
    return <Text>{text.data.instruction}</Text>;
  } else if (text.type === "simple") {
    if (text.split) {
      return (
        <Grid templateColumns="repeat(2, 0.5fr)" w="full">
          <GridItem>
            <Icon h={5} w={5} color="grey" mr={2} as={BiUpArrowCircle} />
            <Box display="inline-block" position="relative" top="-6px">
              {text.data.up}
            </Box>
          </GridItem>
          <GridItem>
            <Icon h={5} w={5} color="grey" mr={2} as={BiDownArrowCircle} />
            <Box display="inline-block" position="relative" top="-6px">
              {text.data.down}
            </Box>
          </GridItem>
        </Grid>
      );
    }
    return <Text>{text.data}</Text>;
  }
  return <Text>{JSON.stringify(text)}</Text>;
};

export { SwimDescription };
