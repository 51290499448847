import { mode } from "@chakra-ui/theme-tools";

export const Text = {
  baseStyle: (props: any) => ({
    color: mode("ssPrimaryDark", "white")(props),
    lineHeight: 1.75,
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};
