import { BoxProps } from "@chakra-ui/react";

interface SwimSmoothLogoWideProps extends BoxProps {
  height: number;
  width: number;
  colour?: string;
  opacity?: number;
}

const SwimSmoothLogoWide: React.FC<SwimSmoothLogoWideProps> = ({
  height,
  width,
  colour,
  opacity,
}) => {
  if (!colour) {
    colour = "#002140";
  }
  if (!opacity) {
    opacity = 1;
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 339.2 99"
      fill={colour}
      fillRule="evenodd"
      clipRule="evenodd"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <g>
          <path d="M21.4,82.4c8.4,0,12.2-3.5,13.7-12l2-11.1c1.1-6.6-1.4-9.1-5.9-12.8-4.6-3.9-5.6-4.6-5.1-7.5l1.6-9c.4-2.1,1.1-3.2,2.4-3.2s2,.8,1.6,3.2l-2.1,11.7h10.3l2.2-11.9c1.3-7.6-2.9-11.9-11.3-11.9s-12,3.2-13.4,11.2l-1.8,10.3c-1.1,6.8,1.7,9.4,6.1,12.9,3.7,2.9,5.5,4.3,4.9,7.8l-1.8,10c-.4,1.9-1,2.8-2.4,2.8s-2.1-1-1.7-3.3l2.3-13.1h-10.8l-2.4,13.2c-1.4,8.1,1.6,12.9,11.7,12.9Z" />
          <path d="M58.3,58.9l1.8-8-1.1,8-.7,22.7h10.2l15.7-51.3h-9.9l-7.1,25.8-2,9.6,1.1-9.6,1.1-25.8h-6.8l-8.3,25.8-2.7,9.6,1.8-9.6,2.5-25.8h-10l-3.2,51.3h10.3l7.4-22.7Z" />
          <path d="M91.7,29.3c4.2,0,5.8-1.2,6.7-6.3.9-5.1-.4-6.4-4.7-6.4s-5.8,1.1-6.7,6.4c-1,5,.4,6.3,4.7,6.3ZM77,81.7h10.7l9-51.2c-1.1.8-2.7,1.3-5.3,1.3s-4.6-.4-5.5-1.3l-8.9,51.2Z" />
          <path d="M108.9,42.5c.4-2.2,1-3.3,2.5-3.3s1.8,1.2,1.4,3.3l-6.8,39.2h10.4l6.9-39.2c.4-2.3,1.1-3.3,2.5-3.3s1.8,1.1,1.5,3.2l-6.9,39.3h10.6s5.6-32,7-40.6c1.4-7.8-1.3-11.3-6.8-11.3s-5.8,2-7.5,5.4c-.4-3.6-2.7-5.4-6.8-5.4s-5.9,1.9-7.6,5.5l-.3-4.9h-8.3l-9,51.3h10.4l6.8-39.2Z" />
        </g>
        <g>
          <path d="M162,83c8.4,0,12.2-3.5,13.7-12l2-11.1c1.1-6.6-1.4-9.1-5.9-12.8-4.6-3.9-5.6-4.6-5.1-7.5l1.6-9c.4-2.1,1.1-3.2,2.4-3.2s2,.8,1.6,3.2l-2.1,11.7h10.3l2.2-11.9c1.3-7.6-2.9-11.9-11.3-11.9s-12,3.2-13.4,11.2l-1.8,10.3c-1.1,6.8,1.7,9.4,6.1,12.9,3.7,2.9,5.5,4.3,4.9,7.8l-1.8,10c-.4,1.9-1,2.8-2.4,2.8s-2.1-1-1.7-3.3l2.3-13.1h-10.8l-2.4,13.2c-1.4,8.1,1.6,12.9,11.7,12.9Z" />
          <path d="M194.4,43c.4-2.2,1-3.3,2.5-3.3s1.8,1.2,1.4,3.3l-6.8,39.2h10.4l6.9-39.2c.4-2.3,1.1-3.3,2.5-3.3s1.8,1.1,1.5,3.2l-6.9,39.3h10.6s5.6-32,7-40.6c1.4-7.8-1.3-11.3-6.8-11.3s-5.8,2-7.5,5.4c-.4-3.6-2.7-5.4-6.8-5.4s-5.9,1.9-7.6,5.5l-.3-4.9h-8.3l-9,51.3h10.4l6.8-39.2Z" />
          <path d="M233.7,83c7.5,0,12.1-4.1,13.3-11.2l4.9-27.4c1.5-8.5-2.2-14-11.4-14s-12,4.5-13.2,11.4l-4.8,27.4c-1.5,8.2,1.8,13.9,11.2,13.9ZM234.4,73.5c-1.4,0-2.1-1-1.7-3.4l4.8-27.4c.4-2.2,1.1-3.2,2.5-3.2s2.1.9,1.7,3.3l-4.7,27.4c-.4,2.4-1.3,3.3-2.6,3.3Z" />
          <path d="M262.3,83c7.5,0,12.1-4.1,13.3-11.2l4.9-27.4c1.5-8.5-2.2-14-11.4-14s-12,4.5-13.2,11.4l-4.8,27.4c-1.5,8.2,1.8,13.9,11.2,13.9ZM263,73.5c-1.4,0-2.1-1-1.7-3.4l4.8-27.4c.4-2.2,1.1-3.2,2.5-3.2s2.1.9,1.7,3.3l-4.7,27.4c-.4,2.4-1.3,3.3-2.6,3.3Z" />
          <path d="M296.1,71.8h-1.5c-2.5,0-3.3-.4-2.7-3.9l4.6-26.3h5l1.8-10.6h-5l1.4-7.8h-7.5c-.4,1.5-1.1,2.8-1.7,4-.8,1.5-1.8,2.5-3,3.4-1.1.7-2.2,1-3.2,1.1l-1.8,9.8h3.8l-5.2,29.2c-1.6,8.9.7,11.5,8.9,11.5h4.1l1.8-10.4Z" />
          <path d="M314.4,43.4c.4-2.3,1-3.7,2.6-3.7s1.8,1.1,1.5,3.2l-6.9,39.4h10.6l7.2-41.1c1.3-7.5-.9-10.7-6.1-10.7s-6.1,2-7.5,5.4l3.5-19.8h-10.4l-11.7,66.2h10.4l6.8-38.8Z" />
        </g>
      </g>
    </svg>
  );
};

export { SwimSmoothLogoWide };
