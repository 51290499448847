import { Heading, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";
import { CssHistory } from "../../components/css/CssHistory";
import { breadcrumbLink } from "../../types/breadcrumb";

const CssHistoryPage = (props: any) => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({
      href: "/css/history",
      title: "CSS History",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading mb={5}>Critical Swim Speed History</Heading>
      <CssHistory />
    </VStack>
  );
};

export { CssHistoryPage };
