import { decodeJwt } from "jose";
import { Dispatch } from "react";
import { updateActivitiesForLinkingState } from "../app/features/activity/activitiesForLinkingSlice";
import { updateActivitiesState } from "../app/features/activity/activitiesSlice";
import { updateActivityState } from "../app/features/activity/activitySlice";
import { updateAffiliateState } from "../app/features/affiliate/affiliateSlice";
import { updateAuthState } from "../app/features/auth/authSlice";
import { updateCoachesState } from "../app/features/coach/coachesSlice";
import { updateCoachAthletesState } from "../app/features/coachAthletes/coachAthletesSlice";
import { updateInvitationsState } from "../app/features/invitations/invitationsSlice";
import { updateAreasState } from "../app/features/library/areasSlice";
import { updateCountryState } from "../app/features/location/countrySlice";
import { updateLocationState } from "../app/features/location/locationSlice";
import { updateSessionRouletteState } from "../app/features/session/sessionRouletteSlice";
import { updateSessionState } from "../app/features/session/sessionSlice";
import { updateSessionsState } from "../app/features/session/sessionsSlice";
import { updateSessionTypesState } from "../app/features/session/sessionTypesSlice";
import { updateVirtualSquadState } from "../app/features/session/virtualSquadSlice";
import { updateWbssWeeksState } from "../app/features/session/wbssWeeksSlice";
import { updateFitnessState } from "../app/features/training/fitnessSlice";
import { updateTrainingTimelineState } from "../app/features/trainingTimeline/trainingTimelineSlice";
import { updateUserState } from "../app/features/user/userSlice";
import { store } from "../app/store";
import { refresh, swap } from "../DataAccess/oauth";
import { getUserById } from "../DataAccess/users";
import {
  SSAccessJWTPayloadRaw,
  authorisationCheckResponse,
  authority,
  role,
} from "../types/auth";

const checkToken = async (
  accessToken: string | null,
  refreshToken?: string | null
): Promise<authorisationCheckResponse> => {
  let authenticated = false;
  let updated = false;
  let userId;
  let accessTokenExpiryDate = null;
  try {
    // Get the access token, if there is no access token we need to login
    if (accessToken) {
      // Decode the access token, if we have no payload or expiry then we need to log in
      const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
        accessToken
      ) as unknown as SSAccessJWTPayloadRaw;
      if (accessTokenPayload && accessTokenPayload.exp) {
        // Check the expiry, if the token has expired we will try the refresh token
        const accessTokenExpiry = accessTokenPayload.exp;
        accessTokenExpiryDate = new Date(
          accessTokenExpiry * 1000
        ).toISOString();
        const now = new Date();
        const nowMillis = now.getTime() / 1000;
        const isAuthenticated = accessTokenExpiry > nowMillis;
        if (isAuthenticated) {
          authenticated = true;
          userId = accessTokenPayload.user;
        } else {
          if (refreshToken) {
            const refreshResult = await refresh(refreshToken);
            console.log("Back", refreshResult);
            accessToken = refreshResult.data.access_token;
            // Success - continue
            authenticated = true;
            userId = refreshResult.data.userId;
            updated = true;
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
    // Go to the login page
  }
  return {
    updated,
    authenticated,
    accessToken,
    accessTokenExpiry: accessTokenExpiryDate,
    refreshToken: refreshToken ? refreshToken : null,
    userId,
  };
};

const checkRole = (
  accessToken: string | null | undefined,
  action: string,
  role: string
): boolean => {
  if (accessToken) {
    const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
      accessToken
    ) as unknown as SSAccessJWTPayloadRaw;

    const processedRoles = accessTokenPayload.roles.map((role: string) => {
      return JSON.parse(role);
    });

    const requestedRole = processedRoles.filter((r: role) => r.id === role);
    const adminRole = processedRoles.filter((r: role) => r.id === "admin");
    const superAdminRole = processedRoles.filter(
      (r: role) => r.id === "superadmin"
    );

    try {
      if (requestedRole.length >= 1) {
        // Requested role must contain requested action which must be granted
        const authorityAll = requestedRole[0].authorities.filter(
          (a: authority) => a.action === "*ALL"
        );
        if (authorityAll[0] && authorityAll[0].granted) {
          return true;
        }
        const authority = requestedRole[0].authorities.filter(
          (a: authority) => a.action === action
        );
        if (authority[0] && authority[0].granted) {
          return true;
        }
      }

      if (adminRole.length >= 1) {
        // Admin role action must still be granted but will be *ALL!
        const authorityAll = adminRole[0].authorities.filter(
          (a: authority) => a.action === "*ALL"
        );
        if (authorityAll[0] && authorityAll[0].granted) {
          return true;
        }
        const authority = adminRole[0].authorities.filter(
          (a: authority) => a.action === action
        );
        if (authority[0] && authority[0].granted) {
          return true;
        }
      }

      if (superAdminRole.length >= 1) {
        // Super Admin role action must still be granted but may be *ALL!
        const authorityAll = superAdminRole[0].authorities.filter(
          (a: authority) => a.action === "*ALL"
        );
        if (authorityAll[0] && authorityAll[0].granted) {
          return true;
        }
        const authority = superAdminRole[0].authorities.filter(
          (a: authority) => a.action === action
        );
        if (authority[0] && authority[0].granted) {
          return true;
        }
      }

      // If none of the above then Foxtrot Romeo Oscar
      return false;
    } catch (error: any) {
      //  Anything goes wrong default to Foxtrot Romea Oscar;
      return false;
    }
  }
  return false;
};

const getLevel = (accessToken: string | null | undefined): string => {
  if (accessToken) {
    const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
      accessToken
    ) as unknown as SSAccessJWTPayloadRaw;

    return accessTokenPayload.level;
  }
  return "Guidance";
};

const getLevelInSentence = (accessToken: string | null | undefined): string => {
  if (accessToken) {
    let prefix = "a";
    const level = getLevel(accessToken);
    if (level === "Understanding" || level === "Ultimate") {
      prefix = "an";
    }
    return prefix + " '" + level + "' subscription";
  }
  return "";
};

const checkLevel = (
  accessToken: string | null | undefined,
  level: string
): boolean => {
  if (accessToken) {
    const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
      accessToken
    ) as unknown as SSAccessJWTPayloadRaw;

    if (accessTokenPayload.level === level) {
      return true;
    }
  }
  return false;
};

const checkLevelInArray = (
  accessToken: string | null | undefined,
  levels: string[]
): boolean => {
  if (accessToken) {
    const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
      accessToken
    ) as unknown as SSAccessJWTPayloadRaw;
    if (levels && levels.includes(accessTokenPayload.level)) {
      return true;
    }
  }
  return false;
};

const forceRefreshToken = async (dispatch: any): Promise<void> => {
  const { authState } = store.getState();
  let refreshToken = authState.refresh;
  if (refreshToken) {
    const refreshResult = await refresh(refreshToken);
    clearState(dispatch, false);
    // Get the user object as it is likely changed too.
    if (authState.userId) {
      const userResponse = await getUserById(authState.userId);
      dispatch(updateUserState(userResponse));
    }
    // Write tokens and user state
    dispatch(
      updateAuthState({
        access: refreshResult.data.access_token,
        forceChangePassword: refreshResult.data.forceChangePassword,
      })
    );
  }
};

const backToAdmin = async (dispatch: any): Promise<void> => {
  const { authState } = store.getState();
  let refreshToken = authState.admin;
  if (refreshToken) {
    const swapResponse = await refresh(refreshToken);
    // Write tokens and user state
    dispatch(
      updateAuthState({
        access: swapResponse.data.access_token,
        refresh: refreshToken,
        admin: null,
        userId: swapResponse.data.userId,
        forceChangePassword: swapResponse.data.forceChangePassword,
      })
    );
    clearState(dispatch, false);
    // Get the user object as it is likely changed too.
    if (swapResponse.data.userId) {
      const userResponse = await getUserById(swapResponse.data.userId);
      dispatch(updateUserState(userResponse));
    }
  }
};

const swapToken = async (dispatch: any, alternate: string): Promise<void> => {
  const { authState } = store.getState();
  let refreshToken = authState.refresh;
  if (refreshToken) {
    const swapResponse = await swap(alternate);
    // Write tokens and user state
    dispatch(
      updateAuthState({
        access: swapResponse.access_token,
        refresh: swapResponse.refresh_token,
        admin: swapResponse.admin_token,
        userId: swapResponse.userId,
      })
    );
    clearState(dispatch, false);
    // Get the user object as it is likely changed too.
    if (swapResponse.userId) {
      const userResponse = await getUserById(swapResponse.userId);
      dispatch(updateUserState(userResponse));
    }
  }
};

const clearState = (dispatch: Dispatch<any>, includeAuth: boolean) => {
  if (includeAuth) {
    dispatch(updateAuthState(null));
  }
  dispatch(updateUserState(null));
  dispatch(updateActivitiesState(null));
  dispatch(updateActivitiesForLinkingState(null));
  dispatch(updateActivityState(null));
  dispatch(updateSessionTypesState(null));
  dispatch(updateSessionsState(null));
  dispatch(updateWbssWeeksState(null));
  dispatch(updateSessionState(null));
  dispatch(updateSessionRouletteState(null));
  dispatch(updateAreasState(null));
  dispatch(updateTrainingTimelineState(null));
  dispatch(updateLocationState(null));
  dispatch(updateCountryState(null));
  dispatch(updateCoachesState(null));
  dispatch(updateVirtualSquadState(null));
  dispatch(updateFitnessState(null));
  dispatch(updateCoachAthletesState(null));
  dispatch(updateInvitationsState(null));
  dispatch(updateAffiliateState(null));
};

export {
  backToAdmin,
  checkLevel,
  checkLevelInArray,
  checkRole,
  checkToken,
  clearState,
  forceRefreshToken,
  getLevel,
  getLevelInSentence,
  swapToken,
};
