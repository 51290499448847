import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  BoxProps,
  Button,
  Center,
  Heading,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import {
  finishTrainingTimelineById,
  progressTrainingTimelineById,
} from "../../DataAccess/trainingTimelines";
import { updateTrainingTimelineState } from "../../app/features/trainingTimeline/trainingTimelineSlice";
import { useAppDispatch } from "../../app/hooks";
import { trainingTimeline } from "../../types/trainingTimeline";
import { user } from "../../types/user";
import { OtherWeekEvent } from "./OtherWeekEvent";
import { OtherWeekSession } from "./OtherWeekSession";

interface NextWeekProps extends BoxProps {
  user: user;
  trainingTimeline: trainingTimeline;
  setLoadingTrainingTimeline: any;
}

const NextWeek: React.FC<NextWeekProps> = ({
  user,
  trainingTimeline,
  setLoadingTrainingTimeline,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const [isGenerating, setIsGenerating] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const generateRef = useRef(null);
  const dispatch = useAppDispatch();

  const progressTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    setIsGenerating(true);
    if (trainingTimeline) {
      if (trainingTimeline.data.status === "Active") {
        const ttl = await progressTrainingTimelineById(trainingTimeline._id);
        dispatch(updateTrainingTimelineState(ttl));
      }
    }
    setLoadingTrainingTimeline(false);
    setIsGenerating(false);
  };

  const finishTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    if (trainingTimeline) {
      if (trainingTimeline.data.status === "Active") {
        await finishTrainingTimelineById(trainingTimeline._id);
        dispatch(updateTrainingTimelineState(null));
      }
    }
    setLoadingTrainingTimeline(false);
  };

  return (
    <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
      <VStack w="full" mb={2}>
        {trainingTimeline.data.overview.remainingWeeks === 0 && (
          <Heading as="h4" size="md">
            It's Event Week
          </Heading>
        )}
        {trainingTimeline.data.overview.remainingWeeks > 0 && (
          <Heading as="h4" size="md">
            Next Week
          </Heading>
        )}
        {trainingTimeline.data.overview.remainingWeeks < 0 && (
          <Heading as="h4" size="md">
            Well Done
          </Heading>
        )}
      </VStack>
      {trainingTimeline.data.weeks.has.nextWeek && (
        <>
          <Heading as="h5" size="sm" mb={2}>
            {trainingTimeline.data.weeks.nextWeek?.comment.short}
          </Heading>
          <Text mb={5} noOfLines={1}>
            {trainingTimeline.data.weeks.nextWeek?.comment.long.map(
              (line: string) => {
                return line;
              }
            )}
          </Text>
          <Heading as="h5" size="sm" mb={2}>
            Sessions
          </Heading>
          {trainingTimeline.data.weeks.nextWeek?.sessions.map(
            (session: any, sessionIndex: number) => {
              if (session.type === "Session") {
                return (
                  <OtherWeekSession
                    key={session._id + "" + sessionIndex}
                    session={session}
                    past={false}
                  />
                );
              } else if (session.type === "Event") {
                return (
                  <OtherWeekEvent
                    key={session._id + "" + sessionIndex}
                    session={session}
                  />
                );
              }
              return <Text>Error</Text>;
            }
          )}
        </>
      )}
      {!trainingTimeline.data.weeks.has.nextWeek &&
        trainingTimeline.data.overview.remainingWeeks > 0 && (
          <>
            <Text mb={2}>
              Next week's sessions will be revealed each weekend.
            </Text>
            {trainingTimeline.type !== "*SPECIAL" && (
              <>
                <Text>
                  If your sessions are not showing, or you just can't wait, then
                  you can give it a nudge by hitting the button below. Please
                  bear in mind that generating sessions early will mean that not
                  all of this week's training will be taken into account.
                </Text>
                <Center my={5}>
                  <>
                    <Button
                      variant="warning"
                      px={10}
                      isLoading={isGenerating}
                      onClick={onOpen}
                    >
                      Generate Sessions
                    </Button>
                    <AlertDialog
                      isOpen={isOpen}
                      leastDestructiveRef={generateRef}
                      onClose={onClose}
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Cancel Subscription
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            Are you sure? Generating the sessions for your
                            training plan early may mean that all of your
                            efforts this week are not taken into account. You
                            can't, and we can't, undo this action afterwards.
                          </AlertDialogBody>

                          <AlertDialogFooter>
                            <Button ref={generateRef} onClick={onClose}>
                              Back
                            </Button>
                            <Button
                              variant="warning"
                              px={20}
                              isLoading={isGenerating}
                              onClick={progressTrainingTimeline}
                              ml={3}
                            >
                              Generate Sessions
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </>
                </Center>
              </>
            )}
          </>
        )}
      {!trainingTimeline.data.weeks.has.nextWeek &&
        trainingTimeline.data.overview.remainingWeeks <= 0 && (
          <>
            <Text mb={2}>
              Congratulations on reaching the last week of your training.
            </Text>
            <Text mb={2}>
              We hope you have enjoyed training and have achieved whatever goal
              you had in mind whether than was simply finishing, smashing a
              personal best or qualifying for something bigger.
            </Text>
            <Text>
              What's next? Simply finish your goal and you can start planning
              your next big adventure.
            </Text>
            <Center my={5}>
              <Button
                w={200}
                variant="success"
                onClick={finishTrainingTimeline}
              >
                Finish
              </Button>
            </Center>
          </>
        )}
    </Box>
  );
};

export { NextWeek };
