import { Box, BoxProps, Text } from "@chakra-ui/react";

interface SingleValueDisplayProps extends BoxProps {
  label: string;
  value: any;
}

const SingleValueDisplay: React.FC<SingleValueDisplayProps> = ({
  label,
  value,
}) => {
  return (
    <Box>
      <Text>{value}</Text>
      <Text fontSize="xs">{label}</Text>
    </Box>
  );
};

export { SingleValueDisplay };
