import { breadcrumbLink } from '../../types/breadcrumb';
import { createContext } from 'react';

export interface breadcrumbContextType {
  breadcrumbLinks: breadcrumbLink[] | null;
  setBreadcrumbLinks(breadcrumbLinks: breadcrumbLink[] | null): void
}

const BreadcrumbContext = createContext<breadcrumbContextType>({
  breadcrumbLinks: [],
  setBreadcrumbLinks: () => {}
});

export { BreadcrumbContext };