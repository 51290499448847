import { Navigate, useParams } from "react-router-dom";
import { LibraryCategory } from "../../components/library/LibraryCategory";

const LibraryCategoryPage = () => {
  const { area, category } = useParams<{ area: string; category: string }>();

  if (!area) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  if (!category) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  return <LibraryCategory areaId={area} categoryId={category} />;
};

export { LibraryCategoryPage };
