import { Heading, VStack } from "@chakra-ui/react";
import * as contentful from "contentful";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { checkLevelInArray } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { Contentful } from "../ui/Contentful";
import Loading from "../ui/Loading";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

interface LibraryEntityDirectProps {
  entityId: string;
}

const LibraryEntityDirect: React.FC<LibraryEntityDirectProps> = ({
  entityId,
}) => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const [linkedEntity, setLinkedEntity] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (linkedEntity) {
      const breadcrumbLinks = [
        { href: "/library", title: "Library" },
        {
          href: "/library/direct/" + entityId,
          title: linkedEntity.fields.title,
        },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    } else {
      const breadcrumbLinks = [
        { href: "/library", title: "Library" },
        { href: "/librarydirect/" + entityId, title: "-" },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedEntity]);

  useEffect(() => {
    const getEntityLocal = async () => {
      const linkedResult = await client.getEntry(entityId);
      setLinkedEntity(linkedResult);
      setLoading(false);
    };
    getEntityLocal();
  }, [entityId]);

  if (loading) {
    return <Loading />;
  }

  if (!user || !linkedEntity) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  if (!checkLevelInArray(accessToken, linkedEntity.fields.levels)) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        {linkedEntity.fields.title}
      </Heading>
      <Contentful entity={linkedEntity} />
    </VStack>
  );
};

export { LibraryEntityDirect };
