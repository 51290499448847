import { VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { ActivityLink } from "../../components/activities/ActivityLink";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";

const ActivityLinkPage = () => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks = [
      { href: "/activities", title: "Activities" },
      { href: "/activities/link", title: "Link Activity" },
    ];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <ActivityLink />
    </VStack>
  );
};

export { ActivityLinkPage };
