import { Text, Tooltip, VStack } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { MdLabelOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SSMenuIcon } from "./SSMenuIcon";

interface SSMenuOptionProps {
  icon?: IconType;
  target?: string;
  onClick?: any;
  title: string;
  iconOnly?: boolean;
}

const SSMenuOption: React.FC<SSMenuOptionProps> = ({
  icon,
  target,
  onClick,
  title,
  iconOnly = false,
}) => {
  const navigate = useNavigate();

  if (typeof icon === "undefined") {
    icon = MdLabelOutline;
  }

  if (!onClick && target) {
    onClick = () => navigate(target, { replace: false });
  }

  let option;
  if (onClick) {
    option = (
      <VStack
        cursor="pointer"
        display="flex"
        minWidth={0}
        alignItems="center"
        onClick={onClick}
        mt={10}
        mx={4}
      >
        <SSMenuIcon icon={icon} iconOnly={iconOnly} />
        {!iconOnly && <Text pl={1}>{title}</Text>}
      </VStack>
    );
  } else {
    option = (
      <VStack display="flex" minWidth={0} alignItems="center" mt={10} mx={3}>
        <SSMenuIcon icon={icon} iconOnly={iconOnly} />
        {!iconOnly && <Text pl={1}>{title}</Text>}
      </VStack>
    );
  }

  if (iconOnly) {
    return (
      <Tooltip placement="top" label={title}>
        {option}
      </Tooltip>
    );
  }
  return option;
};

export { SSMenuOption };
