import { configureStore, Middleware } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/es/storage";
import { axiosMiddleware } from "../axiosSettings";
import * as slices from "./features"; // Assumes an index exporting all slices
import { ActivitiesForLinkingState } from "./features/activity/activitiesForLinkingSlice";
import { ActivitiesState } from "./features/activity/activitiesSlice";
import { ActivityState } from "./features/activity/activitySlice";
import { AffiliateState } from "./features/affiliate/affiliateSlice";
import { AuthState } from "./features/auth/authSlice";
import { CoachesState } from "./features/coach/coachesSlice";
import { CoachAthletesState } from "./features/coachAthletes/coachAthletesSlice";
import { InvitationsState } from "./features/invitations/invitationsSlice";
import { AreasState } from "./features/library/areasSlice";
import { CountryState } from "./features/location/countrySlice";
import { LocationState } from "./features/location/locationSlice";
import { SessionRouletteState } from "./features/session/sessionRouletteSlice";
import { SessionState } from "./features/session/sessionSlice";
import { SessionsState } from "./features/session/sessionsSlice";
import { SessionTypesState } from "./features/session/sessionTypesSlice";
import { VirtualSquadState } from "./features/session/virtualSquadSlice";
import { WbssWeeksState } from "./features/session/wbssWeeksSlice";
import { FitnessState } from "./features/training/fitnessSlice";
import { TrainingTimelineState } from "./features/trainingTimeline/trainingTimelineSlice";
import { UserState } from "./features/user/userSlice";

// Define a helper function to type the persisted reducer correctly
const createPersistedReducer = <T>(key: string, reducer: any) =>
  persistReducer<T>({ key, storage }, reducer);

// Define the expected structure of persistedReducers with each slice type
const persistedReducers = {
  authState: createPersistedReducer<AuthState>("authState", slices.authSlice),
  userState: createPersistedReducer<UserState>("userState", slices.userSlice),
  activitiesState: createPersistedReducer<ActivitiesState>(
    "activitiesState",
    slices.activitiesSlice
  ),
  activitiesForLinkingState: createPersistedReducer<ActivitiesForLinkingState>(
    "activitiesForLinkingState",
    slices.activitiesForLinkingSlice
  ),
  activityState: createPersistedReducer<ActivityState>(
    "activityState",
    slices.activitySlice
  ),
  sessionTypesState: createPersistedReducer<SessionTypesState>(
    "sessionTypesState",
    slices.sessionTypesSlice
  ),
  sessionsState: createPersistedReducer<SessionsState>(
    "sessionsState",
    slices.sessionsSlice
  ),
  wbssWeeksState: createPersistedReducer<WbssWeeksState>(
    "wbssWeeksState",
    slices.wbssWeeksSlice
  ),
  sessionState: createPersistedReducer<SessionState>(
    "sessionState",
    slices.sessionSlice
  ),
  sessionRouletteState: createPersistedReducer<SessionRouletteState>(
    "sessionRouletteState",
    slices.sessionRouletteSlice
  ),
  areasState: createPersistedReducer<AreasState>(
    "areasState",
    slices.areasSlice
  ),
  trainingTimelineState: createPersistedReducer<TrainingTimelineState>(
    "trainingTimelineState",
    slices.trainingTimelineSlice
  ),
  locationState: createPersistedReducer<LocationState>(
    "locationState",
    slices.locationSlice
  ),
  countryState: createPersistedReducer<CountryState>(
    "countryState",
    slices.countrySlice
  ),
  coachesState: createPersistedReducer<CoachesState>(
    "coachesState",
    slices.coachesSlice
  ),
  virtualSquadState: createPersistedReducer<VirtualSquadState>(
    "virtualSquadState",
    slices.virtualSquadSlice
  ),
  fitnessState: createPersistedReducer<FitnessState>(
    "fitnessState",
    slices.fitnessSlice
  ),
  coachAthleteState: createPersistedReducer<CoachAthletesState>(
    "coachAthletesState",
    slices.coachAthletesSlice
  ),
  invitationsState: createPersistedReducer<InvitationsState>(
    "invitationsState",
    slices.invitationsSlice
  ),
  affiliateState: createPersistedReducer<AffiliateState>(
    "affiliateState",
    slices.affiliateSlice
  ),
};

const middleware: Middleware[] = [axiosMiddleware as Middleware<{}, RootState>];

// Create the store
export const store = configureStore({
  reducer: persistedReducers,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types for serializable checks
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware),
});

// Persistor for persisted slices
export const persistor = persistStore(store);

// Helper types for type-checking
export type AppStore = typeof store;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
