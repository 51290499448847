import {
  cssEntry,
  cssEntryRedMistEntry,
  cssEntryResult,
  cssEntryTenWeekChallengeEntry,
  cssEntryTestEngine,
  cssPerformancePredictionEntry,
} from "../types/css";
import { getBeeperSettingForCSS, getBeeperSettingForRMCycle } from "./beeper";
import { dp, interp2, interp3 } from "./coreHelper";

const metresToYardsFactor = 0.9144;
const steadySTSSSpeedRatio = 0.9;
// const maxSTSSSpeedRatio = 2;

const normaliseCSS = (
  secondsPer100: number,
  lapLength: number
): cssEntryResult => {
  const secondsPer100N = convertPace({
    oldPace: secondsPer100,
    oldPoolLength: lapLength,
    newPoolLength: 50,
  });

  return {
    secondsPer100: secondsPer100N,
    secondsPer50: secondsPer100N / 2,
    secondsPer25: secondsPer100N / 4,
    secondsPerUnit: secondsPer100N / 100,
    unitsPerSecond: 100 / secondsPer100N,
  };
};

const calculateCSSFromTest = (tt400: number, tt200: number): number => {
  return dp((tt400 - tt200) / 2, 2);
};

const calculateCSSEngineFromTest = (
  tt400: number,
  tt200: number
): cssEntryTestEngine => {
  const dropOff = ((tt400 - tt200 * 2) / tt400) * 100;
  const avgPace400 = tt400 / 4;
  const avgPace200 = tt200 / 2;
  const dropOffPercent =
    avgPace400 > 0 ? 100 - (avgPace200 / avgPace400) * 100 : 0;
  let result = "";
  if (dropOff > 4) {
    result = "Petrol";
  } else {
    result = "Diesel";
  }

  return {
    result: result,
    calc: {
      dropOff: dropOff,
      dropOffPercent: dropOffPercent,
    },
  };
};

const calculateCSSPacingFromTest = (tt400: number, tt100: number): any => {
  const last300 = tt400 - tt100;
  const avgPace300 = last300 / 3;
  const avgPace100 = tt100;
  const dropOff = avgPace300 - avgPace100;
  const faster = dropOff * 3;
  const further = 300 - (300 * avgPace100 * 3) / last300;

  let result = "";
  if (dropOff < 2) {
    result = "Elite Level Pacing";
  } else if (dropOff < 5) {
    result = "Good Age-Group Standard Pacing";
  } else if (dropOff < 8) {
    result = "Average Squad Swimmer Pacing";
  } else if (dropOff < 13) {
    result = "Your Pacing is Really Holding You Back!";
  } else {
    result = "Your Pacing Needs Some SERIOUS attention!";
  }

  return {
    result: result,
    calc: {
      dropOff: dropOff,
      faster: faster,
      further: further,
      avgPace300: avgPace300,
      avgPace100: avgPace100,
    },
  };
};

const convertPace = (input: any): number => {
  // constrain pool length to a minimum of 10m
  input.oldPoolLength = Math.max(input.oldPoolLength, 10);
  input.newPoolLength = Math.max(input.newPoolLength, 10);

  const armLength = 0.65; // avg 65cm from front of head to hand

  // find turn time (defined as from when wall comes into touching distance to push off initiated)
  var turnTime = 1.5; // initialise with default value
  if (typeof input.turnTime != "undefined") {
    // turn time is specified so use this preferentially
    turnTime = input.turnTime;
  } else {
    // turn time not available so use oldPace to take an educated guess based on their swimming ability
    const paces = [60, 80, 100, 120, 140, 160];
    const turnTimes = [0.9, 1.2, 1.6, 2, 2.5, 2.7];
    turnTime = interp2(paces, turnTimes, input.oldPace);
  }

  const oldLapTimeLessTurn =
    (input.oldPace * input.oldPoolLength) / 100 - turnTime; // time spent swimming per lap in old pool

  const paceTable = {
    x: [10, 15, 20, 25, 30, 35, 40, 45, 50, 400], // pool length m
    y: [80, 90, 120], // pace
    z: [
      // time to get to given distance (normalised to 1 at 50m)
      [0.17, 0.275, 0.38, 0.483, 0.587, 0.691, 0.795, 0.897, 1.0, 8.238], // 80 sec/100m
      [0.163, 0.265, 0.37, 0.475, 0.578, 0.683, 0.789, 0.895, 1.0, 8.35], // 90 sec/100m
      [0.155, 0.255, 0.36, 0.465, 0.568, 0.673, 0.781, 0.89, 1.0, 8.49], // 120 sec/100m
    ],
  };

  const referenceTimeOldPool = interp3(
    paceTable,
    input.oldPoolLength - armLength,
    input.oldPace
  );
  const referenceTimeNewPool = interp3(
    paceTable,
    input.newPoolLength - armLength,
    input.oldPace
  );

  const newLapTimeLessTurn =
    (oldLapTimeLessTurn * referenceTimeNewPool) / referenceTimeOldPool;

  const newPace = ((newLapTimeLessTurn + turnTime) * 100) / input.newPoolLength; // seconds per 100m

  return newPace;
};

const convertCSS = (value: number, lapLength: number, lapUnit: string): any => {
  let cssResult = {
    m: {
      secondsPer100: 0,
      secondsPer50: 0,
      secondsPer25: 0,
      secondsPerUnit: 0,
      unitsPerSecond: 0,
    },
    y: {
      secondsPer100: 0,
      secondsPer50: 0,
      secondsPer25: 0,
      secondsPerUnit: 0,
      unitsPerSecond: 0,
    },
    n: {
      secondsPer100: 0,
      secondsPer50: 0,
      secondsPer25: 0,
      secondsPerUnit: 0,
      unitsPerSecond: 0,
    },
  };

  if (lapUnit === "m") {
    cssResult.m.secondsPer100 = value;
    cssResult.m.secondsPer50 = cssResult.m.secondsPer100 / 2;
    cssResult.m.secondsPer25 = cssResult.m.secondsPer100 / 4;
    cssResult.m.secondsPerUnit = cssResult.m.secondsPer100 / 100;
    cssResult.m.unitsPerSecond = 100 / cssResult.m.secondsPer100;

    cssResult.y.secondsPer100 = value * metresToYardsFactor;
    cssResult.y.secondsPer50 = cssResult.y.secondsPer100 / 2;
    cssResult.y.secondsPer25 = cssResult.y.secondsPer100 / 4;
    cssResult.y.secondsPerUnit = cssResult.y.secondsPer100 / 100;
    cssResult.y.unitsPerSecond = 100 / cssResult.y.secondsPer100;

    cssResult.n = normaliseCSS(cssResult.m.secondsPer100, lapLength);
  } else if (lapUnit === "y") {
    cssResult.y.secondsPer100 = value;
    cssResult.y.secondsPer50 = cssResult.y.secondsPer100 / 2;
    cssResult.y.secondsPer25 = cssResult.y.secondsPer100 / 4;
    cssResult.y.secondsPerUnit = value / 100;
    cssResult.y.unitsPerSecond = 100 / value;

    cssResult.m.secondsPer100 = value / metresToYardsFactor;
    cssResult.m.secondsPer50 = cssResult.m.secondsPer100 / 2;
    cssResult.m.secondsPer25 = cssResult.m.secondsPer100 / 4;
    cssResult.m.secondsPerUnit = cssResult.m.secondsPer100 / 100;
    cssResult.m.unitsPerSecond = 100 / cssResult.m.secondsPer100;

    cssResult.n = normaliseCSS(
      cssResult.m.secondsPer100,
      lapLength * metresToYardsFactor
    );
  }

  return cssResult;
};

const getPerformancePredictions = (
  css: cssEntry
): cssPerformancePredictionEntry => {
  let performancePredictions: cssPerformancePredictionEntry = {
    m: [],
    y: [],
  };

  const predictForM = [
    750, 1000, 1500, 1609, 1900, 3800, 5000, 10000, 17703, 19700, 34000,
  ];
  const predictForMNames = [
    "750m",
    "1000m",
    "1500m",
    "~1 Mile",
    "Half Iron",
    "Iron",
    "5km",
    "10km",
    "Windermere One Way",
    "Rottnest Channel",
    "English Channel",
  ];
  const predictForY = [
    750, 1000, 1500, 1760, 2077, 4156, 5468, 10936, 19360, 21544, 37183,
  ];
  const predictForYNames = [
    "750y",
    "1000y",
    "1500y",
    "~1 Mile",
    "Half Iron",
    "Iron",
    "5km",
    "10km",
    "Windermere One Way",
    "Rottnest Channel",
    "English Channel",
  ];

  // Always calculate these from NORMALISED PACE
  if (!css.n || css.n.secondsPer100 <= 0) {
    return performancePredictions;
  }

  for (let p = 0; p < predictForM.length; p++) {
    const prediction = predictionForDistance(
      css.m.unitsPerSecond,
      predictForM[p],
      predictForM[p],
      0
    );
    performancePredictions.m.push({
      title: predictForMNames[p],
      distanceLength: predictForM[p],
      distanceUnit: "m",
      prediction: prediction.time,
      secondsPer100: prediction.pace,
      secondsPer50: prediction.pace / 2,
      secondsPer25: prediction.pace / 4,
      beeper: getBeeperSettingForCSS(
        {
          lapLength: 50,
          lapUnit: "m",
        },
        {
          has: true,
          data: {
            m: {
              secondsPer100: prediction.pace,
              secondsPerUnit: prediction.pace / 100,
              unitsPerSecond: 100 / prediction.pace,
            },
            y: {
              secondsPer100: prediction.pace,
              secondsPerUnit: prediction.pace / 100,
              unitsPerSecond: 100 / prediction.pace,
            },
          },
        },
        0,
        null
      ),
    });
  }

  for (let p = 0; p < predictForY.length; p++) {
    const prediction = predictionForDistance(
      css.y.unitsPerSecond,
      predictForY[p],
      predictForM[p],
      0
    );
    performancePredictions.y.push({
      title: predictForYNames[p],
      distanceLength: predictForY[p],
      distanceUnit: "y",
      prediction: prediction.time,
      secondsPer100: prediction.pace,
      secondsPer50: prediction.pace / 2,
      secondsPer25: prediction.pace / 4,
      beeper: getBeeperSettingForCSS(
        {
          lapLength: 50,
          lapUnit: "y",
        },
        {
          has: true,
          data: {
            m: {
              secondsPer100: prediction.pace,
              secondsPerUnit: prediction.pace / 100,
              unitsPerSecond: 100 / prediction.pace,
            },
            y: {
              secondsPer100: prediction.pace,
              secondsPerUnit: prediction.pace / 100,
              unitsPerSecond: 100 / prediction.pace,
            },
          },
        },
        0,
        null
      ),
    });
  }

  return performancePredictions;
};

const getTenWeekChallenge = (css: cssEntry): cssEntryTenWeekChallengeEntry => {
  const decay = [
    1, 0.995, 0.994, 0.995, 0.995, 0.995, 0.994, 0.997, 0.997, 0.998,
  ];
  let tenWeekChallenge: cssEntryTenWeekChallengeEntry = {
    m: {
      summary: {
        start: 0,
        end: 0,
        improvement1500: 0,
        improvement1900: 0,
        improvement3800: 0,
        improvement5000: 0,
        improvement10000: 0,
      },
      weeks: [],
    },
    y: {
      summary: {
        start: 0,
        end: 0,
        improvement1500: 0,
        improvement1900: 0,
        improvement3800: 0,
        improvement5000: 0,
        improvement10000: 0,
      },
      weeks: [],
    },
  };

  // if we don't have a CSS time set we cannot make these calcs
  if (!css.n || css.n.secondsPer100 <= 0) {
    return tenWeekChallenge;
  }

  if (!css.y || css.y.secondsPer100 <= 0) {
    return tenWeekChallenge;
  }

  if (!css.m || css.m.secondsPer100 <= 0) {
    return tenWeekChallenge;
  }

  tenWeekChallenge.m.summary.start = css.m.secondsPer100;
  tenWeekChallenge.m.summary.end = css.m.secondsPer100;
  tenWeekChallenge.y.summary.start = css.y.secondsPer100;
  tenWeekChallenge.y.summary.end = css.y.secondsPer100;

  let cssM = css.m.unitsPerSecond;
  let cssY = css.y.unitsPerSecond;
  for (let w = 0; w < 10; w++) {
    cssM = cssM / decay[w];
    cssY = cssY / decay[w];
    tenWeekChallenge.m.weeks.push({
      week: w + 1,
      secondsPer100: 100 / cssM,
      secondsPer50: 100 / cssM / 2,
      secondsPer25: 100 / cssM / 4,
      beeper: getBeeperSettingForCSS(
        {
          lapLength: 50,
          lapUnit: "m",
        },
        {
          has: true,
          data: {
            m: {
              secondsPer100: 100 / cssM,
              secondsPerUnit: cssM / 100,
              unitsPerSecond: cssM,
            },
          },
        },
        0,
        null
      ),
    });
    tenWeekChallenge.y.weeks.push({
      week: w + 1,
      secondsPer100: 100 / cssY,
      secondsPer50: 100 / cssY / 2,
      secondsPer25: 100 / cssY / 4,
      beeper: getBeeperSettingForCSS(
        {
          lapLength: 50,
          lapUnit: "y",
        },
        {
          has: true,
          data: {
            m: {
              secondsPer100: 1,
              secondsPerUnit: 1,
              unitsPerSecond: 1,
            },
            y: {
              secondsPer100: 100 / cssY,
              secondsPerUnit: cssY / 100,
              unitsPerSecond: cssY,
            },
          },
        },
        0,
        null
      ),
    });
    tenWeekChallenge.m.summary.end = 100 / cssM;
    tenWeekChallenge.y.summary.end = 100 / cssY;
  }

  // Summary (M)
  tenWeekChallenge.m.summary.improvement1500 =
    15 * (tenWeekChallenge.m.summary.start - tenWeekChallenge.m.summary.end);
  tenWeekChallenge.m.summary.improvement1900 =
    19 * (tenWeekChallenge.m.summary.start - tenWeekChallenge.m.summary.end);
  tenWeekChallenge.m.summary.improvement3800 =
    38 * (tenWeekChallenge.m.summary.start - tenWeekChallenge.m.summary.end);
  tenWeekChallenge.m.summary.improvement5000 =
    50 * (tenWeekChallenge.m.summary.start - tenWeekChallenge.m.summary.end);
  tenWeekChallenge.m.summary.improvement10000 =
    100 * (tenWeekChallenge.m.summary.start - tenWeekChallenge.m.summary.end);
  // Summary (Y)
  tenWeekChallenge.y.summary.improvement1500 =
    15 * (tenWeekChallenge.y.summary.start - tenWeekChallenge.y.summary.end);
  tenWeekChallenge.y.summary.improvement1900 =
    19 * (tenWeekChallenge.y.summary.start - tenWeekChallenge.y.summary.end);
  tenWeekChallenge.y.summary.improvement3800 =
    38 * (tenWeekChallenge.y.summary.start - tenWeekChallenge.y.summary.end);
  tenWeekChallenge.y.summary.improvement5000 =
    50 * (tenWeekChallenge.y.summary.start - tenWeekChallenge.y.summary.end);
  tenWeekChallenge.y.summary.improvement10000 =
    100 * (tenWeekChallenge.y.summary.start - tenWeekChallenge.y.summary.end);

  return tenWeekChallenge;
};

const getRedMistPaces = (css: cssEntry): cssEntryRedMistEntry => {
  let rmPaces: cssEntryRedMistEntry = {
    m: [],
    y: [],
  };

  // if we don't have a CSS time set we cannot make these calcs
  if (!css.n || css.n.secondsPer100 <= 0) {
    return rmPaces;
  }

  if (!css.y || css.y.secondsPer100 <= 0) {
    return rmPaces;
  }

  if (!css.m || css.m.secondsPer100 <= 0) {
    return rmPaces;
  }

  for (let i = 0; i <= 10; i++) {
    const mSettings = getBeeperSettingForRMCycle(
      { lapDistance: 50, lapUnit: "m" },
      { data: css },
      i,
      null
    );
    rmPaces.m.push({
      rm: i,
      secondsPer100: mSettings.pace,
      secondsPer50: mSettings.pace / 2,
      secondsPer25: mSettings.pace / 4,
      beeper: mSettings,
    });
    const ySettings = getBeeperSettingForRMCycle(
      { lapDistance: 50, lapUnit: "y" },
      { data: css },
      i,
      null
    );
    rmPaces.y.push({
      rm: i,
      secondsPer100: ySettings.pace,
      secondsPer50: ySettings.pace / 2,
      secondsPer25: ySettings.pace / 4,
      beeper: ySettings,
    });
  }

  return rmPaces;
};

const getPaceBands = (css: cssEntry): any[] => {
  let paceBands: any[] = [];

  // if we don't have a CSS time set we cannot make these calcs
  if (!css.n || css.n.secondsPer100 <= 0) {
    return paceBands;
  }

  if (!css.y || css.y.secondsPer100 <= 0) {
    return paceBands;
  }

  if (!css.m || css.m.secondsPer100 <= 0) {
    return paceBands;
  }

  paceBands.push({
    name: "Easy",
    upper: {
      m: 360,
      n: 360,
      y: 360,
    },
    lower: {
      m: css.m.secondsPer100 + 10,
      y: css.y.secondsPer100 + 10,
      n: css.n.secondsPer100 + 10,
    },
  });

  paceBands.push({
    name: "Steady",
    upper: {
      m: css.m.secondsPer100 + 10,
      y: css.y.secondsPer100 + 10,
      n: css.n.secondsPer100 + 10,
    },
    lower: {
      m: css.m.secondsPer100 + 7,
      y: css.y.secondsPer100 + 7,
      n: css.n.secondsPer100 + 7,
    },
  });

  paceBands.push({
    name: "Red Mist",
    upper: {
      m: css.m.secondsPer100 + 7,
      y: css.y.secondsPer100 + 7,
      n: css.n.secondsPer100 + 7,
    },
    lower: {
      m: css.m.secondsPer100 + 3,
      y: css.y.secondsPer100 + 3,
      n: css.n.secondsPer100 + 3,
    },
  });

  paceBands.push({
    name: "CSS",
    upper: {
      m: css.m.secondsPer100 + 3,
      y: css.y.secondsPer100 + 3,
      n: css.n.secondsPer100 + 3,
    },
    lower: {
      m: css.m.secondsPer100 - 3,
      y: css.y.secondsPer100 - 3,
      n: css.n.secondsPer100 - 3,
    },
  });

  paceBands.push({
    name: "VO2max",
    upper: {
      m: css.m.secondsPer100 - 3,
      y: css.y.secondsPer100 - 3,
      n: css.n.secondsPer100 - 3,
    },
    lower: {
      m: css.m.secondsPer100 - 7,
      y: css.y.secondsPer100 - 7,
      n: css.n.secondsPer100 - 7,
    },
  });

  paceBands.push({
    name: "Sprint",
    upper: {
      m: css.m.secondsPer100 - 7,
      y: css.y.secondsPer100 - 7,
      n: css.n.secondsPer100 - 7,
    },
    lower: {
      m: 40,
      n: 40,
      y: 40,
    },
  });

  return paceBands;
};

const predictionForDistance = (
  cssUnitsPerSecond: number,
  distance: number,
  distanceForFactor: number,
  offset: number
): any => {
  let time = 0;
  let pace = 0;

  const paceFactorDistance = [
    100, 400, 750, 1000, 1500, 1900, 2800, 5000, 10000, 34000,
  ];
  const paceFactor = [
    0.95, 0.97, 0.98, 0.992, 1, 1.017, 1.05, 1.08, 1.125, 1.5,
  ];
  const paceFactorInterp = interp2(
    paceFactorDistance,
    paceFactor,
    distanceForFactor
  );

  if (cssUnitsPerSecond && cssUnitsPerSecond > 0) {
    pace = 100 / (cssUnitsPerSecond / paceFactorInterp);
    time = ((pace + offset) * distance) / 100;
  }

  return {
    pace: pace,
    time: time,
  };
};

const getManualActivitySTSS = (
  distance: number,
  distanceUnit: string,
  time: number,
  css: cssEntry
): number => {
  // When checking we will always use normalised CSS which is based on a 50m pool.
  // We will have to convert yards to metres.
  if (distanceUnit === "y") {
    distance = distance * metresToYardsFactor;
  }
  let manualActivitySTSS = 0;
  if (time > 0 && distance > 0) {
    // We've got distance and time so can work out an intensity (speedRatio)
    if (css && css.n && css.n.secondsPer100 > 0) {
      const speedRatio = css.n.secondsPer100 / ((100 / distance) * time);
      manualActivitySTSS = (Math.pow(speedRatio, 3.5) * time) / 36;
    }
  } else if (distance > 0) {
    // No time so work out sTSS assuming entire swim was at steady pace (90% of CSS)
    if (css && css && css.n && css.n.secondsPer100 > 0) {
      const activeTime =
        ((css.n.secondsPer100 / steadySTSSSpeedRatio) * distance) / 100;
      manualActivitySTSS =
        (Math.pow(steadySTSSSpeedRatio, 3.5) * activeTime) / 36;
    }
  }
  return dp(manualActivitySTSS, 1);
};

const checkPaceAgainstCss = (
  distance: number,
  distanceUnit: string,
  time: number,
  css: cssEntry
): any => {
  // When checking we will always use normalised CSS which is based on a 50m pool.
  // We will have to convert yards to metres.
  if (distanceUnit === "y") {
    distance = distance * metresToYardsFactor;
  }

  let removeLap = false;
  let tooFast = false;
  let tooSlow = false;

  let cssSecondsPer100;
  if (css && css && css.n.secondsPer100 > 0) {
    cssSecondsPer100 = css.n.secondsPer100;
  }
  if (time > 0 && distance > 0) {
    const secondsPer100 = (100 / distance) * time;

    // if 100m is faster than 40 seconds
    if (secondsPer100 < 40) {
      removeLap = true;
      tooFast = true;
    }

    // if lap is faster than their css * 0.6
    if (cssSecondsPer100 && secondsPer100 < cssSecondsPer100 * 0.6) {
      removeLap = true;
      tooFast = true;
    }

    // if lap is slower than 6 minutes
    if (secondsPer100 > 360) {
      removeLap = true;
      tooSlow = true;
    }

    // if lap is slower than css * 2.5
    if (cssSecondsPer100 && secondsPer100 > cssSecondsPer100 * 2.5) {
      removeLap = true;
      tooSlow = true;
    }
  } else {
    removeLap = true;
  }

  return {
    removeLap,
    tooFast,
    tooSlow,
  };
};

export {
  calculateCSSEngineFromTest,
  calculateCSSFromTest,
  calculateCSSPacingFromTest,
  checkPaceAgainstCss,
  convertCSS,
  convertPace,
  getManualActivitySTSS,
  getPaceBands,
  getPerformancePredictions,
  getRedMistPaces,
  getTenWeekChallenge,
  normaliseCSS,
  predictionForDistance,
};
