import {
  Box,
  GridItem,
  HStack,
  Image,
  SimpleGrid,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaSwimmer } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import { GoGraph } from "react-icons/go";
import { IoIosFlame } from "react-icons/io";
import {
  MdAdminPanelSettings,
  MdFace,
  MdLogin,
  MdOutlineLocalLibrary,
} from "react-icons/md";
import { TbPigMoney } from "react-icons/tb";
import { VscTools } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectCountryState,
  updateCountryState,
} from "../../app/features/location/countrySlice";
import {
  selectLocationState,
  updateLocationState,
} from "../../app/features/location/locationSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { checkRole, checkToken } from "../../utils/authHelper";
import { countryToCurrency, reverseGeocode } from "../../utils/locationHelper";
import Loading from "../ui/Loading";
import { SSMenuOption } from "./SSMenuOption";
import { UserMenu } from "./UserMenu";

const HeaderMain: React.FC = () => {
  const { access: accessToken, refresh: refreshToken } =
    useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const {
    latitude,
    longitude,
    lastUpdated: lastUpdatedLocation,
  } = useAppSelector(selectLocationState);
  const {
    country,
    currency,
    lastUpdated: lastUpdatedCountry,
  } = useAppSelector(selectCountryState);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLocation, setLoadingLocation] = useState<boolean>(true);
  const [loadingCountry, setLoadingCountry] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Get the users location
    const getLocationLocal = async () => {
      if ("geolocation" in navigator) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              if (position && position.coords) {
                dispatch(
                  updateLocationState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  })
                );
              } else {
                dispatch(updateLocationState(null));
              }
            },
            (error: any) => {
              dispatch(updateLocationState(null));
            }
          );
        } catch (error: any) {
          dispatch(updateLocationState(null));
        }
      } else {
        dispatch(updateLocationState(null));
      }
      setLoadingLocation(false);
    };
    if (lastUpdatedLocation) {
      const test = new Date(lastUpdatedLocation).getTime();
      const aDayAgo = new Date().getTime() - 24 * 1000 * 60 * 60;
      if (!latitude || !longitude || aDayAgo > test) {
        getLocationLocal();
      } else {
        setLoadingLocation(false);
      }
    } else {
      getLocationLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get the users location
    const getCountryLocal = async () => {
      if (user && latitude && longitude) {
        try {
          const country = await reverseGeocode(latitude, longitude);
          const currency = countryToCurrency(country);
          dispatch(
            updateCountryState({
              country: country,
              currency: currency,
            })
          );
        } catch (error: any) {
          setLoadingCountry(false);
        }
      } else {
        dispatch(updateCountryState(null));
      }
      setLoadingCountry(false);
    };
    if (lastUpdatedCountry) {
      const test = new Date(lastUpdatedCountry).getTime();
      const aDayAgo = new Date().getTime() - 24 * 1000 * 60 * 60;
      if (!country || !currency || aDayAgo > test) {
        getCountryLocal();
      } else {
        setLoadingCountry(false);
      }
    } else {
      getCountryLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, latitude, longitude]);

  useEffect(() => {
    const checkTokenLocal = async () => {
      if (accessToken && refreshToken) {
        const authCheckResponse = await checkToken(accessToken, refreshToken);
        setAuthenticated(authCheckResponse.authenticated);
      } else if (accessToken) {
        const authCheckResponse = await checkToken(accessToken);
        setAuthenticated(authCheckResponse.authenticated);
      } else {
        setAuthenticated(false);
      }
      setLoading(false);
    };
    checkTokenLocal();
  }, [accessToken, refreshToken, user]);

  const sizedMenuNoAuth = useBreakpointValue({
    base: (
      <Box
        w="full"
        display="flex"
        alignItems="flex-center"
        justifyContent="center"
      >
        <SSMenuOption
          icon={TbPigMoney}
          title="Pricing"
          target="/pricing"
          iconOnly
        />
      </Box>
    ),
    sm: (
      <Box
        w="full"
        display="flex"
        alignItems="flex-center"
        justifyContent="center"
      >
        <SSMenuOption icon={TbPigMoney} title="Pricing" target="/pricing" />
        <SSMenuOption
          icon={MdFace}
          title="Create Account"
          target="/account/create"
        />
        <SSMenuOption icon={MdLogin} title="Login" target="/" />
      </Box>
    ),
  });
  const sizedMenuAuthenticated = useBreakpointValue({
    base: (
      <Box
        w="full"
        display="flex"
        alignItems="flex-center"
        justifyContent="center"
      >
        <SSMenuOption
          icon={GoGraph}
          title="Progress"
          target="/progress"
          iconOnly
        />
        <SSMenuOption
          icon={FiTarget}
          title="Training"
          target="/training"
          iconOnly
        />
        <SSMenuOption
          icon={FaSwimmer}
          title="Activities"
          target="/activities"
          iconOnly
        />
        <SSMenuOption
          icon={IoIosFlame}
          title="Sessions"
          target="/sessions"
          iconOnly
        />
        <SSMenuOption
          icon={MdOutlineLocalLibrary}
          title="Library"
          target="/library"
          iconOnly
        />
        <SSMenuOption icon={VscTools} title="Tools" target="/tools" iconOnly />
        {(checkRole(accessToken, "access", "adminTools") ||
          checkRole(accessToken, "access", "coachesTools")) && (
          <SSMenuOption
            icon={MdAdminPanelSettings}
            title="Admin"
            target="/admintools"
            iconOnly
          />
        )}
      </Box>
    ),
    sm: (
      <Box
        w="full"
        display="flex"
        alignItems="flex-center"
        justifyContent="center"
      >
        <SSMenuOption icon={GoGraph} title="Progress" target="/progress" />
        <SSMenuOption icon={FiTarget} title="Training" target="/training" />
        <SSMenuOption
          icon={FaSwimmer}
          title="Activities"
          target="/activities"
        />
        <SSMenuOption icon={IoIosFlame} title="Sessions" target="/sessions" />
        <SSMenuOption
          icon={MdOutlineLocalLibrary}
          title="Library"
          target="/library"
        />
        <SSMenuOption icon={VscTools} title="Tools" target="/tools" />
        {(checkRole(accessToken, "access", "adminTools") ||
          checkRole(accessToken, "access", "coachesTools")) && (
          <SSMenuOption
            icon={MdAdminPanelSettings}
            title="Admin"
            target="/admintools"
          />
        )}
      </Box>
    ),
  });

  const sizedMenuLayout = useBreakpointValue({
    base: (
      <VStack w={"full"}>
        <HStack w={"full"} justifyContent={"center"} mt={5}>
          <Box
            cursor={"pointer"}
            onClick={() => {
              navigate("/", { replace: false });
            }}
          >
            <Image
              src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png"
              height={81}
            />
          </Box>
          <Box mx={4}>
            <UserMenu />
          </Box>
        </HStack>
        <Box mb={5}>
          {authenticated ? sizedMenuAuthenticated : sizedMenuNoAuth}
        </Box>
      </VStack>
    ),
    md: (
      <SimpleGrid columns={6} m={5} alignItems={"center"}>
        <GridItem colSpan={1}>
          <Box
            cursor={"pointer"}
            onClick={() => {
              navigate("/", { replace: false });
            }}
          >
            <Image
              src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png"
              height={101}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          {authenticated ? sizedMenuAuthenticated : sizedMenuNoAuth}
        </GridItem>
        <GridItem colSpan={1} display="flex" justifyContent={"right"}>
          <UserMenu />
        </GridItem>
      </SimpleGrid>
    ),
  });

  if (loading) {
    return <Loading message="Loading User" />;
  }

  if (loadingLocation) {
    return <Loading message="Loading Location" />;
  }

  if (loadingCountry) {
    return <Loading message="Loading Country & Currency" />;
  }

  return sizedMenuLayout;
};

export { HeaderMain };
