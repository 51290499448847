import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";

interface RequiredFieldProps extends BoxProps {}

const RequiredField: React.FC<RequiredFieldProps> = ({ ...rest }) => {
  const colour = useColorModeValue("ssNeonOrangeDark", "ssNeonOrangeLight");
  return (
    <Box as="span" color={colour} ml={1}>
      *
    </Box>
  );
};

export { RequiredField };
