import { Box, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import * as contentful from "contentful";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectAreasState,
  updateAreasState,
} from "../../app/features/library/areasSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { checkLevelInArray } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { Contentful, ContentfulBodyProcessor } from "../ui/Contentful";
import Loading from "../ui/Loading";
import { LibraryCategoryItem } from "./LibraryCategoryItem";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

interface LibraryCategoryProps {
  areaId: string;
  categoryId: string;
}

const LibraryCategory: React.FC<LibraryCategoryProps> = ({
  areaId,
  categoryId,
}) => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const { areas, lastUpdated } = useAppSelector(selectAreasState);
  const [loading, setLoading] = useState<boolean>(true);
  const [area, setArea] = useState<any | null>(null);
  const [category, setCategory] = useState<any | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (area && category) {
      const breadcrumbLinks = [
        { href: "/library", title: "Library" },
        { href: "/library/" + areaId, title: area.fields.title },
        {
          href: "/library/" + areaId + "/" + categoryId,
          title: category.fields.title,
        },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    } else {
      const breadcrumbLinks = [
        { href: "/library", title: "Library" },
        { href: "/library/" + areaId, title: "-" },
        { href: "/library/" + areaId + "/" + categoryId, title: "-" },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, category]);

  useEffect(() => {
    const getEntriesLocal = async () => {
      const entries = await client.getEntries({
        content_type: "area",
      });
      dispatch(
        updateAreasState({
          areas: entries.items.sort((a: any, b: any) => {
            return a.fields.order - b.fields.order;
          }),
        })
      );
    };
    if (lastUpdated) {
      const test = new Date(lastUpdated).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!areas || areas.length === 0 || anHourAgo > test) {
        getEntriesLocal();
      }
    } else {
      getEntriesLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedArea = areas.filter((a: any) => a.sys.id === areaId);
    if (selectedArea.length === 1) {
      setArea(selectedArea[0]);
      const selectedCategory = selectedArea[0].fields.categories.filter(
        (c: any) => c.sys.id === categoryId
      );
      if (selectedCategory.length === 1) {
        setCategory(selectedCategory[0]);
        setLoading(false);
      }
    }
  }, [areaId, categoryId, areas]);

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  if (!checkLevelInArray(accessToken, category.fields.levels)) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        {category.fields.title}
      </Heading>
      {category.fields.articles && (
        <VStack w={"full"} alignItems={"flex-start"}>
          <Text pb={4}>{category.fields.shortDescription}</Text>
          {category.fields && category.fields.body && (
            <Box>
              {category.fields.body.content.map(
                (c: any, contentIndex: number) => {
                  return (
                    <ContentfulBodyProcessor
                      key={contentIndex}
                      content={c}
                      contentIndex={contentIndex}
                    />
                  );
                }
              )}
            </Box>
          )}
          <SimpleGrid columns={[1, 1, 2, 2]} gap={[1, 1, 4, 4]} w="full">
            {category.fields.articles.map((a: any) => {
              return (
                <LibraryCategoryItem
                  key={a.sys.id}
                  user={user}
                  entityId={a.sys.id}
                />
              );
            })}
          </SimpleGrid>
        </VStack>
      )}
      {!category.fields.articles && <Contentful entity={category} />}
    </VStack>
  );
};

export { LibraryCategory };
