import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  ListItem,
  OrderedList,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { MultiSelect } from "chakra-multiselect";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import SingleSelectDropdown from "../../components/ui/SingleSelectDropdown";
import Toggle from "../../components/ui/Toggle";
import { checkRole } from "../../utils/authHelper";
import { createPermaToast, createToast } from "../../utils/toastHelper";

interface sessionEditFormDataFields {
  type: string;
  name: string;
  briefing: string;
  listInLibrary: boolean;
  levels: string[];
  status: number;
  tags: string[];
  previewImage?: File;
  whiteboardImage?: File;
}

const sessionTypes = [
  { value: "taper", label: "Taper Sessions" },
  { value: "openwaterskills", label: "Open Water Skills Sessions" },
  { value: "openwaterpool", label: "Open Water Sessions - Pool" },
  { value: "puretechnique", label: "Pure Technique Sessions" },
  { value: "techniqueendurance", label: "Technique Endurance Sessions" },
  { value: "css", label: "CSS Sessions" },
  { value: "redmistendurance", label: "Red Mist Endurance Sessions" },
  { value: "pinkmistendurance", label: "Pink Mist Endurance Sessions" },
  { value: "longaerobicinterval", label: "Long Aerobic Interval Sessions" },
  { value: "sprint", label: "Sprint Sessions" },
  { value: "test", label: "Test Sessions" },
  { value: "quickneasylunchtimeswims", label: "Quick'n'Easy Lunchtime Swims" },
];

const levelOptions = [
  { value: "guidance", label: "Guidance" },
  { value: "understanding", label: "Understanding" },
  { value: "routine", label: "Routine" },
  { value: "ultimate", label: "Ultimate" },
];
const tagOptions = [
  { value: "CSS", label: "CSS" },
  { value: "Red Mist", label: "Red Mist" },
  { value: "Technique", label: "Technique" },
  { value: "Endurance", label: "Endurance" },
  { value: "Test Session", label: "Test Session" },
  { value: "Drills", label: "Drills" },
  { value: "Breathing", label: "Breathing" },
  { value: "Body Position", label: "Body Position" },
  { value: "Leg Kick", label: "Leg Kick" },
  { value: "Posture", label: "Posture" },
  { value: "Alignment", label: "Alignment" },
  { value: "Rotation", label: "Rotation" },
  { value: "Arm Recovery", label: "Arm Recovery" },
  { value: "Catch", label: "Catch" },
  { value: "Pull Through", label: "Pull Through" },
  { value: "Rhythm", label: "Rhythm" },
  { value: "Timing", label: "Timing" },
  { value: "Open Water Skills", label: "Open Water Skills" },
  { value: "Other Skills", label: "Other Skills" },
  { value: "Learn to Swim", label: "Learn to Swim" },
  { value: "Easy", label: "Easy" },
  { value: "Moderate", label: "Moderate" },
  { value: "Hard", label: "Hard" },
];

const SessionEditorEditPage = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const previewImage = useRef<any>(null);
  const whiteboardImage = useRef<any>(null);
  const navigate = useNavigate();
  const toast = useToast();

  const {
    control: controlBasic,
    handleSubmit: handleSubmitBasic,
    reset: resetBasic,
    formState: { errors: errorsBasic },
  } = useForm({
    defaultValues: {
      type: "",
      name: "",
      briefing: "This is a test briefing",
      listInLibrary: true,
      levels: ["routine", "ultimate"] as string[],
      status: 100,
      tags: [] as string[],
      previewImage: undefined,
      whiteboardImage: undefined,
    },
    mode: "all",
  });

  const handleBasic = async (
    data: sessionEditFormDataFields
  ): Promise<void> => {
    toast.closeAll();
    try {
      // Do some stuff
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value) {
          if (key === "previewImage" || key === "whiteboardImage") {
            formData.append(key, value, value.name);
          } else {
            formData.append(key, value);
          }
        }
      });

      const response = await axios.post(
        process.env.REACT_APP_API_ROOT_URL + "files/relationships/bulk",
        formData
      );
      // handle the response
      createPermaToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              Bulk upload file succesfully uploaded (
              {response.data.messages.length} errors)
            </Text>
            {response.data.messages.length > 0 && (
              <OrderedList>
                {response.data.messages.map((message: string) => {
                  return <ListItem>{message}</ListItem>;
                })}
              </OrderedList>
            )}
          </CustomToast>
        );
      });
      if (previewImage.current) {
        previewImage.current.value = "";
      }
      if (whiteboardImage.current) {
        whiteboardImage.current.value = "";
      }
      resetBasic();
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>{error.message}</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  if (!checkRole(accessToken, "*ALL", "admin")) {
    return null;
  }

  if (!sessionId) {
    return <Navigate to={{ pathname: "/admin/sessions/editor" }} />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Session Editor Create
      </Heading>
      <Heading as={"h3"} size={"md"}>
        Session
      </Heading>
      <Grid
        templateColumns="repeat(24, 1fr)"
        gap={2}
        mb={4}
        as="form"
        onSubmit={handleSubmitBasic(handleBasic)}
        w="full"
        alignItems="flex-start"
      >
        <GridItem colSpan={6}>
          <FormControl>
            <FormLabel>
              Type
              {errorsBasic.type && (
                <span className="formError">{errorsBasic.type.message}</span>
              )}
            </FormLabel>
            <Controller
              control={controlBasic}
              name="type"
              rules={{ required: "Type is required" }}
              render={({ field }) => (
                <SingleSelectDropdown
                  name="type"
                  options={sessionTypes}
                  value={field.value}
                  onChange={field.onChange}
                  canClear={true}
                />
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={12}>
          <FormControl>
            <FormLabel>
              Name
              {errorsBasic.name && (
                <span className="formError">{errorsBasic.name.message}</span>
              )}
            </FormLabel>
            <Controller
              control={controlBasic}
              name="name"
              rules={{ required: "Name is required" }}
              render={({ field: { ref, ...restField } }) => (
                <Input {...restField} placeholder="Name" />
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormControl>
            <FormLabel>
              Status
              {errorsBasic.status && (
                <span className="formError">{errorsBasic.status.message}</span>
              )}
            </FormLabel>
            <Controller
              control={controlBasic}
              name="status"
              rules={{ required: "Status is required" }}
              render={({ field: { ref, ...restField } }) => (
                <Select {...restField}>
                  <option value={100}>Draft</option>
                  <option value={500}>Published</option>
                  <option value={900}>Archived</option>
                  <option value={999}>Deleted</option>
                </Select>
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={21}>
          <FormControl>
            <FormLabel>
              Briefing
              {errorsBasic.briefing && (
                <span className="formError">
                  {errorsBasic.briefing.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlBasic}
              name="briefing"
              render={({ field: { ref, value, ...restField } }) => (
                <Textarea {...restField} placeholder="Briefing">
                  {value}
                </Textarea>
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl textAlign={"right"}>
            <FormLabel textAlign={"right"}>
              List in Library
              {errorsBasic.tags && (
                <span className="formError">{errorsBasic.tags.message}</span>
              )}
            </FormLabel>
            <Controller
              control={controlBasic}
              name="listInLibrary"
              render={({ field }) => (
                <Toggle
                  value={field.value}
                  onChange={field.onChange} // Handle form control state
                />
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={12}>
          <FormControl>
            <FormLabel>
              Levels
              {errorsBasic.levels && (
                <span className="formError">{errorsBasic.levels.message}</span>
              )}
            </FormLabel>
            <Controller
              control={controlBasic}
              name="levels"
              rules={{ required: "At least one level is required" }}
              render={({ field: { ref, value, onChange, ...restField } }) => (
                <MultiSelect
                  options={levelOptions}
                  value={value}
                  duplicates={false}
                  onChange={onChange}
                />
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={12}>
          <FormControl>
            <FormLabel>
              Tags
              {errorsBasic.tags && (
                <span className="formError">{errorsBasic.tags.message}</span>
              )}
            </FormLabel>
            <Controller
              control={controlBasic}
              name="tags"
              render={({ field: { ref, value, onChange, ...restField } }) => (
                <MultiSelect
                  options={tagOptions}
                  value={value}
                  duplicates={false}
                  onChange={onChange}
                />
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={12}>
          <FormControl>
            <FormLabel>
              Preview Image
              {errorsBasic.previewImage && (
                <span className="formError">
                  {errorsBasic.previewImage.message}
                </span>
              )}
            </FormLabel>
            <FormControl>
              <Controller
                control={controlBasic}
                rules={{ required: false }}
                name="previewImage"
                render={({ field: { ref, value, onChange, ...restField } }) => (
                  <Input
                    {...restField}
                    onChange={(event) => {
                      if (event && event.target && event.target.files) {
                        onChange(event.target.files[0]);
                      }
                    }}
                    ref={previewImage}
                    type="file"
                    placeholder="Preview Image"
                  />
                )}
              />
            </FormControl>
          </FormControl>
        </GridItem>
        <GridItem colSpan={12}>
          <FormControl>
            <FormLabel>
              Whiteboard Image
              {errorsBasic.whiteboardImage && (
                <span className="formError">
                  {errorsBasic.whiteboardImage.message}
                </span>
              )}
            </FormLabel>
            <FormControl>
              <Controller
                control={controlBasic}
                rules={{ required: false }}
                name="whiteboardImage"
                render={({ field: { ref, value, onChange, ...restField } }) => (
                  <Input
                    {...restField}
                    onChange={(event) => {
                      if (event && event.target && event.target.files) {
                        onChange(event.target.files[0]);
                      }
                    }}
                    ref={whiteboardImage}
                    type="file"
                    placeholder="Whiteboard Image"
                  />
                )}
              />
            </FormControl>
          </FormControl>
        </GridItem>
        <GridItem colSpan={24}>
          <Center w={"full"}>
            <Text ml={1} color={"gray"} fontSize={"xs"}>
              Uploaded images must be either PNG, JPG or GIF and must be under
              300Kb in size.
            </Text>
          </Center>
        </GridItem>
        <GridItem colSpan={24}>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gap={2}
            mt={4}
            w="full"
            alignItems="flex-start"
          >
            <GridItem colSpan={2}>
              <Button
                variant={"warning"}
                onClick={() => {
                  navigate("/admin/sessions/editor", { replace: false });
                }}
              >
                Cancel
              </Button>
            </GridItem>
            <GridItem colSpan={2} textAlign={"right"}>
              <Button type="submit" mr={2}>
                Save
              </Button>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      {sessionId !== "*NEW" && (
        <Heading as={"h3"} size={"md"}>
          Versions
        </Heading>
      )}
      {sessionId !== "*NEW" && (
        <TableContainer mb={10} w={"full"}>
          <Table size="sm" w={"full"}>
            <Thead>
              <Tr>
                <Th>Venue</Th>
                <Th isNumeric>Lap Lengths</Th>
                <Th isNumeric>Lanes</Th>
                <Th isNumeric>Distance</Th>
                <Th isNumeric>Time</Th>
                <Th isNumeric>sTSS</Th>
                <Th>Status</Th>
                <Th isNumeric>Actions</Th>
              </Tr>
            </Thead>
            <Tfoot>
              <Tr>
                <Th>Venue</Th>
                <Th isNumeric>Lap Lengths</Th>
                <Th isNumeric>Lanes</Th>
                <Th isNumeric>Distance</Th>
                <Th isNumeric>Time</Th>
                <Th isNumeric>sTSS</Th>
                <Th>Status</Th>
                <Th isNumeric>Actions</Th>
              </Tr>
            </Tfoot>
            <Tbody>
              <Tr>
                <Td>Pool</Td>
                <Td isNumeric>25, 50</Td>
                <Td isNumeric>4</Td>
                <Td isNumeric>2800</Td>
                <Td isNumeric>80 - 85 mins</Td>
                <Td isNumeric>60.7</Td>
                <Td>Draft</Td>
                <Td isNumeric>
                  <Button mr={2} size={"sm"}>
                    Edit
                  </Button>
                  <Button mr={2} size={"sm"}>
                    Copy
                  </Button>
                  <Button size={"sm"} variant={"warning"}>
                    Delete
                  </Button>
                </Td>
              </Tr>
              <Tr>
                <Td>Pool</Td>
                <Td isNumeric>25, 50</Td>
                <Td isNumeric>2,3</Td>
                <Td isNumeric>2800</Td>
                <Td isNumeric>80 - 85 mins</Td>
                <Td isNumeric>60.7</Td>
                <Td>Draft</Td>
                <Td isNumeric>
                  <Button mr={2} size={"sm"}>
                    Edit
                  </Button>
                  <Button mr={2} size={"sm"}>
                    Copy
                  </Button>
                  <Button size={"sm"} variant={"warning"}>
                    Delete
                  </Button>
                </Td>
              </Tr>
              <Tr>
                <Td>Pool</Td>
                <Td isNumeric>25, 50</Td>
                <Td isNumeric>1</Td>
                <Td isNumeric>2800</Td>
                <Td isNumeric>80 - 85 mins</Td>
                <Td isNumeric>60.7</Td>
                <Td>Draft</Td>
                <Td isNumeric>
                  <Button mr={2} size={"sm"}>
                    Edit
                  </Button>
                  <Button mr={2} size={"sm"}>
                    Copy
                  </Button>
                  <Button size={"sm"} variant={"warning"}>
                    Delete
                  </Button>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </VStack>
  );
};

export { SessionEditorEditPage };
