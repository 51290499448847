import { Box, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { ucFirst } from "../../utils/coreHelper";

const CustomTooltipText: React.FC<TooltipProps<ValueType, NameType>> = ({
  active,
  payload,
  label,
}) => {
  const tooltipColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const tooltipBorderColorMode = useColorModeValue(
    "ssPrimaryDark",
    "ssPrimaryLight"
  );

  if (active && payload && payload.length) {
    return (
      <Box
        bg={tooltipColorMode}
        p={5}
        borderWidth={3}
        borderColor={tooltipBorderColorMode}
        borderRadius={5}
      >
        <Heading as="h5" size="small">
          {ucFirst(label)}
        </Heading>
        {payload.map((payload: any) => {
          return (
            <Text>
              {payload.name}: {payload.value}
            </Text>
          );
        })}
      </Box>
    );
  }

  return null;
};

export { CustomTooltipText };
