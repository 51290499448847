import { ComponentStyleConfig } from "@chakra-ui/react";

export const Header: ComponentStyleConfig = {
  parts: ["main"],
  baseStyle: (props: any) => ({
    main: {
      py: 2,
      px: 5,
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};
