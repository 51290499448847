import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { MessageDisplay } from "../../components/generic/MessageDisplay";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import { PasswordInput } from "../../components/ui/FormControls";
import Loading from "../../components/ui/Loading";
import {
  changeForgottenPassword,
  verifyForgottenPassword,
} from "../../DataAccess/passwords";
import { createToast } from "../../utils/toastHelper";

const ResetPasswordPage = () => {
  const toast = useToast();
  const [search] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [otc, setOtc] = useState<string | null>(null);
  const [otcValid, setOtcValid] = useState<boolean>(false);
  const [otcError, setOtcError] = useState<string | null>(null);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbLinks(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const verifyForgottenPasswordLocal = async () => {
      try {
        const otc = search.get("otc");
        if (!otc) {
          return;
        }
        await verifyForgottenPassword(otc);
        setOtc(otc);
        setOtcValid(true);
        setLoading(false);
      } catch (error: any) {
        setOtcError(error.message ? error.message : "OTC not valid");
        setLoading(false);
      }
    };
    verifyForgottenPasswordLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control: controlPassword,
    reset: resetPassword,
    handleSubmit: handleSubmitPassword,
    formState: {
      errors: errorsPassword,
      isSubmitting: isSubmittingPassword,
      isValid: isValidPassword,
      isDirty: isDirtyPassword,
    },
  } = useForm({
    defaultValues: {
      newPassword: "",
    },
    mode: "all",
  });

  const handlePassword = async (data: any): Promise<void> => {
    try {
      if (otc && data.newPassword) {
        await changeForgottenPassword(otc, data.newPassword);
        resetPassword();
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Change Password"}
              status={"Success"}
              toast={toast}
              toastId={props.id}
            >
              <Text>Your password has been succesfully updated.</Text>
            </CustomToast>
          );
        });
        navigate("/", { replace: false });
      }
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Change Password"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              Unable to change password settings, please contact support.
            </Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (otcError) {
    return (
      <MessageDisplay status="error" title="Unable to reset password">
        <Text>{otcError}</Text>
      </MessageDisplay>
    );
  }

  if (!otcValid) {
    return (
      <MessageDisplay status="error" title="Unable to reset password">
        <Text>
          The one time code provided does not appear to be valid. It may have
          expired or not be for your account.
        </Text>
      </MessageDisplay>
    );
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmitPassword(handlePassword)}
      w="full"
      alignItems="flex-start"
      mb={5}
    >
      <Heading as="h3" size="md">
        Password
      </Heading>
      <FormControl pb={1}>
        <FormLabel>
          New Password
          {errorsPassword.newPassword && (
            <span className="formError">
              {errorsPassword.newPassword.message}
            </span>
          )}
        </FormLabel>
        <Controller
          control={controlPassword}
          rules={{ required: "New Password is required" }}
          name="newPassword"
          render={({ field: { ref, ...restField } }) => (
            <PasswordInput autoComplete="new-password" {...restField} />
          )}
        />
      </FormControl>
      <Button
        isDisabled={!isDirtyPassword || !isValidPassword}
        isLoading={isSubmittingPassword}
        type="submit"
        mt={5}
      >
        Set New Password
      </Button>
    </VStack>
  );
};

export { ResetPasswordPage };
