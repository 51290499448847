import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  BoxProps,
  Button,
  HStack,
  Heading,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCoachByShortCode,
  updateCoachLocationsById,
} from "../../DataAccess/coaches";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { breadcrumbLink } from "../../types/breadcrumb";
import { coach, coachLocation } from "../../types/coach";
import { checkRole } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { MessageDisplay } from "../generic/MessageDisplay";
import Loading from "../ui/Loading";

interface CoachesEditProps extends BoxProps {
  coachShortCode: string;
}

const CoachEditLocations: React.FC<CoachesEditProps> = ({ coachShortCode }) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { user } = useAppSelector(selectUserState);
  const [loadingCoach, setLoadingCoach] = useState<boolean>(true);
  const [selectedCoach, setSelectedCoach] = useState<coach>();
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getCoachLocal = async () => {
      const coachResponse = await getCoachByShortCode(coachShortCode);
      setSelectedCoach(coachResponse);
      setLoadingCoach(false);
    };
    getCoachLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [
      { href: "/coaches", title: "Coaches" },
    ];
    if (selectedCoach) {
      if (selectedCoach.data.displayName) {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title: selectedCoach.data.displayName,
        });
      } else {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title:
            selectedCoach.data.firstName + " " + selectedCoach.data.lastName,
        });
      }
      breadcrumbLinks.push({
        href: "/coaches/" + selectedCoach.data.shortCode + "/edit/locations",
        title: "Edit Locations",
      });
    }
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoach]);

  if (loadingCoach) {
    return <Loading message="Loading Coach" />;
  }

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (
    !checkRole(accessToken, "*ALL", "superadmin") &&
    user._id !== selectedCoach?.data.user
  ) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>403: Not Authorised</Heading>
        <Text>
          We're terribly sorry but it would appear that you have tried to do
          something you are not authorised to do. Could be us, could be you ...
          if you are sure you should be able to do what you are trying to do
          then get in touch using the help button at the bottom left of the
          screen.
        </Text>
      </VStack>
    );
  }

  if (
    selectedCoach &&
    selectedCoach.data.shortCode &&
    selectedCoach.data.shortCode === coachShortCode
  ) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>{selectedCoach.data.displayName} Locations</Heading>
        <HStack>
          <Button
            onClick={() =>
              navigate("/coaches/" + selectedCoach.data.shortCode, {
                replace: false,
              })
            }
          >
            Back
          </Button>
          <Button
            onClick={() =>
              navigate(
                "/coaches/" +
                  selectedCoach.data.shortCode +
                  "/edit/locations/add",
                { replace: false }
              )
            }
          >
            Add Location
          </Button>
        </HStack>
        <SimpleGrid columns={[1, 1, 3, 3]} columnGap={5} rowGap={2} w="full">
          {selectedCoach.data.locations.map((location: coachLocation) => {
            return (
              <CoachEditLocationBox
                key={location.id}
                selectedCoach={selectedCoach}
                location={location}
                setSelectedCoach={setSelectedCoach}
              />
            );
          })}
        </SimpleGrid>
      </VStack>
    );
  }

  if (selectedCoach && !selectedCoach.data.shortCode) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not find coach!">
          <Text>Could not find coach {coachShortCode}.</Text>
        </MessageDisplay>
        <Button onClick={() => navigate("/coaches", { replace: false })}>
          See All Coaches
        </Button>
      </VStack>
    );
  }

  return <Loading />;
};

interface CoachesEditLocationBoxProps extends BoxProps {
  selectedCoach: coach;
  setSelectedCoach: any;
  location: coachLocation;
}

const CoachEditLocationBox: React.FC<CoachesEditLocationBoxProps> = ({
  selectedCoach,
  setSelectedCoach,
  location,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const deleteRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleDelete = async (id: string): Promise<void> => {
    setIsDeleting(true);
    if (selectedCoach) {
      const locations: coachLocation[] = selectedCoach.data.locations.filter(
        (location) => location.id !== id
      );
      const updatedCoach = await updateCoachLocationsById(
        selectedCoach.id,
        locations
      );
      setSelectedCoach(updatedCoach);
      setIsDeleting(false);
      onClose();
    }
  };

  return (
    <Box
      bg={boxColorMode}
      key={location.id}
      p={3}
      borderRadius={10}
      height="100%"
      mb={5}
      w="full"
    >
      <HStack mb={2}>
        <Button
          onClick={() => {
            navigate(
              "/coaches/" +
                selectedCoach.data.shortCode +
                "/edit/locations/edit/" +
                location.id,
              { replace: false }
            );
          }}
        >
          Edit
        </Button>
        <Spacer />
        <Button variant="warning" isLoading={isDeleting} onClick={onOpen}>
          Delete
        </Button>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={deleteRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Location
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={deleteRef} onClick={onClose}>
                  Back
                </Button>
                <Spacer />
                <Button
                  variant="warning"
                  px={20}
                  isLoading={isDeleting}
                  onClick={() => {
                    handleDelete(location.id);
                  }}
                  ml={3}
                >
                  Delete Location
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </HStack>
      <Heading as="h3" size="small">
        {location.name}
      </Heading>
      <Text>{location.type}</Text>
      <Text>{location.description}</Text>
      <Text>{location.w3w}</Text>
    </Box>
  );
};

export { CoachEditLocations };
