import { Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react"

interface messageDisplayProps {
  status: 'error' | 'warning' | 'info' | 'success';
  title: string;
  children: React.ReactNode;
}

const MessageDisplay = (props: messageDisplayProps) => {
  return (
    <Alert status={props.status}>
      <AlertIcon />
      <AlertTitle>{props.title}</AlertTitle>
      <AlertDescription>{props.children}</AlertDescription>
    </Alert>
  );
};

export { MessageDisplay }