import {
  Button,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { subscribeUser } from "../../DataAccess/users";
import { affiliate } from "../../types/affiliate";
import { invitation } from "../../types/invitation";
import { PasswordInput } from "../ui/FormControls";

interface SignupFormProps {
  affiliate: affiliate | null;
  invitation: invitation | null;
}

const SignupForm: React.FC<SignupFormProps> = ({ affiliate, invitation }) => {
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  const captchaRef = useRef<HCaptcha>(null);
  const { colorMode } = useColorMode();

  const {
    control: controlSignup,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      emailAddress: invitation ? invitation.data.recipientEmail : "",
      firstName: "",
      lastName: "",
      password: "",
      gdprRequired: 0,
      gdprMailing: 0,
      gdprMarketing: 0,
      hCaptcha: "",
      hToken: "",
    },
    mode: "all",
  });

  const handleSignup = async (data: any): Promise<void> => {
    try {
      const subscribeResponse = await subscribeUser({
        affiliateId: affiliate ? affiliate._id : "",
        invitationId: invitation ? invitation.id : "",
        emailAddress: invitation
          ? invitation.data.recipientEmail
          : data.emailAddress,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        gdprRequired: data.gdprRequired ? true : false,
        gdprMailing: data.gdprMailing ? true : false,
        gdprMarketing: data.gdprMarketing ? true : false,
        hToken: data.hToken,
      });
      if (subscribeResponse) {
        // Success - redirect to dashboard
        try {
          captchaRef.current?.resetCaptcha();
        } catch (error) {
          // Nothign doing
        }
        navigate("/progress", { replace: false });
      }
    } catch (error: any) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages.length > 0
      ) {
        setError(
          error.response.data.messages[0].message.user
            ? error.response.data.messages[0].message.user
            : "Unable to create account, please contact support."
        );
      } else {
        setError(
          error.message
            ? error.message
            : error
            ? error
            : "Unable to create account, please contact support."
        );
      }
    }
  };

  const handleHCaptchaVerificationSuccess = (token: string) => {
    if (token) {
      setValue("hToken", token);
    }
    trigger();
  };

  const handleHCaptchaExpire = () => {
    setValue("hToken", "");
    trigger();
  };

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit(handleSignup)}
      w="full"
      spacing={3}
      alignItems="flex-start"
    >
      <FormControl pb={1}>
        <FormLabel>
          Email Address
          {errors.emailAddress && (
            <span className="formError">{errors.emailAddress.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSignup}
          rules={{
            required: "Email address is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Email address is invalid",
            },
          }}
          name="emailAddress"
          render={({ field: { ref, ...restField } }) => (
            <Input
              {...restField}
              isReadOnly={
                invitation || (affiliate && affiliate.data.lockEmailAddress)
                  ? true
                  : false
              }
              isDisabled={
                invitation || (affiliate && affiliate.data.lockEmailAddress)
                  ? true
                  : false
              }
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          Password
          {errors.password && (
            <span className="formError">{errors.password.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSignup}
          rules={{ required: "Password is required" }}
          name="password"
          render={({ field: { ref, ...restField } }) => (
            <PasswordInput
              autoComplete="new-password"
              passwordrules="minlength: 8; required: lower; required: upper; required: digit; required: [-];"
              {...restField}
            />
          )}
        />
      </FormControl>

      <Heading as="h2" size="sm">
        Required
      </Heading>
      <FormControl pb={1}>
        <Controller
          control={controlSignup}
          rules={{
            required:
              "To open a Swim Smooth Guru account you must agree to the required consents",
          }}
          name="gdprRequired"
          render={({ field: { value, onChange } }) => (
            <Checkbox spacing={8} onChange={onChange} size={"lg"}>
              <Text fontSize={"xs"} mb={2}>
                I agree to the{" "}
                <Button fontSize={"xs"} variant="link">
                  terms of service
                </Button>{" "}
                and{" "}
                <Button fontSize={"xs"} variant="link">
                  privacy policy
                </Button>{" "}
                and the collection and processing of my health data in order to
                give feedback on my swimming.
              </Text>
            </Checkbox>
          )}
        />
      </FormControl>
      <Heading as="h2" size="sm">
        Recommended
      </Heading>
      <FormControl pb={1}>
        <Controller
          control={controlSignup}
          name="gdprMarketing"
          render={({ field: { onChange, value } }) => (
            <Checkbox spacing={8} onChange={onChange} size={"lg"}>
              <Text fontSize={"xs"} mb={2}>
                I am happy to help Swim Smooth in its mission to democratise
                access to exceptional swimming content and coaching for swimmers
                worldwide by allowing the use of my data for marketing and
                advertising purposes, including the creation of custom and
                lookalike audiences on advertising platforms.
              </Text>
            </Checkbox>
          )}
        />
      </FormControl>
      <Heading as="h2" size="sm">
        Sorry, it's one of those annoying spot the cat things!
      </Heading>
      <FormControl mt={3}>
        <Controller
          control={controlSignup}
          name="hCaptcha"
          render={({ field: { ref, ...restField } }) => (
            <Center>
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY as string}
                onVerify={(token, ekey) =>
                  handleHCaptchaVerificationSuccess(token)
                }
                onExpire={() => handleHCaptchaExpire()}
                onChalExpired={() => handleHCaptchaExpire()}
                onError={(event: string) => {
                  console.log("Error", event);
                }}
                ref={captchaRef}
                theme={colorMode}
              />
            </Center>
          )}
        />
      </FormControl>
      {error && <Text color="red">{error}</Text>}
      <Button
        isDisabled={!isValid}
        isLoading={isSubmitting}
        type="submit"
        w={"full"}
        mt={1}
      >
        Create Account
      </Button>
    </VStack>
  );
};

export { SignupForm };
