import { useColorModeValue } from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";

interface HtmlEditorProps {
  onChange: any;
  height: number;
  value?: string;
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({ onChange, height, value }) => {
  const skin = useColorModeValue("oxide", "oxide-dark");
  const contentCss = useColorModeValue("light", "dark");

  const onChangeWrapper = (value: string) => {
    onChange(value);
  };

  return (
    <Editor
      apiKey={"wekjwc8f3ufm30v6nr29h7s8aftb8eb9u115s5x61dm4cukj"}
      onEditorChange={onChangeWrapper}
      init={{
        skin: skin,
        content_css: contentCss,
        height: height,
        menubar: false,
        plugins: ["advlist", "lists", "visualblocks", "fullscreen"],
        toolbar: "undo redo | bold italic | numlist bullist outdent indent | removeformat",
      }}
      value={value ? value : ""}
    />
  );
};

export { HtmlEditor };
