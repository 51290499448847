import {
  Box,
  Button,
  HStack,
  Heading,
  SimpleGrid,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { getFitness } from "../../DataAccess/training";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectFitnessState,
  updateFitnessState,
  updateViewFitnessState,
} from "../../app/features/training/fitnessSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { FitnessAndFatigueGraph } from "../../components/training/FitnessAndFatigueGraph";
import Loading from "../../components/ui/Loading";
import { SingleValueDisplay } from "../../components/ui/SingleValueDisplay";
import { fitnessSummaryHistory } from "../../types/fitness";
import { checkRole } from "../../utils/authHelper";

const FitnessPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { fitness, view } = useAppSelector(selectFitnessState);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<fitnessSummaryHistory[]>([]);
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    const breadcrumbLinks = [
      { href: "/tools", title: "Tools" },
      { href: "/fitness", title: "Fitness" },
    ];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterFitnessData = (): fitnessSummaryHistory[] => {
    if (fitness) {
      if (view === "sevenDays") {
        setTitle("Last Seven Days");
        return fitness.history.slice(-7 - 42);
      } else if (view === "fourWeeks") {
        setTitle("Last Four Weeks");
        return fitness.history.slice(-28 - 42);
      } else if (view === "twelveWeeks") {
        setTitle("Last Twelve Weeks");
        return fitness.history.slice(-84 - 42);
      } else if (view === "sixMonths") {
        setTitle("Last Six Months");
        return fitness.history.slice(-(6 * 31) - 42);
      } else if (view === "lastTwelveMonths") {
        setTitle("Last Twelve Months");
        return fitness.history.slice(-365 - 42);
      }
      setTitle("Last 3 Years");
      return fitness.history;
    }
    return [];
  };

  const setView = (view: string): void => {
    dispatch(updateViewFitnessState(view));
  };

  useEffect(() => {
    const getFitnessAndFatigueLocal = async () => {
      const newFitness = await getFitness();
      dispatch(
        updateFitnessState({
          fitness: newFitness,
          view: view,
        })
      );
      setData(filterFitnessData());
      setLoading(false);
    };
    if (checkRole(accessToken, "read", "fitnessAndFatigue")) {
      getFitnessAndFatigueLocal();
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(filterFitnessData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  if (loading) {
    return <Loading message="Getting Fitness Data" />;
  }

  if (!fitness) {
    return <Loading message="Getting Fitness Data" />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Fitness ({title})
      </Heading>
      <SimpleGrid columns={[1, 1, 6, 6]} columnGap={5} rowGap={2} w={"full"}>
        <Button
          onClick={() => setView("sevenDays")}
          isDisabled={view === "sevenDays"}
        >
          Last Seven Days
        </Button>
        <Button
          onClick={() => setView("fourWeeks")}
          isDisabled={view === "fourWeeks"}
        >
          Last Four Weeks
        </Button>
        <Button
          onClick={() => setView("twelveWeeks")}
          isDisabled={view === "twelveWeeks"}
        >
          Last Twelve Weeks
        </Button>
        <Button
          onClick={() => setView("sixMonths")}
          isDisabled={view === "sixMonths"}
        >
          Last Six Months
        </Button>
        <Button
          onClick={() => setView("lastTwelveMonths")}
          isDisabled={view === "lastTwelveMonths"}
        >
          Last Twelve Months
        </Button>
        <Button
          onClick={() => setView("allTime")}
          isDisabled={view === "allTime"}
        >
          Last 3 Years
        </Button>
      </SimpleGrid>
      <HStack w={"full"} alignItems={"flex-start"}>
        <Spacer />
        <Box pr={8}>
          <SingleValueDisplay
            label={"Current Fitness"}
            value={fitness.fitness}
          />
        </Box>
        <Box pr={8}>
          <SingleValueDisplay
            label={"Current Fatigue"}
            value={fitness.fatigue}
          />
        </Box>
        <Box pr={8}>
          <SingleValueDisplay label={"Current Form"} value={fitness.form} />
        </Box>
        <Spacer />
      </HStack>
      <FitnessAndFatigueGraph data={data} locked={false} />
    </VStack>
  );
};

export { FitnessPage };
