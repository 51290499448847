import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { bsrEntry } from "../types/bsr";

const tweakBsr = async (value: number): Promise<any> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/bsr";
    const result = await axios.post(url, {
      type: "tweak",
      date: new Date(),
      value: value,
    });
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const createBsrManual = async (value: number): Promise<any> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/bsr";
    const result = await axios.post(url, {
      type: "manual",
      date: new Date(),
      value: value,
    });
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const getBsrCurrent = async (last?: string): Promise<bsrEntry> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/bsr/current";
    const result = await axios.get(url);
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const getBsrHistory = async (last?: string): Promise<bsrEntry[]> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/bsr/";
    const result = await axios.get(url);
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
  return [];
};

const deleteBsr = async (bsrId: string): Promise<void> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/bsr/" + bsrId;
    await axios.delete(url);
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

export { createBsrManual, deleteBsr, getBsrCurrent, getBsrHistory, tweakBsr };
