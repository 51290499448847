import {
  Box,
  BoxProps,
  Heading,
  Image,
  Text,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { coachData } from "../../types/coach";
import { CoachBadges } from "./CoachBadges";

interface CoachBoxProps extends BoxProps {
  coachData: coachData;
  global?: boolean;
}

const CoachBox: React.FC<CoachBoxProps> = ({ coachData, global, ...rest }) => {
  const styles = useMultiStyleConfig("CoachBox", {});
  const navigate = useNavigate();

  return (
    <Box
      cursor="pointer"
      __css={{ ...styles.main }}
      onClick={() =>
        navigate("/coaches/" + coachData.shortCode, { replace: false })
      }
      w="full"
      {...rest}
    >
      <Image
        src={
          coachData.images.length > 0
            ? coachData.images[0].src + "?w=500&h=500&f=face&fit=fill"
            : "https://images.ctfassets.net/50b15ahactsg/2bjEpuoUucy7OzxXezpZkt/2f92a378f22c69fbba09f6803b313ffd/460x0w.png?w=300&h=300&f=face&fit=fill"
        }
        alt={coachData.displayName}
        fit="cover"
      />

      <Box py={4} px={5}>
        <Heading
          fontWeight="bold"
          fontSize="lg"
          as="h4"
          lineHeight="tight"
          noOfLines={1}
        >
          {coachData.displayName}
        </Heading>

        <Text>{global ? "Swim Smooth" : coachData.brandName || "-"}</Text>
        <Text>{coachData.brandLocation || "-"}</Text>
        {coachData.distanceFromYou &&
          coachData.distanceFromYou > -1 &&
          coachData.distanceFromYou < 384400 && (
            <Text color="gray.500" fontSize="xs">
              ~{Number(Math.ceil(coachData.distanceFromYou)).toLocaleString()}
              km from you
            </Text>
          )}
      </Box>

      <CoachBadges tags={coachData.tags} myCoach={coachData.myCoach} />
    </Box>
  );
};

export { CoachBox };
