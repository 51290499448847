import { useParams } from "react-router-dom";
import { CoachEditLocationsEdit } from "../../components/coaches/CoachEditLocationsEdit";

const CoachEditLocationsEditPage = () => {
  const params = useParams<{ coachShortCode: string; locationId: string }>();

  if (!params.coachShortCode || !params.locationId) {
    return null;
  }

  return <CoachEditLocationsEdit coachShortCode={params.coachShortCode} locationId={params.locationId} />;
};

export { CoachEditLocationsEditPage };
