import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { completion } from "../types/completion";

const createCompletion = async (
  userId: string,
  activityId: string | null,
  sessionId: string | null,
  goalSessionId: string | null
): Promise<completion> => {
  try {
    let postData;
    if (activityId) {
      if (goalSessionId) {
        postData = {
          userId: userId,
          activityId: activityId,
          goalSessionId: goalSessionId,
        };
      } else if (sessionId) {
        postData = {
          userId: userId,
          activityId: activityId,
          sessionId: sessionId,
        };
      } else {
        throw new Error("Must provide either session or goal session id");
      }
    } else {
      if (goalSessionId) {
        postData = {
          userId: userId,
          goalSessionId: goalSessionId,
        };
      } else if (sessionId) {
        postData = {
          userId: userId,
          sessionId: sessionId,
        };
      } else {
        throw new Error("Must provide either session or goal session id");
      }
    }
    const result = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "completions",
      postData
    );
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const deleteCompletionById = async (completionId: string): Promise<void> => {
  try {
    await axios.delete(
      process.env.REACT_APP_API_ROOT_URL + "completions/" + completionId
    );
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

export { createCompletion, deleteCompletionById };
