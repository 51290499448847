import { Select } from "@chakra-ui/react";
import { regionDescription } from "../../utils/coachHelper";

const RegionSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="uk" value="uk">
        {regionDescription("uk")}
      </option>
      <option key="europe" value="europe">
        {regionDescription("europe")}
      </option>
      <option key="americas" value="americas">
        {regionDescription("americas")}
      </option>
      <option key="asiaoceania" value="asiaoceania">
        {regionDescription("asiaoceania")}
      </option>
      <option key="africa" value="africa">
        {regionDescription("africa")}
      </option>
      <option key="other" value="other">
        {regionDescription("other")}
      </option>
      <option key="" value="">
        None
      </option>
    </Select>
  );
};

const GenderSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "U";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="F" value="F">
        Female
      </option>
      <option key="M" value="M">
        Male
      </option>
      <option key="N" value="N">
        Non-Binary
      </option>
      <option key="T" value="T">
        Transgender
      </option>
      <option key="I" value="I">
        Intersex
      </option>
      <option key="O" value="O">
        Other
      </option>
      <option key="U" value="U">
        I prefer not to say
      </option>
    </Select>
  );
};

const SwimTypeSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "U";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="ARNIE" value="ARNIE">
        Arnie
      </option>
      <option key="BAMBINO" value="BAMBINO">
        Bambino
      </option>
      <option key="OVERGLIDER" value="OVERGLIDER">
        Overglider
      </option>
      <option key="KICKTASTIC" value="KICKTASTIC">
        Kicktastic
      </option>
      <option key="SMOOTH" value="SMOOTH">
        Smooth
      </option>
      <option key="SWINGER" value="SWINGER">
        Swinger
      </option>
      <option key="U" value="U">
        I don't know
      </option>
    </Select>
  );
};

const ActivityTypeSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "U";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="BEGINNER" value="BEGINNER">
        I am just learning to swim freestyle
      </option>
      <option key="OPENWATER" value="OPENWATER">
        I mostly swim for distance in open water
      </option>
      <option key="POOL" value="POOL">
        I swim laps in my local pool
      </option>
      <option key="LIFETIME" value="LIFETIME">
        I am 60+ and have been swimming all my life
      </option>
      <option key="TRIATHLETE" value="TRIATHLETE">
        I like to take part in triathlon events (Swim - Bike - Run)
      </option>
      <option key="ADVENTURE" value="ADVENTURE">
        I like to take part in 'Swim Run' events or similar
      </option>
      <option key="WILD" value="WILD">
        I am a wild swimmer and swim for the enjoyment of my surroundings
      </option>
      <option key="COACH" value="COACH">
        I am a coach or teacher
      </option>
      <option key="U" value="U">
        Not Set
      </option>
    </Select>
  );
};

const TrainingTypeSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "U";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="RECREATIONAL" value="RECREATIONAL">
        Recreational - just for the love of it
      </option>
      <option key="HEALTH" value="HEALTH">
        Health & Wellbeing - to improve my health and wellbeing
      </option>
      <option key="FITNESS" value="FITNESS">
        Fitness - to improve my fitness
      </option>
      <option key="COMPETITIVE" value="COMPETITIVE">
        Competitive - to compete, even if only occasionally
      </option>
      <option key="U" value="U">
        Not Set
      </option>
    </Select>
  );
};

const LevelSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "U";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="LEARNING" value="LEARNING">
        Learning - I can barely swim a length
      </option>
      <option key="BEGINNER" value="BEGINNER">
        Beginner - I can swim, but I'm quite slow
      </option>
      <option key="INTERMEDIATE" value="INTERMEDIATE">
        Intermediate - I'm confident but would like to improve
      </option>
      <option key="ADVANCED" value="ADVANCED">
        Advanced - I'm a strong swimmer with good technique
      </option>
      <option key="ELITE" value="ELITE">
        Elite - I race, or have raced, at a very high level
      </option>
      <option key="U" value="U">
        Not Set
      </option>
    </Select>
  );
};

const LaneSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "-1";
  const unit = typeof props.unit !== "undefined" ? props.unit : "m";
  const mPaces = [
    "I cannot swim consecutive laps yet",
    "slower than 2:00/100" + unit,
    "1:40/100" + unit + "  to 2:00/100" + unit,
    "1:30/100" + unit + "  to 1:39/100" + unit,
    "1:20/100" + unit + "  to 1:29/100" + unit,
    "1:11/100" + unit + " to 1:19/100" + unit,
    "faster than 1:11/100" + unit,
  ];
  const yPaces = [
    "I cannot swim consecutive laps yet",
    "slower than 1:50/100" + unit,
    "1:31/100" + unit + "  to 1:50/100" + unit,
    "1:22/100" + unit + "  to 1:30/100" + unit,
    "1:13/100" + unit + "  to 1:21/100" + unit,
    "1:05/100" + unit + " to 1:12/100" + unit,
    "faster than 1:05/100" + unit,
  ];
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="L0" value={0}>
        Lane 0 - {unit === "y" ? yPaces[0] : mPaces[0]}
      </option>
      <option key="L1" value={1}>
        Lane 1 - {unit === "y" ? yPaces[1] : mPaces[1]}
      </option>
      <option key="L2" value={2}>
        Lane 2 - {unit === "y" ? yPaces[2] : mPaces[2]}
      </option>
      <option key="L3" value={3}>
        Lane 3 - {unit === "y" ? yPaces[3] : mPaces[3]}
      </option>
      <option key="L4" value={4}>
        Lane 4 - {unit === "y" ? yPaces[4] : mPaces[4]}
      </option>
      <option key="L5" value={5}>
        Lane 5 - {unit === "y" ? yPaces[5] : mPaces[5]}
      </option>
      <option key="L6" value={6}>
        Lane 6 - {unit === "y" ? yPaces[6] : mPaces[6]}
      </option>
      <option key="-1" value={-1}>
        Not Set
      </option>
    </Select>
  );
};

const CountrySelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "";
  return (
    <Select value={value} onChange={props.onChange}>
      <option value="">Select Country ...</option>
      <option value="AF">Afghanistan</option>
      <option value="AL">Albania</option>
      <option value="DZ">Algeria</option>
      <option value="AS">American Samoa</option>
      <option value="AD">Andorra</option>
      <option value="AO">Angola</option>
      <option value="AI">Anguilla</option>
      <option value="AQ">Antarctica</option>
      <option value="AG">Antigua and Barbuda</option>
      <option value="AR">Argentina</option>
      <option value="AM">Armenia</option>
      <option value="AW">Aruba</option>
      <option value="AU">Australia</option>
      <option value="AT">Austria</option>
      <option value="AZ">Azerbaijan</option>
      <option value="BS">Bahamas</option>
      <option value="BH">Bahrain</option>
      <option value="BD">Bangladesh</option>
      <option value="BB">Barbados</option>
      <option value="BY">Belarus</option>
      <option value="BE">Belgium</option>
      <option value="BZ">Belize</option>
      <option value="BJ">Benin</option>
      <option value="BM">Bermuda</option>
      <option value="BT">Bhutan</option>
      <option value="BO">Bolivia</option>
      <option value="BA">Bosnia and Herzegowina</option>
      <option value="BW">Botswana</option>
      <option value="BV">Bouvet Island</option>
      <option value="BR">Brazil</option>
      <option value="IO">British Indian Ocean Territory</option>
      <option value="BN">Brunei Darussalam</option>
      <option value="BG">Bulgaria</option>
      <option value="BF">Burkina Faso</option>
      <option value="BI">Burundi</option>
      <option value="KH">Cambodia</option>
      <option value="CM">Cameroon</option>
      <option value="CA">Canada</option>
      <option value="CV">Cape Verde</option>
      <option value="KY">Cayman Islands</option>
      <option value="CF">Central African Republic</option>
      <option value="TD">Chad</option>
      <option value="CL">Chile</option>
      <option value="CN">China</option>
      <option value="CX">Christmas Island</option>
      <option value="CC">Cocos (Keeling) Islands</option>
      <option value="CO">Colombia</option>
      <option value="KM">Comoros</option>
      <option value="CG">Congo</option>
      <option value="CD">Congo, the Democratic Republic of the</option>
      <option value="CK">Cook Islands</option>
      <option value="CR">Costa Rica</option>
      <option value="CI">Cote d'Ivoire</option>
      <option value="HR">Croatia (Hrvatska)</option>
      <option value="CU">Cuba</option>
      <option value="CY">Cyprus</option>
      <option value="CZ">Czech Republic</option>
      <option value="DK">Denmark</option>
      <option value="DJ">Djibouti</option>
      <option value="DM">Dominica</option>
      <option value="DO">Dominican Republic</option>
      <option value="TP">East Timor</option>
      <option value="EC">Ecuador</option>
      <option value="EG">Egypt</option>
      <option value="SV">El Salvador</option>
      <option value="GQ">Equatorial Guinea</option>
      <option value="ER">Eritrea</option>
      <option value="EE">Estonia</option>
      <option value="ET">Ethiopia</option>
      <option value="FK">Falkland Islands (Malvinas)</option>
      <option value="FO">Faroe Islands</option>
      <option value="FJ">Fiji</option>
      <option value="FI">Finland</option>
      <option value="FR">France</option>
      <option value="FX">France, Metropolitan</option>
      <option value="GF">French Guiana</option>
      <option value="PF">French Polynesia</option>
      <option value="TF">French Southern Territories</option>
      <option value="GA">Gabon</option>
      <option value="GM">Gambia</option>
      <option value="GE">Georgia</option>
      <option value="DE">Germany</option>
      <option value="GH">Ghana</option>
      <option value="GI">Gibraltar</option>
      <option value="GR">Greece</option>
      <option value="GL">Greenland</option>
      <option value="GD">Grenada</option>
      <option value="GP">Guadeloupe</option>
      <option value="GU">Guam</option>
      <option value="GT">Guatemala</option>
      <option value="GN">Guinea</option>
      <option value="GW">Guinea-Bissau</option>
      <option value="GY">Guyana</option>
      <option value="HT">Haiti</option>
      <option value="HM">Heard and Mc Donald Islands</option>
      <option value="VA">Holy See (Vatican City State)</option>
      <option value="HN">Honduras</option>
      <option value="HK">Hong Kong</option>
      <option value="HU">Hungary</option>
      <option value="IS">Iceland</option>
      <option value="IN">India</option>
      <option value="ID">Indonesia</option>
      <option value="IR">Iran (Islamic Republic of)</option>
      <option value="IQ">Iraq</option>
      <option value="IE">Ireland</option>
      <option value="IL">Israel</option>
      <option value="IT">Italy</option>
      <option value="JM">Jamaica</option>
      <option value="JP">Japan</option>
      <option value="JO">Jordan</option>
      <option value="KZ">Kazakhstan</option>
      <option value="KE">Kenya</option>
      <option value="KI">Kiribati</option>
      <option value="KP">Korea, Democratic People's Republic of</option>
      <option value="KR">Korea, Republic of</option>
      <option value="KW">Kuwait</option>
      <option value="KG">Kyrgyzstan</option>
      <option value="LA">Lao People's Democratic Republic</option>
      <option value="LV">Latvia</option>
      <option value="LB">Lebanon</option>
      <option value="LS">Lesotho</option>
      <option value="LR">Liberia</option>
      <option value="LY">Libyan Arab Jamahiriya</option>
      <option value="LI">Liechtenstein</option>
      <option value="LT">Lithuania</option>
      <option value="LU">Luxembourg</option>
      <option value="MO">Macau</option>
      <option value="MK">North Macedonia</option>
      <option value="MG">Madagascar</option>
      <option value="MW">Malawi</option>
      <option value="MY">Malaysia</option>
      <option value="MV">Maldives</option>
      <option value="ML">Mali</option>
      <option value="MT">Malta</option>
      <option value="MH">Marshall Islands</option>
      <option value="MQ">Martinique</option>
      <option value="MR">Mauritania</option>
      <option value="MU">Mauritius</option>
      <option value="YT">Mayotte</option>
      <option value="MX">Mexico</option>
      <option value="FM">Micronesia, Federated States of</option>
      <option value="MD">Moldova, Republic of</option>
      <option value="MC">Monaco</option>
      <option value="MN">Mongolia</option>
      <option value="MS">Montserrat</option>
      <option value="MA">Morocco</option>
      <option value="MZ">Mozambique</option>
      <option value="MM">Myanmar</option>
      <option value="NA">Namibia</option>
      <option value="NR">Nauru</option>
      <option value="NP">Nepal</option>
      <option value="NL">Netherlands</option>
      <option value="AN">Netherlands Antilles</option>
      <option value="NC">New Caledonia</option>
      <option value="NZ">New Zealand</option>
      <option value="NI">Nicaragua</option>
      <option value="NE">Niger</option>
      <option value="NG">Nigeria</option>
      <option value="NU">Niue</option>
      <option value="NF">Norfolk Island</option>
      <option value="MP">Northern Mariana Islands</option>
      <option value="NO">Norway</option>
      <option value="OM">Oman</option>
      <option value="PK">Pakistan</option>
      <option value="PW">Palau</option>
      <option value="PA">Panama</option>
      <option value="PG">Papua New Guinea</option>
      <option value="PY">Paraguay</option>
      <option value="PE">Peru</option>
      <option value="PH">Philippines</option>
      <option value="PN">Pitcairn</option>
      <option value="PL">Poland</option>
      <option value="PT">Portugal</option>
      <option value="PR">Puerto Rico</option>
      <option value="QA">Qatar</option>
      <option value="RE">Reunion</option>
      <option value="RO">Romania</option>
      <option value="RU">Russian Federation</option>
      <option value="RW">Rwanda</option>
      <option value="KN">Saint Kitts and Nevis</option>
      <option value="LC">Saint LUCIA</option>
      <option value="VC">Saint Vincent and the Grenadines</option>
      <option value="WS">Samoa</option>
      <option value="SM">San Marino</option>
      <option value="ST">Sao Tome and Principe</option>
      <option value="SA">Saudi Arabia</option>
      <option value="SN">Senegal</option>
      <option value="SC">Seychelles</option>
      <option value="SL">Sierra Leone</option>
      <option value="SG">Singapore</option>
      <option value="SK">Slovakia (Slovak Republic)</option>
      <option value="SI">Slovenia</option>
      <option value="SB">Solomon Islands</option>
      <option value="SO">Somalia</option>
      <option value="ZA">South Africa</option>
      <option value="GS">South Georgia and the South Sandwich Islands</option>
      <option value="ES">Spain</option>
      <option value="LK">Sri Lanka</option>
      <option value="SH">St. Helena</option>
      <option value="PM">St. Pierre and Miquelon</option>
      <option value="SD">Sudan</option>
      <option value="SR">Suriname</option>
      <option value="SJ">Svalbard and Jan Mayen Islands</option>
      <option value="SZ">Swaziland</option>
      <option value="SE">Sweden</option>
      <option value="CH">Switzerland</option>
      <option value="SY">Syrian Arab Republic</option>
      <option value="TW">Taiwan, Province of China</option>
      <option value="TJ">Tajikistan</option>
      <option value="TZ">Tanzania, United Republic of</option>
      <option value="TH">Thailand</option>
      <option value="TG">Togo</option>
      <option value="TK">Tokelau</option>
      <option value="TO">Tonga</option>
      <option value="TT">Trinidad and Tobago</option>
      <option value="TN">Tunisia</option>
      <option value="TR">Turkey</option>
      <option value="TM">Turkmenistan</option>
      <option value="TC">Turks and Caicos Islands</option>
      <option value="TV">Tuvalu</option>
      <option value="UG">Uganda</option>
      <option value="UA">Ukraine</option>
      <option value="AE">United Arab Emirates</option>
      <option value="GB">United Kingdom</option>
      <option value="US">United States</option>
      <option value="UM">United States Minor Outlying Islands</option>
      <option value="UY">Uruguay</option>
      <option value="UZ">Uzbekistan</option>
      <option value="VU">Vanuatu</option>
      <option value="VE">Venezuela</option>
      <option value="VN">Viet Nam</option>
      <option value="VG">Virgin Islands (British)</option>
      <option value="VI">Virgin Islands (U.S.)</option>
      <option value="WF">Wallis and Futuna Islands</option>
      <option value="EH">Western Sahara</option>
      <option value="YE">Yemen</option>
      <option value="YU">Yugoslavia</option>
      <option value="ZM">Zambia</option>
      <option value="ZW">Zimbabwe</option>
    </Select>
  );
};

const PoolLengthSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "50";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="20" value="20">
        20
      </option>
      <option key="25" value="25">
        25
      </option>
      <option key="33" value="33">
        33
      </option>
      <option key="50" value="50">
        50
      </option>
    </Select>
  );
};

const PoolUnitSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "m";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="m" value="m">
        Metres
      </option>
      <option key="y" value="y">
        Yards
      </option>
    </Select>
  );
};

const TrainingTimelineUnitSelector = (props: any) => {
  const value = props.value ? props.value : "m";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="m" value="m">
        Metres
      </option>
      <option key="km" value="km">
        Kilometres
      </option>
      <option key="y" value="y">
        Yards
      </option>
      <option key="mile" value="mile">
        Miles
      </option>
    </Select>
  );
};

const CurrencySelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="currency_blank" value="">
        Select Currency ...
      </option>
      <option key="GBP" value="GBP">
        Great British Pound (GBP)
      </option>
      <option key="EUR" value="EUR">
        Euros (EUR)
      </option>
      <option key="AUD" value="AUD">
        Australian Dollars (AUD)
      </option>
      <option key="CAD" value="CAD">
        Canadian Dollars (CAD)
      </option>
      <option key="USD" value="USD">
        United States Dollars (USD)
      </option>
    </Select>
  );
};

const LocationTypeSelector = (props: any) => {
  const value = typeof props.value !== "undefined" ? props.value : "";
  return (
    <Select value={value} onChange={props.onChange}>
      <option key="location_type_blank" value="">
        Select Location Type ...
      </option>
      <option key="pool" value="pool">
        Pool
      </option>
      <option key="openwater" value="openwater">
        Open Water
      </option>
      <option key="endlesspool" value="endlesspool">
        Endless Pool
      </option>
      <option key="office" value="office">
        Office
      </option>
      <option key="other" value="other">
        Other
      </option>
    </Select>
  );
};

const PlanSelector = (props: any) => {
  return (
    <Select value={props.value} onChange={props.onChange}>
      <option key="plan_blank" value="">
        Select Plan ...
      </option>
      {props.plans.map((plan: any) => {
        if (plan.promotion) {
          return (
            <option key={plan.id} value={plan.id}>
              {plan.name} - {plan.promotion.price} {plan.currencyISOCode}{" "}
              (Usually {plan.price} {plan.currencyISOCode})
            </option>
          );
        }
        return (
          <option key={plan.id} value={plan.id}>
            {plan.name} - {plan.price} {plan.currencyISOCode}
          </option>
        );
      })}
    </Select>
  );
};

export {
  ActivityTypeSelector,
  CountrySelector,
  CurrencySelector,
  GenderSelector,
  LaneSelector,
  LevelSelector,
  LocationTypeSelector,
  PlanSelector,
  PoolLengthSelector,
  PoolUnitSelector,
  RegionSelector,
  SwimTypeSelector,
  TrainingTimelineUnitSelector,
  TrainingTypeSelector,
};
